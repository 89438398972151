<div class="admin_edit_overlay_container">
  <button mat-mini-fab type="button" class="close-button close-button--left" (click)="closeOverlay()" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="admin_edit_overlay_wrap">
    <div class="admin-icon">
      <mat-icon
        class="admin-icon"
        color="slate"
        svgIcon="streamline_filled:user-circle"
        *ngIf="userType == 'school_admin'"
      ></mat-icon>
    </div>
    <h2 class="h2 h2--bold">{{ admin?.firstname }} {{ admin?.lastname }}</h2>
    <div
      class="student-status-chip"
      [ngClass]="adminStatus == 'active' ? 'student-status-chip--active' : 'student-status-chip--inactive'"
      *ngIf="!editMode"
    >
      <p class="paragraph paragraph--bold paragraph--xs">
        {{
          adminStatus == 'active' ? translatedText.admin_edit_status_active : translatedText.admin_edit_status_inactive
        }}
      </p>
    </div>

    <div class="student-edit-button-wrap" *ngIf="!editMode && userType == 'school_admin'">
      <button mat-flat-button color="tertiary" type="button" (click)="updateAdminStatus()">
        <mat-icon svgIcon="streamline_outlined:pause-window"></mat-icon>
        {{ adminStatus == 'active' ? translatedText.admin_edit_set_inactive : translatedText.admin_edit_set_active }}
      </button>
      <button
        mat-flat-button
        *ngIf="admin?._id !== teacherId"
        color="tertiary"
        type="button"
        (click)="updateAdminRights()"
      >
        <mat-icon svgIcon="streamline_outlined:revoke-admin-rights"></mat-icon>
        {{ translatedText.admin_edit_revoke_admin_rights }}
      </button>
      <!-- <button mat-flat-button color="tertiary" type="button" (click)="updateAdminRights()">
            <mat-icon class="delete-admin-icon" svgIcon="streamline_outlined:delete-circle"></mat-icon>
            Löschen
        </button> -->
    </div>

    <div class="admin-edit-info" *ngIf="!editMode">
      <div class="personal-info">
        <div class="personal-info-header" [ngClass]="userType === 'teacher' ? 'personal-info-teacher-header' : ''">
          <p class="paragraph paragraph--bold headline">
            {{ translatedText.admin_edit_edit_personal_info }}
          </p>
          <button
            *ngIf="userType === 'teacher'"
            (click)="editPersonalInfo()"
            mat-icon-button
            color="filled"
            mat-button
            aria-label="edit"
          >
            <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon>
          </button>
        </div>
        <div>
          <div class="admin-edit-info-row">
            <p class="paragraph paragraph">
              {{ translatedText.admin_edit_salutation }}
            </p>
            <p class="paragraph paragraph--bold">
              {{ rForm.get('salutation').value }}
            </p>
          </div>
          <div class="admin-edit-info-row">
            <p class="paragraph paragraph">
              {{ translatedText.admin_edit_firstname }}
            </p>
            <p class="paragraph paragraph--bold">
              {{ rForm.get('firstname').value }}
            </p>
          </div>
          <div class="admin-edit-info-row">
            <p class="paragraph paragraph">
              {{ translatedText.admin_edit_lastname }}
            </p>
            <p class="paragraph paragraph--bold">
              {{ rForm.get('lastname').value }}
            </p>
          </div>
          <div class="admin-edit-info-row">
            <p class="paragraph paragraph">
              {{ translatedText.admin_edit_email_address }}
            </p>
            <p class="paragraph paragraph--bold">{{ admin?.email }}</p>
          </div>
        </div>
      </div>
      <div class="license-info">
        <p class="paragraph paragraph--bold headline">
          {{ translatedText.admin_edit_access_rights }}
        </p>
        <div>
          <div class="admin-edit-info-row">
            <p class="paragraph paragraph">
              {{ translatedText.admin_edit_role }}
            </p>
            <p class="paragraph paragraph--bold">
              {{ translatedText.admin_edit_admin }},
              {{ translatedText.admin_edit_teacher }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="admin-info-footer" *ngIf="!editMode">
      <button
        mat-flat-button
        color="primary"
        *ngIf="userType === 'school_admin'"
        (click)="editPersonalInfo()"
        type="submit"
      >
        <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon>{{ translatedText.admin_edit_edit_details }}
      </button>
    </div>

    <form [formGroup]="rForm" #formDirective="ngForm" *ngIf="editMode">
      <div class="row">
        <div class="col-md-12">
          <mat-label>{{ translatedText.admin_edit_salutation }}</mat-label>
          <mat-form-field class="example-full-width">
            <input
              matInput
              placeholder="{{ translatedText.admin_edit_salutation }}"
              aria-label="Anrede"
              [matAutocomplete]="auto"
              formControlName="salutation"
              required
            />
            <mat-icon
              class="register-icon"
              matTooltip="{{ translatedText.admin_edit_salutation_tooltip }}"
              svgIcon="streamline_outlined:hint"
            ></mat-icon>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let item of filteredItems | async" [value]="item">
                <span>{{ item }}</span>
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="rForm.get('salutation').hasError('required')">
              <!-- Please enter a salutation. -->
              {{ translatedText.admin_edit_salutation_error }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-label>{{ translatedText.admin_edit_firstname }}</mat-label>
          <mat-form-field>
            <input
              matInput
              formControlName="firstname"
              type="text"
              placeholder="{{ translatedText.admin_edit_firstname }}"
              required
            />
            <mat-error *ngIf="rForm.get('firstname').hasError('required')">
              {{ translatedText.admin_edit_firstname_error }}
            </mat-error>
            <mat-error *ngIf="rForm.get('firstname').hasError('email')">
              {{ translatedText.admin_edit_email_error }}
            </mat-error>
            <mat-error *ngIf="rForm.get('firstname').hasError('hasDigits')">
              {{ translatedText.admin_edit_hasDigits_error }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-label>{{ translatedText.admin_edit_lastname }}</mat-label>
          <mat-form-field>
            <input
              matInput
              formControlName="lastname"
              type="text"
              placeholder="{{ translatedText.admin_edit_lastname }}"
              required
            />
            <mat-error *ngIf="rForm.get('lastname').hasError('required')">
              {{ translatedText.admin_edit_lastname_error }}
            </mat-error>
            <mat-error *ngIf="rForm.get('lastname').hasError('hasDigits')">
              {{ translatedText.admin_edit_hasDigits_error }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-label>{{ translatedText.admin_edit_email_address }}</mat-label>
          <mat-form-field>
            <input matInput type="text" value="{{ admin?.email }}" [disabled]="true" />
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-label>{{ translatedText.admin_edit_role }}</mat-label>
          <mat-form-field>
            <input matInput type="text" [value]="translatedText.admin_edit_roles" [disabled]="true" />
          </mat-form-field>
        </div>
      </div>

      <div class="admin-info-footer">
        <button mat-flat-button color="secondary" (click)="backToPersonalInfo()" type="button">
          {{ translatedText.admin_edit_backbutton }}
        </button>
        <button
          mat-flat-button
          color="primary"
          (click)="submitForm(formDirective)"
          [disabled]="rForm.invalid"
          type="submit"
        >
          {{ translatedText.admin_edit_save }}
        </button>
      </div>
    </form>
  </div>
</div>
