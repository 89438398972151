import { DatePipe } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import moment, { Moment } from 'moment';
import {
  Observable,
  Subject,
  Subscription,
  map,
  startWith,
  takeUntil,
} from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ChangeStudentPasswordComponent } from 'src/app/helpers/change-student-password/change-student-password.component';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { StudentPdfOverlayInfoComponent } from 'src/app/helpers/student-pdf-overlay-info/student-pdf-overlay-info.component';
import { StudentService } from 'src/app/providers/student.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { StudentDeleteComponent } from '../student-delete/student-delete.component';
import { LicenseDistributionComponent } from 'src/app/license/license-distribution/license-distribution.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { AdminService } from 'src/app/providers/admin.service';

export interface Student {
  username?: string;
  avatarUrl?: string;
  _group?: any;
  language?: string;
  age?: string;
  reference_value?: number;
  status?: string;
  diagnostic?: any;
  licenses?: [];
  licenseInfo?: any;
}

// TODO: define interface
export interface DialogData {}

export const DATE_FORMAT = {
  parse: {
    dateInput: 'MM.YYYY',
  },
  display: {
    dateInput: 'MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface Languages {
  name: string;
  code: string;
}

@Component({
  selector: 'app-student-edit',
  templateUrl: './student-edit.component.html',
  styleUrls: ['./student-edit.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class StudentEditComponent implements OnInit, OnDestroy {
  rForm: FormGroup;
  id;
  student: Student;
  group;
  subscription: Subscription;
  languages: any = [];
  filteredOptions: Observable<Languages[]>;
  status: string = 'Aktiv';
  editMode: boolean = false;
  userType;
  studentTasks;
  isLoading: boolean = false;
  tasksLoaded: boolean = false;
  maxDate;
  translatedText: any;
  isFundinglicenseAvailable: boolean = true;
  optionsMenuOpened: boolean = false;
  studentLicenses: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  availableLicenses = {
    totalCountDiagnostic: 0,
    totalCountTraining: 0,
    totalCountLeftDiagnostic: 0,
    totalCountLeftTraining: 0,
  };
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData, // TODO: private or public?
    public dialog: MatDialog, // TODO: private or public?
    public snackBar: MatSnackBar, // TODO: private or public?
    private _formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private authService: AuthService,
    private studentService: StudentService,
    private translationService: TranslationService,
    private adminService: AdminService,
  ) {
    if (injectedData) {
      if (typeof injectedData['id'] !== 'undefined') {
        this.id = this.injectedData['id'];
      }
      if (typeof injectedData['editMode'] !== 'undefined') {
        this.editMode = this.injectedData['editMode'];
      }

      if (typeof injectedData['studentLicenses'] !== 'undefined') {
        this.studentLicenses = this.injectedData['studentLicenses'];
      }
      if (typeof injectedData['availableLicenses'] !== 'undefined') {
        this.availableLicenses = this.injectedData['availableLicenses'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.userType = this.authService.getType();
    const today = moment();
    this.maxDate = today;

    this.studentService
      .getStudentbyId(this.id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.student = res;
        this.studentTasks = res['_tasks'];
        this.group = this.adminService.sortGroups(res['_group']);
        this.tasksLoaded = true;
      });

    this.studentService.getLanguages().subscribe((res) => {
      this.languages = res;
      for (const la of this.languages) {
        la.translate =
          this.translatedText[
            this.languages.filter((obj) => obj.code == la.code)[0]?.code
          ];
      }
      this.filteredOptions = this.rForm.get('language').valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : '';
          return name
            ? this._filter(name as string)
            : this.languages
              ? this.languages?.slice()
              : [];
        }),
      );
    });

    this.initForm();
  }

  hasActiveLicense(licenses: any[], type: string): boolean {
    if (licenses) {
      for (const license of licenses) {
        // Check if the current license has the specified type_key and active is true
        if (license.type_key === type && license.active) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  checkLicenseAboutToExpired(licenses: any[], type: string): boolean {
    if (licenses) {
      const fourWeeksFromNow = new Date();
      fourWeeksFromNow.setDate(fourWeeksFromNow.getDate() + 28);
      licenses = licenses.filter(
        (item) =>
          item.type_key == type &&
          new Date(item.expiration_date) <= fourWeeksFromNow,
      );
      return licenses.length > 0 ? true : false;
    } else {
      return false;
    }
  }

  checkLicenseExistence(licenses: any[], type: string): boolean {
    if (licenses) {
      let count = 0;
      for (const license of licenses) {
        // Check if the current license has the specified type_key and active is true
        if (license.type_key === type) {
          count++;
        }
      }
      return count > 0 ? true : false;
    } else {
      return false;
    }
  }

  private _filter(value: string): Languages[] {
    const filterValue = value.toLowerCase();
    return this.languages.filter(
      (option) =>
        option.name.toLowerCase().startsWith(filterValue) ||
        option.translate.toLowerCase().startsWith(filterValue),
    );
  }

  displayFn(code) {
    return this.getLanguageByCode(code);
  }

  getLanguageByCode(code) {
    return this.languages != undefined
      ? this.translatedText[
          this.languages.filter((obj) => obj.code === code)[0]?.code
        ]
      : '';
  }

  setLanguageOption(value) {
    this.rForm.get('language').patchValue(value);
  }

  isOpened(event: any) {
    this.trigger.menuClosed.subscribe(() => this.optionsMenuOpened === false);
  }

  openStudentDeleteDialog(): void {
    let deleteDialog = this.dialog.open(StudentDeleteComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'student-delete-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'student-delete-overlay',
      data: {
        studentId: this.student['_id'],
        studentName: this.student['username'],
      },
    });
    // dialogRef.disableClose = true;
    deleteDialog
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res !== 'close') {
          deleteDialog.close();
        }
      });
  }

  openLicenseDistribution(element, template): void {
    let dialogRef = this.dialog.open(LicenseDistributionComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'license-add-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'license-transfer-backdrop',
      data: {
        template: template,
        studentId: element?._id,
        username: element?.username,
        avatarUrl: element?.avatarUrl,
        studentLicenses: element?.licenseInfo,
        availableLicenses: this.availableLicenses,
      },
    });
  }

  openPdfInfo(): void {
    let pdfInfo = this.dialog.open(StudentPdfOverlayInfoComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'group-delete-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'group-delete-overlay',
      data: {
        group: this.group,
        studentId: this.id,
      },
    });
    // dialogRef.disableClose = true;
    pdfInfo.afterClosed().subscribe((res) => {
      if (res !== 'close') {
        pdfInfo.close();
      }
    });
  }

  regeneratePdfForStudent() {
    this.isLoading = true;
    this.studentService
      .regeneratePdfForStudent(
        this.group._id,
        this.id,
        false,
        this.translatedText,
      )
      .subscribe({
        next: (response: any) => {
          if (response) {
            this.isLoading = false;
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let blob = new Blob(binaryData, { type: 'application/pdf' });
            let blobUrl = window.URL.createObjectURL(blob);
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
            const day = ('0' + currentDate.getDate()).slice(-2);
            let formattedDate = this.datePipe.transform(
              currentDate,
              `${year}${month}${day}_`,
            );
            let groupName =
              this.group?.grade +
              this.group?.stream +
              ' - ' +
              this.group?.school_year.slice(2);
            // create download object
            var element = document.createElement('a');
            element.setAttribute('href', blobUrl);
            element.setAttribute(
              'download',
              formattedDate + groupName + '.pdf',
            );
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          }
        },
        error: (error: any) => {
          let message = this.translatedText?.technical_error;
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      });
  }

  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>,
  ) {
    const element = this.rForm.get('age');
    const ctrlValue = element.value;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.rForm.get('age').patchValue(ctrlValue);
    datepicker.close();
  }

  initForm() {
    this.rForm = this._formBuilder.group({
      language: [
        '',
        [Validators.required, this.validatorRequireMatch.bind(this)],
      ],
      age: ['', Validators.required],
      reference_value: [''],
      password: [''],
      gender: ['', Validators.required],
    });

    this.subscription = this.studentService
      .getStudentbyId(this.id)
      .subscribe((student) => {
        if (student['age'] != '') {
          this.rForm.get('age').setValue(moment(student['age']));
        }
        this.rForm.get('language').setValue(student['language']);
        this.rForm.get('reference_value').setValue(student['reference_value']);
        this.rForm.get('gender').setValue(student['gender']);
      });
  }

  getGenderValue(gender) {
    if (gender === 'male') {
      return this.translatedText.student_edit_gender_male;
    } else if (gender === 'female') {
      return this.translatedText.student_edit_gender_female;
    } else if (gender === 'divers') {
      return this.translatedText.student_edit_gender_divers;
    } else {
      return '';
    }
  }

  validatorRequireMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (
      this.languages &&
      this.languages.find((obj) => obj.code === selection)
    ) {
      return null;
    }
    return { incorrect: true };
  }

  closeOverlay() {
    this.dialog.closeAll();
  }

  getAge(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  openChangePassword(): void {
    let dialogRef = this.dialog.open(ChangeStudentPasswordComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'change-password-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'change-password-backdrop',
      data: {
        studentId: this.id,
        group: this.getGroupName(this.student?._group),
        username: this.student?.username,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  updateStudentStatus(student) {
    let newStatus = student?.status == 'active' ? 'inactive' : 'active';
    let formData = {
      _id: this.id,
      status: newStatus,
    };
    this.studentService.updateStudent(formData).subscribe({
      next: () => {
        this.student.status = newStatus;
      },
      error: (error: any) => {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText?.technical_error,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }

  submitForm(form: FormGroupDirective) {
    if (this.rForm.invalid) {
      console.log(this.rForm.invalid);
      return;
    }

    let formData = {
      _id: this.id,
      age: this.rForm.value.age,
      language: this.rForm.value.language,
      gender: this.rForm.value.gender,
      reference_value: this.rForm.value.reference_value,
    };

    if (this.rForm.value.password !== '') {
      formData['password'] = this.rForm.value.password;
    }

    this.studentService.updateStudent(formData).subscribe({
      next: (res) => {
        //this.dialog.closeAll();
        this.rForm.get('age').setValue(moment(this.rForm.value.age));
        this.rForm.get('language').setValue(this.rForm.value.language);
        this.rForm
          .get('reference_value')
          .setValue(this.rForm.value.reference_value);
        this.student['age'] = this.rForm.get('age').value;
        this.editMode = false;
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          panelClass: 'snack-success',
          data:
            this.translatedText?.student_edit_success_text +
            ' ' +
            this.student.username +
            ' ' +
            this.translatedText?.student_edit_success_text_2,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
      error: (error: any) => {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText?.technical_error,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }

  editPersonalInfo() {
    this.editMode = true;
  }

  backToPersonalInfo() {
    this.editMode = false;
  }

  getGroupName(groups: any) {
    if (groups) {
      const recentGroup = this.adminService.sortGroups(groups);
      if (recentGroup) {
        return recentGroup;
      }
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
