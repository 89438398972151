import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { MaterialModule } from '../../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IndividualLearnersSelectionComponent } from './individual-learners-selection.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { TrialBannerModule } from '../../trial-phase/trial-banner/trial-banner.module';
import { HintBoxModule } from '../hint-box/hint-box.module';

@NgModule({
  declarations: [IndividualLearnersSelectionComponent],
  imports: [
    BrowserModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
    TrialBannerModule,
    HintBoxModule,
  ],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [IndividualLearnersSelectionComponent],
})
export class IndividualLearnersSelectionModule {}
