import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpEventType,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, Observable, throwError } from 'rxjs';

export interface QrCode {
  qrcode: string;
}
export interface Teacher {
  _id?: string;
  username?: string;
  progress?: number;
  readingPoints?: number;
  readingError?: number;
  speed?: string;
  gender?: string;
  age?: string;
  language?: string;
  _group?: any;
}

export interface TeacherData {
  data: Teacher[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}

export interface Student {
  _id?: string;
  username?: string;
  progress?: number;
  readingPoints?: number;
  readingError?: number;
  speed?: string;
  gender?: string;
  age?: string;
  language?: string;
}

export interface StudentData {
  data: Student[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}

export interface Group {
  _id?: string;
  name?: string;
  school_year?: string;
  _students?: any;
  _teacher?: any;
}

export interface GroupData {
  data: Group[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}

export interface LicenseData {
  data: Group[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}

export interface ClassDetailsForm {
  grade: string;
  stream: string;
  school_year: string;
  avatar: string;
  teachers: string[];
}

export interface SchoolYears {
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private evolutionApiUrl: string;

  private addClassFormStatus = new Subject();
  $addClassFormStatus = this.addClassFormStatus.asObservable();

  private addClassNextBtn = new Subject();
  $addClassNextBtn = this.addClassNextBtn.asObservable();

  constructor(private http: HttpClient) {
    this.evolutionApiUrl = environment.evolutionAPI;
  }

  updateClassFormStatus(payload: any) {
    if (payload) {
      this.addClassFormStatus.next(payload);
    }
  }

  moveNext(src: string) {
    this.addClassNextBtn.next(src);
  }

  // If we have more than one class we need to take recent one
  sortGroups(groups: any) {
    if (groups.length == 1) return groups[0];

    return groups.sort((a, b) => a.school_year > b.school_year)[
      groups.length - 1
    ];
  }

  readSingleTeacher(id: string) {
    return this.http
      .get<Teacher>(
        this.evolutionApiUrl + '/api/teacher/readSingleTeacher/' + id,
        { params: { readalizer_customer: environment.readalizer_customer } },
      )
      .pipe(map((data) => data));
  }

  createUser(userData) {
    return this.http
      .post<any>(this.evolutionApiUrl + '/api/user/a/create', userData, {
        params: { readalizer_customer: environment.readalizer_customer },
      })
      .pipe(tap((res) => console.log(res)));
  }

  // TODO: Does not get used?
  getUserList(
    userRole: string,
    page: number,
    limit: number,
    sortBy: string,
    sortDirection: number,
    searchQuery: string,
  ): Observable<TeacherData> {
    const params = new HttpParams()
      .set('userRole', userRole)
      .set('searchQuery', searchQuery)
      .set('page', String(page))
      .set('limit', String(limit))
      .set('sortBy', sortBy)
      .set('sortDirection', String(sortDirection))
      .set('readalizer_customer', environment.readalizer_customer);
    return this.http
      .get<TeacherData>(this.evolutionApiUrl + '/api/user/a/list', {
        params,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  getTeacherList(
    page: number,
    limit: number,
    sortBy: string,
    sortDirection: number,
    filterValues: any,
    schoolId?: any,
  ): Observable<TeacherData> {
    const params = new HttpParams()
      .set('filterValues', JSON.stringify(filterValues))
      .set('page', String(page))
      .set('limit', String(limit))
      .set('sortBy', sortBy)
      .set('sortDirection', String(sortDirection))
      .set('schoolId', schoolId)
      .set('readalizer_customer', environment.readalizer_customer);

    return this.http
      .get<TeacherData>(this.evolutionApiUrl + '/api/teacher/a/list', {
        params,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  getStudentList(
    page: number,
    limit: number,
    sortBy: string,
    sortDirection: number,
    searchQuery: string,
  ): Observable<StudentData> {
    const params = new HttpParams()
      .set('searchQuery', searchQuery)
      .set('page', String(page))
      .set('limit', String(limit))
      .set('sortBy', sortBy)
      .set('sortDirection', String(sortDirection))
      .set('readalizer_customer', environment.readalizer_customer);
    return this.http
      .get<StudentData>(this.evolutionApiUrl + '/api/student/a/list', {
        params,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  getGroupList(
    page: number,
    limit: number,
    sortBy: string,
    sortDirection: number,
    filterValues: any,
  ): Observable<GroupData> {
    const params = new HttpParams()
      .set('filterValues', JSON.stringify(filterValues))
      .set('page', String(page))
      .set('limit', String(limit))
      .set('sortBy', sortBy)
      .set('sortDirection', String(sortDirection))
      .set('readalizer_customer', environment.readalizer_customer);
    return this.http
      .get<TeacherData>(this.evolutionApiUrl + '/api/group/a/list', {
        params,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  getLanguages() {
    return this.http.get<Student>(
      this.evolutionApiUrl + '/api/student/languages',
      { params: { readalizer_customer: environment.readalizer_customer } },
    );
  }

  demoteSchoolAdminToTeacher(data) {
    // TODO: Fix hearders and json.stringify?
    return this.http.post<Teacher>(
      this.evolutionApiUrl +
        '/api/user/demoteSchoolAdminToTeacher/' +
        data.teacherId,
      JSON.stringify(data),
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        params: { readalizer_customer: environment.readalizer_customer },
      },
    );
  }

  upgradeTeachersToSchoolAdmin(ids) {
    const teacherIds = {
      teacherIds: ids,
    };
    // TODO: Fix hearders and json.stringify?
    return this.http.post<Teacher>(
      this.evolutionApiUrl + '/api/user/upgradeTeachersToSchoolAdmin',
      teacherIds,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        params: { readalizer_customer: environment.readalizer_customer },
      },
    );
  }

  teacherCsvImport(element: File, translatedText) {
    const formData = new FormData();
    formData.append('file', element);
    formData.append('translatedText', translatedText);

    return this.http
      .post<any>(
        this.evolutionApiUrl + '/api/teacher/teacherCsvImport',
        formData,
        {
          reportProgress: true,
          observe: 'events',
          params: { readalizer_customer: environment.readalizer_customer },
        },
      )
      .pipe(
        map((event) => {
          switch (event['type']) {
            case HttpEventType.UploadProgress:
              const progress = Math.round(
                (100 * event['loaded']) / event['total'],
              );
              return {
                status: 'progress',
                message: progress,
              };
            case HttpEventType.Response:
              return {
                status: 'finished',
                data: event['body'],
              };
            default:
              return {
                status: 'unhandled',
                type: event['type'],
              };
          }
        }),
      );
  }

  generateOTPTokenQR(): Observable<QrCode> {
    return this.http
      .get<QrCode>(this.evolutionApiUrl + '/api/user/generateOTPTokenQR', {
        params: { readalizer_customer: environment.readalizer_customer },
      })
      .pipe(catchError((err) => throwError(err)));
  }

  studentsCsvImport(element: File, studentsLength) {
    const formData = new FormData();
    formData.append('file', element);
    formData.append('studentsLength', studentsLength);

    return this.http
      .post<any>(
        this.evolutionApiUrl + '/api/student/studentsCsvImport',
        formData,
        {
          reportProgress: true,
          observe: 'events',
          params: { readalizer_customer: environment.readalizer_customer },
        },
      )
      .pipe(
        map((event) => {
          switch (event['type']) {
            case HttpEventType.UploadProgress:
              const progress = Math.round(
                (100 * event['loaded']) / event['total'],
              );
              return {
                status: 'progress',
                message: progress,
              };
            case HttpEventType.Response:
              return {
                status: 'finished',
                data: event['body'],
              };
            default:
              return {
                status: 'unhandled',
                type: event['type'],
              };
          }
        }),
      );
  }

  createTeachers(teachersData, translatedText, trialStatus) {
    const formData = {
      translatedText: translatedText,
      teachers: teachersData,
      trialStatus: trialStatus,
    };
    return this.http
      .post<any>(this.evolutionApiUrl + '/api/user/createTeachers',
                 formData,
                 { params: { readalizer_customer: environment.readalizer_customer }})
      .pipe(tap((res) => console.log(res)));
  }
  // New Year School Update API's
  updateNewYearClass(payload: any) {
    return this.http
      .post<any>(
        this.evolutionApiUrl + '/api/group/updateGroupOnSchoolYearChange',
        payload,
        { params: { readalizer_customer: environment.readalizer_customer } },
      )
      .pipe(tap((res) => console.log(res)));
  }

  schoolYearChangeCheck() {
    return this.http
      .get<any>(this.evolutionApiUrl + '/api/school/schoolYearChanged', {
        params: { readalizer_customer: environment.readalizer_customer },
      })
      .pipe(catchError((err) => throwError(err)));
  }
}
