import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { TaskService } from 'src/app/providers/task.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { DialogData } from 'src/app/record-rtc/record-rtc.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-task-deadline',
  templateUrl: './edit-task-deadline.component.html',
  styleUrls: ['./edit-task-deadline.component.scss'],
})
export class EditTaskDeadlineComponent implements OnInit {
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  public translatedText;
  public environment = environment;
  public isValidated: boolean = false;
  public deadlineSelector: FormGroup;
  public task;
  public studentId;
  public trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };
  public newSelected;
  @Output() taskVisibility = new EventEmitter<Date>();

  constructor(
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private authService: AuthService,
    private taskService: TaskService,
    private _formBuilder: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
  ) {
    this.deadlineSelector = this._formBuilder.group({
      selectedDate: ['', [Validators.required]],
    });

    if (injectedData) {
      if (typeof injectedData['task'] !== 'undefined') {
        this.task = this.injectedData['task'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.trialStatus = this.authService.getUserTrialStatus();
  }

  getSelectedDate(event) {
    this.newSelected = new Date(event);
    if (this.newSelected) {
      this.isValidated = true;
    }
  }

  updateTaskDeadline() {
    if (this.deadlineSelector.valid) {
      this.taskService
        .updateSingleTask({
          _id: this.task._id,
          deadline: this.newSelected,
        })
        .subscribe({
          next: (updatedElement) => {
            this.snackBar.openFromComponent(SuccessSnackbarComponent, {
              panelClass: 'snack-success',
              data: this.translatedText
                .admin_teacher_edit_task_deadline_success,
              duration: 3000,
              horizontalPosition: 'left',
            });
            this.dialog.closeAll();
          },
          error: (error) => {
            this.snackBar.openFromComponent(ErrorSnackbarComponent, {
              panelClass: 'snack-error',
              data: this.translatedText.admin_teacher_edit_task_deadline_error,
              duration: 3000,
              horizontalPosition: 'left',
            });
            this.dialog.closeAll();
          },
        });
    }
  }
}
