import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-processing-agreement',
  templateUrl: './order-processing-agreement.component.html',
  styleUrls: ['./order-processing-agreement.component.scss'],
})
export class OrderProcessingAgreementComponent implements OnInit {
  public environment = environment;
  constructor() {}

  ngOnInit() {}
}
