<div class="teacher-list-container">
  <div class="">
    <div class="teacher-content-container admin-group-list">
      <app-license-activation-banner></app-license-activation-banner>

      <app-classes-update-banner
        *ngIf="isSchoolYearUpdateRequired && updateRequiredGroupCount >= 1"
        [classesUpdateHeading]="translatedText.classes_year_update_heading"
        [classesUpdateDescription]="translatedText.classes_year_update_desc"
        [updateButton]="translatedText.classes_update"
      >
      </app-classes-update-banner>

      <app-trial-banner
        *ngIf="trialStatus.isActive"
        [trialHeadline]="translatedText.license_trial_title"
        [trialText]="translatedText.license_trial_description"
        [expiredHeadline]="translatedText.license_trial_title_expired"
        [expiredText]="translatedText.license_trial_description_expired"
        [trialStatus]="trialStatus"
        [showExpirationDate]="true"
        [showButtons]="true"
      ></app-trial-banner>

      <div class="admin-teacher-header">
        <div class="title_with_count">
          <h3 class="h3 h3--bold">
            {{ translatedText.admin_group_list_groups }}
          </h3>
          <span class="class_count"> {{ dataSource?.meta?.totalItems }} </span>
        </div>

        <div class="admin-class-list-licenses-count">
          <p class="admin-class-list-licenses-wrap license-expired" *ngIf="totalCountPending > 0">
            <mat-icon
              svgIcon="streamline_outlined:license_warning"
              [matTooltip]="translatedText.admin_group_list_table_header_license_activation"
            ></mat-icon>
          </p>
          <p class="admin-class-list-licenses-wrap diagnosis">
            <mat-icon class="pink-icon" svgIcon="streamline_outlined:target"></mat-icon>
            <span class="licenses-list-name"> {{ translatedText.admin_group_list_table_header_diagnostic }}</span>
            <span [countUp]="totalCountLeftDiagnostic" *ngIf="!isUnlimitedDiagnosticLicense">{{
              totalCountLeftDiagnostic
            }}</span>
            <span *ngIf="isUnlimitedDiagnosticLicense"> ∞ </span>
            <ng-container *ngIf="totalCountLeftDiagnosticExp > 0">
              |
              <span [countUp]="totalCountLeftDiagnosticExp">{{ totalCountLeftDiagnosticExp }}</span>
            </ng-container>
            <mat-icon
              *ngIf="hasExpiringLicenses"
              class="pink-icon"
              svgIcon="streamline_outlined:warning-triangle"
              [matTooltip]="translatedText.admin_group_list_license_expired_info"
            ></mat-icon>
          </p>
          <p class="admin-class-list-licenses-wrap training">
            <mat-icon class="green-icon" svgIcon="streamline_outlined:lightning"></mat-icon>
            <span class="licenses-list-name"> {{ translatedText.admin_group_list_table_header_training }}</span>
            <span [countUp]="totalCountLeftTraining">{{ totalCountLeftTraining }}</span>
            <ng-container *ngIf="totalCountLeftTrainingExp > 0">
              |
              <span [countUp]="totalCountLeftTrainingExp">{{ totalCountLeftTrainingExp }}</span>
            </ng-container>
            <mat-icon
              *ngIf="hasExpiringLicenses"
              [matTooltip]="translatedText.admin_group_list_license_expired_info"
              class="green-icon"
              svgIcon="streamline_outlined:warning-triangle"
            ></mat-icon>
          </p>
          <mat-icon
            [matTooltip]="translatedText.profile_management_available_license_info"
            class="green-icon"
            svgIcon="streamline_filled:info_empty"
          ></mat-icon>
        </div>
        <div class="admin-teacher-header_buttons">
          <button mat-flat-button color="primary" type="button" (click)="handleCreateGroupTrial()">
            <mat-icon svgIcon="streamline_outlined:add-circle"></mat-icon>
            {{ translatedText.admin_group_list_add_groups }}
          </button>
        </div>
      </div>

      <div class="teacher-list-filter-wrap">
        <div>
          <mat-form-field class="full-width search-teacher" floatLabel="never">
            <mat-label>{{ translatedText.admin_teacher_list_search }}</mat-label>
            <input
              matInput
              type="text"
              (input)="onSearchInputFilterChange($event)"
              placeholder="{{ translatedText.admin_teacher_list_search_placeholder }}"
            /><button mat-mini-fab class="not-filled">
              <mat-icon svgIcon="streamline_outlined:search-2"></mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="dropdown-select-with-arrow search-teacher">
            <mat-icon class="mat-select-arrow" svgIcon="streamline_outlined:mat-arrow-down"></mat-icon>
            <mat-label>{{ translatedText.admin_group_list_school_year_select }}</mat-label>
            <mat-select
              name="yearDetails"
              [(ngModel)]="selectedSchoolYear"
              (ngModelChange)="chooseSchoolYear($event)"
              [placeholder]="translatedText.admin_newyear_school_year_placeholder"
            >
              <mat-option *ngFor="let yearDetails of schoolYears" [value]="yearDetails.value">
                {{ translatedText.admin_group_list_filter_school_year + yearDetails.value.slice(2) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <mat-form-field class="full-width search-teacher" floatLabel="never">
          <mat-label>{{ translatedText.admin_teacher_list_search }}</mat-label>
          <input
            matInput
            type="text"
            (input)="onSearchInputFilterChange($event)"
            placeholder="{{ translatedText.admin_teacher_list_search_placeholder }}"
          /><button mat-mini-fab class="not-filled">
            <mat-icon svgIcon="streamline_outlined:search-2"></mat-icon>
          </button>
        </mat-form-field> -->
      </div>
      <div *ngIf="dataSource?.data.length > 0">
        <div class="table-container admin_group_table">
          <table
            mat-table
            [dataSource]="dataSource?.data"
            multiTemplateDataRows
            class="mat-elevation-z8 admin-teacher-table"
            matSort
            matSortDisableClear
            [matSortActive]="sortBy"
            [matSortDirection]="matSortDirection"
            (matSortChange)="onSortChange($event)"
          >
            <ng-container matColumnDef="name" sticky class="hiden-header">
              <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%">
                {{ translatedText.admin_group_list_table_header_group }}
              </th>
              <td mat-cell *matCellDef="let element" width="15%">
                <div class="class-names">
                  <mat-icon svgIcon="streamline_outlined:class-avatar"></mat-icon>
                  <div class="class-details">
                    <p class="paragraph license-count">{{ element.name }}</p>
                    <p class="paragraph learner-count">
                      {{ element._students.length }}
                      {{ translatedText.admin_dashboard_students }}
                    </p>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="test_license">
              <th mat-header-cell *matHeaderCellDef width="20%">
                <div class="license-info-tooltip">
                  {{ translatedText.admin_group_list_table_header_diagnostic }}
                  <mat-icon
                    matTooltip="{{ translatedText.admin_group_list_testLicenseText }}"
                    svgIcon="streamline_outlined:hint"
                  ></mat-icon>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" width="20%">
                <!--
                <div
                  class="licensed-map"
                  matTooltip="{{ translatedText.admin_group_list_number_of_student_without_license }}"
                  *ngIf="element?._teacher?.length > 0"
                >
                  <span class="licensed-count">
                    {{ this.classNoLicenseStudentsNumber[element._id] }}
                  </span>
                  <p class="paragraph licensed-status" *ngIf="userType != 'admin'">
                    {{ translatedText.admin_group_list_nomore_license }}
                  </p>
                </div>-->
              </td>
            </ng-container>

            <ng-container matColumnDef="teacher">
              <th mat-header-cell *matHeaderCellDef mat-sort-header width="40%">
                {{ translatedText.admin_group_list_table_header_teacher }}
              </th>
              <td mat-cell *matCellDef="let element" width="40%">
                <ng-container *ngIf="element?._teacher?.length > 0">
                  <div class="table_teacher" *ngFor="let teacher of element?._teacher">
                    <div
                      class="teacher_details"
                      [ngClass]="{
                        teacher_selected: teacher._user === userAccountId
                      }"
                    >
                      <mat-icon svgIcon="streamline_filled:teacher_name_avatar" *ngIf="teacher._user !== userAccountId">
                      </mat-icon>
                      <mat-icon svgIcon="streamline_filled:teacher_selected" *ngIf="teacher._user === userAccountId">
                      </mat-icon>
                      <p class="paragraph paragraph--s">
                        {{ teacher?.firstname }} {{ teacher?.lastname }}
                        <span *ngIf="teacher?._user.role == 'school_admin'">(A)</span>
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="element?._teacher?.length == 0">
                  <div class="empty-teacher">
                    <mat-icon class="warn-icon" svgIcon="streamline_outlined:prohibition-gray"></mat-icon>
                    <p class="paragraph paragraph--s disable-color">
                      {{ translatedText.admin_group_list_table_no_teacher }}
                    </p>
                  </div>
                </ng-container>
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="training_license">
            <th mat-header-cell *matHeaderCellDef>
              <div class="license-info-tooltip">
                {{ translatedText.admin_group_list_table_header_training }}
                <mat-icon
                  matTooltip="{{ translatedText.admin_group_list_trainingLicenseText }}"
                  svgIcon="streamline_outlined:hint"
                ></mat-icon>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <p class="paragraph paragraph--s" *ngIf="userType != 'admin'"></p>
            </td>
          </ng-container> -->

            <!-- <ng-container matColumnDef="type3_license">
                            <th mat-header-cell *matHeaderCellDef>Type #3 License</th>
                            <td mat-cell *matCellDef="let element"><p class="paragraph paragraph--s"></p></td>
                        </ng-container> -->

            <ng-container matColumnDef="schoolUpdated">
              <th mat-header-cell *matHeaderCellDef class="display_none" width="25%"></th>
              <td mat-cell *matCellDef="let element" width="25%">
                <button
                  *ngIf="showUpdateButton(element) && element?.grade !== 10"
                  mat-flat-button
                  color="primary"
                  type="button"
                  (click)="newSchoolYearOverlay(element); $event.stopPropagation()"
                >
                  {{ translatedText.admin_newyear_school_group_list_btn }}
                </button>
                <div class="tag-not-updated" *ngIf="showUpdateButton(element) && element?.grade === 10">
                  {{ translatedText.admin_group_list_max_class_reached }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="action" stickyEnd="">
              <th mat-header-cell *matHeaderCellDef class="display_none">
                {{ translatedText.admin_group_list_table_header_action }}
              </th>
              <td mat-cell *matCellDef="let element; let i = dataIndex" width="5%">
                <div class="option_button_wrap">
                  <button
                    mat-flat-button
                    color="unset"
                    class="more_action"
                    [ngClass]="{ action_select: selectedTeacherMenuIndex === i, small: showUpdateButton(element) }"
                    [matMenuTriggerFor]="moreGroupMenu"
                    (click)="selectedTeacherMenuIndex = i; $event.stopPropagation()"
                  >
                    <mat-icon svgIcon="streamline_filled:down_arrow" class="ipad-none"></mat-icon>
                    <mat-icon svgIcon="streamline_filled:vertical-dot" class="ipad-visible">asdasd</mat-icon>

                    <span *ngIf="!showUpdateButton(element)">{{ translatedText.student_edit_options }}</span>
                  </button>
                  <mat-menu #moreGroupMenu="matMenu" class="group_list_menu" (closed)="closeTeacherMenu()">
                    <ul class="student_option_menu">
                      <li>
                        <button (click)="addTeacherToGroup(element)" type="button">
                          <mat-icon svgIcon="streamline_outlined:manage_teacher"></mat-icon>
                          <span>{{ translatedText.admin_group_list_manage_teacher }}</span>
                        </button>
                      </li>
                      <li
                        [matTooltip]="element._students.length >= 40 ? translatedText.students_add_max_num_reached : ''"
                      >
                        <button
                          (click)="
                            addStudentsToGroup(
                              element?._id,
                              element.grade,
                              element.name,
                              element.school_year,
                              element._teacher,
                              element._students
                            )
                          "
                          [disabled]="element._students.length >= 40"
                        >
                          <mat-icon svgIcon="streamline_outlined:process_learner"></mat-icon>
                          <span>{{ translatedText.admin_group_list_edit_learners }}</span>
                        </button>
                      </li>

                      <li>
                        <button
                          aria-label="view-detail"
                          (click)="managingGroupOverlay(element)"
                          [disabled]="isLoadingPdf"
                        >
                          <mat-icon svgIcon="streamline_outlined:download-circle"></mat-icon>
                          <span>{{ translatedText.admin_group_list_download_pdf }}</span>
                        </button>
                      </li>
                      <hr />
                      <li *ngIf="checkForCurrentTeacher(element?._teacher) && element.tasks_count == 0">
                        <button (click)="openGroupDeleteDialog(element)" class="delete-btn">
                          <mat-icon svgIcon="streamline_outlined:trash"></mat-icon>
                          <span>{{ translatedText.admin_group_list_delete }}</span>
                        </button>
                      </li>
                      <div class="delete_msg" *ngIf="element.tasks_count != 0">
                        <p>
                          <mat-icon svgIcon="streamline_outlined:trash"></mat-icon>
                          <span>{{ translatedText.admin_group_list_delete }}</span>
                        </p>
                        <p>{{ translatedText.admin_delete_task }}</p>
                      </div>
                    </ul>

                    <!-- <button mat-menu-item aria-label="view-detail" (click)="addTeacherToGroup(element)">
                    <mat-icon svgIcon="streamline_outlined:user-scan"></mat-icon>
                    <span>{{ translatedText.admin_group_list_add_teacher }}</span>
                  </button>
                  <button mat-menu-item aria-label="view-detail" (click)="removeTeacherFromGroup(element)">
                    <mat-icon svgIcon="streamline_outlined:user-scan"></mat-icon>
                    <span>{{ translatedText.admin_group_list_remove_teacher }}</span>
                  </button>
                  <button mat-menu-item aria-label="view-detail" (click)="addExistingStudentsToGroup(element)">
                    <mat-icon svgIcon="streamline_outlined:add-user-alt"></mat-icon>
                    <span>{{ translatedText.admin_group_list_add_existing_students }}</span>
                  </button>
                  <button
                    mat-menu-item
                    aria-label="view-detail"
                    (click)="
                      addStudentsToGroup(
                        element?._id,
                        element.grade,
                        element.name,
                        element.school_year,
                        element._teacher,
                        element._students
                      )
                    "
                  >
                    <mat-icon svgIcon="streamline_outlined:add-circle"></mat-icon>
                    <span>{{ translatedText.admin_group_list_add_students }}</span>
                  </button>
                  <button mat-menu-item aria-label="view-detail" (click)="managingGroupOverlay(element)">
                    <mat-icon svgIcon="streamline_outlined:doc-update"></mat-icon>
                    <span>{{ translatedText.admin_group_list_generate_new_group_list }}</span>
                  </button> -->
                  </mat-menu>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="expand">
              <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
              <td mat-cell *matCellDef="let element">
                <button
                  mat-mini-fab
                  class="not-filled expand"
                  aria-label="expand row"
                  (click)="expandElement($event, element)"
                >
                  <mat-icon *ngIf="expandedElement !== element._id">navigate_next</mat-icon>
                  <mat-icon *ngIf="expandedElement === element._id">expand_more</mat-icon>
                </button>
              </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <td
                mat-cell
                *matCellDef="let element"
                class="nested_table_td"
                [attr.colspan]="displayedColumns.length + 1"
              >
                <table
                  mat-table
                  [dataSource]="element?._students"
                  class="example-element-detail nested-table"
                  [ngClass]="element._id == expandedElement ? 'expanded' : 'collapsed'"
                  [@detailExpand]="element._id == expandedElement ? 'expanded' : 'collapsed'"
                >
                  <!-- Define the columns for the nested table -->
                  <ng-container matColumnDef="placeholder">
                    <th mat-header-cell *matHeaderCellDef class="paragraph--bold"></th>
                    <td mat-cell *matCellDef="let nestedElement"></td>
                  </ng-container>
                  <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef class="paragraph--bold">
                      {{ translatedText.admin_group_list_students }}
                    </th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <span
                        class="paragraph paragraph--bold username-with-avatar"
                        [ngClass]="nestedElement?.status === 'inactive' ? 'student-inactive' : ''"
                      >
                        <span><img *ngIf="nestedElement?.avatarUrl" src="{{ nestedElement?.avatarUrl }}" /></span
                        >{{ nestedElement?.username }}
                      </span>
                    </td>
                  </ng-container>
                  <!-- <ng-container matColumnDef="teacher">
                  <th mat-header-cell *matHeaderCellDef class="paragraph--bold">
                    {{ translatedText.admin_group_list_table_header_teacher }}
                  </th>
                  <td mat-cell *matCellDef="let nestedElement">
                    <p class="paragraph paragraph--s">
                      {{ nestedElement?._teacher?.lastname }}
                      {{ nestedElement?._teacher?.firstname }}
                    </p>
                  </td>
                </ng-container> -->
                  <ng-container matColumnDef="test_license">
                    <th mat-header-cell *matHeaderCellDef class="paragraph--bold">
                      {{ translatedText.admin_group_list_table_header_diagnostic }}
                    </th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <p
                        class="paragraph paragraph--s"
                        [ngClass]="nestedElement?.status === 'inactive' ? 'student-inactive' : ''"
                      >
                        <ng-container *ngIf="hasActiveLicense(nestedElement?.licenses, 'di')">
                          <mat-icon
                            *ngIf="!checkLicenseAboutToExpire(nestedElement?.licenses, 'di', false)"
                            matTooltip="{{ translatedText.admin_group_list_license_info_active }}"
                            color="primary"
                            svgIcon="streamline_outlined:license_available"
                            class="icon-40"
                          ></mat-icon>
                          <mat-icon
                            *ngIf="checkLicenseAboutToExpire(nestedElement?.licenses, 'di', false)"
                            matTooltip="{{
                              translatedText.t('admin_group_list_license_info_expires_soon', {
                                expiryDate:
                                  getLatestLicenseExpirationDate(nestedElement?.licenses, 'di') | date : 'dd.MM.yyyy'
                              })
                            }}"
                            color="color-orange-600"
                            svgIcon="streamline_outlined:expire_status"
                            class="icon-40 hourglassicon"
                          >
                          </mat-icon>
                        </ng-container>

                        <ng-container *ngIf="!hasActiveLicense(nestedElement?.licenses, 'di')">
                          <mat-icon
                            *ngIf="checkLicenseExistence(nestedElement?.licenses, 'di')"
                            matTooltip="{{ translatedText.admin_group_list_license_info_expired }}"
                            color="warn"
                            svgIcon="streamline_outlined:cancel_circle"
                            class="icon-40"
                          ></mat-icon>
                          <mat-icon
                            *ngIf="!checkLicenseExistence(nestedElement?.licenses, 'di')"
                            matTooltip="{{ translatedText.admin_group_list_license_info_none_diagnostic }}"
                            color="secondary"
                            svgIcon="streamline_outlined:license_minus"
                            class="icon-40"
                          ></mat-icon>
                        </ng-container>
                      </p>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="training_license">
                    <th mat-header-cell *matHeaderCellDef class="">
                      {{ translatedText.admin_group_list_table_header_training }}
                    </th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <p
                        class="paragraph paragraph--s"
                        [ngClass]="nestedElement?.status === 'inactive' ? 'student-inactive' : ''"
                      >
                        <ng-container *ngIf="hasActiveLicense(nestedElement?.licenses, 'fo')">
                          <mat-icon
                            *ngIf="!checkLicenseAboutToExpire(nestedElement?.licenses, 'fo', false)"
                            matTooltip="{{ translatedText.admin_group_list_license_info_active }}"
                            color="primary"
                            svgIcon="streamline_outlined:license_available"
                            class="icon-40"
                          ></mat-icon>
                          <mat-icon
                            *ngIf="checkLicenseAboutToExpire(nestedElement?.licenses, 'fo', false)"
                            matTooltip="{{
                              translatedText.t('admin_group_list_license_info_expires_soon', {
                                expiryDate:
                                  getLatestLicenseExpirationDate(nestedElement?.licenses, 'fo') | date : 'dd.MM.yyyy'
                              })
                            }}"
                            color="primary"
                            svgIcon="streamline_outlined:expire_status"
                            class="icon-40"
                          ></mat-icon>
                        </ng-container>

                        <ng-container *ngIf="!hasActiveLicense(nestedElement?.licenses, 'fo')">
                          <mat-icon
                            *ngIf="checkLicenseExistence(nestedElement?.licenses, 'fo')"
                            matTooltip="{{ translatedText.admin_group_list_license_info_expired }}"
                            color="warn"
                            svgIcon="streamline_outlined:cancel_circle"
                            class="icon-40"
                          ></mat-icon>
                          <mat-icon
                            *ngIf="!checkLicenseExistence(nestedElement?.licenses, 'fo')"
                            matTooltip="{{ translatedText.admin_group_list_license_info_none_training }}"
                            color="secondary"
                            svgIcon="streamline_outlined:license_minus"
                            class="icon-40"
                          ></mat-icon>
                        </ng-container>
                      </p>
                    </td>
                  </ng-container>
                  <!-- <ng-container matColumnDef="type3_license">
                                <th mat-header-cell *matHeaderCellDef class="hidden_nested_header">Type #3 License</th>
                                <td mat-cell *matCellDef="let nestedElement"><p class="paragraph paragraph--s"></p></td>
                            </ng-container> -->

                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="">
                      {{ translatedText.admin_group_list_table_header_action }}
                    </th>
                    <!--           [ngClass]="nestedElement?.status === 'inactive' ? 'student-inactive' : ''" -->
                    <td mat-cell *matCellDef="let nestedElement; let j = renderIndex">
                      <div class="license-action-wrap">
                        <button
                          [disabled]="
                            (hasActiveLicense(nestedElement?.licenses, 'di') &&
                              hasActiveLicense(nestedElement?.licenses, 'fo') &&
                              !(
                                checkLicenseAboutToExpire(nestedElement?.licenses, 'di', true) ||
                                checkLicenseAboutToExpire(nestedElement?.licenses, 'fo', true)
                              )) ||
                            (totalCountLeftDiagnostic == 0 && totalCountLeftTraining == 0) ||
                            nestedElement?.status === 'inactive'
                          "
                          mat-flat-button
                          type="button"
                          class="license-button"
                          (click)="openLicenseDistribution(nestedElement, 'assign')"
                          matSuffix
                          matTooltip="{{ translatedText.admin_group_list_detail }}"
                          [ngClass]="nestedElement?.status === 'inactive' ? 'student-inactive' : ''"
                        >
                          <mat-icon svgIcon="streamline_outlined:license_white"></mat-icon>
                          {{ translatedText.admin_dashboard_licenses }}
                        </button>

                        <button
                          type="button"
                          class="more_action more-btn"
                          mat-flat-button
                          color="unset"
                          [ngClass]="{
                            action_select: selectedStudentMenu === nestedElement?._id
                          }"
                          [matMenuTriggerFor]="moreStudentMenu"
                          (click)="chooseStudent(nestedElement); $event.stopPropagation()"
                        >
                          <mat-icon svgIcon="streamline_filled:down_arrow"></mat-icon>
                          <span>{{ translatedText.teacher_mobile_bar_more }} {{ j }}</span>
                        </button>

                        <mat-menu #moreStudentMenu="matMenu" (closed)="closeStudentMenu()">
                          <ul class="student_option_menu">
                            <li *ngIf="userType == 'admin'">
                              <button aria-label="view-detail" (click)="impersonateUser(nestedElement._user._id)">
                                <mat-icon svgIcon="streamline_outlined:user-star"></mat-icon>
                                <span>{{ translatedText.admin_group_list_act_as_studdent }}</span>
                              </button>
                            </li>
                            <li>
                              <button
                                aria-label="view-detail"
                                (click)="openPdfInfo(element, nestedElement)"
                                [disabled]="isLoadingPdf"
                              >
                                <mat-icon svgIcon="streamline_outlined:download-circle"></mat-icon>
                                <span>{{ translatedText.admin_group_list_download_pdf }}</span>
                              </button>
                            </li>
                            <li>
                              <button
                                aria-label="view-detail"
                                (click)="editStudentOverlay(nestedElement, nestedElement._id, true)"
                              >
                                <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon>
                                <span>{{ translatedText.admin_group_list_edit }}</span>
                              </button>
                            </li>
                            <li>
                              <button aria-label="view-detail" (click)="updateStudentStatus(nestedElement)">
                                <mat-icon svgIcon="streamline_outlined:pause-window"></mat-icon>
                                <ng-container *ngIf="nestedElement.status == 'active'">
                                  <span>{{ translatedText.admin_group_list_set_inactive }}</span>
                                </ng-container>
                                <ng-container *ngIf="nestedElement.status == 'inactive'">
                                  <span>{{ translatedText.admin_group_list_set_active }}</span>
                                </ng-container>
                              </button>
                            </li>
                            <li>
                              <button
                                aria-label="view-detail"
                                (click)="openLicenseDistribution(nestedElement, 'assign')"
                                [disabled]="
                                  (hasActiveLicense(nestedElement?.licenses, 'di') &&
                                    hasActiveLicense(nestedElement?.licenses, 'fo') &&
                                    !(
                                      checkLicenseAboutToExpire(nestedElement?.licenses, 'di', true) ||
                                      checkLicenseAboutToExpire(nestedElement?.licenses, 'fo', true)
                                    )) ||
                                  (totalCountLeftDiagnostic == 0 && totalCountLeftTraining == 0) ||
                                  nestedElement?.status === 'inactive'
                                "
                              >
                                <mat-icon svgIcon="streamline_outlined:license-plus"></mat-icon>
                                <span>{{ translatedText.admin_group_list_assign_license }}</span>
                              </button>
                            </li>
                            <li>
                              <button
                                (click)="openLicenseDistribution(nestedElement, 'release')"
                                [disabled]="
                                  !(
                                    (hasActiveLicense(nestedElement?.licenses, 'di') && totalCountDiagnostic < 100) ||
                                    hasActiveLicense(nestedElement?.licenses, 'fo')
                                  )
                                "
                              >
                                <mat-icon svgIcon="streamline_outlined:license-transfer"></mat-icon>
                                <span>{{ translatedText.admin_group_list_transfer_license }}</span>
                              </button>
                            </li>
                            <hr />
                            <li *ngIf="nestedElement?._tasks?.length === 0">
                              <button
                                class="delete-btn"
                                aria-label="view-detail"
                                (click)="openStudentDeleteDialog(nestedElement)"
                              >
                                <mat-icon svgIcon="streamline_outlined:trash"></mat-icon>
                                <span>{{ translatedText.admin_group_list_delete }}</span>
                              </button>
                            </li>
                            <div class="delete_msg" *ngIf="nestedElement?._tasks?.length != 0">
                              <p>
                                <mat-icon svgIcon="streamline_outlined:trash"></mat-icon>
                                <span>{{ translatedText.admin_group_list_delete }}</span>
                              </p>
                              <p>{{ translatedText.admin_delete_student_warning }}</p>
                            </div>
                          </ul>
                        </mat-menu>
                      </div>
                    </td>
                  </ng-container>
                  <!-- Replace the below line with your desired nested table columns -->
                  <!-- Add more ng-container elements for additional columns -->

                  <!-- Define the nested table rows -->
                  <tr class="hidden_nested_tr" mat-header-row *matHeaderRowDef="nestedColumns"></tr>
                  <tr mat-row *matRowDef="let nestedElement; columns: nestedColumns"></tr>
                </table>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
            <tr
              mat-row
              *matRowDef="let element; columns: columnsToDisplayWithExpand"
              class="example-element-row"
              [class.example-expanded-row]="expandedElement === element._id"
              (click)="expandedElement = expandedElement === element._id ? null : element._id"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
          </table>
          <div class="paginator-container">
            <mat-paginator
              [hidePageSize]="true"
              [length]="dataSource?.meta.totalItems"
              [pageSize]="
                dataSource?.meta.totalItems > dataSource?.meta.itemsPerPage
                  ? dataSource?.meta.itemsPerPage
                  : dataSource?.meta.totalItems
              "
              [pageSizeOptions]="[10, 25, 100]"
              (page)="pageEvent = $event; onPaginateChange($event)"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>

      <!-- Empty Class-->
      <ng-container *ngIf="dataSource?.data.length === 0 && totalUnassignedStudents === 0">
        <div class="empty-class-box">
          <mat-icon svgIcon="streamline_outlined:empty_class" class="empty-class-icon"></mat-icon>
          <p class="paragraph paragraph--m paragraph--bold">{{ translatedText.admin_group_class_list_empty }}</p>
        </div>
      </ng-container>
      <!-- Unassigned students -->

      <ng-container *ngIf="totalUnassignedStudents > 0">
        <div class="unassigned-students" (click)="expandUnassigned = !expandUnassigned">
          <button mat-mini-fab class="not-filled expand" aria-label="expand row">
            <mat-icon *ngIf="!expandUnassigned">navigate_next</mat-icon>
            <mat-icon *ngIf="expandUnassigned">expand_more</mat-icon>
          </button>
          <div class="unassigned-students-title-container">
            <mat-icon svgIcon="streamline_filled:unassigned_student"></mat-icon>
            <div class="unassigned-students-title">
              {{ translatedText.admin_group_unassigned_students }}
              <div>
                <p class="paragraph learner-count">
                  {{ totalUnassignedStudents }}
                  <!-- get total of students un this list from API -->
                  {{ translatedText.admin_dashboard_students }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <table mat-table [dataSource]="unassignedData" class="unassigned-table" *ngIf="expandUnassigned">
          <ng-container matColumnDef="placeholder">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"></td>
          </ng-container>
          <ng-container matColumnDef="student">
            <th mat-header-cell *matHeaderCellDef>{{ translatedText.admin_dashboard_students }}</th>
            <td mat-cell *matCellDef="let element">
              <span class="unassigned-username-with-avatar"
                ><img
                  src="{{ evolutionApiUrl + '/files/images/avatar/' + element.avatar + '.png' }}"
                  alt="student avatar"
                />
                {{ element.username }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="_group">
            <th mat-header-cell *matHeaderCellDef>{{ translatedText.admin_group_list_old_class }}</th>
            <td mat-cell *matCellDef="let element">
              <div class="old-class-wrap">
                <p class="unassigned-old-class" *ngIf="element._group.length > 0">
                  {{ getGroupName(element._group).name
                  }}<span> - {{ formatDateString(getGroupName(element._group).school_year) }}</span>
                </p>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-flat-button
                color="primary"
                (click)="openAddToClassOverlay(element)"
                [matTooltip]="
                  totalUpdatedGroups.length === 0 ? translatedText.admin_group_list_add_to_class_tooltip : ''
                "
              >
                <mat-icon svgIcon="streamline_outlined:add-user-alt"></mat-icon>
                {{ translatedText.admin_group_list_unassigned_to_class }}
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="unassignedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: unassignedColumns"></tr>
        </table>
      </ng-container>
    </div>
  </div>
</div>
