<app-admin-group-managing-stepper
  #stepper
  linear
  [stepValue]="selectedIndex"
  (selectionChange)="getActiveSelection($event)"
  (isClosedDialog)="closeAll($event)"
>
  <!-- IN DIALOG BOXES WITH STEPPER COMPONENTS THE CLOSE BUTTON IS IN THE STEPPER COMPONENT AND IS TRIGGERED AS AN EVENT (above)-->
  <!-- STEP 1 -->
  <cdk-step [stepControl]="firstStep">
    <div class="header-wrapper">
      <div class="close_wrap">
        <button mat-flat-button class="close-button-with-text" type="button" matSuffix [mat-dialog-close]="true">
          <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
          {{ translatedText.admin_group_create_cancel }}
        </button>
      </div>
      <div class="chip_wrapper">
        <div class="chip_outline">
          <div class="chip_content add-student-chip">{{ translatedText.admin_group_managing_chip }}</div>
        </div>
      </div>
      <div class="buttons-wrapper">
        <!-- <button mat-flat-button color="unset" disabled [mat-dialog-close]="true">
          {{ translatedText.students_add_back }}
        </button> -->
        <button
          mat-flat-button
          color="primary"
          class="submit-button"
          [disabled]="!onlyGroupOverview && !completeGroupOverview"
          (click)="checkStep()"
          cdkStepperNext
        >
          {{ translatedText.admin_group_managing_next }}
        </button>
      </div>
    </div>
    <div class="training-task_select_container">
      <div class="training-task_create_headline">
        <h2 class="h2 h2--bold">
          {{ translatedText.admin_group_managing_create_group_list }}
        </h2>
        <!-- <p class="paragraph paragraph--s">
          {{ translatedText.admin_group_managing_group_overview_headline }}
        </p> -->
      </div>
      <div class="training-task_selector_wrap">
        <button
          class="training-task_selector"
          [ngClass]="onlyGroupOverview ? 'active' : ''"
          (click)="setTaskData('onlyGroupOverview')"
        >
          <div class="training-task_button_wrap">
            <mat-icon svgIcon="streamline_outlined:only-group-overview"></mat-icon>
            <div class="training-task_selector_content">
              <p class="paragraph paragraph--m paragraph--bold">
                {{ translatedText.admin_group_managing_group_overview }}
              </p>
              <p class="paragraph paragraph--s">
                {{ translatedText.admin_group_managing_group_overview_only_list }}
              </p>
            </div>
          </div>
        </button>
        <button
          class="training-task_selector"
          [ngClass]="completeGroupOverview ? 'active' : ''"
          (click)="setTaskData('completeGroupOverview')"
        >
          <div class="training-task_button_wrap">
            <mat-icon svgIcon="streamline_outlined:complete-group-overview"></mat-icon>
            <div class="training-task_selector_content">
              <p class="paragraph paragraph--m paragraph--bold">
                {{ translatedText.admin_group_managing_group_overview_and_pages }}
              </p>
              <p class="paragraph paragraph--s">
                {{ translatedText.admin_group_managing_group_overview_and_pages_access }}
              </p>
            </div>
          </div>
        </button>
      </div>
    </div>
  </cdk-step>
  <!-- STEP 2 -->
  <cdk-step [stepControl]="secondStep">
    <div class="header-wrapper">
      <div class="close_wrap">
        <button mat-flat-button class="close-button-with-text" type="button" matSuffix [mat-dialog-close]="true">
          <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
          {{ translatedText.admin_group_create_cancel }}
        </button>
      </div>
      <div class="chip_wrapper">
        <div class="chip_outline">
          <div class="chip_content add-student-chip">{{ translatedText.admin_group_managing_chip }}</div>
        </div>
      </div>
      <div class="buttons-wrapper">
        <button mat-flat-button class="close-button-with-text" type="button" cdkStepperPrevious>
          {{ translatedText.admin_group_managing_back }}
        </button>
        <button mat-flat-button color="primary" [disabled]="!withNames && !withoutNames" (click)="pdfstep()">
          {{ translatedText.admin_group_managing_next }}
        </button>
      </div>
    </div>
    <div class="training-task_select_container">
      <div class="training-task_create_headline">
        <h2 class="h2 h2--bold">
          {{ translatedText.admin_group_managing_set_names_in_list }}
        </h2>
        <!-- <p class="paragraph paragraph--xl">
          {{ translatedText.admin_group_managing_set_names_in_list_question }}
        </p> -->
      </div>
      <div class="training-task_selector_wrap">
        <button class="training-task_selector" [ngClass]="withNames ? 'active' : ''" (click)="setTaskData('withNames')">
          <div class="training-task_button_wrap">
            <mat-icon svgIcon="streamline_outlined:with-names"></mat-icon>
            <div class="training-task_selector_content">
              <p class="paragraph paragraph--m paragraph--bold">
                {{ translatedText.admin_group_managing_set_names }}
              </p>
              <p class="paragraph paragraph--s">
                {{ translatedText.admin_group_managing_admin_set_names }}
              </p>
            </div>
          </div>
        </button>
        <button
          class="training-task_selector"
          [ngClass]="withoutNames ? 'active' : ''"
          (click)="setTaskData('withoutNames')"
          *ngIf="checkStudentsGender() == 0"
        >
          <div class="training-task_button_wrap">
            <mat-icon svgIcon="streamline_outlined:without-names"></mat-icon>
            <div class="training-task_selector_content">
              <p class="paragraph paragraph--m paragraph--bold">
                {{ translatedText.admin_group_managing_not_enter_name }}
              </p>
              <p class="paragraph paragraph--s">
                {{ translatedText.admin_group_managing_not_enter_name_text }}
              </p>
            </div>
          </div>
        </button>
      </div>
      <div class="managing-create-passwords" [hidden]="onlyGroupOverview">
        <mat-checkbox #passwordSelection class="example-margin" (change)="onPasswordSelectionChange($event.checked)">{{
          translatedText.admin_group_managing_create_new_password
        }}</mat-checkbox>
      </div>

      <div class="pdf-notification pass_hint_wrap" *ngIf="showPasswordHint">
        <div class="notification-box warn-mode">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="tn-heading">
                <mat-icon svgIcon="streamline_outlined:warning-triangle"></mat-icon>

                <div class="tn-heading">{{ translatedText.admin_group_managing__password_title }}</div>
              </div>
              <p class="paragraph paragraph--s">
                {{ translatedText.admin_group_managing__password_desc }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="training-task_select_button">
      <button mat-flat-button color="secondary" cdkStepperPrevious>
        {{ translatedText.admin_group_managing_back }}
      </button>
      <button mat-flat-button color="primary" [disabled]="!withNames && !withoutNames" (click)="pdfstep()">
        {{ translatedText.admin_group_managing_next }}
      </button>
    </div> -->
  </cdk-step>
  <!-- STEP 3 -->
  <cdk-step [stepControl]="rForm">
    <div class="header-wrapper">
      <div class="close_wrap">
        <button mat-flat-button class="close-button-with-text" type="button" matSuffix [mat-dialog-close]="true">
          <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
          {{ translatedText.admin_group_create_cancel }}
        </button>
      </div>
      <div class="chip_wrapper">
        <div class="chip_outline">
          <div class="chip_content add-student-chip">{{ translatedText.admin_group_managing_chip }}</div>
        </div>
      </div>
      <div class="buttons-wrapper">
        <button mat-flat-button class="close-button-with-text" type="button" cdkStepperPrevious>
          {{ translatedText.admin_group_managing_back }}
        </button>
        <button [disabled]="rForm.invalid" mat-flat-button color="primary" (click)="pdfStep()">
          {{ translatedText.admin_group_managing_next }}
        </button>
      </div>
    </div>
    <div class="training-task_select_container edit-group">
      <div class="group-info">
        <h2 class="h2 h2--bold">
          {{ translatedText.admin_group_managing__group_title }}
        </h2>
        <p>
          {{ translatedText.admin_group_managing__group_desc }}
        </p>
      </div>
      <form [formGroup]="rForm" #formDirective="ngForm">
        <div formArrayName="students" class="third_step children_row_wrap">
          <div
            *ngFor="let student of getStudentsFormControls(); let i = index"
            [formGroupName]="i"
            class="children_row"
            [ngClass]="{ children_row_finished: student.get('name').value }"
          >
            <p class="paragraph paragraph--bold paragraph--l group_row_number">
              <span class="gender-index">{{ i + 1 }}</span>
            </p>
            <div class="row group_row">
              <div class="col-md-4">
                <span class="paragraph paragraph--bold paragraph--l username-with-avatar username-edit">
                  <span
                    ><img
                      *ngIf="getStudentInfo(student.value.id).avatarUrl"
                      src="{{ getStudentInfo(student.value.id).avatarUrl }}" /></span
                  >{{ getStudentInfo(student.value.id).username }}
                </span>
                <mat-label>{{ translatedText.admin_group_managing_full_name }}</mat-label>
                <mat-form-field>
                  <input
                    matInput
                    formControlName="name"
                    type="text"
                    placeholder="{{ translatedText.admin_group_managing_full_name }}"
                  />
                </mat-form-field>
              </div>
              <div class="col-3">
                <mat-label>{{ translatedText.admin_group_managing_language }}</mat-label>
                <mat-form-field>
                  <input
                    type="text"
                    placeholder="{{ translatedText.admin_group_managing_language }}"
                    aria-label="Muttersprache"
                    matInput
                    formControlName="language"
                    [matAutocomplete]="auto"
                    appAutocompletePosition="trigger"
                    required
                    readonly
                  />
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    [displayWith]="displayFn.bind(this)"
                    (optionSelected)="setLanguageOption($event?.option?.value, i)"
                  >
                    <mat-option *ngFor="let language of filteredOptions[i] | async" [value]="language?.code">
                      {{ getLanguageByCode(language?.code) }}</mat-option
                    >
                  </mat-autocomplete>
                  <mat-error
                    *ngIf="
                      validadeStudentField(i).get('language').hasError('incorrect') &&
                      validadeStudentField(i).get('language').touched &&
                      !validadeStudentField(i).get('language').hasError('required')
                    "
                  >
                    {{ translatedText.admin_group_managing_please_select_language }}
                  </mat-error>
                  <mat-error *ngIf="validadeStudentField(i).get('language').hasError('required')">
                    <!-- Please enter a class name. -->
                    {{ translatedText.admin_group_managing_language_error }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-3">
                <mat-label>{{ translatedText.admin_group_managing_birthdate }}</mat-label>
                <mat-form-field>
                  <input
                    matInput
                    [max]="maxBirthday"
                    formControlName="age"
                    type="text"
                    [matDatepicker]="birthdaypicker"
                    required
                    readonly
                  />
                  <mat-datepicker-toggle matSuffix [for]="birthdaypicker" [disabled]="true"></mat-datepicker-toggle>
                  <mat-datepicker #birthdaypicker startView="multi-year" disabled="true"></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-label>{{ translatedText.students_add_gender }}*</mat-label>
                <mat-form-field>
                  <mat-select
                    formControlName="gender"
                    aria-label="Select an option"
                    [value]="student.gender"
                    disableOptionCentering
                    panelClass="larger-select"
                    [disabled]="true"
                  >
                    <mat-option (change)="genderSelection()" value="male">
                      <mat-icon class="male-icon" svgIcon="streamline_outlined:male"></mat-icon>
                      {{ translatedText.students_add_gender_male }}
                    </mat-option>
                    <mat-option (change)="genderSelection()" value="female">
                      <mat-icon class="female-icon" svgIcon="streamline_outlined:female"></mat-icon>
                      {{ translatedText.students_add_gender_female }}
                    </mat-option>
                    <mat-option (change)="genderSelection()" value="divers">
                      <mat-icon class="divers-icon" svgIcon="streamline_outlined:divers"></mat-icon>
                      {{ translatedText.students_add_gender_divers }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- <div class="gender-selection">
                  <mat-label>{{
                    translatedText.admin_group_managing_gender
                  }}</mat-label>
                  <mat-radio-group formControlName="gender" aria-label="Select an option" [value]="student.gender">
                    <mat-radio-button (change)="genderSelection()" value="male">
                      <mat-icon class="male-icon" svgIcon="streamline_outlined:male"></mat-icon>{{
                        translatedText.admin_group_managing_male
                      }}
                    </mat-radio-button>
                    <mat-radio-button (change)="genderSelection()" value="female">
                      <mat-icon class="female-icon" svgIcon="streamline_outlined:female"></mat-icon>{{
                        translatedText.admin_group_managing_female
                      }}
                    </mat-radio-button>
                    <mat-radio-button (change)="genderSelection()" value="divers">
                      <mat-icon class="divers-icon" svgIcon="streamline_outlined:divers"></mat-icon>{{
                        translatedText.admin_group_managing_divers
                      }}
                    </mat-radio-button>
                  </mat-radio-group>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- <div class="training-task_select_button">
      <button mat-flat-button color="secondary" cdkStepperPrevious>
        {{ translatedText.admin_group_managing_back }}
      </button>
      <button [disabled]="rForm.invalid" mat-flat-button color="primary" (click)="submitForm(formDirective)">
        {{ translatedText.admin_group_managing_save }}
      </button>
    </div> -->
  </cdk-step>
  <!-- STEP 4 -->
  <cdk-step>
    <div class="header-wrapper">
      <div class="close_wrap">
        <button
          mat-flat-button
          class="close-button-with-text"
          type="button"
          [mat-dialog-close]="true"
          matSuffix
          [ngClass]="{ 'hidden-btn': blobUrl }"
          [disabled]="blobUrl"
        >
          <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
          {{ translatedText.admin_group_create_cancel }}
        </button>
      </div>

      <div class="chip_wrapper">
        <div class="chip_outline">
          <div class="chip_content add-student-chip">
            {{ translatedText.admin_group_managing_chip }}
          </div>
        </div>
      </div>
      <div class="buttons-wrapper">
        <button
          mat-flat-button
          class="close-button-with-text"
          type="button"
          [ngClass]="{ 'hidden-btn': isLoading || blobUrl }"
          (click)="backToStep()"
        >
          {{ translatedText.admin_group_managing_back }}
        </button>
        <button
          mat-flat-button
          color="primary"
          class="submit-button"
          cdkStepperNext
          [disabled]="blobUrl === null || blobUrl === undefined"
          [mat-dialog-close]="true"
        >
          {{ translatedText.admin_group_close_btn }}
        </button>
      </div>
    </div>
    <div class="step_padding">
      <div class="training-task_create_headline">
        <h2 class="h2 h2--bold">{{ translatedText.admin_group_class_pdf_generate }}</h2>
      </div>
    </div>
    <div class="generate-pdf-section">
      <div class="pdf-notification">
        <div class="notification-box open-mode">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="tn-heading">
                <mat-icon svgIcon="streamline_outlined:shield-check-v2"></mat-icon>
                <div class="tn-heading">{{ translatedText.generate_pdf_data_protection }}</div>
              </div>
              <p class="paragraph paragraph--s">
                {{ translatedText.generate_pdf_data_protection_text }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="pdf-container">
        <mat-icon svgIcon="streamline_filled:pdf-doc" class="icon-56" *ngIf="blobUrl === null || blobUrl === undefined">
        </mat-icon>
        <mat-icon svgIcon="streamline_filled:download-boxed" class="icon-56" *ngIf="blobUrl"></mat-icon>
        <p class="paragraph paragraph--bold paragraph--xl">
          {{
            blobUrl === null || blobUrl === undefined
              ? translatedText.generate_class_pdf
              : translatedText.generate_class_pdf_ready
          }}
        </p>
        <p class="paragraph paragraph--m">
          {{
            blobUrl === null || blobUrl === undefined
              ? translatedText.generate_pdf_attention_text
              : translatedText.generate_pdf_ready_text
          }}
        </p>
        <button
          mat-flat-button
          color="primary"
          class="mt-4 generate_pdf_button"
          *ngIf="!isLoading && (blobUrl === null || blobUrl === undefined)"
          (click)="regeneratePdfForGroup()"
        >
          {{ translatedText.generate_pdf_generate_PDF }}
        </button>
        <button mat-flat-button class="mt-4 button-light-green generate_pdf_button" *ngIf="isLoading">
          <mat-icon svgIcon="streamline_outlined:pdf-loader" class="mr-2"></mat-icon>
          {{ translatedText.generate_pdf_PDF_being_generated }}
        </button>
        <button
          mat-flat-button
          color="primary"
          class="mt-4 generate_pdf_button"
          *ngIf="blobUrl"
          (click)="downloadPDF()"
        >
          <mat-icon svgIcon="streamline_outlined:download-pdf" class="mr-2"></mat-icon>
          {{ translatedText.admin_group_list_download_pdf }}
        </button>
      </div>

      <div class="pdf-notification" *ngIf="!clickedDownload">
        <div class="notification-box warn-mode">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="tn-heading">
                <mat-icon svgIcon="streamline_outlined:warning-triangle"></mat-icon>

                <div class="tn-heading">{{ translatedText.generate_pdf_save_where }}</div>
              </div>
              <p class="paragraph paragraph--s">
                {{ translatedText.generate_pdf_save_where_text }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="pdf-notification" *ngIf="clickedDownload">
        <div class="notification-box success-mode">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="tn-heading">
                <mat-icon svgIcon="streamline_outlined:checkmark"></mat-icon>

                <div class="tn-heading">{{ translatedText.admin_group_class_generate_pdf_successfully }}</div>
              </div>
              <p class="paragraph paragraph--s">
                {{ translatedText.admin_group_class_generate_pdf_successfully_desc }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </cdk-step>
</app-admin-group-managing-stepper>
