import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { TaskService } from 'src/app/providers/task.service';
import { TranslationService } from 'src/app/providers/translation.service';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';

export const DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

// Custom Date Adapter to set the first day of the week to Monday
class CustomDateAdapter extends MomentDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
}
// Interface for the diagnostic date range data structure
interface DiagosisDateRange {
  deadline: string;
  startDate: string;
  endDate: string;
  name: string;
  phase: number;
  value: string;
  checked?: boolean;
  isNote?: boolean;
}

@Component({
  selector: 'app-diagnostic-tasks-create',
  templateUrl: './diagnostic-tasks-create.component.html',
  styleUrls: ['./diagnostic-tasks-create.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class DiagnosticTasksCreateComponent implements OnInit {
  translatedText: any;
  diagnostics: DiagosisDateRange[] = [];
  dateTodayIso: string = new Date().toISOString();
  dateToday: Date = new Date();
  chooseDateForm: FormGroup;
  selectedDateRange: DiagosisDateRange;
  DiagnosticTypesArray: string[] = [];
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };
  hintBoxId: string = 'task';
  infoBoxClosed: boolean = false;
  trialEndDate: any;
  trialToolTip: string = '';
  selectAnyDate: boolean = false;
  @Output('formData') formData = new EventEmitter<any>();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  taskVisibleFrom: Date = null;
  viewValueTaskVisibily: string = '';

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private translationService: TranslationService,
    private taskService: TaskService,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.firstChild.data.translation;

    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    // Get the trial status from the authentication service
    this.trialStatus = this.authService.getUserTrialStatus();

    // Get Task Start Date and End Date details from the service

    this.taskService.getPhaseDates();
    this.taskService
      .getPhaseDatesUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        // Only set diagnostics outside of trials
        // avoids overwriting the trial dummy data with just one diagnostic
        /* if (this.trialStatus.isActive) {
          this.getMaxDate(res[0].endDate, true);
          this.diagnostics = [
            {
              phase: 0,
              startDate: moment().toISOString(),
              endDate: moment(this.trialStatus.expiryDate).toISOString(),
              name: 'Diagnose 1',
              value: 'diagnostic-pre',
              deadline: 'DiagnosticPre',
            },
          ];

          if (this.trialStatus.expiryDate.toISOString() <= res[0].endDate) {
            let day = this.trialStatus.expiryDate.getDate().toString();
            let month = this.trialStatus.expiryDate.getMonth() + 1;
            let year = this.trialStatus.expiryDate.getFullYear().toString();
            const shortExpiryDate =
              day + '. ' + this.translatedText.t(month + '_short') + ' ' + year;

            this.trialToolTip = this.translatedText.t('trial_expires_before', {
              endDate: shortExpiryDate,
            });
          }
          return;
        } */

        this.diagnostics = res;
        this.setDateRanges(this.diagnostics);
      });

    // Build the form structure
    this.buildDateForm();

    // Emit form data whenever the form status changes
    this.chooseDateForm.statusChanges.subscribe((val) => {
      if (val) {
        this.formData.emit({ status: val, payload: this.chooseDateForm.value });
      }
    });
  }

  setDateRanges(res) {
    for (let i = 0; i < res.length; i++) {
      const currentStartDate = new Date(res[i].startDate);
      currentStartDate.setDate(currentStartDate.getDate() + 7);
      res[i].startDate = currentStartDate.toISOString();
      res[i].isNote = false;
    }
  }
  // Method to build the reactive form structure
  buildDateForm() {
    this.chooseDateForm = this._formBuilder.group({
      diagnostic: ['', Validators.required],
      deadlineDiagnosticPre: [''],
      deadlineDiagnosticMid: [''],
      deadlineDiagnosticPost: [''],
    });
  }

  // Method to select a date range from the available diagnostics
  selectDateRange(item: DiagosisDateRange) {
    this.resetSelectedDate();
    this.chooseDateForm.patchValue({ diagnostic: item.value.trim() });
    item.checked = true;
    this.selectedDateRange = item;
    this.setValidationForDeadline(item);
    //Show the note message
    const currentDate = new Date();
    const endDate = new Date(item.endDate);
    if (endDate < currentDate) {
      item.isNote = true;
    } else {
      item.isNote = false;
    }
  }
  dateValueChanged(event: any) {
    const resultDate = new Date(event.value);
    resultDate.setDate(event.value._d.getDate() - 28);
    this.taskVisibleFrom = resultDate;

    //sets view value translations of visibility date
    let day = this.taskVisibleFrom.getDate().toString();
    let month = this.taskVisibleFrom.getMonth() + 1;
    let year = this.taskVisibleFrom.getFullYear().toString();
    this.viewValueTaskVisibily =
      day + '. ' + this.translatedText.t(month + '_short') + ' ' + year;
  }
  getMinDate(startDate: string, checked: boolean) {
    if (checked) {
      if (startDate > this.dateTodayIso) {
        return startDate;
      } else {
        return this.dateTodayIso;
      }
    } else {
      return;
    }
  }

  getMaxDate(endDate: string, checked: boolean) {
    const trialExpireDate = this.trialStatus.expiryDate.toISOString();
    if (checked) {
      if (this.trialStatus.isActive) {
        this.trialEndDate = this.trialStatus.expiryDate;
        return this.trialStatus.expiryDate;
      } else {
        this.trialEndDate = endDate;
        return endDate;
      }
    }
  }

  // Method to reset the selected date and clear form fields
  resetSelectedDate() {
    const currentDate = new Date();
    for (const diagnostic of this.diagnostics) {
      const endDate = new Date(diagnostic.endDate);
      diagnostic.checked = false;
      if (endDate < currentDate) {
        if (diagnostic.deadline === 'DiagnosticPre') {
          this.chooseDateForm.get('deadlineDiagnosticPre').setValue('');
        } else if (diagnostic.deadline === 'DiagnosticMid') {
          this.chooseDateForm.get('deadlineDiagnosticMid').setValue('');
        } else if (diagnostic.deadline === 'DiagnosticPost') {
          this.chooseDateForm.get('deadlineDiagnosticPost').setValue('');
        }
      }
    }
  }

  // Method to set validation rules for deadline fields based on the selected date range
  setValidationForDeadline(item: DiagosisDateRange) {
    this.DiagnosticTypesArray = [item.value];
    if (item.value === 'diagnostic-pre') {
      this.chooseDateForm.get('deadlineDiagnosticMid').setValue('');
      this.chooseDateForm.get('deadlineDiagnosticPost').setValue('');
      // Set validation if the selected diagnostic is 'diagnostic-pre'
      this.chooseDateForm
        .get('deadlineDiagnosticPre')
        .setValidators([Validators.required]);
      this.chooseDateForm.controls[
        'deadlineDiagnosticPre'
      ].updateValueAndValidity();
    } else {
      // Clear validation if not 'diagnostic-pre'
      this.chooseDateForm.get('deadlineDiagnosticPre').setValidators(null);
      this.chooseDateForm.controls[
        'deadlineDiagnosticPre'
      ].updateValueAndValidity();
    }

    if (item.value === 'diagnostic-mid') {
      this.chooseDateForm.get('deadlineDiagnosticPre').setValue('');
      this.chooseDateForm.get('deadlineDiagnosticPost').setValue('');
      this.chooseDateForm
        .get('deadlineDiagnosticMid')
        .setValidators([Validators.required]);
      this.chooseDateForm.controls[
        'deadlineDiagnosticMid'
      ].updateValueAndValidity();
    } else {
      this.chooseDateForm.get('deadlineDiagnosticMid').setValidators(null);
      this.chooseDateForm.controls[
        'deadlineDiagnosticMid'
      ].updateValueAndValidity();
    }

    if (item.value === 'diagnostic-post') {
      this.chooseDateForm.get('deadlineDiagnosticMid').setValue('');
      this.chooseDateForm.get('deadlineDiagnosticPre').setValue('');
      this.chooseDateForm
        .get('deadlineDiagnosticPost')
        .setValidators([Validators.required]);
      this.chooseDateForm.controls[
        'deadlineDiagnosticPost'
      ].updateValueAndValidity();
    } else {
      this.chooseDateForm.get('deadlineDiagnosticPost').setValidators(null);
      this.chooseDateForm.controls[
        'deadlineDiagnosticPost'
      ].updateValueAndValidity();
    }
  }

  // Utility method to convert a date string into a Date object
  getDateFromString(dateString: string): Date {
    return new Date(dateString);
  }

  // Method to check if a diagnostic should be disabled based on its end date
  isDiagnosticDisabled(diagnosticEndDate: Date) {
    if (!this.selectAnyDate) {
      const endDate = new Date(diagnosticEndDate);
      const today = new Date(this.dateTodayIso);
      endDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      return endDate < today;
    } else {
      return;
    }
  }

  closedNote: boolean = false;
  hideNote(diagnostic) {
    this.closedNote = true;
  }
  maxDate: any;
  //Method to make the selection of dates of all diagnostic tasks free
  freeDateSelection(event) {
    if (this.trialStatus.isActive) {
      this.maxDate = this.trialStatus.expiryDate;
    } else {
      this.maxDate = '';
    }

    this.selectAnyDate = !this.selectAnyDate;
    return this.selectAnyDate;
  }
}
