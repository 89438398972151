import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TranslationService } from 'src/app/providers/translation.service';

export interface selectedDiagnostic {
  deadlineDiagnosticMid?: any;
  deadlineDiagnosticPost?: any;
  deadlineDiagnosticPre?: any;
  diagnostic: string;
  source: string;
  taskVisibleFrom: any;
  dueDate: string;
  headline?: string;
  taskType?: string;
  bookTitle?: string;
  author?: string;
  wordCount?: number;
  taskMode?: string;
}

@Component({
  selector: 'app-task-test-overview',
  templateUrl: './task-test-overview.component.html',
  styleUrls: ['./task-test-overview.component.scss'],
})
export class TaskTestOverviewComponent implements OnInit, OnChanges {
  translatedText: any;
  today: Date | null = new Date();
  @Input() selectedTypeDetails: selectedDiagnostic;
  @Input() studentList: any;
  groupedStudents: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  // Group the students with count
  getGroupByStudentsData(data) {
    const groupedData = data.reduce((result, item) => {
      const { groupName, groupLength, year, ...studentData } = item;

      // If the group doesn't exist in the result, create it
      if (!result[groupName]) {
        result[groupName] = {
          groupName,
          year,
          groupLength,
          students: [],
        };
      }

      // Add the student to the group's students array
      result[groupName].students.push(studentData);

      return result;
    }, {});

    // Convert the groupedData object back to an array if needed
    this.groupedStudents = Object.values(groupedData);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['selectedTypeDetails'] &&
      changes['selectedTypeDetails']['currentValue']
    ) {
      this.selectedTypeDetails = changes['selectedTypeDetails']['currentValue'];
    }
    if (changes['studentList'] && changes['studentList']['currentValue']) {
      const students = changes['studentList']['currentValue'];
      this.getGroupByStudentsData(students);
    }
    this.cdr.detectChanges();
  }
}
