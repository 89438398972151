<section class="book_overlay">
  <div class="step_container">
    <app-hint-box
      *ngIf="!isLoading"
      [title]="translatedText.create_task_select_book"
      [text]="translatedText.create_task_select_book_desc"
      id="create-task-select-book"
      icon="glasses"
    ></app-hint-box>

    <div class="row">
      <div class="col-md-12">
        <div class="book_list-container">
          <app-trial-banner
            *ngIf="trialStatus.isActive"
            [trialHeadline]="translatedText.license_books_trial_title"
            [trialText]="translatedText.license_books_trial_description"
            [expiredHeadline]="translatedText.license_books_trial_title"
            [expiredText]="translatedText.license_books_trial_description"
            [trialStatus]="trialStatus"
            [showExpirationDate]="false"
            [showButtons]="false"
            [id]="'book-list-component'"
          ></app-trial-banner>

          <div class="book-list-filter row" *ngIf="!isLoading">
            <!-- search -->
            <mat-form-field class="book_list_search col-md-8">
              <mat-label>{{ translatedText.book_list_search }}</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="searchInputText"
                (input)="onSearchInputFilterChange($event)"
                placeholder="{{ translatedText.book_list_search }}"
              />
              <button mat-mini-fab class="light-gray" *ngIf="searchInputText == ''">
                <mat-icon svgIcon="streamline_outlined:search-2"></mat-icon>
              </button>
              <button mat-mini-fab class="light-gray" *ngIf="searchInputText != ''" (click)="resetSearch()">
                <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
              </button>
            </mat-form-field>
            <!-- filters 'button' -->
            <div class="col-md-4">
              <button class="input-button">
                <span *ngIf="numberOfSelectedFilters === 0" (click)="openBookFilters()">Filter</span>
                <span *ngIf="numberOfSelectedFilters > 0" (click)="openBookFilters()" class="selected-filter-span"
                  >{{ translatedText.training_task_create_applied_filters }}
                  <span class="orange-bg"> {{ numberOfSelectedFilters }}</span></span
                >
                <button
                  mat-mini-fab
                  class="light-gray"
                  (click)="openBookFilters()"
                  *ngIf="numberOfSelectedFilters === 0"
                >
                  <mat-icon svgIcon="streamline_outlined:filter-alt"></mat-icon>
                </button>
                <button
                  mat-mini-fab
                  class="light-gray light-gray--close"
                  (click)="resetFilters()"
                  *ngIf="numberOfSelectedFilters > 0"
                >
                  <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
                </button>
              </button>
            </div>
          </div>

          <app-loading-spinner *ngIf="isLoading" [id]="'fixed-height'"></app-loading-spinner>

          <div class="book_list_content" *ngIf="!isLoading">
            <button
              class="any-book-button"
              [ngClass]="{ active: anyBookSelection }"
              (click)="studentChoosesBook($event)"
              *ngIf="taskType === 'time'"
            >
              <img src="../../../assets/any_book_img.svg" alt="Image of a pile of books" />
              <div>
                <p class="any-book-title paragraph--bold paragraph--l">
                  {{ translatedText.training_task_create_any_book_button_title }}
                </p>
                <p>{{ translatedText.training_task_create_any_book_button_desc }}</p>
              </div>
            </button>

            <div *ngFor="let category of filterCategories">
              <div class="row">
                <div class="col-md-12" *ngIf="categoryCheck(category)">
                  <h2>
                    {{ translatedText['book_preferences_' + this.replaceUmlautsAndLowercaseAndPrefix(category)] }}
                  </h2>
                </div>
                <div class="col-md-12">
                  <div class="book_wrap">
                    <div
                      *ngFor="let book of categoryFilter(category)"
                      [ngClass]="{ active: selectedIsbn === book?._book?.isbn,  }"
                      class="book"
                      (click)="
                        setBook(
                          book?._book?.isbn,
                          book?._book?.title,
                          book?._book?.book_description,
                          book?._book?.author,
                          book?._contenthub?.dsef_version
                        )
                      "
                    >
                      <div>
                        <img
                          class="book_cover"
                          src="{{ getBookCoverByIsbn(book?._book?.isbn, 'normal') }}"
                          alt="{{ book?._book?.title }}"
                          loading="lazy"
                          (error)="handleImageError($event, book?._book)"
                          *ngIf="book?._book"
                        />

                        <p class="paragraph paragraph--bold">{{ book?._book?.title }}</p>
                        <p class="paragraph paragraph--s gray-text">{{ book?._book?.author }}</p>
                        <!-- <span class="paragraph paragraph--bold" [innerHtml]="book?.title"></span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="book_list_empty" *ngIf="dataSource?.data?.length == 0">
              <img class="empty-books-img" src="../../../assets/no-books.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--xl">
                {{ translatedText.book_list_not_found }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
