<app-teacher-managing-stepper
  #stepper
  [linear]="true"
  [stepValue]="selectedIndex"
  (selectionChange)="getActiveSelection($event)"
>
  <!-- TODO: REMOVE STEPPER FROM THIS COMPONENT -->
  <cdk-step [stepControl]="rForm" #step1="cdkStep">
    <!-- step 1 -->
    <div *ngIf="stepCount === 1" class="step1_container">
      <div class="training-task_select_container">
        <div class="training_task_headline">
          <h3 class="h3 h3--bold">{{ formTitle }}</h3>
          <p class="paragraph">{{ groupName }}</p>
        </div>
        <form [formGroup]="rForm" #formDirective="ngForm">
          <div>
            <div class="label-wrap">
              <mat-label>{{ translatedText.admin_manage_teacher_selection }}</mat-label>
              <p class="paragraph paragraph--s">{{ translatedText.admin_manage_maximum_teacher }}</p>
            </div>
            <mat-form-field class="group-select">
              <mat-label>{{ translatedText.admin_group_create_select_paceholder }}</mat-label>
              <mat-icon class="mat-select-arrow" svgIcon="streamline_outlined:mat-arrow-down"></mat-icon>
              <mat-select
                formControlName="teachers"
                (valueChange)="selectedTeacherValue($event)"
                [max]="3"
                panelClass="admin-group-select teacher-managing-group-select"
                disableOptionCentering
                [multiple]="isMultipleSelection"
              >
                <mat-select-trigger>
                  <div class="selected-teacher-container">
                    <ng-container *ngFor="let selectedText of getSelectedText(); let i = index">
                      <div class="selected-teacher teacher_with_icon teacher_with_icon--gray">
                        <mat-icon class="user-icon" svgIcon="streamline_filled:round-user"></mat-icon>
                        <p class="paragraph paragraph--bold paragraph--xs">{{ selectedText.name }}</p>
                        <mat-icon
                          class="close-icon"
                          svgIcon="streamline_outlined:close"
                          (click)="removeSelectedTeacher(selectedText, $event)"
                        ></mat-icon>
                      </div>
                    </ng-container>
                  </div>
                </mat-select-trigger>
                <mat-option
                  class="teacher-selection"
                  *ngFor="let teacher of teachers"
                  [value]="teacher?._id"
                  [disabled]="checkTeacherArray(teacher?._id, groupTeachers) || checkGrouplength(groupTeachers)"
                >
                  <div class="teacher_options-wrap">
                    <div class="teacher_with_icon">
                      <mat-icon svgIcon="streamline_filled:round-user"></mat-icon>
                      <p class="paragraph paragraph--s">
                        {{ teacher?.firstname }} {{ teacher?.lastname }}
                        <span *ngIf="teacher?._user.role == 'school_admin'">(A)</span>
                      </p>
                    </div>
                    <div class="teachers-group">
                      <p class="paragraph paragraph--s" *ngFor="let group of teacher._group">
                        {{ group?.name }} - {{ group?.school_year }}<span>,</span>
                      </p>
                    </div>
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="selected_teacher_group">
        <div class="teacher_with_icon teacher_with_icon--gray" *ngIf="groupTeachers.length === 0">
          <mat-icon class="teacher_icon" svgIcon="streamline_filled:round-user"></mat-icon>
          <p class="paragraph">{{ translatedText.admin_manager_no_teacher_msg }}</p>
        </div>
        <ul class="selected_teacher_info" *ngIf="groupTeachers.length">
          <li *ngFor="let teacher of groupTeachers" class="selected_teacher_list">
            <div class="teacher_with_icon teacher_with_icon--gray">
              <mat-icon class="teacher_icon" svgIcon="streamline_filled:round-user"></mat-icon>
              <p class="paragraph paragraph--s">{{ teacher.firstname }} {{ teacher.lastname }}</p>
            </div>
            <div class="teacher_status">
              <span>{{
                teacher.status === 'active'
                  ? translatedText.admin_edit_status_active
                  : translatedText.admin_edit_status_inactive
              }}</span>
            </div>

            <mat-icon
              svgIcon="streamline_filled:remove_teacher"
              class="icon-27"
              (click)="removeIndividualTeacher(teacher)"
            ></mat-icon>
          </li>
        </ul>
      </div>
      <div class="teacher_add_remove_status" *ngIf="successMsg !== ''">
        <div>
          <p class="paragraph paragraph--s" [innerHtml]="successMsg"></p>
        </div>
        <div class="teacher_status_undo">
          <mat-icon svgIcon="streamline_filled:round-user"></mat-icon>
          <p class="paragraph paragraph--s paragraph--bold">{{ translatedText.admin_manage_teacher_remove_undo }}</p>
        </div>
      </div>
      <div class="button_wrap">
        <button mat-flat-button color="secondary" type="button" (click)="closeTeacherOverlay()" [disabled]="isLoading">
          {{ translatedText.admin_teacher_managing_cancel }}
        </button>
        <button
          [disabled]="rForm.invalid || isLoading"
          class="teacher_sidebar_primary"
          mat-flat-button
          color="primary"
          type="button"
          (click)="submitForm(formDirective)"
        >
          <span *ngIf="!isLoading">{{ submitText }}</span>
          <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
        </button>
      </div>
    </div>
    <!-- step 2 -->
    <div *ngIf="stepCount === 2" class="remove_teacher_confirm_section">
      <div class="training-task_select_container remove_teacher_confirm">
        <div class="help_info_icon">
          <mat-icon svgIcon="streamline_outlined:teacher_help_info" class="check-icon"></mat-icon>
        </div>

        <div class="training_task_headline">
          <h3 class="h3 h3--bold">{{ translatedText.admin_manage_teacher_transform }}</h3>
        </div>

        <div class="button_wrap">
          <button mat-flat-button color="secondary" (click)="removeTeacherFromGroup()">
            {{ translatedText.dashboard_license_teacher_no }}
          </button>
          <button mat-flat-button color="primary" (click)="stepCount = 3">
            <span *ngIf="!isLoading">{{ translatedText.teacher_selection_yes }}</span>
            <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
          </button>
        </div>
      </div>
    </div>
    <!-- step 3 -->
    <div *ngIf="stepCount === 3" class="remove_teacher_confirm_section">
      <div class="training-task_select_container remove_teacher_confirm">
        <form [formGroup]="removeReacherForm" #formDirective="ngForm">
          <div class="remove_teacher_headline">
            <h3 class="h3 h3--bold">{{ translatedText.admin_manage_teacher_delegate }}</h3>
            <p class="p_task_info">{{ translatedText.admin_manage_teacher_transfer_task_info }}</p>
          </div>
          <mat-form-field class="group-select">
            <mat-icon class="mat-select-arrow" svgIcon="streamline_outlined:mat-arrow-down"></mat-icon>
            <mat-select
              formControlName="teacher"
              panelClass="admin-group-select teacher-managing-group-select"
              (valueChange)="selectSubstituteTeacher($event)"
              disableOptionCentering
            >
              <mat-select-trigger>
                <div class="selected-group-container substitute_teacher">
                  <ng-container>
                    <div class="selected-group">
                      <mat-icon svgIcon="streamline_filled:round-user" class="selected-teacher"></mat-icon>
                      <p class="paragraph paragraph--bold paragraph--xs" *ngIf="selectedSubstituteTeacher">
                        {{ selectedSubstituteTeacher.firstname }} {{ selectedSubstituteTeacher.lastname }}
                      </p>
                    </div>
                  </ng-container>
                </div>
              </mat-select-trigger>
              <mat-option
                class="teacher-selection"
                *ngFor="let teacher of substituteTeachersList"
                [value]="teacher._id"
              >
                <div class="selected-group-wrap">
                  <div class="teacher_with_icon teacher_with_icon--gray option">
                    <mat-icon svgIcon="streamline_filled:round-user"></mat-icon>
                    <p class="paragraph paragraph--bold">
                      {{ teacher?.firstname }} {{ teacher?.lastname }}
                      <span *ngIf="teacher?._user.role == 'school_admin'">(A)</span>
                    </p>
                  </div>
                  <div class="teachers-group">
                    <p class="paragraph paragraph--s" *ngFor="let group of teacher._group">
                      {{ group?.name }} - {{ group?.school_year }}<span>,</span>
                    </p>
                  </div>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="button_wrap">
            <button mat-flat-button color="secondary" (click)="stepCount = 2">
              {{ translatedText.admin_edit_backbutton }}
            </button>
            <button
              [disabled]="removeReacherForm.invalid || isLoading"
              mat-flat-button
              color="primary"
              (click)="submitRemoveTeacherForm()"
            >
              <span *ngIf="!isLoading">{{ translatedText.admin_edit_save }}</span>
              <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Single Teacher Remove with out task -->
    <div *ngIf="isRemoveConfirmation && stepCount === 5" class="remove_teacher_confirm_section">
      <div class="training-task_select_container remove_teacher_confirm">
        <div class="help_info_icon">
          <mat-icon svgIcon="streamline_outlined:warning-circle-orange" class="check-icon"></mat-icon>
        </div>
        <h3 class="h3 h3--bold mt-3">{{ translatedText.admin_group_list_remove_teacher }}</h3>
        <p class="paragraph paragraph--xl mt-3">{{ translatedText.teacher_delete_text }}</p>
        <div class="teacher-name-wrap mt-3">
          <mat-icon svgIcon="streamline_filled:remove-teacher-empty-icon" class="icon-80"></mat-icon>
          <h3 class="h3 h3--bold">{{ selectedTeacher.firstname }} {{ selectedTeacher.lastname }}</h3>
        </div>

        <div class="button_wrap">
          <button mat-flat-button color="secondary" type="button" [mat-dialog-close]="'close'">
            {{ translatedText.teacher_delete_cancel }}
          </button>
          <button
            mat-flat-button
            color="warning"
            type="button"
            class="teacher-delete_button"
            (click)="removeTeacherFromGroup()"
          >
            <span *ngIf="!isLoading">{{ translatedText.teacher_delete_accept }}</span>
            <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
          </button>
        </div>
      </div>
    </div>
  </cdk-step>
</app-teacher-managing-stepper>
