<div class="row">
  <div class="col-md-12 bookview_overlay_col">
    <div *ngIf="!isTask" class="bookview_header">
      <div class="row bookview_header_row">
        <!-- <div class="col-lg-3 col-md-4">
                        <button mat-fab type="button" class="backButton close_preview" [mat-dialog-close]="true" matSuffix>
                            <mat-icon svgIcon="streamline_outlined:left"></mat-icon>
                            Zurück
                        </button> 
                    </div> -->
        <div class="col-lg-10 col-md-10 col-xl-7">
          <!-- <p class="paragraph paragraph--bold paragraph--l"><span [innerHtml]="bookTitle"></span>
                            <span class="paragraph paragraph--l" *ngIf="bookAuthor"> von {{ bookAuthor}} </span>
                        </p> -->
          <p class="paragraph paragraph--l">
            <strong
              ><span class="reading-countdown">{{ 180 - time | readingTimeFormat | timeformat : 'min' }}</span>
              {{ translatedText.book_view_minutes }}</strong
            >
            {{ translatedText.book_view_time }}
          </p>
        </div>
        <div class="col-lg-5 col-md-7">
          <div class="inline_text">
            <p class="paragraph paragraph--l">
              {{ translatedText.book_view_selected }}
              <span class="paragraph paragraph--bold paragraph--l primary_text"
                >{{ wordcount }} {{ translatedText.book_view_words }}</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isTask" class="bookview_header">
      <div class="row">
        <div class="col-8">
          <div class="inline_text">
            <button
              mat-fab
              type="button"
              class="backButton close_preview book_view_close"
              [mat-dialog-close]="true"
              matSuffix
            >
              <mat-icon svgIcon="streamline_outlined:left"></mat-icon>
            </button>
            <p class="paragraph paragraph--l">
              {{ translatedText.book_view_you_have }}
              <strong
                ><span class="reading-countdown">{{ 180 - time | readingTimeFormat | timeformat : 'min' }}</span>
                {{ translatedText.book_view_minutes }}</strong
              >
              {{ translatedText.book_view_time_text }}
            </p>
          </div>
        </div>
        <div class="col-4">
          <div class="inline_text bookview_header-bookinfo">
            <p class="paragraph paragraph--bold paragraph--l" [innerHtml]="bookTitle"></p>
            <p class="paragraph paragraph--bold paragraph--s">
              {{ bookAuthor }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row book_reader_row_height">
      <div class="col-md-12">
        <app-book-reader-view
          [userTaskUuid]="userTaskUuid"
          [resetSelection]="resetSelection"
          [bookIsbn]="bookIsbn"
          [dsefVersion]="dsefVersion"
          [bookReaderType]="isTask ? 'get' : 'set'"
          fromComponent="bookview"
        ></app-book-reader-view>
      </div>
    </div>
    <div class="bookview_footer" [ngClass]="{ 'button-up': iPadResolution }">
      <div class="book_reader_settings">
        <button
          mat-mini-fab
          type="button"
          class="backButton toc"
          #toctooltip="matTooltip"
          matTooltip="{{ translatedText.book_view_toc }}"
          matTooltipClass="toc-tooltip"
          (click)="openBookReaderToc()"
          matSuffix
        >
          <!-- <mat-icon aria-hidden="false" fontIcon="toc"></mat-icon> -->
          <mat-icon aria-hidden="false" fontIcon="toc"></mat-icon>
          <!-- {{translatedText.book_view_toc_short}} -->
        </button>
        <!--  <div class="col-4">
        <app-book-reader-nav [bookReaderType]="isTask ? 'get' : 'set'"></app-book-reader-nav> 
        </div> -->
        <!-- <app-book-reader-pagination></app-book-reader-pagination> -->
        <div *ngIf="isTeacher && wordcount > 0">
          <button mat-flat-button color="secondary" type="button" (click)="resetWordcount()">
            {{ translatedText.book_view_reset }}
          </button>
        </div>
      </div>
      <div class="bookview_footer_buttons">
        <button *ngIf="!isTeacher" mat-flat-button color="primary-student" type="button" (click)="startRecord()">
          {{ translatedText.book_view_start }}
        </button>
        <button *ngIf="!isTask" mat-flat-button color="secondary" (click)="navigateBackToBookPreview()">
          {{ translatedText.book_view_back }}
        </button>
        <button
          *ngIf="isTeacher"
          mat-flat-button
          color="primary"
          type="button"
          (click)="closeBookview()"
          [disabled]="wordcount == 0"
        >
          {{ translatedText.book_view_select_text }}
        </button>
      </div>
    </div>
  </div>
</div>
