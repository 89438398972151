<div class="diagnostic-task-create">
  <div class="text-align-center">
    <h2 class="h2 h2--bold mt-5 mb-2">{{ translatedText.diagnostic_create_create_test }}</h2>
    <p class="paragraph paragraph--m">{{ translatedText.diagnostic_create_select_diagnostic }}</p>
  </div>

  <p class="paragraph paragraph--l paragraph--bold mt-5">{{ translatedText.diagnostic_create_select_date }}</p>
  <app-trial-banner
    *ngIf="trialStatus.isActive"
    [trialHeadline]="translatedText.license_books_trial_title"
    [trialText]="translatedText.license_task_trial_diagnostic_description"
    [trialStatus]="trialStatus"
    [showExpirationDate]="false"
    [showButtons]="false"
  ></app-trial-banner>
  <form [formGroup]="chooseDateForm" class="force-margin">
    <div
      class="create-box"
      *ngFor="let diagnostic of diagnostics"
      (click)="selectDateRange(diagnostic)"
      [ngClass]="{
        selected: diagnostic.checked,
        'disabled-div': isDiagnosticDisabled(diagnostic.endDate) && !diagnostic.checked
      }"
      [matTooltip]="isDiagnosticDisabled(diagnostic.endDate) ? translatedText.diagnostic_create_disabled_tooltip : ''"
    >
      <div class="box-header" [ngSwitch]="diagnostic.phase">
        <div class="box-title" *ngSwitchCase="1">{{ translatedText.diagnostic_create_beginning_year }}</div>
        <div class="box-title" *ngSwitchCase="2">{{ translatedText.diagnostic_create_middle_year }}</div>
        <div class="box-title" *ngSwitchCase="3">{{ translatedText.diagnostic_create_end_year }}</div>

        <div class="box-date">
          {{ diagnostic.startDate | date : 'd. MMM y' }} - {{ diagnostic.endDate | date : 'd. MMM y' }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <p class="paragraph paragraph--s paragraph--bold">{{ translatedText.diagnostic_create_due_date }}</p>
        </div>
        <div class="col-8">
          <mat-form-field (click)="$event.stopPropagation()">
            <input
              matInput
              placeholder="{{ translatedText.date_format }}"
              [min]="selectAnyDate ? dateToday : getMinDate(diagnostic.startDate, diagnostic.checked)"
              [max]="selectAnyDate ? maxDate : getMaxDate(diagnostic.endDate, diagnostic.checked)"
              onkeypress="return false"
              [formControlName]="'deadline' + diagnostic.deadline"
              type="text"
              (click)="deadlinepicker.open(); selectDateRange(diagnostic)"
              [matDatepicker]="deadlinepicker"
              [matTooltip]="trialToolTip"
              (dateChange)="dateValueChanged($event)"
              [disabled]="(isDiagnosticDisabled(diagnostic.endDate) && !diagnostic.checked) || !selectAnyDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="deadlinepicker"
              (click)="selectDateRange(diagnostic)"
              [disabled]="isDiagnosticDisabled(diagnostic.endDate) && !diagnostic.checked && !selectAnyDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #deadlinepicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="create-info-box" *ngIf="diagnostic.isNote && diagnostic?.checked === true && !selectAnyDate">
        <div class="info-details">
          <!-- <button mat-mini-fab type="button" class="info-close" (click)="diagnostic.isNote = false">
            <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
          </button> -->
          <div><mat-icon class="icon-24" svgIcon="streamline_outlined:info_empty_gray"></mat-icon></div>

          <p class="paragraph paragraph--s">
            {{ translatedText.diagnostic_create_recommended_due_date }}
          </p>
        </div>
      </div>
    </div>
    <div class="diagnostic-date-changer">
      <mat-checkbox #diagnosticDateChanger (change)="freeDateSelection($event)">{{
        translatedText.diagnostic_create_time
      }}</mat-checkbox
      ><!-- [disabled]="trialStatus.isActive" -->
      <mat-icon
        matTooltip="{{ translatedText.diagnostic_create_time_text }}"
        svgIcon="streamline_outlined:hint"
      ></mat-icon>
    </div>
    <div class="avatar-hint-box">
      <app-hint-box
        [title]="translatedText.diagnostic_create_hintbox_title"
        [text]="translatedText.diagnostic_create_hintbox_desc"
        [id]="hintBoxId"
      ></app-hint-box>
    </div>

    <div class="task-visibility-box mt-3" *ngIf="!infoBoxClosed && taskVisibleFrom">
      <span
        ><mat-icon svgIcon="streamline_outlined:calendar"></mat-icon>
        <p class="paragraph paragraph--m paragraph--bold">
          {{ translatedText.diagnostic_create_recommended_visibility_learner }}
        </p></span
      >
      <!-- <p class="paragraph paragraph--s">
        {{ translatedText.diagnostic_create_recommended_visibility_weeks }}
      </p> -->
      <p class="paragraph">
        <span *ngIf="dateToday >= taskVisibleFrom">
          {{ translatedText.training_task_create_task_visibility_desc_immmediately }}</span
        >
        <span *ngIf="dateToday < taskVisibleFrom">{{
          translatedText.t('training_task_create_task_visibility_desc', {
            taskVisibleFrom: viewValueTaskVisibily
          })
        }}</span>
      </p>

      <button mat-mini-fab type="button" class="close-button" (click)="infoBoxClosed = !infoBoxClosed">
        <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
      </button>
    </div>
  </form>
</div>
