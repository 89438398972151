<div class="hint-box hint-box--right-top {{ className }}" *ngIf="!isClosed">
  <div class="hint-box_content">
    <span class="close-icon" (click)="handleClose()">&times;</span>
    <span class="inline_text">
      <mat-icon svgIcon="streamline_outlined:{{ icon }}" *ngIf="icon != undefined"></mat-icon>
      <p class="paragraph paragraph--bold">{{ title }}</p></span
    >
    <p class="paragraph paragraph--s">{{ text }}</p>
  </div>
  <div class="hint-box_arrow"></div>
</div>
