<div class="student_edit_overlay_container">
  <button mat-mini-fab type="button" class="close-button close-button--left" (click)="closeOverlay()"
    [matDialogClose]="'close'" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="student_edit_overlay_wrap">
    <div class="more_action_position">
      <button mat-flat-button color="unset" class="more_action" [matMenuTriggerFor]="moreGroupMenu"
        (click)="optionsMenuOpened = !optionsMenuOpened" [ngClass]="{ action_select: optionsMenuOpened }">
        <mat-icon svgIcon="streamline_filled:down_arrow"></mat-icon>
        <span>{{ translatedText.student_edit_options }}</span>
      </button>
      <mat-menu #moreGroupMenu="matMenu" class="wider-mat-menu" (closed)="optionsMenuOpened = false">
        <ul class="student_option_menu">
          <li>
            <button type="button" (click)="openPdfInfo()" [disabled]="
                getAge(rForm.get('age').value) == 0 ||
                student?.age == '' ||
                rForm.get('language').value == '' ||
                getGenderValue(rForm.get('gender').value) == '' ||
                isLoading
              ">
              <mat-icon svgIcon="streamline_outlined:student_download"></mat-icon>
              <span>{{ translatedText.student_pdf_overlay_info_download }}</span>
            </button>
          </li>
          <li *ngIf="!editMode">
            <button aria-label="edit-profile" (click)="editPersonalInfo()">
              <mat-icon svgIcon="streamline_outlined:student_edit"></mat-icon>
              <span>{{ translatedText.student_edit }}</span>
            </button>
          </li>
          <li>
            <button aria-label="license-assign" (click)="openLicenseDistribution(student, 'assign')" [disabled]="
                (hasActiveLicense(student?.licenseInfo, 'di') && hasActiveLicense(student?.licenseInfo, 'fo')) ||
                student?.status == 'inactive'
              ">
              <mat-icon svgIcon="streamline_outlined:student_assign_license"></mat-icon>
              <span>{{ translatedText.student_assign_license }}</span>
            </button>
          </li>
          <li>
            <button aria-label="license-release" (click)="openLicenseDistribution(student, 'release')" [disabled]="
                !(
                  (hasActiveLicense(student?.licenseInfo, 'di') && availableLicenses.totalCountDiagnostic < 100) ||
                  hasActiveLicense(student?.licenseInfo, 'fo')
                )
              ">
              <mat-icon svgIcon="streamline_outlined:student_release_license"></mat-icon>
              <span>{{ translatedText.student_release_license }}</span>
            </button>
          </li>
          <li>
            <button aria-label="view-detail" (click)="updateStudentStatus(student)">
              <mat-icon svgIcon="streamline_outlined:pause-window"></mat-icon>
              <ng-container *ngIf="student?.status == 'active'">
                <span>{{ translatedText.admin_group_list_set_inactive }}</span>
              </ng-container>
              <ng-container *ngIf="student?.status == 'inactive'">
                <span>{{ translatedText.admin_group_list_set_active }}</span>
              </ng-container>
            </button>
          </li>
          <li>
            <button aria-label="change-password" (click)="openChangePassword()">
              <mat-icon svgIcon="streamline_outlined:student-password-lock"></mat-icon>
              <span>{{ translatedText.student_edit_change_password }}</span>
            </button>
          </li>
          <hr />
          <li class="delete" *ngIf="tasksLoaded && studentTasks?.length === 0">
            <button class="delete-btn" aria-label="view-detail" (click)="openStudentDeleteDialog()">
              <mat-icon svgIcon="streamline_outlined:trash"></mat-icon>
              <span>{{ translatedText.admin_group_list_delete }}</span>
            </button>
          </li>
          <div class="delete_msg" *ngIf="tasksLoaded && studentTasks?.length != 0">
            <p>
              <mat-icon svgIcon="streamline_outlined:trash"></mat-icon>
              <span>{{ translatedText.admin_group_list_delete }}</span>
            </p>
            <p>{{ translatedText.admin_delete_student_warning }}</p>
          </div>
        </ul>
      </mat-menu>
    </div>

    <div class="user_profile_section">
      <img src="{{ student?.avatarUrl }}" alt="student avatar" />
      <h2 class="h2 h2--bold">{{ student?.username }}</h2>
    </div>
    <!-- <div
      class="student-status-chip"
      [ngClass]="
        student?.status == 'active'
          ? 'student-status-chip--active'
          : 'student-status-chip--inactive'
      "
      *ngIf="!editMode"
    >
      <p class="paragraph paragraph--bold paragraph--xs">
        {{
          student?.status == "active"
            ? translatedText.student_edit_status_active
            : translatedText.student_edit_status_inactive
        }}
      </p>
    </div> -->

    <!-- <div
      class="student-edit-button-wrap"
      *ngIf="!editMode && userType == 'school_admin'"
    >
       <button
        mat-flat-button
        color="tertiary"
        type="button"
        (click)="openPdfInfo()"
        [disabled]="
          getAge(rForm.get('age').value) == 0 ||
          student?.age == '' ||
          rForm.get('language').value == '' ||
          getGenderValue(rForm.get('gender').value) == '' ||
          isLoading
        "
      >
        <mat-icon svgIcon="streamline_outlined:download-circle"></mat-icon
        >{{ translatedText.student_edit_download }}
      </button> -->

    <!-- <button
        mat-flat-button
        color="tertiary"
        type="button"
        (click)="updateStudentStatus(student?.status)"
      >
        <mat-icon svgIcon="streamline_outlined:pause-window"></mat-icon>
        {{
          student?.status == "active"
            ? translatedText.student_edit_set_inactive
            : translatedText.student_edit_set_active
        }}
      </button>
    </div>-->

    <div class="student-edit-info mt-38" *ngIf="!editMode">
      <div class="personal-info">
        <!-- <div class="personal-info-header" [ngClass]="userType == 'teacher' ? 'personal-info-teacher-header' : ''">
          <button
            *ngIf="userType == 'teacher'"
            (click)="editPersonalInfo()"
            mat-flat-button
            color="tertiary"
            aria-label="edit"
          >
            <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon>
            {{ translatedText.student_edit_personal_info }}
          </button>
        </div> -->

        <div>
          <div class="paragraph paragraph--bold">
            {{ translatedText.student_personal_info }}
          </div>
          <div class="student-edit-info-row">
            <p class="paragraph paragraph">
              {{ translatedText.student_edit_group }}
            </p>
            <p class="paragraph paragraph--bold">
              {{ getGroupName(student?._group)?.name }}
            </p>
          </div>

          <div class="student-edit-info-row">
            <p class="paragraph paragraph">
              {{ translatedText.student_edit_language }}
            </p>
            <p class="paragraph paragraph--bold">
              {{ getLanguageByCode(rForm.get('language').value) }}
            </p>
          </div>

          <div class="student-edit-info-row">
            <p class="paragraph paragraph">
              {{ translatedText.student_birthdate_month }}
            </p>
            <p class="paragraph paragraph--bold">
              <span *ngIf="getAge(rForm.get('age').value) !== 0 && student?.age !== ''">
                {{ rForm.get('age').value | date : 'MMM y' }} ({{ getAge(rForm.get('age').value) }}
                {{ translatedText.student_edit_years }})
              </span>
            </p>
          </div>

          <div class="student-edit-info-row">
            <p class="paragraph paragraph">
              {{ translatedText.student_gender }}
            </p>
            <p class="paragraph paragraph--bold">
              <span class="gender-info">
                <mat-icon *ngIf="rForm.get('gender').value == 'male'" class="male-icon"
                  svgIcon="streamline_outlined:male"></mat-icon>
                <mat-icon *ngIf="rForm.get('gender').value == 'female'" class="female-icon"
                  svgIcon="streamline_outlined:female"></mat-icon>
                <mat-icon *ngIf="rForm.get('gender').value == 'divers'" class="divers-icon"
                  svgIcon="streamline_outlined:divers"></mat-icon>
                {{ getGenderValue(rForm.get('gender').value) }}
              </span>
            </p>
          </div>

          <!-- <div class="student-edit-info-row" *ngIf="userType == 'school_admin'">
            <p class="paragraph paragraph">
              {{ translatedText.student_edit_role }}
            </p>
            <p class="paragraph paragraph--bold">
              {{ translatedText.student_edit_students }}
            </p>
          </div> -->
          <!-- <div class="student-edit-info-row" *ngIf="userType == 'teacher'">
              <p class="paragraph paragraph">Zielwert</p>
              <p class="paragraph paragraph--bold">{{this.rForm.get('reference_value').value}}</p>
            </div> -->
          <!-- <div class="student-edit-info-row">
            <p class="paragraph paragraph">{{ translatedText.student_edit_password }}</p>
            <a class="links" (click)="openChangePassword()">{{ translatedText.student_edit_change_password }}</a>
          </div> -->
        </div>

        <div class="mt-38">
          <div class="paragraph paragraph--bold">
            {{ translatedText.student_licenses }}
          </div>

          <div class="student-edit-info-row">
            <p class="paragraph paragraph">{{ translatedText.student_diagnostic_license }}</p>

            <ng-container *ngIf="hasActiveLicense(student?.licenseInfo, 'di')">
              <mat-icon *ngIf="!checkLicenseAboutToExpired(student?.licenseInfo, 'di')"
                matTooltip="{{ translatedText.admin_group_list_license_info_active }}" color="primary"
                svgIcon="streamline_outlined:license_available" class="icon-40"></mat-icon>
              <mat-icon *ngIf="checkLicenseAboutToExpired(student?.licenseInfo, 'di')"
                matTooltip="{{ translatedText.admin_group_list_license_info_active }}" color="primary"
                svgIcon="streamline_outlined:license_info" class="icon-40"></mat-icon>
            </ng-container>

            <ng-container *ngIf="!hasActiveLicense(student?.licenseInfo, 'di')">
              <mat-icon *ngIf="checkLicenseExistence(student?.licenseInfo, 'di')"
                matTooltip="{{ translatedText.admin_group_list_license_info_expired }}" color="warn"
                svgIcon="streamline_outlined:cancel_circle" class="icon-40"></mat-icon>
              <mat-icon *ngIf="!checkLicenseExistence(student?.licenseInfo, 'di')"
                matTooltip="{{ translatedText.admin_group_list_license_info_none_diagnostic }}" color="secondary"
                svgIcon="streamline_outlined:license_minus" class="icon-40"></mat-icon>
            </ng-container>
          </div>
          <div class="student-edit-info-row">
            <p class="paragraph paragraph">
              {{ translatedText.student_training_license }}
            </p>
            <ng-container *ngIf="hasActiveLicense(student?.licenseInfo, 'fo')">
              <mat-icon *ngIf="!checkLicenseAboutToExpired(student?.licenseInfo, 'fo')"
                matTooltip="{{ translatedText.admin_group_list_license_info_active }}" color="primary"
                svgIcon="streamline_outlined:license_available" class="icon-40"></mat-icon>
              <mat-icon *ngIf="checkLicenseAboutToExpired(student?.licenseInfo, 'fo')"
                matTooltip="{{ translatedText.admin_group_list_license_info_active }}" color="primary"
                svgIcon="streamline_outlined:license_info" class="icon-40"></mat-icon>
            </ng-container>

            <ng-container *ngIf="!hasActiveLicense(student?.licenseInfo, 'fo')">
              <mat-icon *ngIf="checkLicenseExistence(student?.licenseInfo, 'fo')"
                matTooltip="{{ translatedText.admin_group_list_license_info_expired }}" color="warn"
                svgIcon="streamline_outlined:cancel_circle" class="icon-40"></mat-icon>
              <mat-icon *ngIf="!checkLicenseExistence(student?.licenseInfo, 'fo')"
                matTooltip="{{ translatedText.admin_group_list_license_info_none_training }}" color="secondary"
                svgIcon="streamline_outlined:license_minus" class="icon-40"></mat-icon>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- <div class="license-info">
          <p class="paragraph paragraph--bold headline">Lizenzen</p>
          <div>
            <div class="student-edit-info-row">
              <p class="paragraph paragraph">Diagnoselizenzen</p>
              <div class="student-licenses">
                <p class="paragraph paragraph--bold">0</p>
              </div>
            </div>
            <div class="student-edit-info-row">
              <p class="paragraph paragraph">{{environment.whitelabel.name}} Minuten</p>
              <div class="student-licenses">
                <p class="paragraph paragraph--bold">0</p>
              </div>
            </div>
          </div>
        </div> -->
    </div>

    <div class="student-info-footer" *ngIf="!editMode">
      <!-- <button
        mat-flat-button
        color="primary"
        *ngIf="userType == 'school_admin'"
        (click)="editPersonalInfo()"
        type="submit"
      >
        <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon
        >{{ translatedText.student_edit_edit_personal_info }}
      </button> -->

      <button mat-flat-button color="primary" *ngIf="userType == 'teacher'" (click)="regeneratePdfForStudent()"
        type="submit" [disabled]="isLoading">
        <span class="inline_text" *ngIf="!isLoading">
          <mat-icon svgIcon="streamline_outlined:download-circle"></mat-icon>
          {{ translatedText.student_edit_download_pdf }}
        </span>
        <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
      </button>
    </div>

    <form [formGroup]="rForm" #formDirective="ngForm" *ngIf="editMode">
      <div class="student-edit-info">
        <div class="paragraph paragraph--bold">
          {{ translatedText.student_personal_info }}
        </div>

        <div class="student-edit-info-row">
          <p class="paragraph paragraph">
            {{ translatedText.student_edit_group }}
          </p>
          <p class="paragraph paragraph--bold">
            {{ getGroupName(student?._group)?.name }}
          </p>
        </div>
        <div class="student-edit-info-row">
          <p class="paragraph paragraph flex-1">
            {{ translatedText.student_edit_language }}
          </p>

          <mat-form-field class="flex-1">
            <input type="text" placeholder="{{ translatedText.student_edit_language }}" aria-label="Muttersprache"
              matInput formControlName="language" [matAutocomplete]="auto" appAutocompletePosition="trigger" required />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"
              (optionSelected)="setLanguageOption($event?.option?.value)">
              <mat-option *ngFor="let language of filteredOptions | async" [value]="language?.code">
                {{ getLanguageByCode(language?.code) }}</mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="
                rForm.get('language').hasError('incorrect') &&
                rForm.get('language').touched &&
                !rForm.get('language').hasError('required')
              ">
              {{ translatedText.student_edit_language_error }}
            </mat-error>
            <mat-error *ngIf="rForm.get('language').hasError('required')">
              {{ translatedText.student_edit_language_selection }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="student-edit-info-row">
          <p class="paragraph paragraph flex-1">
            {{ translatedText.student_birthdate_month }}
          </p>

          <mat-form-field class="flex-1">
            <input matInput formControlName="age" type="text" [max]="maxDate" (click)="birthdaypicker.open()"
              [matDatepicker]="birthdaypicker" placeholder="MM.JJJJ" required />
            <mat-datepicker-toggle matSuffix [for]="birthdaypicker"></mat-datepicker-toggle>
            <mat-datepicker #birthdaypicker startView="multi-year"
              (monthSelected)="setMonthAndYear($event, birthdaypicker)"></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="student-edit-info-row">
          <p class="paragraph paragraph flex-1">
            {{ translatedText.student_edit_gender }}
          </p>
          <div class="flex-1">
            <mat-form-field class="dropdown-select-with-arrow">
              <mat-icon class="mat-select-arrow" svgIcon="streamline_outlined:mat-arrow-down"></mat-icon>
              <mat-select formControlName="gender" aria-label="Select an option" [value]="rForm.get('gender').value">
                <mat-option value="male">
                  {{ translatedText.student_edit_gender_male }}
                  <mat-icon class="male-icon" svgIcon="streamline_outlined:male"></mat-icon>
                </mat-option>
                <mat-option value="female">
                  <mat-icon class="female-icon" svgIcon="streamline_outlined:female"></mat-icon>
                  {{ translatedText.student_edit_gender_female }}
                </mat-option>
                <mat-option value="divers">
                  <mat-icon class="divers-icon" svgIcon="streamline_outlined:divers"></mat-icon>
                  {{ translatedText.student_edit_gender_divers }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="mt-38">
          <div class="paragraph paragraph--bold">
            {{ translatedText.student_licenses }}
          </div>

          <div class="student-edit-info-row" *ngIf="student">
            <p class="paragraph paragraph">
              {{ translatedText.student_diagnostic_license }}
            </p>

            <ng-container *ngIf="hasActiveLicense(student?.licenseInfo, 'di')">
              <mat-icon *ngIf="!checkLicenseAboutToExpired(student?.licenseInfo, 'di')"
                matTooltip="{{ translatedText.admin_group_list_license_info_active }}" color="primary"
                svgIcon="streamline_outlined:license_available" class="icon-40"></mat-icon>
              <mat-icon *ngIf="checkLicenseAboutToExpired(student?.licenseInfo, 'di')"
                matTooltip="{{ translatedText.admin_group_list_license_info_active }}" color="primary"
                svgIcon="streamline_outlined:license_info" class="icon-40"></mat-icon>
            </ng-container>

            <ng-container *ngIf="!hasActiveLicense(student?.licenseInfo, 'di')">
              <mat-icon *ngIf="checkLicenseExistence(student?.licenseInfo, 'di')"
                matTooltip="{{ translatedText.admin_group_list_license_info_expired }}" color="warn"
                svgIcon="streamline_outlined:cancel_circle" class="icon-40"></mat-icon>
              <mat-icon *ngIf="!checkLicenseExistence(student?.licenseInfo, 'di')"
                matTooltip="{{ translatedText.admin_group_list_license_info_none_diagnostic }}" color="secondary"
                svgIcon="streamline_outlined:license_minus" class="icon-40"></mat-icon>
            </ng-container>
          </div>
          <div class="student-edit-info-row">
            <p class="paragraph paragraph">
              {{ translatedText.student_training_license }}
            </p>
            <ng-container *ngIf="hasActiveLicense(student?.licenseInfo, 'fo')">
              <mat-icon *ngIf="!checkLicenseAboutToExpired(student?.licenseInfo, 'fo')"
                matTooltip="{{ translatedText.admin_group_list_license_info_active }}" color="primary"
                svgIcon="streamline_outlined:license_available" class="icon-40"></mat-icon>
              <mat-icon *ngIf="checkLicenseAboutToExpired(student?.licenseInfo, 'fo')"
                matTooltip="{{ translatedText.admin_group_list_license_info_active }}" color="primary"
                svgIcon="streamline_outlined:license_info" class="icon-40"></mat-icon>
            </ng-container>

            <ng-container *ngIf="!hasActiveLicense(student?.licenseInfo, 'fo')">
              <mat-icon *ngIf="checkLicenseExistence(student?.licenseInfo, 'fo')"
                matTooltip="{{ translatedText.admin_group_list_license_info_expired }}" color="warn"
                svgIcon="streamline_outlined:cancel_circle" class="icon-40"></mat-icon>
              <mat-icon *ngIf="!checkLicenseExistence(student?.licenseInfo, 'fo')"
                matTooltip="{{ translatedText.admin_group_list_license_info_none_training }}" color="secondary"
                svgIcon="streamline_outlined:license_minus" class="icon-40"></mat-icon>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- <div class="row"> -->
      <!-- <div class="col-md-6">
                <mat-form-field>
                    <mat-label>{{translatedText.student_edit_birthdate_month}student_edit_gender_2}}</mat-label>
                    <mat-select formControlName="gender" type="role"  disabled="true">
                      <mat-option value="male">{{translatedText.student_edit_gender_male}}</mat-option>
                      <mat-option value="female">{{translatedText.student_edit_gender_female}}</mat-option>
                      <mat-option value="divers">{{translatedText.student_edit_gender_divers}}</mat-option>
                    </mat-select>
                  </mat-form-field>
              </div> -->

      <!-- <div class="col-md-12">
          <div class="gender-selection student-edit">
            <mat-label></mat-label>
            <mat-form-field>
                    <mat-select formControlName="gender" type="role" disableOptionCentering panelClass="grade-select">
                      <mat-option value="male">{{translatedText.student_edit_gender_male}}</mat-option>
                      <mat-option value="female">{{translatedText.student_edit_gender_female}}</mat-option>
                      <mat-option value="{{translatedText.student_edit_gender_divers}}">{{translatedText.student_edit_gender_divers}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="rForm.get('gender').hasError('required')">
                      Bitte Geschlecht auswählen
                    </mat-error>
                  </mat-form-field>

          </div>
        </div> -->
      <!-- <div class="col-md-12" *ngIf="userType == 'teacher'">
                <mat-label>Zielwert (Optional) </mat-label>
                <mat-form-field>
                  <input matInput type="number" [min]="0" formControlName="reference_value" placeholder="Geben Sie einen Zielwert für den Lernenden ein">
                </mat-form-field>
              </div> -->

      <!-- <div class="col-md-12">
          <mat-label>{{ translatedText.student_edit_role }}</mat-label>
          <mat-form-field>
            <input
              matInput
              type="text"
              [value]="translatedText.student_edit_students"
              [disabled]="true"
            />
          </mat-form-field>
        </div> -->
      <!-- </div> -->

      <!-- <div class="row">
              <div class="col-6">
                <mat-form-field>
                  <input #passwordField matInput formControlName="password"  type="password" placeholder="Passwort">
                  <button mat-icon-button type="button" (click)="passwordField.type === 'password' ? (passwordField.type = 'text'): (passwordField.type = 'password')" matSuffix>
                    <mat-icon *ngIf="passwordField.type === 'password'" svgIcon="streamline_outlined:closed_eye"></mat-icon>
                    <mat-icon *ngIf="passwordField.type === 'text'" svgIcon="streamline_outlined:opened_eye"></mat-icon>
                  </button>

                  <mat-error *ngIf="rForm.hasError('required', 'password')">
                    Bitte setzen Sie ein Passwort
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field>
                  <mat-label>Muttersprache</mat-label>
                  <input type="text"
                  placeholder="Muttersprache"
                  aria-label="Muttersprache"
                  matInput
                  formControlName="language"
                  [matAutocomplete]="auto" required>
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)" (optionSelected)="setLanguageOption($event?.option?.value)">
                      <mat-option *ngFor="let language of filteredOptions | async " [value]="language?.code" >{{getLanguageByCode(language?.code)}}</mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="rForm.get('language').hasError('incorrect') && rForm.get('language').touched && !rForm.get('language').hasError('required')">
                    {{translatedText.student_edit_language_error}}
                  </mat-error>
                  <mat-error *ngIf="rForm.get('language').hasError('required')">
                    {{translatedText.student_edit_language_selection}}
                  </mat-error>
                </mat-form-field>
              </div>
          </div> -->

      <div class="student-info-footer">
        <button mat-flat-button color="secondary" (click)="backToPersonalInfo()" type="button">
          {{ translatedText.student_edit_back }}
        </button>
        <button mat-flat-button color="primary" (click)="submitForm(formDirective)" [disabled]="rForm.invalid"
          type="submit">
          {{ translatedText.student_edit_save }}
        </button>
      </div>
    </form>
  </div>
</div>
