<div class="task_wrap" *ngIf="isActive" class="teacher-task-list-container">
  <div class="row" *ngIf="isCardView">
    <div class="col-md-6 col-sm-6 equal_height_col" *ngFor="let task of getTasksByStatus('active')">
      <div class="task_options">
        <div class="task_option_header">
          <div class="set_inline">
            <img class="avatar_img--small" *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" />
            <div class="inline_text">
              <p class="paragraph paragraph--bold paragraph--s username">
                {{ student?.username }}
                <span class="paragraph paragraph--xs" *ngIf="getGroupName(student?._group)?.name"
                  >({{ getGroupName(student?._group)?.name }})</span
                >
              </p>
            </div>
          </div>
          <div class="task_chips_wrap">
            <ng-container *ngIf="task?.type == 'diagnostic-pre'">
              <div class="task_chip task_chip__diagnostic-pre">
                <p>{{ translatedText.task_list_diagnostic_pre }}</p>
              </div>
            </ng-container>
            <ng-container *ngIf="task?.type != 'diagnostic-pre'">
              <div class="task_chip task_chip__type">
                <p>{{ translatedText.task_list_training }}</p>
              </div>
            </ng-container>

            <div class="task_chip task_chip__test-area">
              <p>{{ translatedText.task_list_fluency }}</p>
            </div>
          </div>
        </div>
        <div>
          <div class="inline_text">
            <ng-container *ngIf="task?.type == 'text'">
              <p class="paragraph paragraph--s">
                {{ translatedText.task_list_book }}
              </p>
              <p class="paragraph paragraph--bold paragraph--s">
                {{ replaceHtmlContent(task?.book_name) }}
              </p>
            </ng-container>
            <ng-container
              *ngIf="
                task?.type == 'diagnostic-pre' || task?.type == 'diagnostic-mid' || task?.type == 'diagnostic-post'
              "
            >
              <p class="paragraph paragraph--s">
                {{ generateDiagnosticTitle(task?.type) }}
              </p>
            </ng-container>
          </div>
          <div class="inline_text">
            <p class="paragraph paragraph--s">
              {{ translatedText.task_list_task }}
            </p>
            <p class="paragraph paragraph--bold paragraph--s">
              {{ task?.description }}
            </p>
          </div>
          <div class="inline_text">
            <p class="paragraph paragraph--s">{{ translatedText.task_list_continuous }}:</p>
            <p class="paragraph paragraph--bold paragraph--s">-</p>
          </div>
          <div class="inline_text">
            <p class="paragraph paragraph--s">{{ translatedText.task_list_deadline }}:</p>
            <p class="paragraph paragraph--bold paragraph--s">
              {{ task?.deadline | date : 'dd.MM.yyyy' }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isTableView">
    <div class="table-wrapper">
      <div class="table-container">
        <table
          mat-table
          [dataSource]="getTableData('active')"
          matSort
          matSortDisableClear
          matSortDirection="desc"
          class="mat-elevation-z8 task_table_view"
        >
          <ng-container matColumnDef="username">
            <th mat-sort-header="username" mat-header-cell *matHeaderCellDef class="column-hidden-tablet">
              <span class="caption">{{ translatedText.task_list_students }}</span>
            </th>
            <td mat-cell *matCellDef="let element" class="column-hidden-tablet">
              <span class="paragraph paragraph--s paragraph--bold username-with-avatar">
                <span><img *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" /></span>{{ student?.username }}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th
              mat-sort-header="type"
              mat-header-cell
              *matHeaderCellDef
              class="mat-column-align--left"
              matTooltip="{{ translatedText.task_list_type_info }}"
            >
              <span class="caption">{{ translatedText.task_list_type }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span
                [ngClass]="{
                  'table_chip--hasicon': generateTaskTypeTitle(element?.type, element?.mode).hasIcon
                }"
                class="paragraph table_chip table_chip--{{ element?.type }}"
              >
                {{ generateTaskTypeTitle(element?.type, element?.mode).title }}
                <mat-icon
                  *ngIf="generateTaskTypeTitle(element?.type, element?.mode).hasIcon"
                  svgIcon="{{ generateTaskTypeTitle(element?.type, element?.mode).iconName }}"
                ></mat-icon>
              </span>
            </td>
          </ng-container>
          <!--     
            <ng-container matColumnDef="evaluation">
                <th mat-sort-header="evaluation" mat-header-cell *matHeaderCellDef class="mat-column-align--center">
                    <span class="caption">{{translatedText.task_list_testarea}}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="paragraph paragraph--s ">
                        {{element?.evaluation}}
                    </span>
                  </td>
            </ng-container> -->

          <!-- <ng-container matColumnDef="continuous">
                <th mat-sort-header="continuous" mat-header-cell *matHeaderCellDef class="mat-column-align--center">
                    <span class="caption">{{translatedText.task_list_continuous}}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element?.continuous != undefined" class="paragraph paragraph--s ">
                        {{element?.continuous}}
                    </span>
                    <span *ngIf="element?.continuous == undefined" class="paragraph paragraph--s ">
                       -
                    </span>
                  </td>
            </ng-container> -->

          <ng-container matColumnDef="deadline">
            <th
              mat-sort-header="deadline"
              mat-header-cell
              *matHeaderCellDef
              class="mat-column-align--center"
              matTooltip="{{ translatedText.task_list_deadline_info }}"
            >
              <span class="caption">{{ translatedText.task_list_deadline }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="paragraph paragraph--s">
                {{ element?.deadline | date : 'dd.MM.yyyy' }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th
              mat-sort-header="description"
              mat-header-cell
              *matHeaderCellDef
              class="mat-column-align--center"
              matTooltip="{{ translatedText.task_list_duration_info }}"
            >
              <span class="caption">{{ translatedText.task_list_duration }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container
                *ngIf="
                  element.type == 'text' ||
                  element.type == 'diagnostic-pre' ||
                  element.type == 'diagnostic-mid' ||
                  element.type == 'diagnostic-post'
                "
              >
                <p class="paragraph paragraph--s">{{ element?.wordcount }} {{ translatedText.task_list_words }}</p>
              </ng-container>
              <ng-container *ngIf="element.type == 'time'">
                <p class="paragraph paragraph--s">
                  {{ element.time }}
                  {{ element?.time === 1 ? translatedText.task_list_minute : translatedText.task_list_minutes }}
                </p>
              </ng-container>
              <ng-container *ngIf="element.type == 'time-choice'">
                <p class="paragraph paragraph--s">
                  {{ element?.time }}
                  {{ element?.time === 1 ? translatedText.task_list_minute : translatedText.task_list_minutes }}
                </p>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="book_name">
            <th
              mat-sort-header="book_name"
              mat-header-cell
              *matHeaderCellDef
              class="mat-column-align--left"
              matTooltip="{{ translatedText.task_list_content_info }}"
            >
              <span class="caption">{{ translatedText.task_list_content }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <div>
                <span
                  *ngIf="element?.book_name != undefined && element?.book_name != ''"
                  class="paragraph paragraph--s"
                >
                  {{ replaceHtmlContent(element?.book_name) }}
                </span>
                <span
                  *ngIf="
                    element?.book_name == undefined || (element?.book_name == '' && element?.type != 'time-choice')
                  "
                  class="paragraph paragraph--s"
                  >-</span
                >
                <span
                  *ngIf="
                    (element?.type == 'time-choice' && element?.book_name == '') || element?.book_name == undefined
                  "
                  class="paragraph paragraph--s"
                  >{{ translatedText.task_list_self }}</span
                >
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="button">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="mat-column-align--right">
              <div class="table-buttons-wrap">
                <button
                  mat-mini-fab
                  matTooltip="{{ translatedText.task_list_detail_view }}"
                  aria-label="view-detail"
                  [routerLink]="['/task-detail/' + element?._master_task]"
                >
                  <mat-icon svgIcon="streamline_outlined:table"></mat-icon>
                </button>
                <!-- <button [matMenuTriggerFor]="moreTeacherMenu" (click)="$event.stopPropagation()" mat-icon-button type="button" class="group-more" matSuffix>
                            <mat-icon svgIcon="streamline_filled:more-vert"></mat-icon>
                        </button> -->
                <mat-menu #moreTeacherMenu="matMenu">
                  <button
                    mat-menu-item
                    aria-label="view-detail"
                    [routerLink]="['/task-detail/' + element?._master_task]"
                  >
                    <mat-icon svgIcon="streamline_outlined:table"></mat-icon>
                    <span>{{ translatedText.task_list_detail_view }}</span>
                  </button>
                  <!-- <button mat-menu-item color="filled" class="task_list_note" type="button" (click)="showAlert()">
                                <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon>
                                <span>Bearbeiten</span>
                             </button> -->
                </mat-menu>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </ng-container>
</div>

<div class="task_wrap" *ngIf="isFinished" class="teacher-task-list-container">
  <div class="row" *ngIf="isCardView">
    <div class="col-md-6 col-sm-6 equal_height_col" *ngFor="let task of getTasksByStatus('finished')">
      <div class="task_options">
        <!-- <div class="set_inline task_herder">
                    <p class="paragraph paragraph--s">Zu erledigen bis: {{task?.deadline | date: 'dd.MM.yyyy'}}</p>
                    <a class="card_link" *ngIf="task?.completed_at">{{translatedText.task_list_completed}} {{task?.completed_at | date: 'dd.MM.yyyy'}}</a>
                </div> -->
        <div class="task_option_header">
          <div class="set_inline">
            <img class="avatar_img--small" *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" />
            <div class="inline_text">
              <p class="paragraph paragraph--bold paragraph--s student_username">
                {{ student?.username }}
                <span *ngIf="getGroupName(student?._group)?.name">({{ getGroupName(student?._group)?.name }})</span>
              </p>
            </div>
          </div>
          <div class="task_chips_wrap">
            <ng-container *ngIf="task?.type == 'diagnostic-pre'">
              <div class="task_chip task_chip__diagnostic-pre">
                <p>{{ translatedText.task_list_diagnostic_pre }}</p>
              </div>
            </ng-container>
            <ng-container *ngIf="task?.type != 'diagnostic-pre'">
              <div class="task_chip task_chip__type">
                <p>{{ translatedText.task_list_training }}</p>
              </div>
            </ng-container>

            <div class="task_chip task_chip__test-area">
              <p>{{ translatedText.task_list_fluency }}</p>
            </div>
            <button
              mat-icon-button
              color="unset"
              (click)="openNotificationOverlay(task?._id, task?.notification)"
              class="task_list_note"
              type="button"
            >
              <mat-icon svgIcon="streamline_filled:task-note"></mat-icon>
            </button>
          </div>
        </div>
        <div class="teacher_task_info">
          <div class="inline_text">
            <p class="paragraph paragraph--s">
              {{ translatedText.task_list_book }}
            </p>
            <p class="paragraph paragraph--bold paragraph--s" [innerHTML]="task?.book_name"></p>

            <!-- <ng-container *ngIf="task?.type == 'diagnostic-pre' || task?.type == 'diagnostic-mid' || task?.type == 'diagnostic-post'">
                            <p class="paragraph paragraph--s">{{generateDiagnosticTitle(task?.type)}}</p>
                        </ng-container> -->
          </div>
          <div class="inline_text">
            <p class="paragraph paragraph--s">{{ translatedText.task_list_task }}:</p>
            <p class="paragraph paragraph--bold paragraph--s">
              {{ task?.description }}
            </p>
          </div>
          <div class="inline_text">
            <p class="paragraph paragraph--s">
              {{ translatedText.task_list_time_read }}
            </p>
            <span class="paragraph paragraph--bold paragraph--s"
              >{{ task?.time }}
              {{ task?.time === 1 ? translatedText.task_list_minute : translatedText.task_list_minutes }}</span
            >
          </div>
          <div class="inline_text">
            <p class="paragraph paragraph--s">
              {{ translatedText.task_list_reading_points }}
            </p>
            <span class="paragraph paragraph--bold paragraph--s"
              >{{ task?.reading_score?.reading_combined_score }} / 100</span
            >
          </div>
          <div class="inline_text">
            <p class="paragraph paragraph--s">{{ translatedText.task_list_deadline }}:</p>
            <p class="paragraph paragraph--bold paragraph--s">
              {{ task?.deadline | date : 'dd.MM.yyyy' }}
              <span class="task_completed_date">(Komplett {{ task?.completed_at | date : 'dd.MM.yyyy' }})</span>
            </p>
          </div>
        </div>
        <div class="view_button">
          <button
            mat-mini-fab
            color="primary"
            type="button"
            [queryParams]="{ studentId: studentId }"
            [routerLink]="['/student-report/' + task?._id]"
          >
            <mat-icon svgIcon="streamline_outlined:eye"></mat-icon>{{ translatedText.task_list_detail }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isTableView">
    <div class="table-wrapper">
      <div class="table-container">
        <table
          mat-table
          [dataSource]="getTableData('finished')"
          matSort
          matSortDisableClear
          matSortDirection="desc"
          class="mat-elevation-z8 task_table_view"
          (matSortChange)="onSortChange($event)"
        >
          <ng-container matColumnDef="username">
            <th mat-sort-header="username" mat-header-cell *matHeaderCellDef class="column-hidden-tablet">
              <span class="caption">{{ translatedText.task_list_students }}</span>
            </th>
            <td mat-cell *matCellDef="let element" class="column-hidden-tablet">
              <span class="paragraph paragraph--s paragraph--bold username-with-avatar">
                <span><img *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" /></span>{{ student?.username }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th
              mat-sort-header="type"
              mat-header-cell
              *matHeaderCellDef
              class="mat-column-align--left"
              matTooltip="{{ translatedText.task_list_type_info }}"
            >
              <span class="caption">{{ translatedText.task_list_type }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span
                class="paragraph table_chip table_chip--{{ element?.type }}"
                [ngClass]="{
                  'table_chip--hasicon': generateTaskTypeTitle(element?.type, element?.mode).hasIcon
                }"
              >
                {{ generateTaskTypeTitle(element?.type, element?.mode).title }}
                <mat-icon
                  *ngIf="generateTaskTypeTitle(element?.type, element?.mode).hasIcon"
                  svgIcon="{{ generateTaskTypeTitle(element?.type, element?.mode).iconName }}"
                ></mat-icon>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="evaluation">
            <th mat-sort-header="evaluation" mat-header-cell *matHeaderCellDef class="mat-column-align--center">
              <span class="caption">{{ translatedText.task_list_testarea }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="paragraph paragraph--s">
                {{ element?.evaluation }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="continuous">
            <th mat-sort-header="continuous" mat-header-cell *matHeaderCellDef class="mat-column-align--center">
              <span class="caption">{{ translatedText.task_list_continuous }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element?.continuous != undefined" class="paragraph paragraph--s">
                {{ element?.continuous }}
              </span>
              <span *ngIf="element?.continuous == undefined" class="paragraph paragraph--s"> - </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="completed_at">
            <th mat-sort-header="completed_at" mat-header-cell *matHeaderCellDef class="mat-column-align--center">
              <span class="caption">{{ translatedText.task_list_completed_task }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="paragraph paragraph--s">
                {{ element?.completed_at | date : 'dd.MM.yyyy' }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th
              mat-sort-header="description"
              mat-header-cell
              *matHeaderCellDef
              class="mat-column-align--center"
              matTooltip="{{ translatedText.task_list_duration_info }}"
            >
              <span class="caption">{{ translatedText.task_list_duration }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container
                *ngIf="
                  element.type == 'text' ||
                  element.type == 'diagnostic-pre' ||
                  element.type == 'diagnostic-mid' ||
                  element.type == 'diagnostic-post'
                "
              >
                <p class="paragraph paragraph--s">{{ element?.wordcount }} {{ translatedText.task_list_words }}</p>
              </ng-container>
              <ng-container *ngIf="element.type == 'time' && element.mode != 'autonomous'">
                <p class="paragraph paragraph--s">
                  {{ element?.time }}
                  {{ element?.time === 1 ? translatedText.task_list_minute : translatedText.task_list_minutes }}
                </p>
              </ng-container>
              <ng-container *ngIf="element.mode == 'autonomous'">
                <p class="paragraph paragraph--s" *ngIf="element.time > 0">
                  {{ element?.time }}
                  {{ element?.time === 1 ? translatedText.task_list_minute : translatedText.task_list_minutes }}
                </p>
                <p class="paragraph paragraph--s" *ngIf="element.time == 0">
                  {{ element?.wordcount }} {{ translatedText.task_list_words }}
                </p>
              </ng-container>
              <ng-container *ngIf="element.type == 'time-choice'">
                <p class="paragraph paragraph--s">
                  {{ element?.time }}
                  {{ element?.time === 1 ? translatedText.task_list_minute : translatedText.task_list_minutes }}
                </p>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="book_name">
            <th
              mat-sort-header="book_name"
              mat-header-cell
              *matHeaderCellDef
              class="mat-column-align--left"
              matTooltip="{{ translatedText.task_list_content_info }}"
            >
              <span class="caption">{{ translatedText.task_list_content }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <div>
                <span *ngIf="element?.book_name != undefined" class="paragraph paragraph--s">
                  {{ replaceHtmlContent(element?.book_name) }}
                </span>
                <span *ngIf="element?.book_name == undefined || element?.book_name == ''">-</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="reading_speed_correct_words_per_minute">
            <th
              mat-sort-header="reading_speed_correct_words_per_minute"
              mat-header-cell
              *matHeaderCellDef
              class="mat-column-align--left"
              matTooltip="{{ translatedText.task_list_wcpm_tooltip }}"
            >
              <span class="caption">{{ translatedText.task_list_wcpm }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span
                class="paragraph paragraph--s"
                [countUp]="element?.reading_score?.reading_speed_correct_words_per_minute"
                >{{ element?.reading_score?.reading_speed_correct_words_per_minute }}</span
              >
            </td>
          </ng-container>

          <ng-container matColumnDef="button">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="mat-column-align--right">
              <!-- <div class="table-buttons-wrap">
                        <button mat-icon-button color="filled" type="button" class="license-accept" matSuffix [routerLink]="['/task-detail/' + element?._master_task]">
                            <mat-icon svgIcon="streamline_outlined:eye"></mat-icon>
                        </button>
                        <button mat-icon-button color="filled" (click)="openNotificationOverlay(element?._id, element?.notification, element?._student)" class="task_list_note" type="button">
                            <mat-icon svgIcon="streamline_outlined:chat-3"></mat-icon>
                        </button>
                    </div> -->
              <div class="table-buttons-wrap">
                <button
                  mat-mini-fab
                  aria-label="view-detail"
                  matTooltip="{{ translatedText.task_list_show_result }}"
                  [queryParams]="{ studentId: element?._student }"
                  [routerLink]="['/student-report/' + element?._id]"
                >
                  <mat-icon svgIcon="streamline_outlined:eye"></mat-icon>
                </button>

                <!-- <button mat-mini-fab color="secondary" aria-label="view-detail"  matTooltip="{{translatedText.task_list_show_result}}" [queryParams]="{studentId: element?._student?._id}" [routerLink]="['/student-report/' + element?._id]">
                            <mat-icon svgIcon="streamline_outlined:eye"></mat-icon>
                        </button> -->
                <button
                  [matMenuTriggerFor]="moreTeacherMenu"
                  (click)="$event.stopPropagation()"
                  mat-mini-fab
                  type="button"
                  class="not-filled"
                  matSuffix
                >
                  <mat-icon svgIcon="streamline_filled:more-vert"></mat-icon>
                </button>
                <mat-menu #moreTeacherMenu="matMenu">
                  <button
                    mat-menu-item
                    aria-label="view-detail"
                    *ngIf="
                      (element?.type == 'diagnostic-pre' ||
                        element?.type == 'diagnostic-mid' ||
                        element?.type == 'diagnostic-post') &&
                      element?.repeated == 0
                    "
                    (click)="repeatDiagnostic(element?._master_task, element?.type)"
                    matTooltip="{{ translatedText.task_list_repeats_info }}"
                  >
                    <mat-icon svgIcon="streamline_outlined:refresh"></mat-icon>
                    <span>{{ translatedText.task_list_repeat }}</span>
                  </button>
                  <button
                    mat-menu-item
                    aria-label="view-detail"
                    [routerLink]="['/task-detail/' + element?._master_task]"
                  >
                    <mat-icon svgIcon="streamline_outlined:table"></mat-icon>
                    <span>{{ translatedText.task_list_detail_view }}</span>
                  </button>
                  <button
                    mat-menu-item
                    color="filled"
                    (click)="openNotificationOverlay(element?._id, element?.notification, element?._student)"
                    class="task_list_note"
                    type="button"
                  >
                    <mat-icon svgIcon="streamline_outlined:chat-3"></mat-icon>
                    <span>{{ translatedText.task_list_message }}</span>
                  </button>
                </mat-menu>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="finishedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: finishedColumns"></tr>
        </table>
      </div>
    </div>
  </ng-container>
</div>

<div class="task_wrap" *ngIf="isExpired" class="teacher-task-list-container">
  <div class="row" *ngIf="isCardView">
    <div class="col-md-6 col-sm-6 equal_height_col" *ngFor="let task of getTasksByStatus('expired')">
      <div class="task_options">
        <div class="task_option_header">
          <div class="set_inline">
            <img class="avatar_img--small" *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" />
            <div class="inline_text">
              <p class="paragraph paragraph--bold paragraph--s student_username">
                {{ student?.username }}
                <span *ngIf="getGroupName(student?._group)?.name">({{ getGroupName(student?._group)?.name }})</span>
              </p>
            </div>
          </div>
          <div class="task_chips_wrap">
            <ng-container *ngIf="task?.type == 'diagnostic-pre'">
              <div class="task_chip task_chip__diagnostic-pre">
                <p>{{ translatedText.task_list_diagnostic_pre }}</p>
              </div>
            </ng-container>
            <ng-container *ngIf="task?.type != 'diagnostic-pre'">
              <div class="task_chip task_chip__type">
                <p>{{ translatedText.task_list_training }}</p>
              </div>
            </ng-container>

            <div class="task_chip task_chip__test-area">
              <p>{{ translatedText.task_list_fluency }}</p>
            </div>
            <button mat-icon-button color="unset" class="task_list_note" type="button">
              <mat-icon svgIcon="streamline_filled:repeat"></mat-icon>
            </button>
          </div>
        </div>
        <div class="teacher_task_info">
          <div class="inline_text">
            <p class="paragraph paragraph--s">
              {{ translatedText.task_list_book }}
            </p>
            <p class="paragraph paragraph--bold paragraph--s">
              {{ replaceHtmlContent(task?.book_name) }}
            </p>
          </div>
          <div class="inline_text">
            <p class="paragraph paragraph--s">{{ translatedText.task_list_task }}:</p>
            <p class="paragraph paragraph--bold paragraph--s">
              {{ task?.description }}
            </p>
          </div>
          <div class="inline_text">
            <p class="paragraph paragraph--s">{{ translatedText.task_list_continuous }}:</p>
            <span class="paragraph paragraph--bold paragraph--s">-</span>
          </div>
          <div class="inline_text">
            <p class="paragraph paragraph--s">{{ translatedText.task_list_deadline }}:</p>
            <p class="paragraph paragraph--bold paragraph--s">
              {{ task?.deadline | date : 'dd.MM.yyyy' }}
              <span class="task_completed_date">({{ translatedText.task_list_expired }})</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isTableView">
    <div class="table-wrapper">
      <div class="table-container">
        <table
          mat-table
          [dataSource]="getTableData('expired')"
          matSort
          matSortDisableClear
          matSortDirection="desc"
          class="mat-elevation-z8 task_table_view"
        >
          <ng-container matColumnDef="username">
            <th mat-sort-header="username" mat-header-cell *matHeaderCellDef class="column-hidden-tablet">
              <span class="caption">{{ translatedText.task_list_students }}</span>
            </th>
            <td mat-cell *matCellDef="let element" class="column-hidden-tablet">
              <span class="paragraph paragraph--s paragraph--bold username-with-avatar">
                <span><img *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" /></span>{{ student?.username }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th
              mat-sort-header="type"
              mat-header-cell
              *matHeaderCellDef
              class="mat-column-align--left"
              matTooltip="{{ translatedText.task_list_type_info }}"
            >
              <span class="caption">{{ translatedText.task_list_type }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span
                class="paragraph table_chip table_chip--{{ element?.type }}"
                [ngClass]="{
                  'table_chip--hasicon': generateTaskTypeTitle(element?.type, element?.mode).hasIcon
                }"
              >
                {{ generateTaskTypeTitle(element?.type, element?.mode).title }}
                <mat-icon
                  *ngIf="generateTaskTypeTitle(element?.type, element?.mode).hasIcon"
                  svgIcon="{{ generateTaskTypeTitle(element?.type, element?.mode).iconName }}"
                ></mat-icon>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="evaluation">
            <th mat-sort-header="evaluation" mat-header-cell *matHeaderCellDef class="mat-column-align--center">
              <span class="caption">{{ translatedText.task_list_testarea }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="paragraph paragraph--s">
                {{ element?.evaluation }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="continuous">
            <th mat-sort-header="continuous" mat-header-cell *matHeaderCellDef class="mat-column-align--center">
              <span class="caption">{{ translatedText.task_list_continuous }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element?.continuous != undefined" class="paragraph paragraph--s">
                {{ element?.continuous }}
              </span>
              <span *ngIf="element?.continuous == undefined" class="paragraph paragraph--s"> - </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="deadline">
            <th
              mat-sort-header="deadline"
              mat-header-cell
              *matHeaderCellDef
              class="mat-column-align--center"
              matTooltip="{{ translatedText.task_list_deadline_info }}"
            >
              <span class="caption">{{ translatedText.task_list_deadline }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="paragraph paragraph--s">
                {{ element?.deadline | date : 'dd.MM.yyyy' }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th
              mat-sort-header="description"
              mat-header-cell
              *matHeaderCellDef
              class="mat-column-align--center"
              matTooltip="{{ translatedText.task_list_duration_info }}"
            >
              <span class="caption">{{ translatedText.task_list_duration }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container
                *ngIf="
                  element.type == 'text' ||
                  element.type == 'diagnostic-pre' ||
                  element.type == 'diagnostic-mid' ||
                  element.type == 'diagnostic-post'
                "
              >
                <p class="paragraph paragraph--s">{{ element?.wordcount }} {{ translatedText.task_list_words }}</p>
              </ng-container>
              <ng-container *ngIf="element.type == 'time' && element.mode != 'autonomous'">
                <p class="paragraph paragraph--s">
                  {{ element.time }}
                  {{ element?.time === 1 ? translatedText.task_list_minute : translatedText.task_list_minutes }}
                </p>
              </ng-container>
              <ng-container *ngIf="element.type == 'time-choice'">
                <p class="paragraph paragraph--s">
                  {{ element?.time }}
                  {{ element?.time === 1 ? translatedText.task_list_minute : translatedText.task_list_minutes }}
                </p>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="book_name">
            <th
              mat-sort-header="book_name"
              mat-header-cell
              *matHeaderCellDef
              class="mat-column-align--left"
              matTooltip="{{ translatedText.task_list_content_info }}"
            >
              <span class="caption">{{ translatedText.task_list_content }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <div>
                <span
                  *ngIf="
                    element?.book_name == undefined || (element?.book_name == '' && element?.type != 'time-choice')
                  "
                  class="paragraph paragraph--s"
                  >-</span
                >
                <span
                  *ngIf="
                    (element?.type == 'time-choice' && element?.book_name == '') || element?.book_name == undefined
                  "
                  class="paragraph paragraph--s"
                  >{{ translatedText.task_list_self }}</span
                >
                <span
                  *ngIf="element?.book_name != undefined && element?.book_name != ''"
                  class="paragraph paragraph--s"
                >
                  {{ replaceHtmlContent(element?.book_name) }}
                </span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="button">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="mat-column-align--right">
              <div class="table-buttons-wrap">
                <!-- <button mat-mini-fab color="primary" mat-button aria-label="Edit" (click)="showAlert()">
                            <mat-icon svgIcon="streamline_filled:edit"></mat-icon>
                        </button> -->

                <button
                  mat-mini-fab
                  matTooltip="{{ translatedText.task_list_detail_view }}"
                  aria-label="view-detail"
                  [routerLink]="['/task-detail/' + element?._master_task]"
                >
                  <mat-icon svgIcon="streamline_outlined:table"></mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </ng-container>
</div>

<div class="task_wrap" *ngIf="isselfing">
  <div class="row">
    <div class="col-md-4 col-sm-6 equal_height_col" *ngFor="let task of getselfingTasks()">
      <div class="task_options">
        <div class="task_option_header">
          <div class="set_inline">
            <img class="avatar_img--small" *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" />
            <div class="inline_text">
              <p class="paragraph paragraph--bold paragraph--s student_username">
                {{ student?.username }}
                <span *ngIf="getGroupName(student?._group)?.name">({{ getGroupName(student?._group)?.name }})</span>
              </p>
            </div>
          </div>

          <!-- <a class="card_link">Bearbeiten</a> -->
        </div>
        <div class="teacher_task_info">
          <div class="inline_text">
            <ng-container *ngIf="task?.type == 'self'">
              <p class="paragraph paragraph--s">
                {{ translatedText.task_list_book }}
                <span class="paragraph paragraph--bold paragraph--s">
                  {{ replaceHtmlContent(task?.book_name) }}
                </span>
              </p>
            </ng-container>
          </div>
          <!-- <div class="inline_text">
                        <ng-container *ngIf="task?.type == 'self'">
                            <p class="paragraph paragraph--s">Zeit: <span class="paragraph paragraph--bold paragraph--s">{{task?.time}} {{translatedText.task_list_minutes}}</span></p>
                        </ng-container>
                    </div> -->
          <div class="inline_text">
            <p class="paragraph paragraph--s">
              {{ translatedText.task_list_completed }}
              <span class="paragraph paragraph--bold paragraph--s">{{ task?.completed_at | date : 'dd.MM.yyyy' }}</span>
            </p>
          </div>
        </div>
        <div class="view_button">
          <button
            mat-mini-fab
            color="primary"
            type="button"
            [queryParams]="{ studentId: studentId }"
            [routerLink]="['/student-report/' + task?._id]"
          >
            <mat-icon svgIcon="streamline_filled:view"></mat-icon>{{ translatedText.task_list_detail }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
