import { TranslationService } from 'src/app/providers/translation.service';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { SchoolService } from 'src/app/providers/school.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-license-trial-extension',
  templateUrl: './license-trial-extension.component.html',
  styleUrls: ['./license-trial-extension.component.scss'],
})
export class LicenseTrialExtensionComponent implements OnInit {
  translatedText: any;
  route: any;
  trialReason: string = '';
  isTrialRequest: boolean = false;
  maxChars = 200;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public Data: any, // TODO: private or public?
    private dialog: MatDialog,
    private translationService: TranslationService,
    private schoolService: SchoolService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<LicenseTrialExtensionComponent>,
  ) {}

  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  sendRequest() {
    this.schoolService.requestTrialExtension(this.trialReason).subscribe({
      next: (res) => {
        this.isTrialRequest = true;
      },
      error: (error) => {
        let message = this.translatedText?.technical_error;
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: message,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }

  closeOverlay() {
    this.dialogRef.close(this.isTrialRequest);
  }
}
