import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { TeacherService } from 'src/app/providers/teacher.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from 'src/environments/environment';
export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-expert-group',
  templateUrl: './expert-group.component.html',
  styleUrls: ['./expert-group.component.scss'],
})
export class ExpertGroupComponent implements OnInit, OnDestroy {
  rForm: FormGroup;
  request;
  isLoading: boolean = false;
  teacherId: string;
  translatedText: any;
  environment = environment;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translationService: TranslationService,
    private teacherService: TeacherService,
  ) {
    if (injectedData) {
      if (typeof injectedData['request'] !== 'undefined') {
        this.request = this.injectedData['request'];
      }
    }
  }

  ngOnInit() {
    this.teacherId = this.authService.getTeacherId();

    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.rForm = this._formBuilder.group({
      isExpert: [''],
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  submitForm(form: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }
    this.isLoading = true;
    let formData = {
      teacherId: this.teacherId,
      isExpert: this.rForm.value.isExpert,
    };

    this.teacherService.updateTeacher(formData).subscribe({
      next: (res) => {
        this.dialog.closeAll();
        // Update teacher list
      },
      error: (error: any) => {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText?.technical_error,
          duration: 3000,
          horizontalPosition: 'left',
        });
        this.isLoading = false;
      },
    });
  }

  closePdfOverlay() {
    this.dialog.closeAll();
  }
}
