import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { GroupService } from 'src/app/providers/group.service';
import { LicenseService } from 'src/app/providers/license.service';
import { SchoolService } from 'src/app/providers/school.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { StudentService } from 'src/app/providers/student.service';
import { environment } from 'src/environments/environment';
import {
  UpgradeDialogComponent,
  UpgradeDialogData,
} from 'src/app/trial-phase/upgrade-banner/upgrade-banner.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-due-date-selector',
  templateUrl: './due-date-selector.component.html',
  styleUrls: ['./due-date-selector.component.scss'],
})
export class DueDateSelectorComponent implements OnInit {
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  public translatedText;
  public environment = environment;

  public minDate: Date;
  //public readingTimeSelector: FormGroup;
  public selectedDeadline: Date = null;
  public today: any | null = new Date();
  public taskVisibleFrom: Date = null;

  public clickedCloseBtn: boolean = false;
  public viewValueTaskVisibily: any;

  public trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };
  @Output() taskVisibility = new EventEmitter<Date>();
  @Output() newSelectedDeadline = new EventEmitter<Date>();
  @Input() readingTimeSelector?: FormGroup;
  @Input() control?: FormControl;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private translationService: TranslationService,
    private authService: AuthService,

    private groupService: GroupService,
    private licenseService: LicenseService,
    private schoolService: SchoolService,
    private studentService: StudentService,
  ) {
    this.minDate = new Date();
    this.minDate.setHours(0, 0, 0, 0);
    this.readingTimeSelector = this._formBuilder.group({
      selectedDate: [this.selectedDeadline, this.selectedDateValidator()],
    });
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.trialStatus = this.authService.getUserTrialStatus();
  }
  // function to disable past dates
  dateClass(date: Date): string {
    return date < this.today ? 'disabled-date' : '';
  }

  selectedDateValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedDate = control.value;
      if (!selectedDate) return null;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return selectedDate < today ? { pastDate: true } : null;
    };
  }

  onDateSelected(date: Date) {
    this.selectedDeadline = new Date(date);
    const dateControl = this.readingTimeSelector.get('selectedDate');
    if (dateControl) {
      dateControl.markAsTouched();
      this.readingTimeSelector.patchValue({
        selectedDate: this.selectedDeadline,
      });
      this.dateClass(this.selectedDeadline);

      //if trial, limits the selection to the trial expiry date
      if (
        this.trialStatus.isActive &&
        this.selectedDeadline > this.trialStatus.expiryDate
      ) {
        this.selectedDeadline = this.trialStatus.expiryDate;
        this.openUpgradeModal();
      }
    }
    this.newSelectedDeadline.emit(this.selectedDeadline);
    this.taskVisibilityDate();
  }

  //calulates task visibility of four weeks
  taskVisibilityDate() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.selectedDeadline.setHours(0, 0, 0, 0);
    const subtractedDate = new Date(this.selectedDeadline);
    subtractedDate.setDate(this.selectedDeadline.getDate() - 28);
    this.dateToString(subtractedDate);

    if (subtractedDate < today) {
      this.taskVisibleFrom = null;
    } else {
      this.taskVisibleFrom = subtractedDate;
    }
    this.taskVisibility.emit(this.taskVisibleFrom);
  }

  //makes the date taskVisibleFrom into a translatable string to show on the visibility box
  dateToString(date) {
    let day = date.getDate().toString();
    let month = date.getMonth() + 1;
    let year = date.getFullYear().toString();
    this.viewValueTaskVisibily =
      day + '. ' + this.translatedText.t(month + '_short') + ' ' + year;
  }

  openUpgradeModal() {
    this.dialog.open<UpgradeDialogComponent, UpgradeDialogData>(
      UpgradeDialogComponent,
      {
        width: '85vw',
        autoFocus: false,
        disableClose: true,
        panelClass: 'home-trial-dialog-panel',
        // hasBackdrop: false,
        backdropClass: 'license-add-backdrop',
        data: { upgradeModalText: 'readingMinutes' },
      },
    );
  }
}
