import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslationService } from 'src/app/providers/translation.service';

@Component({
  selector: 'app-success-overlay',
  templateUrl: './success-overlay.component.html',
  styleUrls: ['./success-overlay.component.scss'],
})
export class SuccessOverlayComponent {
  successMsg: string = '';
  translatedText: any;

  private _unsubscribeAll = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,

    private translationService: TranslationService,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: any,
  ) {
    if (injectedData && injectedData['message']) {
      this.successMsg = injectedData['message'];
    }
  }

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  closeOverlay() {
    this.dialog.closeAll();
  }
}
