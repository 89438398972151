import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { NotificationService } from 'src/app/providers/notification.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { NotificationListComponent } from '../notification-list/notification-list.component';
import { StudentReportComponent } from 'src/app/student/student-report/student-report.component';
import { RecordResultComponent } from 'src/app/record-rtc/record-result/record-result.component';
import { BookDataService } from 'src/app/providers/book-data.service';
import { StudentService } from 'src/app/providers/student.service';
import moment from 'moment';
import { TaskService } from 'src/app/providers/task.service';

export interface DialogData {
  message: string;
}

export interface Notification {
  notificationId?: string;
  message?: string;
  recipient?: any;
  _task?: any;
  type?: string;
  readAt?: any;
  createdAt?: any;
  sender?: any;
}

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss'],
})
export class NotificationMenuComponent implements OnInit, OnDestroy {
  @Input() notifications: Notification[] = [];
  @Output() loadingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  currentIndex: number = 0;
  userType;
  task;
  view;
  translatedText: any;
  //notificationType: string = 'result';
  taskType: string;
  isLoading: boolean = false;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    //public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private authService: AuthService,
    private translationService: TranslationService,
    private studentService: StudentService,
    private taskService: TaskService,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.userType = this.authService.getType();
  }

  messageText(message?) {
    return message.length > 110 ? message.substring(0, 110) + '...' : message;
  }

  notificationTaskType(notification) {
    switch (notification?._task?.type) {
      case 'time-choice':
      case 'time':
      case 'diagnostic-pre':
      case 'diagnostic-mid':
      case 'diagnostic-post':
        return (
          notification?._task?.time +
          ' ' +
          (notification?._task?.time === 1
            ? this.translatedText.app_component_minute
            : this.translatedText.app_component_minutes)
        );
      case 'text':
        return (
          notification?._task?.wordcount +
          ' ' +
          this.translatedText.app_component_words
        );
    }
  }
  openMessagePopup(notification): void {
    let dialogRef = this.dialog.open(NotificationListComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'notification-list-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'books-preferences-overlay-panel_backdrop',
      data: {
        notifications: [notification],
        task: notification['_task'],
        view: 'list',
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      this.notificationService
        .updateNotification(notification._id)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          //console.log(res);
        });
    });
  }

  openRecordResult(notification): void {
    this.dialog.open(RecordResultComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'record-rtc-result-overlay-panel',
      disableClose: true,
      backdropClass: 'license-add-backdrop',
      data: {
        isLoading: true,
      },
    });
    let task_session_uuid = notification?.task_session_uuid;
    if (task_session_uuid == undefined) {
      if (notification?._task.mode == 'multiple') {
        task_session_uuid = notification?._task._split_task[0]?.session_uuid;
      } else {
        task_session_uuid = notification?._task.session_uuid;
      }
    }
    this.studentService
      .getStudentTaskReport(notification?._task?._id, task_session_uuid)
      .pipe()
      .subscribe((res) => {
        this.loadingEvent.emit(false);
        const taskResult = res?.sessionData;
        let dialogRef = this.dialog.open(RecordResultComponent, {
          width: '100%',
          autoFocus: false,
          panelClass: 'record-rtc-result-overlay-panel',
          disableClose: true,
          //backdropClass: 'license-add-backdrop',
          data: {
            isLoading: false,
            result: taskResult,
            taskData: notification._task,
          },
        });
        dialogRef.afterClosed().subscribe(() => {
          this.notificationService
            .updateNotification(notification._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
              this.notificationService.getUnreadNotificationList();
            });
        });
      });
  }
  replaceHtmlContent(string) {
    return this.taskService.formatHTMLContent(string);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
