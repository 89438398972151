import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormGroupName,
} from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { GroupService } from '../../providers/group.service';
import { StudentService } from '../../providers/student.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AdminService } from 'src/app/providers/admin.service';
import { TeacherService } from 'src/app/providers/teacher.service';
import { DatePipe } from '@angular/common';
import { TranslationService } from 'src/app/providers/translation.service';
import { LicenseService } from 'src/app/providers/license.service';
import { MatTable } from '@angular/material/table';
import { MatCheckbox } from '@angular/material/checkbox';
import { TrialBannersComponent } from '../trial-banners/trial-banners.component';
import { environment } from 'src/environments/environment';
import { StudentLimitReachedComponent } from 'src/app/helpers/student-limit-reached/student-limit-reached.component';

export interface DialogData {
  currentStudentAmount: number;
}
export const DATE_FORMAT = {
  parse: {
    dateInput: 'MM.YYYY',
  },
  display: {
    dateInput: 'MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface Languages {
  name: string;
  code: string;
}

export interface StudentList {
  Firstname: string;
  Lastname: string;
  Language: string;
  BirthMonthYear: string;
  Gender: string;
}

@Component({
  selector: 'app-create-learners',
  templateUrl: './create-learners.component.html',
  styleUrls: ['./create-learners.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class CreateLearnersComponent implements OnInit, OnChanges {
  environment = environment;
  isLoading: boolean = false;
  studentFormStep: FormGroup;
  languages: any = [];
  filteredOptions: Observable<Languages[]>[] = new Array();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  femaleCount: number = 0;
  maleCount: number = 0;
  diversCount: number = 0;
  groupGrade;
  groupName;
  selectGroupType;
  isTeacherFill: boolean = false;
  isAdminFill: boolean = false;
  isClosedDialog: boolean = false;
  checked: boolean = false;
  generatePDF;
  studentsArray = [];
  stud;
  maxBirthday;
  licenseStep;
  lastStep;
  blobUrl;
  groupId;
  teachers;
  teacherIds;
  studentsAmount: number;
  groupYear;
  translatedText;
  recommendedAvatar = 'animal';
  licensesToSchool: any = [];
  licensesToSchoolLoading: boolean = true;
  totalCountLeftDiagnostic: number;
  totalCountLeftTraining: number;
  displayedColumns: string[] = ['fullname', 'diLicense', 'foLicense'];
  dataSource = [];
  fullname;
  selectedAll: any;
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChildren('licenseCheckbox') licensecheckbox: Array<MatCheckbox>;
  @Input('totalStudents') totalStudents: number;
  @Input('title') title: string;
  @Input('diagnosticTotalCountLicenses') diagnosticTotalCountLicenses: boolean =
    false;
  @Input('diagnosticTotalCount') diagnosticTotalCount: number = 0;
  @Input('trainingTotalCount') trainingTotalCount: number = 0;
  @Input('diagnosticLeftCount') diagnosticLeftCount: number = 0;
  @Input('trainingLeftCount') trainingLeftCount: number = 0;
  @Input('studentLimit') STUDENT_LIMIT: number = 40;
  @Input('grade') grade: number = 1;
  @Input('studentList') studentList: StudentList[] = [];
  @Input('csvLanguageCode') csvLanguageCode: string = 'en';
  languageInEn: any[] = [];
  constructor(
    private datePipe: DatePipe,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private studentService: StudentService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private adminService: AdminService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    const currentYear = moment().year();
    this.maxBirthday = moment([currentYear - 5, 11, 31]);

    //this.teacherIds = this.teachers.map((item) => item._id);

    this.studentFormStep = this._formBuilder.group({
      group: this._formBuilder.array([]),
    });

    this.studentService
      .getLanguages()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        //console.log(res);
        this.languages = res;
        this.generateLanguageForEn();
      });

    this.trialStatus = this.authService.getUserTrialStatus();
    this.studentFormStep.statusChanges.subscribe((val) => {
      if (val) {
        this.adminService.updateClassFormStatus({
          form: 'studentFormStep',
          status: val,
          formData: this.studentFormStep,
        });
      }
    });
    console.log('create students', this.totalStudents);
  }

  // Need to get Language code from CSV language name. By Default API returing language in German language
  generateLanguageForEn() {
    this.languageInEn = [];
    for (const lan of this.languages) {
      const language = this.languages.filter((obj) => obj.code == lan.code);
      const enLan = this.translatedText[language[0]?.code];
      this.languageInEn.push({ code: language[0]?.code, name: enLan });
    }
  }

  allFilled(i: number) {
    let arrayControl = this.studentFormStep.get('group') as FormArray;
    let status = arrayControl.at(i).valid;
    if (status) {
      return 'active';
    }
  }

  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>,
    index,
  ) {
    const element = this.studentFormStep.get('group');
    if (element != undefined) {
      const ctrlValue = element.value[index].age;
      ctrlValue.month(normalizedMonthAndYear.month());
      ctrlValue.year(normalizedMonthAndYear.year());
      ((this.studentFormStep.get('group') as FormArray).at(index) as FormGroup)
        .get('age')
        .patchValue(ctrlValue);
      datepicker.close();
    }
  }

  createClassArray(): FormGroup {
    let schoolClassAge = Number(this.grade) + 5;
    const birthyearEstimation = new Date();
    birthyearEstimation.setFullYear(
      birthyearEstimation.getFullYear() - schoolClassAge,
    );
    return this._formBuilder.group({
      name: ['', Validators.required],
      gender: ['', Validators.required],
      language: [
        '',
        [Validators.required, this.validatorRequireMatch.bind(this)],
      ],
      age: [moment(birthyearEstimation), Validators.required],
    });
  }

  private _filter(value: string): Languages[] {
    const filterValue = value.toLowerCase();
    return this.languages.filter((option) =>
      option.name.toLowerCase().startsWith(filterValue),
    );
  }

  validatorRequireMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (this.languages && this.languages.find((obj) => obj.code == selection)) {
      return null;
    }
    return { incorrect: true };
  }

  displayFn(code) {
    return this.getLanguageByCode(code);
  }

  getLanguageByCode(code) {
    return this.languages != undefined
      ? this.translatedText[
          this.languages.filter((obj) => obj.code == code)[0]?.code
        ]
      : '';
  }

  setLanguages(index) {
    this.filteredOptions[index] = (
      (this.studentFormStep?.get('group') as FormArray).at(index) as FormGroup
    )
      .get('language')
      .valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : '';
          return name
            ? this._filter(name as string)
            : this.languages
            ? this.languages?.slice()
            : [];
        }),
      );
  }

  setLanguageOption(value, index) {
    ((this.studentFormStep?.get('group') as FormArray).at(index) as FormGroup)
      .get('language')
      .patchValue(value);
  }

  addNewStudent() {
    if (this.studentFormStep.value.group.length + 1 > this.STUDENT_LIMIT) {
      if (this.trialStatus.isActive) {
        this.openTrialBanner();
      }
      return;
    }
    const currentClass = this.studentFormStep?.get('group') as FormArray;
    currentClass.push(this.createClassArray());
    this.setLanguages(this.studentFormStep.value.group.length - 1);
    this.totalStudents = currentClass.length;
  }

  createStudents(studentAmount: number) {
    const currentClass = this.studentFormStep?.get('group') as FormArray;
    if (currentClass.length >= studentAmount) return;

    console.log('create', studentAmount, currentClass.length);
    let studentsToAdd = studentAmount - currentClass.length;
    for (let index = 0; index < studentsToAdd; index++) {
      currentClass.push(this.createClassArray());
    }
    // Create language observables
    let count = studentAmount;
    for (let index = 0; index < count; index++) {
      this.setLanguages(index);
    }
    this.totalStudents = currentClass.length;
  }

  removeStudent(i: number) {
    console.log(this.totalStudents);
    if (this.totalStudents > 1) {
      const currentClass = this.studentFormStep?.get('group') as FormArray;
      currentClass.removeAt(i);
      this.totalStudents = currentClass.length;
    }
  }

  getClassFormControls(): AbstractControl[] {
    return (<FormArray>this.studentFormStep?.get('group'))?.controls;
  }

  genderSelection() {
    setTimeout(() => {
      this.maleCount = this.studentFormStep
        .get('group')
        .value.filter((item) => item.gender == 'male').length;
      this.femaleCount = this.studentFormStep
        .get('group')
        .value.filter((item) => item.gender == 'female').length;
      this.diversCount = this.studentFormStep
        .get('group')
        .value.filter((item) => item.gender == 'divers').length;
    }, 0);
  }

  checkMaxStudentCount() {
    return this.STUDENT_LIMIT > this.studentFormStep.value.group.length
      ? false
      : true;
  }

  removeValidations() {
    const formArray = this.studentFormStep.get('group') as FormArray;
    let count = 2; //this.studentAmountForm.get('studentAmount').value;
    for (let index = 0; index < count; index++) {
      const formGroup = formArray.at(index) as FormGroup;
      // Entfernen Sie die Validierungen für jeden Formularwert
      if (formGroup != undefined) {
        formGroup.get('gender').clearValidators();
        formGroup.get('language').clearValidators();
        formGroup.get('age').clearValidators();

        // Aktualisieren Sie die Validierungszustände
        formGroup.get('gender').updateValueAndValidity();
        formGroup.get('language').updateValueAndValidity();
        formGroup.get('age').updateValueAndValidity();
      }
    }
  }

  setValidation() {
    let schoolClassAge = parseInt(this.groupGrade) + 5;
    const birthyearEstimation = new Date();
    birthyearEstimation.setFullYear(
      birthyearEstimation.getFullYear() - schoolClassAge,
    );

    const formArray = this.studentFormStep.get('group') as FormArray;
    let count = 2; //this.studentAmountForm.get('studentAmount').value;
    for (let index = 0; index < count; index++) {
      const formGroup = formArray.at(index) as FormGroup;
      // Entfernen Sie die Validierungen für jeden Formularwert
      formGroup.get('gender').setValidators([Validators.required]);
      formGroup
        .get('language')
        .setValidators([
          Validators.required,
          this.validatorRequireMatch.bind(this),
        ]);
      formGroup.get('age').setValidators([Validators.required]);
      formGroup.get('age').setValue(moment(birthyearEstimation));

      // Aktualisieren Sie die Validierungszustände
      formGroup.get('gender').updateValueAndValidity();
      formGroup.get('language').updateValueAndValidity();
      formGroup.get('age').updateValueAndValidity();
    }
  }

  openTrialBanner(): void {
    let dialogRef = this.dialog.open(TrialBannersComponent, {
      width: '100%',
      autoFocus: false,
      disableClose: true,
      panelClass: 'trial-banner-panel',
      backdropClass: 'license-transfer-backdrop',
      data: {
        source: 'upgrade',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {});
  }

  createStudentsFromCSVData(csvData: StudentList[]) {
    let studentAmount = this.STUDENT_LIMIT;
    if (csvData.length < this.STUDENT_LIMIT) {
      studentAmount = csvData.length;
    }
    let currentClass = this.studentFormStep?.get('group') as FormArray;
    for (let index = 0; index < studentAmount; index++) {
      currentClass.push(this.createClassArray());
    }
    // Create language observables
    let count = studentAmount;
    for (let index = 0; index < count; index++) {
      this.setLanguages(index);
    }
    this.totalStudents = currentClass.length;

    // Fill the CSV data
    for (var i = 0; i < studentAmount; i++) {
      var element = (this.studentFormStep.get('group') as FormArray).at(
        i,
      ) as FormGroup;
      element
        .get('name')
        .patchValue(csvData[i].Firstname + ' ' + csvData[i].Lastname);
      element.get('gender').patchValue(csvData[i].Gender.toLowerCase());
      var birthdate: any =
        csvData[i].BirthMonthYear !== ''
          ? csvData[i].BirthMonthYear.split('-')
          : [];
      if (birthdate.length) {
        const ctrlValue = element.get('age').value;
        ctrlValue.month(birthdate[1] - 1); // real date minus 1 - i.e. december = 11
        ctrlValue.year(birthdate[0]);
        element.get('age').patchValue(ctrlValue);
      }
      //Set Language
      let getLanguageCode = [];
      if (this.csvLanguageCode === 'en') {
        getLanguageCode = this.languageInEn.filter(
          (obj) => obj.name.toLowerCase() === csvData[i].Language.toLowerCase(),
        );
      } else {
        getLanguageCode = this.languages.filter(
          (obj) => obj.name.toLowerCase() === csvData[i].Language.toLowerCase(),
        );
      }

      if (getLanguageCode.length) {
        this.setLanguageOption(getLanguageCode[0].code.toLowerCase(), i);
      }
    }

    // Enable Student Limit popup
    if (csvData.length > this.STUDENT_LIMIT) {
      this.openStudentLimitReachedDialog();
    }
  }

  openStudentLimitReachedDialog(): void {
    let dialogRef = this.dialog.open(StudentLimitReachedComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'student-limit-reached-popover',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'license-add-backdrop',
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.totalStudents != undefined) {
      if (changes.totalStudents.currentValue > 0) {
        setTimeout(() => {
          this.createStudents(changes.totalStudents.currentValue);
        }, 500);
      }
    }

    if (changes.studentList !== undefined) {
      if (changes.studentList.currentValue.length > 0) {
        setTimeout(() => {
          this.createStudentsFromCSVData(changes.studentList.currentValue);
        }, 1300);
      }
    }

    if (changes.csvLanguageCode !== undefined) {
      if (changes.csvLanguageCode.currentValue) {
        this.csvLanguageCode = changes.csvLanguageCode.currentValue;
      }
    }
  }
}

/*  clearStudentList() {
    for (let i = this.totalStudents; i > -1; i--) {
      this.removeStudent(i);
    }
    this.totalStudents = 0;
  } */

/*  selectedGroup(value?) {
    if (value == 'dataType') {
      if (this.isAdminFill) {
        //this.stepper.selectedIndex = 2;
        this.studentFormStep = this._formBuilder.group({
          group: this._formBuilder.array([this.createClassArray()]),
        });
        this.createStudents(this.totalStudents);
        this.generatePDF = true;
      } else if (this.isTeacherFill) {
        this.removeValidations();
        this.generatePDF = false;
        for (let i = 0; i < this.totalStudents; i++) {
          this.studentsArray.push({
            name: '',
            gender: '',
            language: '',
            age: '',
          });
        }
      }
    }
  } */
