export default {
  language_switcher: true,
  disable_school_family: false,
  disable_homepage_video: false,
  disable_expert: false,
  homepage_video_class: '',
  register_no_dialog: false,
  register_form: 'school-germany',
  landingpage: 'home',
  hide_register_text: false,
  hide_klett_shop: false,
  hide_klett_homepage_product_link: false,
  hide_products_price_table: false,
  trial_number_students: 10,
  generic_logo: 'laletu-logo.svg',
  logo: 'landingpage-logo.png',
  name: 'LaLeTu',
  domain_name: 'laletu.de',
  contact_url: '',
  support_url: 'https://support.laletu.de/hc/de',
  hide_code_helpdesk: false,
  always_show_footer: false,
  terms_of_use_name: '„LaLeTu“ (Lautlesetutor)',
  terms_of_use_url: 'www.laletu.de',
  terms_of_use_klett: 'Ernst Klett Verlag GmbH („Klett“)',
  footer_terms_of_use_url: '/nutzungsbedingungen',
  footer_privacy_url: '/datenschutz',
  footer_privacy_surveys_url: '/datenschutz/umfragen',
  footer_terms_and_conditions_url: '/agb',
  footer_imprint_url: '/impressum',
  footer_contact_url: '/kontakt',
  footer_parents_letter_filename: 'LaLeTu_Elterninformation.docx',
  footer_hide_link_terms_of_use: false,
  footer_hide_link_privacy: false,
  footer_hide_link_privacy_surveys: false,
  footer_hide_link_terms_and_conditions: false,
  footer_hide_link_imprint: false,
  footer_hide_link_contact: false,
  footer_hide_link_parents_letter: false,
  new_task_creation: true,
  sidebar_hint: false,
  hide_header: true,
  enable_csv_learns_upload: false,
  csv_import_support_url: 'https://support.laletu.de/hc/de/requests/new',
  logout_route: '/home',
};
