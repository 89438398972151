<div class="container-fluid">
  <button mat-mini-fab type="button" [mat-dialog-close]="'close'" class="close-button close-button--right" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="row">
    <div class="col-md-12">
      <div class="license-add-wrap" *ngIf="isFormView">
        <mat-icon svgIcon="streamline_filled:license-doc-plus" class="icon-120"></mat-icon>
        <h3 class="h3 h3--bold mt-3">
          {{ translatedText.license_add_license_code }}
        </h3>
        <p class="paragraph paragraph--xl text-center">
          {{ translatedText.license_add_desc }}
        </p>
        <form [formGroup]="licenseForm" #licenseFormElement="ngForm">
          <div class="license_input">
            <input
              #inputs
              formControlName="license"
              [class.ng-invalid]="licenseError"
              placeholder="{{ translatedText.license_add_license_code }}"
              required
            />
            <mat-icon
              svgIcon="streamline_outlined:tips"
              matTooltip="{{ translatedText.license_add_tooltip }}"
            ></mat-icon>
            <p class="paragraph paragraph--s error" *ngIf="licenseError">
              {{ translatedText.license_create_error }}
            </p>
          </div>

          <div class="instructionVideo_wrap">
            <button
              mat-flat-button
              color="secondary"
              type="button"
              class="mat-flat-button--full"
              aria-label="cancel"
              [mat-dialog-close]="'close'"
            >
              {{ translatedText.license_add_cancel }}
            </button>
            <button
              mat-flat-button
              color="primary"
              (click)="submitForm(licenseFormElement)"
              type="submit"
              class="mat-flat-button--full"
              aria-label="Connect"
              [disabled]="licenseForm.invalid || isLoading"
              #submitButton
            >
              <span *ngIf="!isLoading">{{ translatedText.license_add_save }}</span>
              <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
            </button>
          </div>
        </form>
      </div>
      <div class="license-add-wrap" *ngIf="licenseView">
        <h3 class="h3 h3--bold">
          {{ translatedText.license_add_available_license }}
        </h3>
        <!--  <div class="new-license-info">
          {{ translatedText.license_add_available_license_info }}
        </div> -->
        <table class="license-info-table">
          <tr>
            <th>
              <p class="paragraph paragraph--bold">
                {{ translatedText.license_add_license_type }}
              </p>
            </th>
            <th>
              <p class="paragraph paragraph--bold">
                {{ translatedText.license_add_amount }}
              </p>
            </th>
          </tr>
          <tr class="license-info-item">
            <td>
              <p class="paragraph paragraph--bold">
                {{ translatedText.license_add_diagnostic }}
              </p>
            </td>
            <td>
              <p class="paragraph paragraph--bold">
                {{ getLicenseAmountWithText('di') }}
              </p>
            </td>
          </tr>
          <tr class="license-info-item">
            <td>
              <p class="paragraph paragraph--bold">
                {{ translatedText.license_add_training }}
              </p>
            </td>
            <td>
              <p class="paragraph paragraph--bold">
                {{ getLicenseAmountWithText('fo') }}
              </p>
            </td>
          </tr>
          <!-- <tr>
                <td>Typ #3</td>
                <td>{{licenseInfo[2]?.amount}}</td>
              </tr> -->
        </table>
        <div class="button_wrap">
          <!--  <button
            mat-flat-button
            color="secondary"
            type="button"
            (click)="goToLink('https://support.laletu.de/hc/de/articles/14559661309724')"
          >
            {{ translatedText.license_add_license_faq }}
          </button> -->
          <button
            mat-flat-button
            color="primary"
            [mat-dialog-close]="'close'"
            type="submit"
            class="mat-flat-button--full done"
          >
            {{ translatedText.license_add_ok }}
          </button>
        </div>
      </div>

      <!-- <div class="license-add-wrap" *ngIf="licenseError">
        <mat-icon svgIcon="streamline_filled:delete-circle" class="icon-120"></mat-icon>
        <h3 class="h3 h3--bold mt-3">{{ translatedText.license_code_error_title }}</h3>
        <p class="paragraph paragraph--xl text-center">
          {{ translatedText.license_code_error_desc }}
        </p>
        <form [formGroup]="licenseForm" #licenseFormElement="ngForm">
          <div class="instructionVideo_wrap">
            <button
              mat-flat-button
              color="secondary"
              type="button"
              class="mat-flat-button--full"
              aria-label="cancel"
              [mat-dialog-close]="'close'"
            >
              {{ translatedText.license_code_error_cancel }}
            </button>
            <button mat-flat-button color="primary" type="submit" class="mat-flat-button--full" aria-label="Connect">
              <span>{{ translatedText.admin_manage_teacher_confirm }}</span>
              <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
            </button>
          </div>
        </form>
      </div> -->
    </div>
  </div>
</div>
