import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AuthInterceptor } from 'src/app/auth/auth-interceptor';
import { BookListModule } from 'src/app/books/new-book-list/book-list.module';
import { BookPreviewModule } from 'src/app/books/book-preview/book-preview.module';
import { BookViewModule } from 'src/app/books/book-view/book-view.module';
import { ErrorInterceptor } from 'src/app/error/error-interceptor';
import { LoadingSpinnerModule } from 'src/app/helpers/loading-spinner/loading-spinner.module';
import { MaterialModule } from 'src/app/material.module';
import { TrainingTaskCreateComponent } from './training-task-create.component';
import { TrialBannerModule } from 'src/app/trial-phase/trial-banner/trial-banner.module';
import { IndividualLearnersSelectionModule } from 'src/app/helpers/individual-learners-selection/individual-learners-selection.module';
import { AutomodeTaskFrequencyModule } from 'src/app/task/automode-task-frequency/automode-task-frequency.module';
import { PipeModule } from 'src/app/pipes/pipe.module';
import { TaskCreationBookViewModule } from 'src/app/books/task-creation-book-view/task-creation-book-view.module';
import { HintBoxModule } from 'src/app/helpers/hint-box/hint-box.module';
import { DueDateSelectorModule } from '../due-date-selector/due-date-selector.module';

@NgModule({
  declarations: [TrainingTaskCreateComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
    BookListModule,
    BookPreviewModule,
    BookViewModule,
    TrialBannerModule,
    IndividualLearnersSelectionModule,
    AutomodeTaskFrequencyModule,
    PipeModule,
    TaskCreationBookViewModule,
    HintBoxModule,
    DueDateSelectorModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [TrainingTaskCreateComponent],
})
export class TrainingTaskCreateModule {}
