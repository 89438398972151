import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { OrderProcessingAgreementComponent } from './order-processing-agreement.component';

@NgModule({
  declarations: [OrderProcessingAgreementComponent],
  imports: [BrowserModule, MaterialModule],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [OrderProcessingAgreementComponent],
})
export class OrderProcessingAgreementModule {}
