<div class="table-container admin-profile-licence-table">
  <table
    [dataSource]="dataSource"
    mat-table
    matSort
    matSortDisableClear
    [matSortActive]="sortBy"
    [matSortDirection]="matSortDirection"
  >
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>

    <ng-container matColumnDef="Content" sticky>
      <th mat-header-cell *matHeaderCellDef>
        {{ translatedText.admin_profile_licence_content }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="">
          <p class="paragraph paragraph--s" [attr.data-masterkey]="element?.master_license_key">
            {{ element?.content }}
          </p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="quota" sticky>
      <th mat-header-cell *matHeaderCellDef>
        {{ translatedText.admin_profile_licence_number_of_licenses }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="">
          <p class="paragraph paragraph--s">
            {{ element?.quota }}
          </p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Activated on" sticky>
      <th mat-header-cell *matHeaderCellDef>
        {{ translatedText.admin_profile_licence_activated_on }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div [ngClass]="element?.status == 'active' ? 'licence-active' : 'licence-Inactive'">
          <ng-container *ngIf="element?.status == 'pending'">
            <mat-icon class="trial_progress_icon" svgIcon="streamline_outlined:trial_progress_orange"></mat-icon>
            {{ translatedText.admin_profile_licence_status_not_activated }}</ng-container
          >
          <ng-container *ngIf="element?.status != 'pending'">
            <mat-icon svgIcon="streamline_filled:license-active-check"></mat-icon>
            {{ element?.activated_on | date : 'dd.MM.yyyy' }}</ng-container
          >
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="Expiry Date" sticky>
      <th mat-header-cell *matHeaderCellDef>
        {{ translatedText.admin_profile_licence_expiry_date }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div [ngClass]="element?.status == 'active' ? 'licence-active' : 'licence-Inactive'">
          <ng-container *ngIf="element?.status == 'pending'">
            <mat-icon class="trial_progress_icon" svgIcon="streamline_outlined:trial_progress_orange"></mat-icon>
            {{ translatedText.admin_profile_licence_status_not_activated }}</ng-container
          >
          <ng-container *ngIf="element?.status != 'pending'"
            ><mat-icon svgIcon="streamline_filled:license-active-check"></mat-icon>
            {{ element?.expiry_Date | date : 'dd.MM.yyyy' }}</ng-container
          >
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Activate" sticky>
      <th mat-header-cell *matHeaderCellDef>
        {{ translatedText.admin_profile_licence_activation }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="btn-center">
          <button
            mat-flat-button
            color="primary"
            type="button"
            [disabled]="element?.status == 'active'"
            (click)="activateLicenseOverlay(element?.master_license_key)"
          >
            <mat-icon svgIcon="streamline_outlined:license_check"></mat-icon>
            {{
              element?.status == 'active'
                ? translatedText.admin_profile_licence_status_activated
                : translatedText.admin_profile_licence_status_activate
            }}
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-footer-row *matFooterRowDef="['noRecord']" [hidden]="dataSource.length > 0"></tr>
    <ng-container matColumnDef="noRecord">
      <mat-footer-cell *matFooterCellDef colspan="2">
        <span>
          {{ translatedText.admin_teacher_list_no_results }}
        </span>
      </mat-footer-cell>
    </ng-container>
  </table>
  <!-- <mat-paginator
    [hidePageSize]="true"
    [length]="dataSource?.meta.totalItems"
    [pageSize]="
      dataSource?.meta.totalItems > dataSource?.meta.itemsPerPage
        ? dataSource?.meta.itemsPerPage
        : dataSource?.meta.totalItems
    "
    [pageSizeOptions]="[10, 25, 100]"
    (page)="pageEvent = $event; onPaginateChange($event)"
    showFirstLastButtons
  >
  </mat-paginator> -->
</div>
