<div class="edit-task-header-wrapper">
  <div class="cancel-btn-wrapper">
    <button mat-flat-button class="close-button-with-text" type="button" matSuffix mat-dialog-close>
      <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
      {{ translatedText.admin_group_create_cancel }}
    </button>
  </div>
  <div class="edit_task_middle_chip_wrapper">
    <div class="chip_wrapper">
      <div class="chip_outline">
        <div class="chip_content add-student-chip">{{ translatedText.admin_teacher_edit_task_deadline_chip }}</div>
      </div>
    </div>
  </div>
  <div class="confirm-button">
    <button mat-flat-button color="primary" [disabled]="!isValidated" mat-dialog-close (click)="updateTaskDeadline()">
      {{ translatedText.admin_manage_teacher_confirm }}
    </button>
  </div>
</div>
<div class="due-date-container">
  <h1>{{ translatedText.training_task_create_due_date }}</h1>
  <app-due-date-selector
    [control]="deadlineSelector.get('selectedDate')"
    [readingTimeSelector]="deadlineSelector"
    (newSelectedDeadline)="getSelectedDate($event)"
  >
  </app-due-date-selector>
</div>
