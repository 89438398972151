import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from 'src/app/material.module';
import { NotificationMenuComponent } from './notification-menu.component';
import { LoadingSpinnerModule } from '../../helpers/loading-spinner/loading-spinner.module';
@NgModule({
  declarations: [NotificationMenuComponent],
  imports: [
    BrowserModule,
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
  ],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [NotificationMenuComponent],
})
export class NotificationMenuModule {}
