<div class="task-test-overview">
  <div class="text-align-center mb-5">
    <h2 class="h2 h2--bold mt-5 mb-2">{{ translatedText?.diagnostic_task_overview_title }}</h2>
  </div>
  <div class="overview-box">
    <p class="paragraph paragraph--s paragraph--bold custom-t-color">
      {{ translatedText?.diagnostic_task_overview_sm_title }}
    </p>
    <div class="row-margin border-bottom-1">
      <div class="row">
        <div class="col-6">
          <p class="paragraph paragraph--s paragraph--bold">{{ translatedText?.task_list_type }}</p>
          <div class="test-batch">
            <mat-icon svgIcon="streamline_outlined:target" class="blue-icon"></mat-icon>
            <p class="paragraph paragraph--m paragraph--bold">
              {{ translatedText?.diagnostic_task_overview_task_type }}
            </p>
          </div>
        </div>
        <div class="col-6">
          <p class="paragraph paragraph--s paragraph--bold">
            {{ translatedText?.diagnostic_task_overview_timeframe_title }}
          </p>
          <div class="test-batch">
            <mat-icon svgIcon="streamline_outlined:calendar" class="blue-icon"></mat-icon>
            <p class="paragraph paragraph--m paragraph--bold">{{ selectedTypeDetails?.headline }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row-margin">
      <div class="row">
        <div class="col-6">
          <p class="paragraph paragraph--s paragraph--bold">{{ translatedText?.diagnostic_create_due_date }}</p>
          <div class="test-batch">
            <p class="paragraph paragraph--m paragraph--bold">
              {{ selectedTypeDetails?.dueDate | date : 'dd.MM.yyyy' }}
            </p>
          </div>
        </div>
        <div class="col-6">
          <p class="paragraph paragraph--s paragraph--bold">
            {{ translatedText?.diagnostic_task_overview_visible_title }}
          </p>
          <div class="test-batch">
            <p class="paragraph paragraph--m paragraph--bold">
              <span *ngIf="today >= selectedTypeDetails?.taskVisibleFrom">{{
                translatedText?.diagnostic_task_overview_timeframe_type
              }}</span>
              <span *ngIf="today < selectedTypeDetails?.taskVisibleFrom">{{
                selectedTypeDetails?.taskVisibleFrom | date : 'dd.MM.yyyy'
              }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="overview-box">
    <p class="paragraph paragraph--s paragraph--bold custom-t-color">{{ translatedText?.admin_dashboard_students }}</p>
    <div class="accordion_wrap selected-students-chips">
      <mat-accordion>
        <mat-expansion-panel [togglePosition]="'before'" *ngFor="let group of groupedStudents">
          <mat-expansion-panel-header>
            <mat-panel-title class="round-checkbox">
              <p class="paragraph paragraph--bold paragraph--xl">
                {{ group.groupName }} -
                {{ group.year | slice : 2 }}
              </p>
            </mat-panel-title>
            <mat-panel-description>
              <div class="Learners-count">
                <span class="Learners-count-inner">{{ group.students?.length }} / {{ group.groupLength }}</span>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-selection-list>
            <div class="task_overview-chip-wrap">
              <div *ngFor="let student of group['students']; let i = index" class="task_overview-chip">
                <div class="user-img">
                  <img *ngIf="student.avatarUrl" [src]="student.avatarUrl" />
                  <p class="paragraph paragraph--s paragraph--bold">
                    {{ student.username }}
                  </p>
                </div>
              </div>
            </div>
          </mat-selection-list>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
