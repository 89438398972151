import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetConfig,
} from '@angular/material/bottom-sheet';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TaskStartOverlayComponent } from 'src/app/helpers/task-start-overlay/task-start-overlay.component';
import { BookDataService } from 'src/app/providers/book-data.service';
import { StudentService } from 'src/app/providers/student.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { AuthService } from '../../auth/auth.service';
import { BookReaderService } from '../../book-reader/book-reader-service';
import { BookReaderSettingsComponent } from '../../book-reader/book-reader-settings/book-reader-settings.component';
import { BookService } from '../../providers/book.service';
import { TaskService } from '../../providers/task.service';
import { RecordRTCComponent } from '../../record-rtc/record-rtc.component';
import { BookListComponent } from '../new-book-list/book-list.component';
import { TimemodeOverlayComponent } from 'src/app/helpers/timemode-overlay/timemode-overlay.component';

export interface DialogData {}

export interface Book {
  title?: string;
  _id: string;
}

@Component({
  selector: 'app-book-view-student',
  templateUrl: './book-view-student.component.html',
  styleUrls: ['./book-view-student.component.scss'],
})
export class BookViewStudentComponent implements OnInit, OnDestroy {
  @Input('bookData') bookData: any;
  @Input('task') task: any;
  @Output() backToBookPreview = new EventEmitter<boolean>();
  @Output() goToReaderSettings = new EventEmitter<boolean>();
  @Output() backToTaskPreview = new EventEmitter<any>();
  @Output('selectedStartEvent') selectedStartEvent = new EventEmitter<any>();

  bookIsbn: string;
  dsefVersion = 1.0;
  book: any;
  bookUrl: string;
  bookImgUrl;
  bookTitle;
  bookAuthor;
  taskType;
  readingTime;
  readingTimeTitle;
  openFromBookmark = {};
  time: number = 0;
  interval;
  userType;
  taskId;
  userTaskUuid;
  wordcount = 0;
  isTeacher: boolean = false;
  resetSelection: boolean = false;
  tocToggle: boolean = false;
  bookReaderSettings;
  translatedText;
  syllabificationError: boolean = false;
  syllabificationDisableTool: string = 'Nicht verfügbar für diese Aufgabe';
  selectedStart: boolean = false;
  selectedStartWord = {};

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public viewDialog: MatDialogRef<BookViewStudentComponent>,
    public listDialog: MatDialogRef<BookListComponent>,
    public resDialog: MatDialogRef<TimemodeOverlayComponent>,
    private _bottomSheet: MatBottomSheet,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private bookService: BookService,
    private bookDataService: BookDataService,
    private bookReaderService: BookReaderService,
    private studentService: StudentService,
    private taskService: TaskService,
    private translationService: TranslationService,
  ) {
    // if (injectedData) {
    //   if (typeof(injectedData['isbn'])!=="undefined") {
    //     this.bookIsbn = this.injectedData["isbn"];
    //   } else {
    //     this.bookIsbn = this.route.snapshot.params['id'];
    //   }
    //   if (typeof(injectedData['title'])!=="undefined") {
    //     this.bookTitle = this.injectedData["title"];
    //   }
    //   if (typeof(injectedData['author'])!=="undefined") {
    //     this.bookAuthor = this.injectedData["author"];
    //   }
    //   if (typeof(injectedData['type'])!=="undefined") {
    //     this.taskType = this.injectedData["type"];
    //   }
    //   if (typeof(injectedData['time'])!=="undefined") {
    //     this.readingTime = this.injectedData["time"];
    //   }
    //   if (typeof(injectedData['taskId'])!=="undefined") {
    //     this.taskId = this.injectedData["taskId"];
    //   }
    //   if (typeof(injectedData['userTaskUuid'])!=="undefined") {
    //     this.userTaskUuid = this.injectedData["userTaskUuid"];
    //   }
    // }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    console.log(this.task);
    if (this.task !== undefined) {
      this.bookIsbn = this.task.book_isbn;
      this.dsefVersion = this.task.dsef_version ? this.task.dsef_version : 1.0;
      this.bookReaderSettings = this.task.reader_settings;
    } else {
      this.bookIsbn = this.route.snapshot.queryParams.isbn;
    }

    this.taskId = this.route.snapshot.params['id'];
    // Reset wordcount first
    this.bookReaderService.setWordCount(0);
    console.log('TASK ID' + ' ' + this.taskId);
    this.userType = this.authService.getType();

    if (this.userType !== 'student') {
      this.isTeacher = true;
      this.reversTimer();
    } else {
      this.isTeacher = false;
      this.reversTimer();
    }

    this.readingTimeTitle =
      'Lies ' + this.bookTitle + ' ' + this.readingTime + ' Minuten lang ';

    this.bookService.getBookById(this.bookIsbn);
    this.bookService
      .getBookUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.book = data;
        //   // Get book image by url
        //   this.getBookImageUrl(this.book.isbn);
      });

    this.bookReaderService
      .getWordCountUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (data: any) => {
          console.log(data);
          this.wordcount = data;
        },
        error: (error: any) => {
          console.error(error);
        },
      });

    // Open continous task from latest bookmark
    if (this.task?._split_task.length > 0) {
      let latestBookmarkByIsbn = this.studentService.getStudentBookmarkByIsbn(
        this.bookIsbn,
      );
      if (latestBookmarkByIsbn) {
        this.openFromBookmark = latestBookmarkByIsbn['start'];
      }
    }
    if (this.task?.type === 'time' || this.task?.type === 'time-choice') {
      if (localStorage.getItem('dialogStart') !== 'dontShow') {
        let dialogRef = this.dialog.open(TimemodeOverlayComponent, {
          width: '100%',
          autoFocus: false,
          panelClass: 'timemode-overlay',
          //hasBackdrop: false,
          disableClose: true,
          backdropClass: 'license-add-backdrop',
          data: {},
        });
      }
    }
  }

  clickedWord(evt) {
    this.selectedStartWord = evt.start;
    this.selectedStartEvent.emit(evt);
    this.selectedStart = true;
  }

  resetWordcount() {
    this.wordcount = 0;
    this.resetSelection = true;
    setTimeout(() => {
      this.resetSelection = false;
    }, 1000);
  }

  openBookReaderSettings() {
    let maxCharcount = this.bookReaderService.getMaxCharcount();
    let paragraphClass = this.bookReaderService.getParagraphClass();
    const config: MatBottomSheetConfig = {
      data: {
        maxCharcount: maxCharcount,
        paragraphClass: paragraphClass,
      },
    };
    this._bottomSheet.open(BookReaderSettingsComponent, config);
  }

  openBookReaderToc() {
    this.tocToggle = !this.bookDataService.getShowTableOfContents();
    this.bookDataService.setShowTableOfContentsUpdateListener(this.tocToggle);
  }

  async reversTimer() {
    this.interval = setInterval(async () => {
      this.time++;
      //console.log(this.time)
      if (this.time === 180) {
        if (this.userType !== 'student') {
          if (this.wordcount > 0) {
            this.closeBookview();
          } else {
            this.navigateBackToBookPreview();
          }
        } else {
          // this.startRecord();
          //let startFrom = await this.bookDataService.getCurrentPageAttributes();
          // this.backToTaskPreview.emit(
          //   {
          //     type:'start',
          //     data: startFrom
          //   }
          // );
          this.backToTaskPreview.emit({ type: 'close' });
        }
      }
    }, 1000);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  closeBookview() {
    // let data = {
    //   isbn : this.bookIsbn,
    //   title: this.bookTitle
    // }
    // this.viewDialog.close(data)
    this.goToReaderSettings.emit(true);
  }

  async startRecord() {
    // Close all open dialogs before
    //this.dialog.closeAll();

    clearInterval(this.interval);
    let startFrom = await this.bookDataService.getCurrentPageAttributes();

    this.viewDialog.close('taskStarted');
    // this.previewDialog.close();
    // this.listDialog.close();
    this.listDialog.afterClosed().subscribe((result) => {
      // todo start without timeout and remove scroll
      setTimeout(() => {
        let taskData = {
          type: this.taskType,
          readingTitle: this.readingTimeTitle,
          taskId: this.taskId,
          book_isbn: this.bookIsbn,
          book_name: this.bookTitle,
          startFrom: startFrom,
          userTaskUuid: this.userTaskUuid,
        };
        if (this.taskType === 'time') {
          taskData['time'] = this.readingTime;
        }

        let dialogRef = this.dialog.open(RecordRTCComponent, {
          width: '100%',
          autoFocus: false,
          panelClass: 'start-task-overlay-panel',
          hasBackdrop: false,
          disableClose: true,
          backdropClass: 'start-task-overlay-panel-backdrop',
          data: taskData,
        });
        // dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe();
      }, 500);
    });
  }

  getBookImageUrl(isbn) {
    this.bookService.getBookimage(isbn).subscribe((response) => {
      if (response) {
        // blob
        // let dataType = response.type;
        // let binaryData = [];
        //     binaryData.push(response);

        // let blob = new Blob(binaryData, {type: dataType});
        // let blobUrl = window.URL.createObjectURL(blob);
        // //window.open(blobUrl, '_blank');
        // this.bookImgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);

        // just url
        this.bookImgUrl = response.link;
      }
    });
  }

  //needed update task with startPage
  updateTask() {
    this.taskService
      .updateSingleTaskBook({
        _id: this.taskId,
        book_name: this.book?.title,
        book_isbn: this.book?.isbn,
      })
      .subscribe((res) => {
        this.router.navigate(['/task-preview/' + this.taskId]);
      });
  }

  backToPreview() {
    if (this.task !== undefined) {
      this.backToTaskPreview.emit({ type: 'close' });
      clearInterval(this.interval);
    } else {
      this.router.navigate(['/book-preview-student/' + this.taskId], {
        queryParams: { isbn: this.bookIsbn },
      });
    }
  }

  navigateBackToBookPreview() {
    this.backToBookPreview.emit(true);
  }

  async startTaskOverlay() {
    this.pauseTimer();
    let startFrom = await this.bookDataService.getCurrentPageAttributes();
    console.log('startFrom: ', startFrom);
    let startTaskDialog;
    startTaskDialog = this.dialog.open(TaskStartOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'task-start-overlay-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'task-start-overlay-task-panel-backdrop',
    });
    // dialogRef.disableClose = true;
    startTaskDialog.afterClosed().subscribe((res) => {
      if (res === 'start') {
        this.backToTaskPreview.emit({
          type: 'start',
          data: this.bookDataService.getBookmarkStartObj(),
          bookReaderSettings: this.bookReaderSettings,
        });
      } else if ('close') {
        this.reversTimer();
      }
    });
  }

  updateReaderSettings(event, option) {
    switch (option) {
      case 'font_family':
        this.bookReaderSettings.font_family = event.value;
        this.bookDataService.setFontFamilyUpdateListener(event.value);
        break;
      case 'font_size':
        this.bookReaderSettings.font_size = event.value;
        this.bookDataService.setFontSizeUpdateListener(event.value);
        break;
      case 'line_height':
        this.bookReaderSettings.line_height = event.value;
        this.bookDataService.setLineHeightUpdateListener(event.value);
        break;
      case 'letter_spacing':
        this.bookReaderSettings.letter_spacing = event.value;
        this.bookDataService.setLetterSpacingUpdateListener(event.value);
        break;
      case 'hyphenation_mode':
        this.bookReaderSettings.hyphenation_mode = event.value;
        this.bookDataService.setHyphenationModeUpdateListener(event.value);
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    clearInterval(this.interval);
  }
}
