<div class="container-fluid static_container">
  <div class="row">
    <div class="col-md-12">
      <div class="static_wrap">
        <div class="static_content">
          <h1 class="h1 static_header">Impressum</h1>
          <p>
            Digi Sapiens – Digital Learning GmbH<br />
            vertreten durch die Gesellschafter und Geschäftsführer: Daniel Iglesias und Andreas Haag
          </p>
          <p>
            <br /><span>Opernplatz 14</span><br />
            <span>60313 Frankfurt am Main</span><br />
            Service-Desk: +49 (0)69 380 299 333
            <br />Zentrale: +49 (0)69 380 299 300 <br />E-Mail-Adresse:
            <a href="mailto:info@digi-sapiens.de">info@digi-sapiens.de</a><br />
            Internet: <a href="https://www.digi-sapiens.de/" target="_blank">https://www.digi-sapiens.de/</a>
          </p>
          <p>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV: Daniel Iglesias</p>
          <h3>Registereintrag:</h3>
          <p>Eingetragen beim Amtsgericht Frankfurt am Main Registernummer: HRB 120684</p>

          <h3>Umsatzsteuer:</h3>
          <p>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE338276800</p>
          <h3>Streitschlichtung:</h3>
          <p>
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
            <a href="https://ec.europa.eu/consumers/odr" target="_blank"> https://ec.europa.eu/consumers/odr.</a> Unsere
            E-Mail-Adresse finden Sie oben im Impressum.
          </p>
          <p>
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle teilzunehmen.
          </p>

          <h3>Urheberrecht</h3>
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
            Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
            Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          </p>

          <h3>Quellangaben zu Bildern</h3>
          <p>Anmelden:</p>
          <ul>
            <li>https://www.freepik.com/premium-photo/diligent-man-with-books_34297741.htm</li>

            <li>https://www.freepik.com/premium-photo/funny-kid-with-textbook-head-yellow-background_8559475.htm</li>
          </ul>

          <p>Registrieren:</p>

          <ul>
            <li>
              https://www.freepik.com/free-photo/vertical-shot-thoughtful-young-female-with-glasses-working-computer-lights_12448560.htm
            </li>
          </ul>

          <p>LaLeTu Family:</p>

          <ul>
            <li>https://www.freepik.com/premium-photo/book-day-event-with-little-childrens_6929868.htm</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
