import { CdkStepper } from '@angular/cdk/stepper';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { BetaInfoComponent } from 'src/app/helpers/beta-info/beta-info.component';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { BookDataService } from 'src/app/providers/book-data.service';
import { BookService } from 'src/app/providers/book.service';
import { CapacitorService } from 'src/app/providers/capacitor.service';
import { GroupService } from 'src/app/providers/group.service';
import { LicenseService } from 'src/app/providers/license.service';
import { SchoolService } from 'src/app/providers/school.service';
import { TaskService } from 'src/app/providers/task.service';
import { TranslationService } from 'src/app/providers/translation.service';
import {
  UpgradeDialogComponent,
  UpgradeDialogData,
} from 'src/app/trial-phase/upgrade-banner/upgrade-banner.component';

const MAX_ALLOWED_TRAINING_TASKS = 4;

// TODO: define interface
export interface Group {}

export const DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export interface DialogData {}

class CustomDateAdapter extends MomentDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
}

@Component({
  selector: 'app-training-task-create',
  templateUrl: './training-task-create.component.html',
  styleUrls: ['./training-task-create.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class OldTrainingTaskCreateComponent implements OnInit, OnDestroy {
  selectedIndex: number;
  isMaintenanceMode: boolean = false;
  taskModeStep;
  taskAreaStep;
  taskChoiceStep;
  taskOverviewStep;
  lastStep;
  readerSetting: FormGroup;
  deadlineStep: FormGroup;
  groupSelectStep: FormGroup;
  completionDetailStep: FormGroup;
  durationForm: FormGroup;
  bookSelectionStep;
  completionTaskStep;
  durationStep;
  isManuelMode: boolean = false;
  isAutoMode: boolean = false;
  isReading_fluency: boolean = false;
  isUnderstand_text: boolean = false;
  isListening_comprehension: boolean = false;
  isSpecific_text: boolean = false;
  isSpecific_book: boolean = false;
  isOneTimeTask: boolean = false;
  isSeveralSteps: boolean = false;
  isEvery_book: boolean = false;
  licensesToSchool: any = [];
  licensesToSchoolLoading: boolean = true;
  contentType: 'klett' | 'full' | 'trial' = 'trial';
  taskType;
  taskChoice;
  @ViewChild('stepper') stepper: CdkStepper;
  @ViewChild('disableInHolidays') disableInHolidays: CdkStepper;
  taskMode;
  taskArea;
  taskCompletionMode;
  fontSize: string = 'small';
  line_height: string = 'small';
  letter_spacing: string = 'small';
  hyphenation_mode: string = 'option0';
  fontFamilyClass: string = 'fontFamily fontFamily__abeezee';
  fontSizeClass: string = 'fontSize fontSize__small';
  lineSpacingClass: string = 'line_height line_height__small';
  spaceBetweenClass: string = 'letter_spacing letter_spacing__small';
  hyphenationClass: string = 'hyphenation_mode';
  todayDate: Date = new Date();
  teacherId;
  userType;
  groups: any = [];
  studentsArray = [];
  isSingleGroup: boolean = false;
  isSingleStudent: boolean = false;
  groupOverview = [];
  description;
  tasks;
  taskOverview = [];
  groupArray = [];
  estimationByGrade = [];
  wordcount;
  bookSelected;
  isBookList: boolean = false;
  isBookPreview: boolean = false;
  isBookView: boolean = false;
  isClosedDialog: boolean = false;
  bookData = {
    author: '',
    description: '',
    isbn: '',
    title: '',
    dsef_version: 1.0,
  };
  disableStep: boolean = false;
  selectedCompletionDetail = 'minutes';
  isLoading: boolean = false;
  isAllowedReadMore: boolean = false;
  deadline;
  //maxMinimumValue = 0;
  selectedWeekdays: string[] = [];
  checkSelection: boolean = false;
  trainingLicenseLeft;
  @ViewChild('students') students: MatSelectionList;
  selectedDayLength: number = 0;
  betaInfoText: string =
    'Diese Funktion befindet sich im Beta-Stadium und kann daher unbegrenzt kostenfrei genutzt werden. Ihr Minutenbudget wird nicht belastet. In jedem Fall profitieren die Lernenden aber von den Inhalten und der Lautlesepraxis. Sie als Lehrkraft haben Transparenz über den Erledigungsstand von Aufgaben und können in die Aufzeichnungen reinhören. Wir arbeiten mit Hochdruck daran, diese Funktion schnellstmöglich fehlerfrei bereitzustellen.';
  isGroupLoading: boolean = true;
  showBetaInfo: boolean = false;
  translatedText;
  weekdaysSelected: string[] = [];
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };
  bookImgUrl;
  imageLoadFailed: boolean = false;
  iPadResolution: boolean = false;

  //year change variables
  currentYear: string;
  schoolYearTransition: boolean;
  nextYear: string;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData, // TODO: private or public?
    public dialog: MatDialog, // TODO: private or public?
    public snackBar: MatSnackBar, // TODO: private or public?
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private bookDataService: BookDataService,
    private groupService: GroupService,
    private taskService: TaskService,
    private translationService: TranslationService,
    private licenseService: LicenseService,
    private schoolService: SchoolService,
    private bookService: BookService,
    private capacitorService: CapacitorService,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.teacherId = this.authService.getTeacherId();
    this.userType = this.authService.getType();
    this.getLicenses();

    // this.teacherService.readSingleTeacher(this.teacherId).pipe(
    //   takeUntil(this._unsubscribeAll)
    // ).subscribe(res => {
    //   console.log(res)
    //     if (res["betaInfoSeen"] === true) {
    //       this.showBetaInfo = false;
    //     } else {
    //       this.showBetaInfo = true;
    //     }
    // });
    this.showBetaInfo = true;

    this.groupService.getGroupsToTeacher(false).subscribe((res) => {
      this.isGroupLoading = false;
      this.groups = res;
      console.log(this.groups);
      this.groups.sort(function (a, b) {
        return a['group'].name.localeCompare(b['group'].name);
      });
      this.groups.forEach((element) => {
        element.group._students.sort(function (a, b) {
          return a['username'].localeCompare(b['username']);
        });
      });
    });

    this.readerSetting = this._formBuilder.group({
      font_family: ['abeezee'],
      font_size: [''],
      line_height: [''],
      letter_spacing: [''],
      hyphenation_mode: [''],
      editable: [false],
    });

    this.deadlineStep = this._formBuilder.group({
      deadline: ['', Validators.required],
    });

    this.groupSelectStep = this._formBuilder.group({
      students: [''],
    });

    this.completionDetailStep = this._formBuilder.group({
      minutes: [null, [Validators.required, Validators.min(1)]],
      //minimum  : [null]
    });

    this.durationForm = this._formBuilder.group({
      duration: ['1', Validators.required],
      // weekdays  : ["",  [Validators.required, Validators.minLength(3), Validators.maxLength(3)]]
    });

    this.trialStatus = this.authService.getUserTrialStatus();
    this.getSchoolYearChanged();
    this.checkIfiPad();
  }

  getCurrentLanguage() {
    return this.translationService.getCurrentLanguage();
  }

  getLicenses() {
    this.licenseService.getLicensesToSchool();
    this.licenseService
      .getLicensesUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((licenses: any[]) => {
        this.licensesToSchool = licenses;
        this.contentType = this.licenseService.getContentTypeByLicense(
          this.licensesToSchool,
        );
        this.licensesToSchoolLoading = false;
      });
  }

  getCurrentYear() {
    this.schoolService
      .currentYear()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        console.log(data);
        this.currentYear = data.year;
      });
  }

  getSchoolYearChanged() {
    this.schoolService
      .schoolYearChanged()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.schoolYearTransition = data.yearChange;
        this.getCurrentYear();
        this.nextYear = data.nextYear;
      });
  }

  getBookImageUrl(isbn) {
    this.bookService.getBookimage(isbn).subscribe((response) => {
      if (response) {
        // just url
        this.bookImgUrl = response.link;
      }
    });
  }
  getBookCoverByIsbn(isbn, size) {
    return this.bookService.getBookCoverByIsbn(isbn, size);
  }

  handleImageError(isbn, event: any) {
    // just try once
    if (!this.imageLoadFailed) {
      this.imageLoadFailed = true;
      this.bookService.getBookimage(isbn).subscribe((response) => {
        if (response) {
          // just url
          event.target.src = response.link;
        }
      });
    }
  }

  // Logic for minimum
  // updateMaxValue() {
  //   const minutesValue = this.completionDetailStep.get('minutes').value;
  //   this.maxMinimumValue = minutesValue || 0;
  //   if (this.maxMinimumValue > 0) {
  //     this.completionDetailStep.get('minimum').enable();
  //   } else {
  //     this.completionDetailStep.get('minimum').disable();
  //   }
  //   if (this.isSeveralSteps === true) {
  //     this.completionDetailStep.get('minimum').setValidators([Validators.required , Validators.min(1), Validators.max(this.maxMinimumValue)]);
  //     this.completionDetailStep.get('minimum').updateValueAndValidity();
  //   } else {
  //     this.completionDetailStep.get('minimum').setValidators(null);
  //     this.completionDetailStep.get('minimum').updateValueAndValidity();
  //   }
  // }

  getLicenseAmount(licenses, tag, type) {
    let amount = 0;
    if (licenses.length > 0) {
      licenses.map((item) => {
        if (item.type_key === tag) {
          if (type === 'total') {
            amount += item.amount_total;
          } else {
            amount += item.amount_left;
          }
        }
      });
      if (tag === 'fo') {
        amount = amount > 0 ? amount / 60 : amount;
      }
    }
    return Math.floor(amount);
  }

  calculatedLicenses(licenseLeft, readingMinutes, blockedLicenses) {
    const licenseCount = blockedLicenses + readingMinutes;
    // Beta mode free to use
    if (
      /*this.taskChoice === "specific_book" || this.taskChoice === "every_book" ||*/ this
        .isAutoMode
    ) {
      return licenseLeft;
    } else {
      return licenseLeft - licenseCount < 0 ? 0 : licenseLeft - licenseCount;
    }
  }

  readingMinutes(students, studentsSelected) {
    if (this.isManuelMode) {
      if (this.isSpecific_text) {
        let minutes = 0;
        if (students.length > 0) {
          students.map((student) => {
            const isSelected = studentsSelected.some(
              (selectedStudent: any) =>
                selectedStudent._value._id === student._id,
            );
            if (isSelected) {
              let filteredGrade = this.estimationByGrade.filter((item) =>
                student._group.some((el) => el.grade === item.grade),
              );
              minutes +=
                filteredGrade.length > 0
                  ? Math.ceil(filteredGrade[0].minutes)
                  : 0;
            }
          });
        }
        return minutes;
      } else {
        return (
          this.completionDetailStep.get('minutes').value *
          studentsSelected.length
        );
      }
    } else if (this.isAutoMode) {
      return (
        this.durationForm.get('duration').value *
        4 *
        this.selectedDayLength *
        15 *
        studentsSelected.length
      );
    }
  }

  filterGroupsWithoutPersonalData(groups) {
    // only list groups that have students with gender value
    return groups?.filter((element) => {
      const hasStudents =
        element.group._students && element.group._students.length > 0;
      const hasEmptyGender = element.group._students.some(
        (student) => !student.gender,
      );
      return hasStudents && !hasEmptyGender;
    });
  }

  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
    console.log(this.selectedIndex);
    // Get estimated minutes by wordcount and group grade
    if (this.selectedIndex === 9 && this.isSpecific_text) {
      this.getEstimatedReadingTime();
    }
  }

  getEstimatedReadingTime() {
    this.wordcount = this.bookDataService.getWordCountForTask();
    this.taskService.getEstimatedReadingTime(this.wordcount).subscribe({
      next: (estimations: any) => {
        this.estimationByGrade = estimations.groups;
      },
      error: (error: any) => {
        console.log(error);
        let message =
          'Die erwarteten Minuten Lesezeit konnten nicht berechnet werden.';
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: message,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }

  // add style and set task data for selectedType()
  setTaskData(value) {
    //step 0
    if (value === 'manuel') {
      this.isManuelMode = true;
      this.isAutoMode = false;
    }
    if (value === 'auto') {
      this.isManuelMode = false;
      this.isAutoMode = true;
    }
    if (this.isManuelMode === true) {
      this.taskMode = 'manuel';
      //this.durationForm.get('weekdays').setValidators(null);
      this.durationForm.get('duration').setValidators(null);
      //this.durationForm.get('weekdays').updateValueAndValidity();
      this.durationForm.get('duration').updateValueAndValidity();
    } else if (this.isAutoMode === true) {
      this.taskMode = 'auto';
      //this.durationForm.get('weekdays').setValidators([Validators.required]);
      this.durationForm.get('duration').setValidators([Validators.required]);
      //this.durationForm.get('weekdays').updateValueAndValidity();
      this.durationForm.get('duration').updateValueAndValidity();
    } else {
      this.taskMode = '';
    }

    // step 1
    if (value === 'reading_fluency') {
      this.isReading_fluency = true;
    }

    if (this.isReading_fluency === true) {
      this.taskArea = 'reading_fluency';
    } else {
      this.taskArea = '';
    }

    // step2
    if (value === 'specific_text') {
      this.isSpecific_text = true;
      this.isSpecific_book = false;
      this.isEvery_book = false;
    } else if (value === 'specific_book') {
      this.isSpecific_book = true;
      this.isSpecific_text = false;
      this.isEvery_book = false;
    } else if (value === 'every_book') {
      this.isEvery_book = true;
      this.isSpecific_book = false;
      this.isSpecific_text = false;
    }

    if (this.isSpecific_text === true) {
      this.taskChoice = 'specific_text';
      this.taskType = 'text';
    } else if (this.isSpecific_book === true) {
      this.taskChoice = 'specific_book';
      this.taskType = 'time';
    } else if (this.isEvery_book === true) {
      this.taskChoice = 'every_book';
      this.taskType = 'time-choice';
    } else {
      this.taskChoice = '';
    }

    if (value === 'one_time_task') {
      this.isOneTimeTask = true;
      this.isSeveralSteps = false;
    } else if (value === 'several_steps') {
      this.isSeveralSteps = true;
      this.isOneTimeTask = false;
    }

    console.log(this.isOneTimeTask);
    console.log(this.isSeveralSteps);
    if (this.isOneTimeTask === true) {
      this.taskCompletionMode = 'one_time_task';
      // this.completionDetailStep.get('minimum').setValidators(null);
      // this.completionDetailStep.get('minimum').updateValueAndValidity();
      // this.completionDetailStep.get('minimum').setValue(null);
      this.completionDetailStep
        .get('minutes')
        .setValidators([Validators.required, Validators.min(1)]);
      this.completionDetailStep.get('minutes').updateValueAndValidity();
    } else if (this.isSeveralSteps === true) {
      this.taskCompletionMode = 'several_steps';
      console.log(this.completionDetailStep.get('minutes').value);
      // if (this.completionDetailStep.get('minutes').value === null) {
      //   this.completionDetailStep.get('minimum').disable();
      // }
      // this.completionDetailStep.get('minimum').setValidators([Validators.required , Validators.min(1), Validators.max(this.maxMinimumValue)]);
      // this.completionDetailStep.get('minimum').updateValueAndValidity();
      this.completionDetailStep
        .get('minutes')
        .setValidators([Validators.required, Validators.min(1)]);
      this.completionDetailStep.get('minutes').updateValueAndValidity();
    } else {
      this.taskCompletionMode = '';
      this.completionDetailStep.get('minutes').setValidators(null);
      this.completionDetailStep.get('minutes').updateValueAndValidity();
      // this.completionDetailStep.get('minimum').setValue(null);
      // this.completionDetailStep.get('minimum').setValidators(null);
      // this.completionDetailStep.get('minimum').updateValueAndValidity();
    }
  }

  closeAll(event: boolean): void {
    this.dialog.closeAll();
    this.isClosedDialog = event;
  }

  getSelectedWeekdays(selectedValues: any[]) {
    const weekdaysControl = this.durationForm.get('weekdays') as FormControl;
    this.selectedDayLength = selectedValues.length;
    if (selectedValues.length <= 3) {
      weekdaysControl.setValue(selectedValues);
    } else {
      weekdaysControl.setValue(selectedValues.slice(0, 3));
    }
  }

  optionSetting(value, name) {
    console.log(value.currentTarget.checked);
    if (name === 'reader') {
      if (value.currentTarget.checked === true) {
        this.readerSetting.get('editable').setValue(true);
      } else {
        this.readerSetting.get('editable').setValue(false);
      }
    } else if (name === 'read_more') {
      if (value.currentTarget.checked === true) {
        this.isAllowedReadMore = true;
      } else {
        this.isAllowedReadMore = false;
      }
    }
  }

  toggleChipSelection(chip: string): void {
    const index = this.weekdaysSelected.indexOf(chip);
    console.log(this.weekdaysSelected);
    if (index >= 0) {
      this.weekdaysSelected.splice(index, 1);
    } else {
      if (this.weekdaysSelected.length < 3) {
        this.weekdaysSelected.push(chip);
      }
    }
  }

  navigateToDeadline() {
    this.stepper.selectedIndex = 7;
    this.stepper.selectedIndex = 8;
  }

  selectedType(value?) {
    //step 0
    if (this.stepper.selectedIndex === 0) {
      if (this.taskMode === 'auto') {
        // remove validation from deadline for auto mode
        this.deadlineStep.get('deadline').setValidators(null);
        this.deadlineStep.get('deadline').updateValueAndValidity();
        this.stepper.selectedIndex = 1;
        // this.stepper.selectedIndex = 2;
        // this.stepper.selectedIndex = 3;
        // this.stepper.selectedIndex = 4;
        // this.stepper.selectedIndex = 5;
        // this.stepper.selectedIndex = 6;
        // this.stepper.selectedIndex = 7;
        if (this.showBetaInfo === true) {
          this.openBetaInfo(false);
        }
      } else if (this.taskMode === 'manuel') {
        this.durationForm.get('duration').setValidators(null);
        this.durationForm.get('duration').updateValueAndValidity();
        this.deadlineStep.get('deadline').setValidators([Validators.required]);
        this.deadlineStep.get('deadline').updateValueAndValidity();
        this.stepper.selectedIndex = 1;
      } else {
        let message = 'Bitte wählen Sie ein Trainingsmodus aus.';
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText
            ?.training_task_create_error_snackbar_select_mode,
          duration: 3000,
          horizontalPosition: 'left',
        });
      }
    }

    if (value === 'area') {
      if (this.taskArea === 'reading_fluency') {
        if (this.taskMode === 'auto') {
          this.stepper.selectedIndex = 2;
          this.stepper.selectedIndex = 3;
          this.stepper.selectedIndex = 4;
          this.stepper.selectedIndex = 5;
          this.stepper.selectedIndex = 6;
          this.stepper.selectedIndex = 7;
        } else if (this.taskMode === 'manuel') {
          this.stepper.selectedIndex = 2;
        }
      } else {
        let message = 'Bitte wählen Sie ein Testbereich aus.';
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText
            ?.training_task_create_error_snackbar_select_test,
          duration: 3000,
          horizontalPosition: 'left',
        });
      }
    }
  }

  updateReaderOptions(event, option) {
    switch (option) {
      case 'fontFamily':
        console.log(event);
        if (event.value === 'abeezee') {
          this.fontFamilyClass = 'fontFamily fontFamily__abeezee';
        } else if (event.value === 'poppins') {
          this.fontFamilyClass = 'fontFamily fontFamily__poppins';
        } else {
          this.fontFamilyClass = '';
        }
        break;
      case 'fontSize':
        this.fontSize = event.value;
        if (event.value === 'small') {
          this.fontSizeClass = 'fontSize fontSize__small';
        } else if (event.value === 'normal') {
          this.fontSizeClass = 'fontSize fontSize__normal';
        } else if (event.value === 'big') {
          this.fontSizeClass = 'fontSize fontSize__big';
        } else {
          this.fontSizeClass = '';
        }
        break;
      case 'line_height':
        this.line_height = event.value;
        if (event.value === 'small') {
          this.lineSpacingClass = 'line_height line_height__small';
        } else if (event.value === 'normal') {
          this.lineSpacingClass = 'line_height line_height__normal';
        } else if (event.value === 'big') {
          this.lineSpacingClass = 'line_height line_height__big';
        } else {
          this.lineSpacingClass = '';
        }
        break;
      case 'letter_spacing':
        this.letter_spacing = event.value;
        if (event.value === 'small') {
          this.spaceBetweenClass = 'letter_spacing letter_spacing__small';
        } else if (event.value === 'normal') {
          this.spaceBetweenClass = 'letter_spacing letter_spacing__normal';
        } else if (event.value === 'big') {
          this.spaceBetweenClass = 'letter_spacing letter_spacing__big';
        } else {
          this.spaceBetweenClass = '';
        }
        break;
      case 'hyphenation_mode':
        this.hyphenation_mode = event.value;
        if (event.value === 'option1') {
          this.hyphenationClass =
            'hyphenation_preview hyphenation_preview__option1';
        } else if (event.value === 'option2') {
          this.hyphenationClass =
            'hyphenation_preview hyphenation_preview__option2';
        } else if (event.value === 'option3') {
          this.hyphenationClass =
            'hyphenation_preview hyphenation_preview__option3';
        } else {
          this.hyphenationClass = '';
        }
        break;
      default:
        break;
    }
  }

  // select all students student and add or remove to array
  toggleSelectAll(students, select) {
    if (select === true) {
      students.options.forEach((element) => {
        if (
          element?._value?.status === 'active' &&
          (!element._value.trial?.training ||
            element._value.trial?.training < MAX_ALLOWED_TRAINING_TASKS)
        ) {
          element.selected = true;
          this.studentsArray.push(element._value);
        }
      });
    } else {
      students.deselectAll();
      students.options.forEach((element) => {
        this.studentsArray = this.studentsArray.filter(
          (item) => item._id !== element._value._id,
        );
      });
    }
  }

  // select student and add or remove to array
  handleSelection(event, students) {
    if (this.isSingleGroup == false) {
      if (event.options[0].selected === true) {
        this.studentsArray.push(event.options[0].value);
      } else if (
        event.options[0].selected == false &&
        this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray = this.studentsArray.filter(
          (item) => item !== event.options[0].value,
        );
      }
    } else if (this.isSingleGroup === true || this.isSingleStudent === true) {
      if (
        event.options[0].selected == false ||
        this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray = this.studentsArray.filter(
          (item) => item._id !== event.options[0].value._id,
        );
      } else if (
        event.options[0].selected === true &&
        !this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray.push(event.options[0].value);
        //this.studentsArray =  this.studentsArray.filter(item => item !== event.options[0].value);
      }
    }
  }

  disableSelectAll(students) {
    let count = 0;
    students.forEach((student) => {
      student.licenses.forEach((license) => {
        if (license.type_key === 'fo' && license.active) {
          count++;
        }
      });
    });
    return count == students.length ? false : true;
  }

  isOptionDisabled(group: any, selectedOptions: any[], student: any): boolean {
    const foundStudent = group._students.find(
      (item) => item._user.uuid === student._user.uuid,
    );
    if (foundStudent) {
      const license = student.licenses.find(
        (license) => license.type_key === 'fo',
      );
      return license ? !license.active : true;
    } else {
      return true;
    }
  }

  hasTooManyTrialTasks(group, student) {
    if (!this.trialStatus.isActive) {
      return false;
    }
    const foundStudent = group._students.find(
      (s) => s._user.uuid === student._user.uuid,
    );
    if (foundStudent) {
      if (
        foundStudent.trial?.training && // Make sure that training exists before accessing it
        foundStudent.trial.training >= MAX_ALLOWED_TRAINING_TASKS
      ) {
        return true;
      }
    }
    return false;
  }

  checkForSelection(student, index) {
    if (
      this.isSingleGroup === true ||
      this.isSingleStudent === true ||
      this.checkSelection === true
    ) {
      return this.studentsArray.some((el) => {
        return el._id === student._id;
      });
    }
  }

  getStundentsByGroup(id) {
    return this.tasks.filter((task) => task._group === id);
  }

  removeFromStudentsArray(index: number, id): void {
    // this.studentsArray.splice(index, 1);
    this.studentsArray = this.studentsArray.filter((item) => item._id !== id);
    console.log(this.studentsArray);
  }

  navigateBackToStudentSelection() {
    this.checkSelection = true;
    this.stepper.selectedIndex = 9;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  // set data for task overview step
  setTaskDataForOverview() {
    this.taskOverview = [];
    this.groupArray = [];
    this.groupOverview = [];
    this.studentsArray.forEach((element) => {
      element.groupName = this.groups.filter((group) =>
        element?._group.some((el) => el._id === group?.group?._id),
      )[0]?.group?.name;
      element.groupSchoolYear = this.groups.filter((group) =>
        element?._group.some((el) => el._id === group?.group?._id),
      )[0]?.group?.school_year;
      element.groupLength = this.groups.filter((group) =>
        element?._group.some((el) => el._id === group?.group?._id),
      )[0]?.group?._students?.length;
      this.taskOverview.push({
        _id: element._id,
        gender: element.gender,
        username: element.username,
        avatar: element.avatar,
        avatarUrl: element.avatarUrl,
        _group: element._group,
        groupName: this.groups.filter((group) =>
          element?._group.some((el) => el._id === group?.group?._id),
        )[0]?.group?.name,
      });
    });

    this.tasks = this.taskOverview;
    this.tasks.forEach((element) => {
      this.groupArray.push(
        this.tasks.filter((task) => element._group === task._group)[0]._group,
      );
    });

    this.wordcount = this.bookDataService.getWordCountForTask();

    let minutesString;
    if (this.completionDetailStep.value.minutes === 1) {
      minutesString = 'Minute';
    } else {
      minutesString = 'Minuten';
    }

    // set description text
    if (this.taskChoice === 'specific_text') {
      this.description =
        this.translationService.getTranslatedText('finished_task_read') +
        ' ' +
        this.wordcount +
        ' ' +
        this.translationService.getTranslatedText('finished_task_read_words') +
        ' ' +
        this.bookData?.title +
        '.';
    } else if (this.taskChoice === 'specific_book') {
      this.description =
        this.translationService.getTranslatedText('finished_task_read') +
        ' ' +
        this.completionDetailStep.value.minutes +
        ' ' +
        minutesString +
        ' ' +
        this.translationService.getTranslatedText('finished_task_read_in') +
        ' ' +
        this.bookData?.title +
        '.';
    } else if (this.taskChoice === 'every_book') {
      this.description =
        this.translationService.getTranslatedText('finished_task_read') +
        ' ' +
        this.completionDetailStep.value.minutes +
        ' ' +
        minutesString +
        ' ' +
        this.translationService.getTranslatedText('finished_task_read_book');
    }

    this.groupArray = this.groupArray.filter(
      (value, index) => this.groupArray.indexOf(value) === index,
    );
    console.log(this.groupArray);
    this.groupArray.forEach((element) => {
      let group = {
        id: element,
        name: this.tasks.filter((task) => element === task._group)[0].groupName,
        username: this.tasks.filter((task) => element === task._group)[0]
          .username,
        avatarUrl: this.tasks.filter((task) => element === task._group)[0]
          .avatarUrl,
      };
      this.groupOverview.push(group);
    });
    // this.groupArray = this.groupArray.map(obj => {
    //   //obj =  [];
    //   console.group(obj)
    //   // obj["id"] = obj;
    //  //  obj["name"] = this.tasks.filter(task => obj === task._group)[0].groupName;
    //   return obj;
    // })
    console.log(this.groupOverview);
    if (!this.isAutoMode) {
      this.stepper.selectedIndex = 10;
    } else {
      this.stepper.selectedIndex = 10;
      this.stepper.selectedIndex = 11;
      this.isLoading = true;
      this.submitTask();
    }
  }

  bookListData(event) {
    this.isBookList = event.listview;
    this.isBookPreview = event.preview;
    this.bookData = event;
    console.log(event);
  }

  backToLibrary(event) {
    this.isBookList = event;
    this.isBookPreview = false;
    this.isBookView = false;
  }

  backToBookPreview(event) {
    this.isBookList = false;
    this.isBookPreview = true;
    this.isBookView = false;
  }

  navigateToReaderSettings(event) {
    this.isBookList = false;
    this.isBookPreview = false;
    this.isBookView = false;
    this.disableStep = true;

    // skip step 4 & 5 and navigate to 6
    this.stepper.selectedIndex = 4;
    this.stepper.selectedIndex = 5;
    this.stepper.selectedIndex = 6;

    //console.log(this.stepper.selectedIndex);
  }

  navigateBackToStep() {
    if (this.taskChoice === 'specific_text') {
      if (this.stepper.selectedIndex === 6) {
        this.stepper.selectedIndex = 3;
        this.isBookList = false;
        this.isBookPreview = false;
        this.isBookView = true;
      } else if (this.stepper.selectedIndex === 8) {
        this.stepper.selectedIndex = 6;
      }
    }

    if (this.taskChoice === 'specific_book') {
      if (this.stepper.selectedIndex === 4) {
        this.stepper.selectedIndex = 3;
        this.isBookList = false;
        this.isBookPreview = true;
        this.isBookView = false;
      } else if (this.stepper.selectedIndex === 8) {
        this.stepper.selectedIndex = 6;
      } else if (this.stepper.selectedIndex === 6) {
        this.stepper.selectedIndex = 5;
      } else if (this.stepper.selectedIndex === 9) {
        this.stepper.selectedIndex = 8;
      }
    }

    if (this.taskChoice === 'every_book') {
      if (this.stepper.selectedIndex === 6) {
        this.stepper.selectedIndex = 5;
      } else if (this.stepper.selectedIndex === 8) {
        this.stepper.selectedIndex = 6;
      } else if (this.stepper.selectedIndex === 4) {
        this.stepper.selectedIndex = 2;
      }
    }

    if (this.taskMode === 'auto') {
      if (this.stepper.selectedIndex === 7) {
        this.stepper.selectedIndex = 0;
      } else if (this.stepper.selectedIndex === 9) {
        this.stepper.selectedIndex = 7;
        if (this.students != undefined) {
          this.students.deselectAll();
        }
      }
    } else {
      if (this.stepper.selectedIndex === 9) {
        this.stepper.selectedIndex = 8;
        if (this.students != undefined) {
          this.students.deselectAll();
        }
      }
    }
  }

  navigateToStep(value?) {
    console.log(this.stepper.selectedIndex);
    console.log(this.taskCompletionMode);
    if (value === 'taskChoice') {
      // Show beta leave info
      if (
        this.taskChoice === 'specific_book' ||
        this.taskChoice === 'specific_text'
      ) {
        this.isBookList = true;
      }

      if (
        this.taskChoice === 'specific_text' ||
        this.taskChoice === 'specific_book'
      ) {
        if (this.stepper.selectedIndex === 2) {
          this.stepper.selectedIndex = 3;
        }
      } else if (this.taskChoice === 'every_book') {
        if (this.stepper.selectedIndex === 2) {
          this.stepper.selectedIndex = 3;
          this.stepper.selectedIndex = 4;
        }
      } else {
        let message = 'Bitte wählen Sie eine Art der Aufgabe aus.';
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText
            ?.training_task_create_error_snackbar_select_type_task,
          duration: 3000,
          horizontalPosition: 'left',
        });
      }
    }

    if (this.taskMode === 'auto') {
      if (this.stepper.selectedIndex === 7) {
        this.stepper.selectedIndex = 8;
        this.stepper.selectedIndex = 9;
      }
    }

    if (value === 'completion') {
      if (
        this.taskCompletionMode === 'one_time_task' ||
        this.taskCompletionMode === 'several_steps'
      ) {
        if (this.stepper.selectedIndex === 4) {
          this.stepper.selectedIndex = 5;
        }
      } else {
        let message = 'Bitte wählen Sie eine Erledigung der Aufgabe aus.';
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText
            ?.training_task_create_error_snackbar_select_to_be_completed,
          duration: 3000,
          horizontalPosition: 'left',
        });
      }
    }
  }

  openBookView(event) {
    if (this.taskChoice === 'specific_text') {
      this.isBookList = false;
      this.isBookPreview = false;
      this.isBookView = event;
    } else if (this.taskChoice === 'specific_book') {
      this.isBookList = false;
      this.isBookPreview = false;
      this.isBookView = false;
      this.stepper.selectedIndex = 4;
    }
  }

  bookListClose(event) {
    this.stepper.selectedIndex = 2;
  }

  replaceHtmlContent(string) {
    return this.taskService.formatHTMLContent(string);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  // open beta info and update teacher after close
  openBetaInfo(betaLeaveInfo): void {
    let dialogRef = this.dialog.open(BetaInfoComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'beta-info-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'helpdesk-panel-backdrop',
      data: {
        betaLeaveInfo: betaLeaveInfo,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      // if (res === 'seen') {
      //   let formData = {
      //     teacherId: this.teacherId,
      //     betaInfoSeen: true
      //   }
      //   this.teacherService.updateTeacher(formData).pipe(
      //     takeUntil(this._unsubscribeAll)
      //   ).subscribe(res => {
      //     // Update teacher list
      //   },(error: any) => {
      //       this.snackBar.openFromComponent(ErrorSnackbarComponent, {
      //         panelClass: 'snack-error',
      //         data: this.translatedText?.technical_error,
      //         duration: 3000,
      //         horizontalPosition: 'left'
      //       });
      //     });
      // }
    });
  }

  submitTask(formDirective?: FormGroupDirective) {
    if (
      this.readerSetting.invalid ||
      this.deadlineStep.invalid ||
      this.groupSelectStep.invalid ||
      this.completionDetailStep.invalid ||
      this.durationForm.invalid
    ) {
      console.log('Form invalid');
      return;
    }
    this.isLoading = true;
    if (!this.isAutoMode) {
      this.stepper.selectedIndex = 11;
    }

    const reader_settings = {
      font_family: this.readerSetting.value.font_family,
      font_size: this.fontSize,
      line_height: this.line_height,
      letter_spacing: this.letter_spacing,
      hyphenation_mode: this.hyphenation_mode,
      editable: this.readerSetting.value.editable,
    };

    let weekdays = [];
    let disableInHolidays: boolean;
    if (this.taskMode === 'auto') {
      this.taskType = 'time';
      let durationMonth = this.durationForm.value.duration;
      let durationDate = moment();
      durationDate.add(durationMonth, 'month');
      this.deadline = durationDate.endOf('day');
      weekdays = this.weekdaysSelected;
      disableInHolidays = this.disableInHolidays['_checked'];
    } else {
      this.deadline = this.deadlineStep.value.deadline.endOf('day');
    }

    let taskModeVal = '';
    let minumumValue = null;
    if (this.isSeveralSteps) {
      taskModeVal = 'multiple';
      // minumumValue = this.completionDetailStep.value.minimum;
    } else if (this.taskMode === 'auto') {
      taskModeVal = 'autonomous';
    } else {
      taskModeVal = 'single';
    }

    let task = {
      teacherId: this.teacherId,
      description: this.description,
      status: 'active',
      wordcount: this.wordcount,
      type: this.taskType,
      deadline: this.deadline,
      book_isbn: this.bookData?.isbn,
      book_name: this.bookData?.title,
      mode: taskModeVal,
      task_area: this.taskArea,
      reader_settings: reader_settings,
      auto_stop: !this.isAllowedReadMore,
      dsef_version: this.bookData?.dsef_version
        ? this.bookData?.dsef_version
        : 1.0,
      // minimum: minumumValue
    };
    if (this.taskType === 'time' || this.taskType === 'time-choice') {
      task['time'] = this.completionDetailStep.value.minutes;
    }
    if (this.taskType === 'text') {
      task['range'] = this.bookDataService.getRangeForTask();
    }
    if (task.mode === 'autonomous') {
      task['weekdays'] = weekdays;
      task['disableInHolidays'] = this.disableInHolidays['_checked'];
    }
    console.log(task);

    this.taskService
      .createMasterTask(
        task,
        this.teacherId,
        this.studentsArray,
        this.trialStatus.isActive,
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          // Update local student trial phase
          this.groupOverview.forEach((go) => {
            const group = this.groups.find((g) => g.group._id === go.id[0]._id);
            group.group._students.forEach((student) => {
              if (
                this.groupOverview
                  .map((go) => go.id[0].name)
                  .includes(student.name)
              ) {
                if (!student.trial) {
                  student.trial = { training: 1 };
                } else if (!student.trial.training) {
                  student.trial = { ...student.trial, training: 1 };
                } else {
                  student.trial.training += 1;
                }
              }
            });
          });
          setTimeout(() => {
            this.dialog.closeAll();
          }, 3000);
          let message =
            this.translatedText?.training_task_create_success_message;
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            panelClass: 'snack-success',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
          // update task list in studentOverview
          this.isLoading = false;
          //fix with next?
          if (this.isSingleStudent === true) {
            // this.taskService.getTasksToStudents(this.studentId);
          } else {
            // update list
            this.taskService.getMasterTasksFromTeacher();
          }
        },
        error: (error: any) => {
          console.log(error);
          let message = this.translatedText?.technical_error;
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      });
  }

  onMinutesChanged(event) {
    const currentValue = this.completionDetailStep.get('minutes').value;
    const maxMinutesDuringTrial = 10;
    if (this.trialStatus.isActive && currentValue > maxMinutesDuringTrial) {
      this.completionDetailStep
        .get('minutes')
        .patchValue(maxMinutesDuringTrial);
      this.openUpgradeModal();
    } else {
      let maxMinutesDuringFairUse = 45;
      if (this.isSeveralSteps) {
        maxMinutesDuringFairUse = 120;
      }
      if (currentValue > maxMinutesDuringFairUse) {
        this.completionDetailStep
          .get('minutes')
          .patchValue(maxMinutesDuringFairUse);
      }
    }
  }

  onChangeDeadline(event) {
    const currentValue = this.deadlineStep.get('deadline').value; // Value from mat date picker
    if (
      this.trialStatus.isActive &&
      currentValue > this.trialStatus.expiryDate
    ) {
      this.deadlineStep.get('deadline').patchValue(this.trialStatus.expiryDate);
      this.openUpgradeModal();
    }
  }

  openUpgradeModal() {
    this.dialog.open<UpgradeDialogComponent, UpgradeDialogData>(
      UpgradeDialogComponent,
      {
        width: '85vw',
        autoFocus: false,
        disableClose: true,
        panelClass: 'home-trial-dialog-panel',
        // hasBackdrop: false,
        backdropClass: 'license-add-backdrop',
        data: { upgradeModalText: 'readingMinutes' },
      },
    );
  }

  checkIfiPad() {
    this.capacitorService.readDeviceInfo().then((data) => {
      let deviceInfo = data;
      let devicePlatform = deviceInfo.platform.toLowerCase();
      let deviceOS = deviceInfo.operatingSystem;

      if (devicePlatform === 'web' && deviceOS === 'ios') {
        this.iPadResolution = true;
      } else {
        this.iPadResolution = false;
      }
    });
  }
}
