<div class="impersonation-bar" *ngIf="isImpersonationMode">
  <div class="impersonation-bar-left">
    <p class="paragraph">
      {{ translatedText.app_component_impersonate }}
      <strong>{{ impersonationName }}</strong>
    </p>
  </div>
  <div class="impersonation-bar-right">
    <button mat-flat-button type="button" color="warning" (click)="leaveImpersonationMode()">
      {{ translatedText.app_component_disconnect }}
    </button>
  </div>
</div>
<div class="app-root-inner" [ngClass]="IS_APP ? 'is-app' : ''">
  <mat-toolbar class="header-bar" [ngClass]="{ student: userType == 'student' }">
    <mat-toolbar-row>
      <div class="left-wrap">
        <img [src]="'assets/' + environment.whitelabel.logo" (click)="goToHomeOrDashboard()" class="logo" alt="" />
        <!-- <img *ngIf="isHomeButton" src="../../assets/laletu-logo.svg" (click)="goToHomeOrDashboard()" class="logo" alt="">  -->
        <p
          *ngIf="!environment.whitelabel.disable_school_family"
          class="home-parents-topbar-text"
          [hidden]="userIsAuthenticated"
        >
          <span [ngClass]="isHomeButton ? 'black' : 'blue'">
            <a routerLinkActive="active-link" [routerLink]="['/home']">
              <span [hidden]="!isHomeButton" class="bar"> | </span>
              {{ environment.whitelabel.name }} Schule
            </a>
          </span>
          <span [ngClass]="!isHomeButton ? 'black' : 'blue'" class="home-parents-topbar-text--right">
            <a routerLinkActive="active-link" [routerLink]="['/family']">
              <span [hidden]="isHomeButton" class="bar"> | </span>
              {{ environment.whitelabel.name }} Family
            </a>
          </span>
        </p>
      </div>
      <div [hidden]="!userIsAuthenticated" class="center-wrap" *ngIf="userType == 'student'">
        <button
          mat-flat-button
          color="unset"
          class="student-nav-button"
          [routerLink]="['/dashboard-student']"
          routerLinkActive="active-button"
          #studentDashboard="routerLinkActive"
        >
          <mat-icon *ngIf="studentDashboard.isActive" class="header_notification" svgIcon="streamline_filled:done">
          </mat-icon>
          <mat-icon *ngIf="!studentDashboard.isActive" class="header_notification" svgIcon="streamline_outlined:done">
          </mat-icon>
          {{ translatedText.app_component_tasks }}
        </button>

        <button
          mat-flat-button
          color="unset"
          class="student-nav-button"
          [routerLink]="['/student-results']"
          routerLinkActive="active-button"
          #studentReport="routerLinkActive"
        >
          <mat-icon *ngIf="studentReport.isActive" class="header_notification" svgIcon="streamline_filled:star">
          </mat-icon>
          <mat-icon *ngIf="!studentReport.isActive" class="header_notification" svgIcon="streamline_outlined:star">
          </mat-icon>
          {{ translatedText.app_component_achievements }}
        </button>
        <button
          mat-flat-button
          color="unset"
          class="student-nav-button"
          [routerLink]="['/dashboard-diagnostics']"
          routerLinkActive="active-button"
          #dashboardDiagnostics="routerLinkActive"
        >
          <mat-icon
            *ngIf="dashboardDiagnostics.isActive"
            class="header_notification"
            svgIcon="streamline_filled:lightning"
          ></mat-icon>
          <mat-icon
            *ngIf="!dashboardDiagnostics.isActive"
            class="header_notification"
            svgIcon="streamline_outlined:lightning"
          ></mat-icon>
          {{ translatedText.app_component_results }}
        </button>
      </div>
      <div [hidden]="!userIsAuthenticated" class="center-wrap" *ngIf="userType !== 'student'">
        <p *ngIf="!isExpert && !environment.whitelabel.disable_expert" class="paragraph expert_group_header">
          <span class="expert_group_header_new">{{ translatedText.expert_group_new }}:&nbsp; </span>
          {{ translatedText.expert_group_new1 }}
          <img [src]="'/assets/' + environment.whitelabel.generic_logo" class="logo" alt="" />
          {{ translatedText.expert_group_new2 }}&nbsp;
          <span class="expert_group_overlay" (click)="openExpertGroupOverlay()">
            {{ translatedText.expert_group_info }}</span
          >
        </p>
      </div>
      <div class="right-wrap">
        <button
          *ngIf="environment.whitelabel.language_switcher"
          #languageMenuButton="matMenuTrigger"
          mat-flat-button
          class="language-dropdown"
          [matMenuTriggerFor]="languageMenu"
          [ngClass]="{
            'language-dropdown-open': languageMenuButton.menuOpen === true
          }"
        >
          <mat-icon>language</mat-icon>
          <span>{{ getCurrentLanguage() }}</span>
          <!-- <mat-icon class="dropdown-icon" svgIcon="streamline_filled:next_header"></mat-icon> -->
        </button>

        <div class="desktop-menu-container" [hidden]="!isHomeButton">
          <ul class="static_nav">
            <button
              mat-flat-button
              color="primary"
              [ngClass]="hoverClass"
              (click)="openLoginDialog()"
              class="home-primary_inverted"
              type="button"
            >
              {{ translatedText.app_component_login }}
            </button>
            <button
              *ngIf="environment.whitelabel.landingpage !== 'registrierung'"
              mat-flat-button
              color="primary"
              [ngClass]="hoverClass"
              (click)="openSubscribeDialog()"
              class="home-primary_student register-btn"
              type="button"
            >
              <span class="inline_text">
                {{ translatedText.app_component_register }}
                <mat-icon class="next_home_icon">navigate_next</mat-icon>
              </span>
            </button>
            <!-- <li><a class="paragraph paragraph--l" [routerLink]="['/home']" routerLinkActive="active-link" [hidden]="userIsAuthenticated">{{translatedText.app_component_login}}</a></li>
          <li><a class="paragraph paragraph--l" [routerLink]="['/ueber-uns']" routerLinkActive="active-link" [hidden]="userIsAuthenticated">Über {{environment.whitelabel.name}}</a></li>
          <li><a class="paragraph paragraph--l" [routerLink]="['/nutzungsbedingungen']" routerLinkActive="active-link" [hidden]="userIsAuthenticated">{{translatedText.app_component_terms}}</a></li>
          <li><a class="paragraph paragraph--l" [routerLink]="['/datenschutz']" routerLinkActive="active-link" [hidden]="userIsAuthenticated">{{translatedText.app_component_privacy}}</a></li>
          <li><a class="paragraph paragraph--l" [routerLink]="['/kontakt']" routerLinkActive="active-link" [hidden]="userIsAuthenticated">{{translatedText.app_component_contact}}</a></li>
          <li><a class="paragraph paragraph--l" [routerLink]="['/impressum']" routerLinkActive="active-link" [hidden]="userIsAuthenticated">{{translatedText.app_component_impress}}</a></li> -->
          </ul>
        </div>

        <!-- <div class="language-dropdown">
          <mat-icon>language</mat-icon>
          <mat-select [value]="getCurrentLanguage()" (selectionChange)="selectLanguage($event.value)" panelClass="language-dropdown-panel" #languageSelect>
            <mat-select-trigger>
              {{ languageSelect.value }}
            </mat-select-trigger>
            <mat-option *ngFor="let lang of languages" [value]="lang.value">
              {{ lang.viewValue }}
            </mat-option>
          </mat-select>
        </div> -->

        <!-- MOBILE MENU -->
        <div [hidden]="!isHomeButton" class="mobile-menu-container">
          <button mat-flat-button [ngClass]="clickedMobileMenu ? 'outlined' : 'filled'" (click)="handleMobileMenu()">
            <span class="button-with-icon">
              {{ translatedText.app_component_menu }}
              <mat-icon [hidden]="clickedMobileMenu">menu</mat-icon>
              <mat-icon [hidden]="!clickedMobileMenu">close</mat-icon>
            </span>
          </button>
        </div>

        <mat-menu #languageMenu="matMenu" class="language-dropdown-panel">
          <mat-list>
            <mat-list-item *ngFor="let lang of languages" (click)="selectLanguage(lang.value)">
              {{ lang.viewValue }}
            </mat-list-item>
          </mat-list>
        </mat-menu>

        <ng-container *ngIf="userIsAuthenticated">
          <div class="inline_text teacher_nav" *ngIf="userType != 'student'">
            <!-- <div class="license_chip">
              <mat-icon class="license_icon_left" svgIcon="streamline_outlined:veriefied"></mat-icon>
              <p>2 Lizenzen</p>
              <mat-icon class="license_icon_right" svgIcon="streamline_outlined:tips"></mat-icon>
            </div> -->
            <!-- <div class="language-toggle">
              <button
                mat-flat-button
                (click)="selectLanguage('en')"
                value="de"
                *ngIf="getCurrentLanguage() === 'de'"
                matTooltip="{{
                  translatedText.app_component_language_tooltip
                }}"
              >
                <mat-icon>language</mat-icon> DE
              </button>
              <button
                mat-flat-button
                (click)="selectLanguage('de')"
                value="en"
                *ngIf="getCurrentLanguage() === 'en'"
                matTooltip="{{
                  translatedText.app_component_language_tooltip
                }}"
              >
                <mat-icon>language</mat-icon> EN
              </button>
            </div> -->
            <!-- <button mat-icon-button color="primary" type="button" *ngIf="notificationBadgeCount < 1" class="nav_notification">   <mat-icon class="header_notification" svgIcon="streamline_filled:notification-empty"></mat-icon>
            </button>
            <button mat-icon-button color="primary" type="button" *ngIf="notificationBadgeCount > 0" class="nav_notification active" (click)="openNotificationList()">   <mat-icon matBadge="{{notificationBadgeCount}}" [matBadgeHidden]="notificationBadgeCount < 1" matBadgeColor="warn" class="header_notification" svgIcon="streamline_filled:notification"></mat-icon></button> -->
            <!-- <div class="visible-tablet-or-smaller">
              <button
                class="task_creation_btn"
                mat-flat-button
                color="primary"
                type="button"
                #menuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="sidebar_menu"
                *ngIf="diagnosticLicenseStatusActive || trainingLicenseStatusActive"
                (click)="openTaskMenu()"
              >
                <div class="create-task-inside-wrap">
                  <span class="create-task-inside-span" 
                    >--><!-- <mat-icon class="create-task-plus-icon" svgIcon="streamline_outlined:plus-v2"></mat-icon
                    > --><!-- <span class="create-task-button-text">{{ translatedText.teacher_sidebar_create_task }}</span>
                  </span>
                  <mat-icon [ngClass]="{ 'rotate-icon': openedMenu }" class="create-task-expand-icon">
                    expand_more
                  </mat-icon>
                </div>
              </button>
            </div> -->
            <mat-menu #sidebar_menu="matMenu" class="sidebar_menu">
              <button mat-menu-item (click)="createDiagnoseDialog()" *ngIf="diagnosticLicenseStatusActive">
                <mat-icon svgIcon="streamline_outlined:target"></mat-icon>
                {{ translatedText.teacher_sidebar_diagnostic }}
              </button>
              <button mat-menu-item (click)="createTaskDialog()" *ngIf="trainingLicenseStatusActive">
                <mat-icon svgIcon="streamline_outlined:lightning"></mat-icon>
                {{ translatedText.teacher_sidebar_training }}
              </button>
              <!--  <span class="paragraph paragraph--s info-text">
                <strong>Achtung:</strong><br>
                <strong>Bevor Sie Aufgaben erstellen </strong>stellen Sie bitte sicher, dass <br>
                <ul>
                  <li>zuerst Ihre Klasse mit Lernenden angelegt wurde,<br></li>
                  <li>Sie genügend Diagnoselizenzen oder Förderminuten haben.<br></li>
                </ul>
                Wenden Sie sich bitte an Ihre Schuladministration, wenn noch keine Klasse angelegt wurde oder fragen Sie
                neue Lizenzen an.
               </span>  -->
            </mat-menu>

            <mat-menu #sidebar_menu="matMenu" class="sidebar_menu visible-tablet-or-smaller">
              <button mat-menu-item (click)="createDiagnoseDialog()" *ngIf="diagnosticLicenseStatusActive">
                <mat-icon svgIcon="streamline_outlined:target"></mat-icon>Diagnose
              </button>
              <button mat-menu-item (click)="createTaskDialog()" *ngIf="trainingLicenseStatusActive">
                <mat-icon svgIcon="streamline_outlined:lightning"></mat-icon>Förderung
              </button>
              <!--  <span class="paragraph paragraph--s info-text"> -->
              <strong>Achtung:</strong><br />
              <strong>Bevor Sie Aufgaben erstellen </strong>stellen Sie bitte sicher, dass <br />
              <ul>
                <li>zuerst Ihre Klasse mit Lernenden angelegt wurde,<br /></li>
                <li>Sie genügend Diagnoselizenzen oder Förderminuten haben.<br /></li>
              </ul>
              Wenden Sie sich bitte an Ihre Schuladministration, wenn noch keine Klasse angelegt wurde oder fragen Sie
              neue Lizenzen an.
              <!-- </span>  -->
            </mat-menu>

            <button
              #menuButton="matMenuTrigger"
              [ngClass]="{ 'menu-open': menuButton.menuOpen === true }"
              mat-flat-button
              color="unset"
              [matMenuTriggerFor]="teacher_menu"
              class="teacher_menu_button"
            >
              <div class="menu-wrapper">
                <div class="admin-icon visible-only-desktop">
                  <mat-icon
                    class="admin-icon"
                    [color]="isExpert ? 'gold' : 'slate'"
                    svgIcon="streamline_filled:user-circle"
                    *ngIf="userType == 'school_admin' || userType == 'teacher'"
                  >
                  </mat-icon>
                </div>
                <div class="teacher_icon_wrap-tablet visible-tablet-or-smaller">
                  <p
                    class="teacher_icon paragraph paragraph--bold paragraph--s"
                    *ngIf="userType == 'teacher' || userType == 'school_admin'"
                  >
                    {{ teacherInitials }}
                  </p>
                  <div *ngIf="userType == 'school_admin'" class="admin-icon visible-tablet-or-smaller">
                    <p class="admin-chip-mobile">A</p>
                  </div>
                </div>

                <p class="paragraph paragraph--l visible-only-desktop">{{ teacherSalutation }} {{ teacherLastname }}</p>
                <ng-container *ngIf="userType == 'school_admin'">
                  <div class="admin-chip visible-only-desktop">ADMIN</div>
                </ng-container>
                <mat-icon class="dropdown-icon" svgIcon="streamline_filled:next_header"></mat-icon>
              </div>
            </button>

            <mat-menu #teacher_menu="matMenu" class="teacher_menu" xPosition="before">
              <button
                mat-menu-item
                *ngIf="userType == 'teacher' || userType == 'school_admin'"
                routerLink="/admin-profile-management"
              >
                <!-- (click)="openEditTeacher()" -->
                <mat-icon svgIcon="streamline_outlined:user-circle"></mat-icon>
                {{ translatedText.app_component_profile }}
              </button>
              <!-- <button
                mat-menu-item
                *ngIf="userType == 'teacher' || userType == 'school_admin'"
                (click)="openChangePassword()"
              >
                <mat-icon svgIcon="streamline_outlined:lock"></mat-icon
                >{{ translatedText.app_component_change_password }}
              </button> -->
              <button mat-menu-item (click)="impersonateUser(id)" *ngIf="!environment.whitelabel.hide_code_helpdesk">
                <mat-icon svgIcon="streamline_outlined:password-cursor"></mat-icon>
                {{ translatedText.pin_code_help_desk }}
              </button>
              <button
                mat-menu-item
                (click)="logout(environment.whitelabel.logout_route, userType)"
                class="logout-button"
              >
                <mat-icon svgIcon="streamline_outlined:logout"></mat-icon>{{ translatedText.app_component_logout }}
              </button>
            </mat-menu>
          </div>
          <div class="inline_text" *ngIf="userType == 'student'">
            <button
              mat-fab
              type="button"
              [matMenuTriggerFor]="notificationMenu"
              *ngIf="notificationBadgeCount < 1"
              class="nav_notification"
            >
              <mat-icon class="header_notification" svgIcon="streamline_filled:notification-empty"></mat-icon>
            </button>
            <button
              [matMenuTriggerFor]="notificationMenu"
              mat-fab
              type="button"
              *ngIf="notificationBadgeCount > 0"
              class="nav_notification active"
            >
              <mat-icon
                matBadge="{{ notificationBadgeCount }}"
                [matBadgeHidden]="notificationBadgeCount < 1"
                matBadgeColor="warn"
                class="header_notification"
                svgIcon="streamline_filled:notification"
              ></mat-icon>
            </button>
            <mat-menu #notificationMenu="matMenu" class="notification-menu">
              <ng-container *ngIf="notifications.length == 0">
                <p class="paragraph">
                  {{ translatedText.app_component_no_unread_messages }}
                </p>
              </ng-container>
              <ng-container *ngIf="notifications.length > 0">
                <app-notification-menu [notifications]="notifications"></app-notification-menu>
              </ng-container>
            </mat-menu>

            <button
              #menuButton="matMenuTrigger"
              [ngClass]="{ 'menu-open': menuButton.menuOpen === true }"
              mat-flat-button
              color="unset"
              [matMenuTriggerFor]="student_menu"
              class="student_menu_button"
            >
              <ng-container>
                <div class="star-badge-wrap" [ngClass]="'star-badge-wrap--level' + starBadgeLevel">
                  <div class="star-badge-wrap__icon">
                    <mat-icon class="star-icon" color="primary" svgIcon="{{ generateStudentBadge(student?.level) }}">
                    </mat-icon>
                  </div>
                  <img class="avatar_img" *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" />
                  <p>{{ student?.username }}</p>
                </div>
              </ng-container>
              <mat-icon class="dropdown-icon" svgIcon="streamline_filled:next_header"></mat-icon>
            </button>

            <mat-menu #student_menu="matMenu" class="student_menu">
              <!-- <button mat-menu-item><mat-icon svgIcon="streamline_outlined:smile"></mat-icon>Als Elternteil anmelden</button> -->
              <button mat-menu-item (click)="openMicrophoneCheck()">
                <mat-icon svgIcon="streamline_outlined:mic-check"></mat-icon>
                {{ translatedText.app_component_microfont }}
              </button>
              <button mat-menu-item (click)="openBookPreferences()">
                <mat-icon svgIcon="streamline_filled:magic-wand"></mat-icon>
                {{ translatedText.app_component_my_preferences }}
              </button>
              <button mat-menu-item (click)="logout(environment.whitelabel.logout_route)">
                <mat-icon svgIcon="streamline_outlined:logout"></mat-icon>{{ translatedText.app_component_logout }}
              </button>
              <!-- <button mat-menu-item (click)="openMicrophoneCheck()"><mat-icon
                  svgIcon="streamline_outlined:mic-check"></mat-icon>Mikrofontest</button>
              <button mat-menu-item (click)="openBookPreferences()"><mat-icon
                  svgIcon="streamline_filled:magic-wand"></mat-icon>Meine Vorlieben anpassen</button>
              <button mat-menu-item (click)="logout()"><mat-icon
                  svgIcon="streamline_outlined:logout"></mat-icon>Abmelden</button> -->
            </mat-menu>
          </div>
        </ng-container>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <!-- MOBILE MENU  -->
  <div [hidden]="!clickedMobileMenu" class="mobile-menu-items">
    <div class="link" (click)="handleMobileMenu()" [ngClass]="isHomeButton ? 'black' : 'blue'">
      <a routerLinkActive="active-link" [routerLink]="['/home']">
        <span [hidden]="!isHomeButton" class="bar"> | </span> {{ environment.whitelabel.name }} Schule</a
      >
    </div>

    <div class="link" (click)="handleMobileMenu()" [ngClass]="!isHomeButton ? 'black' : 'blue'">
      <a routerLinkActive="active-link" [routerLink]="['/family']"
        ><span [hidden]="isHomeButton" class="bar"> | </span>{{ environment.whitelabel.name }} Family</a
      >
    </div>
    <button
      mat-flat-button
      color="primary"
      [ngClass]="hoverClass"
      (click)="openLoginDialog()"
      class="home-primary_inverted"
      type="button"
    >
      {{ translatedText.app_component_login }}
    </button>
    <button
      mat-flat-button
      color="primary"
      [ngClass]="hoverClass"
      (click)="openSubscribeDialog()"
      class="home-primary_student"
      type="button"
    >
      {{ translatedText.app_component_register }}<mat-icon class="next_home_icon">navigate_next</mat-icon>
    </button>
  </div>

  <section
    (mouseover)="hoverSection($event)"
    (mouseout)="hoverSection($event)"
    [ngClass]="hoverClass"
    class="home_register_section dashboard_register_section"
  >
    <div class="row">
      <div class="col-xl-6 col-md-12 col-sm-12">
        <div class="home_box_teacher">
          <h3 class="h3">{{ translatedText.app_component_login_details }}</h3>
          <p class="paragraph">
            <span class="home_register_text">{{ translatedText.app_component_login_details_text }}</span
            ><br /><br />
            <span class="home_register_text"
              >{{ translatedText.app_component_no_login }}
              <a href="https://www.klett.de/inhalt/272087" rel="noopener" target="_blank">{{
                translatedText.app_component_online_shop
              }}</a
              >, {{ translatedText.app_component_no_login_text }}</span
            ><br /><br />
            <span class="home_register_text">{{ translatedText.app_component_teacher }}</span>
            <!-- <span class="home_warning">Wichtig: </span> Bis Sommer 2023 befinden wir uns in einer <span class="home_warning">kostenlosen, wissenschaftlich begleiteten Erprobungsphase</span> des {{environment.whitelabel.name}}. <span class="home_warning">Aufgrund hoher Nachfrage, sind aktuell keine weiteren Kapazitäten vorhanden.</span> Mit Ihrer Registrierung werden Sie auf eine <span class="home_warning">Liste für Interessenten</span> aufgenommen. Wir melden uns bei Ihnen sobald zusätzliche Kapazitäten geschaffen wurden. -->
          </p>
          <div class="home_buttons">
            <!-- <button mat-flat-button color="accent" class="home-primary" type="button" (click)="alert('Diese Funktion gibt es nicht mehr!')"><mat-icon>navigate_next</mat-icon><s>{{translatedText.app_component_register}}</s></button> -->
            <button
              mat-flat-button
              color="primary"
              class="home-secondary teacher-login"
              type="button"
              [routerLink]="['/login/teacher']"
            >
              <mat-icon>navigate_next</mat-icon>{{ translatedText.app_component_login }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-md-12 col-sm-12">
        <div class="home_box_student">
          <img class="home_box_student_img header" src="../../../assets/Piri_Anmeldung.png" alt="" />
          <div class="home_box_student_content header">
            <h3 class="h3 h3--bold">
              {{ translatedText.app_component_student }}
            </h3>
            <p class="paragraph">
              {{ translatedText.app_component_student_text }}
            </p>
            <div class="home_buttons">
              <button
                mat-flat-button
                color="primary"
                class="home-primary_student"
                type="button"
                [routerLink]="['/login']"
              >
                {{ translatedText.app_component_button }}<mat-icon>navigate_next</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="teacher_sidebar_section"
    *ngIf="userType !== 'student' && userIsAuthenticated && userType !== 'support' && userType !== 'admin'"
  >
    <div *ngIf="mobileOverlay" class="overlay-mobile-menu" (click)="clickedOverlay()"></div>
    <div class="container-fluid container-fluid--nopadding">
      <div class="dashboard-container" *ngIf="userIsAuthenticated">
        <div
          *ngIf="isSidebar"
          [ngClass]="{
            'collapsed-width': collapsedSidebar,
            'hidden-sidebar': !isSidebar
          }"
        >
          <app-teacher-sidebar (reduceSidebar)="reduceSidebar($event)"></app-teacher-sidebar>
        </div>

        <div class="teacher-content-section container-fluid--bg-gray container-fluid--full-inside-height">
          <router-outlet></router-outlet>
        </div>
      </div>
      <app-teacher-mobile-menu (mobileOverlay)="mobileOverlayHandler($event)" [mobileOverlayClicked]="mobileOverlay">
      </app-teacher-mobile-menu>
    </div>
  </section>
  <router-outlet
    *ngIf="userType === 'student' || !userIsAuthenticated || userType === 'support' || userType === 'admin'"
  >
  </router-outlet>

  <footer>
    <div class="footer_wrap">
      <ul class="footer-nav" *ngIf="!userIsAuthenticated || environment.whitelabel.always_show_footer">
        <li *ngIf="!environment.whitelabel.footer_hide_link_terms_of_use">
          <a
            class="paragraph paragraph--s"
            (click)="openUrl(environment.whitelabel.footer_terms_of_use_url)"
            routerLinkActive="active-link"
            >{{ translatedText.app_component_terms }}</a
          >
        </li>
        <li *ngIf="!environment.whitelabel.footer_hide_link_parents_letter">
          <a
            class="paragraph paragraph--s"
            (click)="downloadDocument(environment.whitelabel.footer_parents_letter_filename)"
            >{{ translatedText.app_component_parents_letter }}</a
          >
        </li>
        <li *ngIf="!environment.whitelabel.footer_hide_link_contact">
          <a
            class="paragraph paragraph--s"
            (click)="openUrl(environment.whitelabel.footer_contact_url)"
            routerLinkActive="active-link"
            >{{ translatedText.app_component_contact }}</a
          >
        </li>
        <li *ngIf="!environment.whitelabel.footer_hide_link_privacy">
          <a
            class="paragraph paragraph--s"
            (click)="openUrl(environment.whitelabel.footer_privacy_url)"
            routerLinkActive="active-link"
            >{{ translatedText.app_component_privacy }}</a
          >
        </li>
        <li *ngIf="!environment.whitelabel.footer_hide_link_privacy_surveys">
          <a
            class="paragraph paragraph--s"
            (click)="openUrl(environment.whitelabel.footer_privacy_surveys_url)"
            routerLinkActive="active-link"
            >{{ translatedText.app_component_privacy_surveys }}</a
          >
        </li>
        <li *ngIf="!environment.whitelabel.footer_hide_link_terms_and_conditions">
          <a
            class="paragraph paragraph--s"
            (click)="openUrl(environment.whitelabel.footer_terms_and_conditions_url)"
            routerLinkActive="active-link"
            >{{ translatedText.app_component_terms_and_conditions }}</a
          >
        </li>

        <li *ngIf="!environment.whitelabel.footer_hide_link_imprint">
          <a
            class="paragraph paragraph--s"
            (click)="openUrl(environment.whitelabel.footer_imprint_url)"
            routerLinkActive="active-link"
            >{{ translatedText.app_component_impress }}</a
          >
        </li>
      </ul>
      <div class="app-version hide-in-mobile" (click)="showHotfixVersion()" [hidden]="!userIsAuthenticated">
        <a routerLinkActive="active-link" [routerLink]="['/release-notes']"
          >{{ APP_VERSION }}
          <span class="hotfix-version" [hidden]="hideHotfixVersion"> - {{ HOTFIX_VERSION }} </span>
        </a>
      </div>
    </div>
  </footer>
</div>
