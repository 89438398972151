import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../../app-routing.module';
import { MaterialModule } from '../../material.module';
import { AuthInterceptor } from '../../auth/auth-interceptor';
import { ErrorInterceptor } from '../../error/error-interceptor';
import { StudentsAddComponent } from './students-add.component';
import { StudentsAddStepperComponent } from './students-add-stepper/students-add-stepper.component';
import { LoadingSpinnerModule } from '../../helpers/loading-spinner/loading-spinner.module';
import { DirectivesModule } from '../../directives/directives.module';
import { CreateLearnersModule } from 'src/app/helpers/create-learners/create-learners.module';
import { IndividualLearnersSelectionModule } from '../../helpers/individual-learners-selection/individual-learners-selection.module';

@NgModule({
  declarations: [StudentsAddComponent, StudentsAddStepperComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
    DirectivesModule,
    CreateLearnersModule,
    IndividualLearnersSelectionModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [StudentsAddComponent],
})
export class StudentsAddModule {}
