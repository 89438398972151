import { CdkStepper } from '@angular/cdk/stepper';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormGroupDirective,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TeacherService } from 'src/app/providers/teacher.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { TeacherSelectionComponent } from 'src/app/teacher/teacher-selection/teacher-selection.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { AdminService, TeacherData } from 'src/app/providers/admin.service';
import find from 'lodash/find';
import { findIndex, map as lsmap } from 'lodash';
import { TaskService } from 'src/app/providers/task.service';

export interface DialogData {}
export interface School {
  _id?: string;
  name?: string;
  zip?: string;
  city?: string;
  address?: string;
  school_type?: string;
  state?: string;
}

/**
 * Teacher create form
 */
@Component({
  selector: 'app-admin-teacher-managing',
  templateUrl: './admin-teacher-managing.component.html',
  styleUrls: ['./admin-teacher-managing.component.scss'],
})
export class AdminTeacherManagingComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: CdkStepper;
  rForm: FormGroup;
  removeReacherForm: FormGroup;
  isLoading: boolean = false;
  selectedIndex: number;
  stepCount: number = 1;
  groupId;
  groupName;
  page = 1;
  limit = -1;
  sortBy = 'lastname';
  sortDirection = 1;
  matSortDirection = 'asc';
  filterValue: string = '';
  teachers;
  managingType;
  groupTeachers: any = [];
  groupTeachersList: any = [];
  substituteTeachersList: any = [];
  taskCount;
  isMultipleSelection: boolean = true;
  formTitle: string;
  submitText: string;
  disabledTeacher;
  selectedTeacher;
  buttonText;
  selectedText;
  selectedTeacherObject;
  translatedText: any;
  selectedSubstituteTeacher: any = null;
  successMsg: string = '';
  $timeoutSubscribe: any = null;
  isRemoveConfirmation: boolean = false;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private teacherService: TeacherService,
    private translationService: TranslationService,
    private taskService: TaskService,
  ) {
    if (injectedData) {
      if (typeof injectedData['groupId'] !== 'undefined') {
        this.groupId = this.injectedData['groupId'];
      }
      if (typeof injectedData['groupName'] !== 'undefined') {
        this.groupName = this.injectedData['groupName'];
      }
      if (typeof injectedData['managingType'] !== 'undefined') {
        this.managingType = this.injectedData['managingType'];
      }
    }
  }

  ngOnInit(): void {
    //console.log('Group ID =', this.groupId);
    this.rForm = this._formBuilder.group({
      teachers: ['', Validators.required],
    });

    this.removeReacherForm = this._formBuilder.group({
      teacher: ['', Validators.required],
    });

    this.setTranslation(this.route.snapshot.firstChild.data.translation);
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.setTranslation(translatedText);
      });

    this.teacherService.getTeachersToGroup(this.groupId);
    this.teacherService
      .getTeachersToGroupUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (this.managingType == 'remove') {
          this.teachers = res['teachers'];
          this.taskCount = res['taskCount'];
        }
        this.groupTeachers = res['teachers'] ? res['teachers'] : [];
        this.groupTeachersList = [...res['teachers']];
        const selectedTeacherIds = lsmap(this.groupTeachers, '_id');
        if (selectedTeacherIds.length) {
          this.rForm.controls['teachers'].patchValue(selectedTeacherIds);
        }
      });
  }

  setTranslation(translatedText) {
    this.translatedText = translatedText;
    if (this.managingType === 'add') {
      this.getTeachers();
      this.submitText = this.translatedText?.admin_manage_teacher_confirm;
      this.formTitle = this.translatedText?.admin_group_list_manage_teacher;
    } else {
      this.isMultipleSelection = false;
      this.formTitle =
        this.translatedText?.admin_teacher_managing_remove_teacher;
      this.submitText = this.translatedText?.admin_teacher_managing_remove;
    }
  }

  checkTeacherArray(teacher, groupTeacher) {
    // get disabled teacher length
    this.disabledTeacher = groupTeacher.length;
    if (this.managingType == 'add') {
      return groupTeacher.some((item) => item._id === teacher);
    }
  }

  checkGrouplength(groupTeacher) {
    if (this.managingType == 'add' && groupTeacher.length == 3) {
      return true;
    }
  }

  getSelectedText() {
    this.selectedText = this.teachers?.filter((teacher: any) =>
      this.rForm.get('teachers').value?.includes(teacher._id),
    );
    return this.selectedText;
  }

  removeSelectedTeacher(teacher: any, event): void {
    const teacherIndex = this.teachers.findIndex((t) => t._id === teacher._id);
    this.removeIndividualTeacher(teacher);
    event.stopPropagation();
    if (this.managingType == 'add') {
      this.rForm.get('teachers').value?.splice(teacherIndex, 1);
      this.rForm.get('teachers').setValue(this.rForm.get('teachers')?.value);
    } else {
      this.rForm.get('teachers').setValue(null);
    }
  }

  removeIndividualTeacher(teacher: any) {
    this.selectedTeacher = teacher;
    // Check Selected Teacher is part of this group
    const teacherIndex = this.groupTeachersList.findIndex((e: any) => {
      return e._id == teacher._id;
    });
    // If not part of this group, remove directly from group list and form select
    if (teacherIndex === -1) {
      this.groupTeachers = this.groupTeachers.filter((e: any) => {
        return e._id !== teacher._id;
      });
      const selectedTeacherIds = lsmap(this.groupTeachers, '_id');
      this.rForm.controls['teachers'].patchValue(selectedTeacherIds);
    } else {
      if (teacher._tasks && teacher._tasks.length === 0) {
        this.isRemoveConfirmation = true;
        this.stepCount = 5;
      } else {
        // If Teacher has task , need to transfer the task first
        this.stepCount = 2;
        this.substituteTeachersList = this.teachers.filter(
          (item: any) => item._id != teacher._id,
        );
      }
    }
  }

  selectedTeacherValue(value) {
    for (const id of value) {
      const exactTeacher = find(this.teachers, { _id: id });
      const teacherIndex = findIndex(
        this.groupTeachers,
        (e: any) => {
          return e._id == id;
        },
        0,
      );
      if (exactTeacher && teacherIndex === -1) {
        this.groupTeachers.push(exactTeacher);
      }
    }
  }

  openTeacherSelectionOverlay(teachers, groupId): void {
    let dialogRef = this.dialog.open(TeacherSelectionComponent, {
      width: '100%',
      autoFocus: false,
      disableClose: true,
      panelClass: 'license-add-panel',
      backdropClass: 'license-transfer-backdrop',
      data: {
        teacher: teachers,
        groupId: groupId,
        groupName: this.groupName,
        teacherName: this.selectedText,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      this.isLoading = false;
    });
  }

  removeTeacherFromGroup() {
    this.isLoading = true;
    const data = {
      teacherId: this.selectedTeacher._id,
      groupId: this.groupId,
    };

    this.teacherService.removeTeachersFromGroup(data).subscribe({
      next: (res) => {
        this.dialog.closeAll();
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          panelClass: 'snack-success',
          data:
            this.translatedText?.admin_teacher_managing_teacher +
            ' ' +
            this.translatedText?.admin_teacher_managing_teacher_2 +
            '  ' +
            this.groupName +
            ' ' +
            this.translatedText?.admin_teacher_managing_teacher_3,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      },
    });
  }

  /**
   * API call on adminService to retrieve requested teacher
   */
  getTeachers() {
    this.adminService
      .getTeacherList(
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValue,
      )
      .pipe(
        map((teacherData: TeacherData) => {
          teacherData.data.forEach((item) => {
            // combine "firstname" & "lastname" to "name"-Attribut
            item['name'] = `${item['firstname']} ${item['lastname']}`;
          });
          this.teachers = teacherData.data;
        }),
      )
      .subscribe();
  }

  submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      console.log('Form invalid');
      return;
    }
    if (this.managingType == 'add') {
      if (this.rForm.get('teachers').value.length > 3) {
        this.snackBar.open(
          this.translatedText?.admin_teacher_managing_max,
          '',
          {
            panelClass: 'snack-error',
            duration: 3000,
            horizontalPosition: 'left',
          },
        );
        return;
      }
    }
    let finalTeacherList = this.rForm.value.teachers;
    // Remove already added teacher list from current action inorder to avoid api error
    for (const i of this.groupTeachersList) {
      const index = finalTeacherList.indexOf(i._id);
      if (index > -1) {
        finalTeacherList.splice(index, 1);
      }
    }
    this.isLoading = true;
    //console.log('teacherlist', this.selectedText);
    if (this.managingType == 'add') {
      const data = {
        teacherIds: finalTeacherList,
        groupId: this.groupId,
      };

      this.teacherService.addTeachersToGroup(data).subscribe({
        next: (res) => {
          this.dialog.closeAll();
          let teacherNames = this.selectedText
            .map((user) => user.firstname + ' ' + user.lastname)
            .join(' & ');
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            panelClass: 'snack-success',
            data:
              this.translatedText?.admin_teacher_managing_teacher +
              ' ' +
              teacherNames +
              ' ' +
              this.translatedText?.admin_teacher_managing_teacher_5 +
              ' ' +
              this.groupName +
              ' ' +
              this.translatedText?.admin_teacher_managing_teacher_4,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
        error: (error) => {
          this.isLoading = false;
          console.error(error);
          this.snackBar.open(
            this.translatedText?.admin_teacher_managing_error,
            '',
            {
              panelClass: 'snack-error ',
              duration: 3000,
              horizontalPosition: 'left',
            },
          );
        },
      });
    } else {
      this.snackBar.open(
        this.translatedText?.admin_teacher_managing_error,
        '',
        {
          panelClass: 'snack-error',
          duration: 3000,
          horizontalPosition: 'left',
        },
      );
    }
  }

  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  }

  /*********
   *  Remove with Substitute Teacher Task  Start
   *********/

  selectSubstituteTeacher(teacher) {
    const teacherDetails = find(this.teachers, { _id: teacher });
    if (teacherDetails) {
      this.selectedSubstituteTeacher = teacherDetails;
    }
  }

  submitRemoveTeacherForm() {
    if (this.removeReacherForm.invalid) {
      return;
    }
    this.isLoading = true;
    const taker_teacher_id = this.removeReacherForm.value.teacher;
    this.successMsg =
      "<span class='paragraph--bold'>" +
      this.selectedTeacher.firstname +
      ' ' +
      this.selectedTeacher.lastname +
      '</span>' +
      ' ' +
      this.translatedText.admin_manage_teacher_transfer_task_success +
      ' ' +
      "<span class='paragraph--bold'>" +
      this.selectedSubstituteTeacher.firstname +
      ' ' +
      this.selectedSubstituteTeacher.lastname;
    ('</span>');

    this.removeTeacherFromGroup();
    this.taskService
      .transferTasksToTeacher(
        this.selectedTeacher._id,
        taker_teacher_id,
        this.groupId,
      )
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          console.log(res);
          this.stepCount = 1;
          this.$timeoutSubscribe = setTimeout(
            () => this.closeTeacherOverlay(),
            500,
          );
        },
        error: (error) => {
          this.isLoading = false;
          console.error(error);
        },
      });
  }

  /*********
   *  Remove with Substitute Teacher Task  End
   **********/

  /**
   *  close overlay
   */
  closeTeacherOverlay() {
    this.dialog.closeAll();
  }

  /**
   * Unsubscribe on detroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    if (this.$timeoutSubscribe) {
      clearTimeout(this.$timeoutSubscribe);
    }
  }
}
