<app-forgot-password-stepper #stepper linear [stepValue]="selectedIndex" (selectionChange)="getActiveSelection($event)">
  <cdk-step [stepControl]="rForm">
    <div class="container-fluid login-container">
      <img class="img-left-top" src="../../../../../assets/sign_up-left.svg" alt="" />
      <img class="img-right-bottom" src="../../../../../assets/sign-up-right.svg" alt="" />
      <div class="login row">
        <div class="col-md-12">
          <div class="login_backButton">
            <button
              mat-flat-button
              type="button"
              color="tertiary"
              (click)="navigateBack()"
              class="stepper_backButton"
              matSuffix
            >
              <mat-icon svgIcon="streamline_outlined:back"></mat-icon> {{ translatedText.forgot_password_back }}
            </button>
          </div>
          <div class="login_wrap">
            <!-- <div class="login_img">
              <img src="../../../assets/laletu-logo.svg" alt="" />
            </div> -->
            <h2 class="h2 h2--bold">{{ translatedText.forgot_password_title }}</h2>
            <!-- <p class="paragraph paragraph--l text-center">Wir senden Ihnen per E-Mail Anweisungen, wie Sie Ihr Passwort wiederherstellen können.</p> -->
            <form [formGroup]="rForm" #formDirective="ngForm">
              <div class="forgot-password">
                <mat-label>{{ translatedText.forgot_password_email }}</mat-label>
                <mat-form-field>
                  <input
                    matInput
                    formControlName="email"
                    type="text"
                    placeholder="{{ translatedText.forgot_password_email }}"
                  />
                </mat-form-field>
              </div>
              <div class="instructionVideo_wrap">
                <button
                  [disabled]="rForm.invalid || isloading"
                  mat-flat-button
                  color="primary"
                  (click)="submitForm(formDirective)"
                  type="submit"
                  class="mat-flat-button--full"
                  aria-label="LOG IN"
                >
                  {{ translatedText.forgot_password_send }}
                </button>
              </div>
            </form>
            <!-- <div class="login_footer">
              <p class="paragraph paragraph--s">Erinnern Sie sich an Ihr Passwort?</p>
              <a (click)="navigateToLogin()">{{ translatedText.forgot_password_back }} zur Anmeldung</a>
            </div> -->
            <img class="footer-img" [src]="'/assets/' + environment.whitelabel.logo" alt="" />
          </div>
        </div>
        <!-- <div class="col-md-6 login_right">
          <img src="../../../assets/login.svg" alt="Login" />
        </div> -->
      </div>
    </div>
  </cdk-step>
  <cdk-step [stepControl]="rForm">
    <div class="container-fluid login-container">
      <img class="img-left-top" src="../../../../../assets/sign_up-left.svg" alt="" />
      <img class="img-right-bottom" src="../../../../../assets/sign-up-right.svg" alt="" />
      <div class="login row">
        <div class="col-md-12">
          <div class="login_backButton">
            <button
              mat-flat-button
              type="button"
              color="tertiary"
              *ngIf="userRole != undefined"
              (click)="navigateToDashboard()"
              class="stepper_backButton"
              matSuffix
            >
              <mat-icon svgIcon="streamline_outlined:back"></mat-icon> {{ translatedText.forgot_password_back }}
            </button>
          </div>
          <div class="login_wrap">
            <div class="school_admin-wrap">
              <div class="school_admin-content last-step">
                <img src="../../../../assets/sign-up.svg" alt="sign-up" />
                <h2 class="h2 h2--bold">
                  {{ translatedText.forgot_password_email_sent }} {{ maskEmail(rForm.get('email').value) }}
                  {{ translatedText.forgot_password_email_sent_2 }}
                </h2>
              </div>
            </div>
            <img class="footer-img" [src]="'/assets/' + environment.whitelabel.generic_logo" alt="" />
          </div>
        </div>
      </div>
    </div>
  </cdk-step>
</app-forgot-password-stepper>
