<div class="automode-task-frequency">
  <div class="text-align-center">
    <h2 class="h2 h2--bold mt-5 mb-3">{{ translatedText.automode_frequency_title }}</h2>
    <p class="paragraph paragraph--m">{{ translatedText.automode_frequency_desc }}</p>
  </div>

  <div class="create-box mt-4">
    <p class="paragraph paragraph--m mb-2">{{ translatedText.automode_per_week_task_create }}</p>
    <p class="paragraph paragraph--m">{{ translatedText.automode_select_weekdays_desc }}</p>

    <div class="custom-toggle-btn">
      <div class="automode-day-selection-wrap">
        <mat-chip-set aria-label="category selection">
          <mat-chip
            (click)="toggleChipSelection('1')"
            [selected]="weekdaysSelected.includes('1')"
            [ngClass]="{
              active: weekdaysSelected.includes('1'),
              disabled: weekdaysSelected.length == 3 && !weekdaysSelected.includes('1')
            }"
          >
            <p class="paragraph paragraph--s paragraph--bold">{{ translatedText.training_task_create_monday }}</p>
          </mat-chip>
          <mat-chip
            (click)="toggleChipSelection('2')"
            [selected]="weekdaysSelected.includes('2')"
            [ngClass]="{
              active: weekdaysSelected.includes('2'),
              disabled: weekdaysSelected.length == 3 && !weekdaysSelected.includes('2')
            }"
          >
            <p class="paragraph paragraph--s paragraph--bold">{{ translatedText.training_task_create_tuesday }}</p>
          </mat-chip>
          <mat-chip
            (click)="toggleChipSelection('3')"
            [selected]="weekdaysSelected.includes('3')"
            [ngClass]="{
              active: weekdaysSelected.includes('3'),
              disabled: weekdaysSelected.length == 3 && !weekdaysSelected.includes('3')
            }"
          >
            <p class="paragraph paragraph--s paragraph--bold">{{ translatedText.training_task_create_wednesday }}</p>
          </mat-chip>
          <mat-chip
            (click)="toggleChipSelection('4')"
            [selected]="weekdaysSelected.includes('4')"
            [ngClass]="{
              active: weekdaysSelected.includes('4'),
              disabled: weekdaysSelected.length == 3 && !weekdaysSelected.includes('4')
            }"
          >
            <p class="paragraph paragraph--s paragraph--bold">{{ translatedText.training_task_create_thursday }}</p>
          </mat-chip>
          <mat-chip
            (click)="toggleChipSelection('5')"
            [selected]="weekdaysSelected.includes('5')"
            [ngClass]="{
              active: weekdaysSelected.includes('5'),
              disabled: weekdaysSelected.length == 3 && !weekdaysSelected.includes('5')
            }"
          >
            <p class="paragraph paragraph--s paragraph--bold">{{ translatedText.training_task_create_friday }}</p>
          </mat-chip>
        </mat-chip-set>
      </div>
    </div>
  </div>
  <div class="holiday-accept-box">
    <img class="img-40" src="assets/holiday-celebration.png" />
    <div>
      <p class="paragraph paragraph--m paragraph--bold">{{ translatedText.automode_holiday_title }}</p>
      <p class="paragraph paragraph--s">{{ translatedText.automode_holiday_desc }}</p>
    </div>
    <div class="taggle-position">
      <div class="chart_toggle">
        <input
          type="checkbox"
          #disableInHolidays
          [(ngModel)]="isHolidayTask"
          (ngModelChange)="holidayTaskNeeded($event)"
          id="switch"
        /><label for="switch"></label>
      </div>
      <!--  <mat-slide-toggle
        #disableInHolidays
        [(ngModel)]="isHolidayTask"
        (ngModelChange)="holidayTaskNeeded($event)"
      ></mat-slide-toggle> -->
    </div>
  </div>

  <div class="task-visibility-box mt-3">
    <span
      ><mat-icon svgIcon="streamline_outlined:calendar"></mat-icon>
      <p class="paragraph paragraph--m paragraph--bold">{{ translatedText.automode_visibility_title }}</p></span
    >
    <p class="paragraph paragraph--s">
      {{ translatedText.automode_visibility_dec }}
    </p>
    <!-- <button mat-mini-fab type="button" class="close-button">
      <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
    </button> -->
  </div>
</div>
