import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DSReaderService } from 'digi-sapiens-reader';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { BookDataService } from '../../providers/book-data.service';
import { BookService } from '../../providers/book.service';
import { RecordRTCComponent } from '../../record-rtc/record-rtc.component';
import { BookListComponent } from '../new-book-list/book-list.component';
import { CapacitorService } from 'src/app/providers/capacitor.service';

export interface DialogData {}

export interface Book {
  title?: string;
  _id: string;
}

@Component({
  selector: 'app-book-view',
  templateUrl: './book-view.component.html',
  styleUrls: ['./book-view.component.scss'],
})
export class BookViewComponent implements OnInit {
  @Input('bookData') bookData: any;
  @Output() backToBookPreview = new EventEmitter<boolean>();
  @Output() goToReaderSettings = new EventEmitter<boolean>();

  restServerUrl;
  bookIsbn: string;
  dsefVersion = 1.0;
  book: any;
  bookUrl: string;
  bookImgUrl;
  bookTitle;
  bookAuthor;
  isTask: boolean = false;
  taskType;
  readingTime;
  readingTimeTitle;
  time: number = 0;
  interval;
  userType;
  taskId;
  userTaskUuid;
  wordcount = 0;
  isTeacher: boolean = false;
  resetSelection: boolean = false;
  tocToggle: boolean = false;
  translatedText: any;
  iPadResolution: boolean = false;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public viewDialog: MatDialogRef<BookViewComponent>,
    public listDialog: MatDialogRef<BookListComponent>,
    private route: ActivatedRoute,
    private authService: AuthService,
    public bookService: BookService,
    private readerService: DSReaderService,
    private bookDataService: BookDataService,
    private translationService: TranslationService,
    private capacitorService: CapacitorService,
  ) {
    if (injectedData) {
      if (typeof injectedData['isbn'] !== 'undefined') {
        this.bookIsbn = this.injectedData['isbn'];
      } else {
        this.bookIsbn = this.route.snapshot.params['id'];
      }
      if (typeof injectedData['title'] !== 'undefined') {
        this.bookTitle = this.injectedData['title'];
      }
      if (typeof injectedData['author'] !== 'undefined') {
        this.bookAuthor = this.injectedData['author'];
      }
      if (typeof injectedData['type'] !== 'undefined') {
        this.taskType = this.injectedData['type'];
      }
      if (typeof injectedData['time'] !== 'undefined') {
        this.readingTime = this.injectedData['time'];
      }
      if (typeof injectedData['taskId'] !== 'undefined') {
        this.taskId = this.injectedData['taskId'];
      }
      if (typeof injectedData['userTaskUuid'] !== 'undefined') {
        this.userTaskUuid = this.injectedData['userTaskUuid'];
      }
    }
    this.restServerUrl = environment.evolutionAPI;
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    // book info from stepper
    this.bookIsbn = this.bookData.isbn;
    this.bookTitle = this.bookData.title;
    this.bookAuthor = this.bookData.author;
    this.dsefVersion = this.bookData.dsef_version
      ? this.bookData.dsef_version
      : 1.0;

    // Reset wordcount first
    this.bookDataService.setWordCount(0);
    console.log('TASK ID' + ' ' + this.userTaskUuid);
    this.userType = this.authService.getType();

    if (this.userType != 'student') {
      this.isTeacher = true;
      this.reversTimer();
    } else {
      this.isTeacher = false;
      this.reversTimer();
    }

    this.readingTimeTitle =
      'Lies ' + this.bookTitle + ' ' + this.readingTime + ' Minuten lang ';

    if (this.taskType == 'time' || this.taskType == 'self') {
      this.isTask = true;
    }
    // Get book
    // this.bookService.getBookById(this.bookIsbn).pipe(
    //   takeUntil(this._unsubscribeAll)
    // ).subscribe(data => {
    //   this.book = data;
    //   // Get book by isbn
    //   this.bookService.getBook(this.book.isbn).pipe(
    //     takeUntil(this._unsubscribeAll)
    //   ).subscribe(response => {
    //     if (response) {
    //       let dataType = response.type;
    //       let binaryData = [];
    //           binaryData.push(response);
    //       let blob = new Blob(binaryData, {type: dataType});
    //       let blobUrl = window.URL.createObjectURL(blob);
    //       //window.open(blobUrl, '_blank');
    //       this.bookUrl = blobUrl;
    //       this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
    //     }
    //   });
    //   // Get book image by url
    //   this.getBookImageUrl(this.book.isbn);
    // });

    this.bookDataService
      .getWordCountUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (data: any) => {
          console.log(data);
          this.wordcount = data;
        },
        error: (error: any) => {
          console.error(error);
        },
      });
    this.checkIfiPad();
  }

  resetWordcount() {
    this.bookDataService.setWordCount(0);
    this.readerService.clearTextSelection();
    this.resetSelection = true;
    setTimeout(() => {
      this.resetSelection = false;
    }, 1000);
  }

  openBookReaderToc() {
    this.tocToggle = !this.bookDataService.getShowTableOfContents();
    this.bookDataService.setShowTableOfContentsUpdateListener(this.tocToggle);
  }

  reversTimer() {
    this.interval = setInterval(() => {
      this.time++;
      //console.log(this.time)
      if (this.time == 180) {
        if (this.userType != 'student') {
          if (this.wordcount > 0) {
            this.closeBookview();
          } else {
            this.navigateBackToBookPreview();
          }
        } else {
          this.startRecord();
        }
      }
    }, 1000);
  }

  closeBookview() {
    // let data = {
    //   isbn : this.bookIsbn,
    //   title: this.bookTitle
    // }
    // this.viewDialog.close(data)
    this.goToReaderSettings.emit(true);
  }

  async startRecord() {
    // Close all open dialogs before
    //this.dialog.closeAll();

    clearInterval(this.interval);
    let startFrom = await this.bookDataService.getCurrentPageAttributes();

    this.viewDialog.close('taskStarted');
    // this.previewDialog.close();
    // this.listDialog.close();
    this.listDialog.afterClosed().subscribe((result) => {
      // todo start without timeout and remove scroll
      setTimeout(() => {
        let taskData = {
          type: this.taskType,
          readingTitle: this.readingTimeTitle,
          taskId: this.taskId,
          book_isbn: this.bookIsbn,
          book_name: this.bookTitle,
          startFrom: startFrom,
          userTaskUuid: this.userTaskUuid,
        };
        if (this.taskType == 'time') {
          taskData['time'] = this.readingTime;
        }

        let dialogRef = this.dialog.open(RecordRTCComponent, {
          width: '100%',
          autoFocus: false,
          panelClass: 'start-task-overlay-panel',
          hasBackdrop: false,
          disableClose: true,
          backdropClass: 'start-task-overlay-panel-backdrop',
          data: taskData,
        });
        // dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe();
      }, 500);
    });
  }

  getBookImageUrl(isbn) {
    this.bookService.getBookimage(isbn).subscribe((response) => {
      if (response) {
        // blob
        // let dataType = response.type;
        // let binaryData = [];
        //     binaryData.push(response);

        // let blob = new Blob(binaryData, {type: dataType});
        // let blobUrl = window.URL.createObjectURL(blob);
        // //window.open(blobUrl, '_blank');
        // this.bookImgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);

        // just url
        this.bookImgUrl = response.link;
      }
    });
  }

  navigateBackToBookPreview() {
    this.backToBookPreview.emit(true);
  }

  checkIfiPad() {
    this.capacitorService.readDeviceInfo().then((data) => {
      let deviceInfo = data;
      let devicePlatform = deviceInfo.platform.toLowerCase();
      let deviceOS = deviceInfo.operatingSystem;

      if (devicePlatform === 'web' && deviceOS === 'ios') {
        this.iPadResolution = true;
      } else {
        this.iPadResolution = false;
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    clearInterval(this.interval);
  }
}
