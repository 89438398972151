import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as shape from 'd3-shape';
import { Subject, takeUntil } from 'rxjs';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-training-chart',
  templateUrl: './training-chart.component.html',
  styleUrls: ['./training-chart.component.scss'],
})
export class TrainingChartComponent implements OnInit {
  @Input('chart_data') chart_data: any;
  isLoading: boolean = true;
  progressChartData = [
    {
      name: 'Lesepunkte',
      series: [],
    },
  ];
  legend: boolean = false;
  legendTitle: string = '';
  linearCurve = shape.curveBumpX;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  showGridLines: boolean = true;
  timeline: boolean = true;
  legendPosition: string = 'below';
  colorSchemeResult = {
    domain: ['#03cc90'],
  };
  chartInfoText =
    'Die Förderergebnisse werden in Lesepunkten dargestellt. Diese setzen sich zusammen aus der Lesegeschwindigkeit, der Betonung und den Lesefehlern.';
  selectedResult = 'last_month';
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
        this.progressChartData[0].name =
          this.translatedText?.group_widget_reading_points;
      });
    // console.log(this.chart_data)
    this.selectResult('last_month');
  }

  selectResult(event) {
    this.isLoading = true;
    // if (event === "last_week") {
    //   groupArray["currentResultValue"] = groupArray?.group?.chart_data?.studying?.daily?.reading_combined_score;
    // }
    if (event === 'last_month') {
      // console.log(this.chart_data?.studying?.weekly?.reading_combined_score.length)
      if (this.chart_data?.studying?.weekly?.reading_combined_score) {
        this.progressChartData[0]['series'] =
          this.chart_data?.studying?.weekly?.reading_combined_score?.slice(
            8,
            this.chart_data?.studying?.weekly?.reading_combined_score.length,
          ); // last 4 weeks
      }

      if (environment.language === 'en') {
        for (let i = 0; i < this.progressChartData[0].series.length; i++) {
          this.progressChartData[0].series[i].name =
            this.progressChartData[0].series[i].name.replace('KW', 'Week');
        }
      }
      this.progressChartData = [...this.progressChartData];
      // console.log(this.progressChartData)
    }
    // if (event === "half_year") {
    //   groupArray['progressChartData'][0]['series'] = groupArray?.group?.chart_data?.studying?.monthly?.reading_combined_score.slice(6, groupArray?.group?.chart_data?.studying?.monthly?.reading_combined_score.length); // last 6 month
    //   groupArray['progressChartData'] = [...groupArray['progressChartData']];
    //   console.log( groupArray['progressChartData']);
    // }
    this.selectedResult = event;
    this.isLoading = false;
  }

  roundValue(value) {
    return Math.round(value);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
