import {
  Component,
  OnInit,
  Inject,
  Optional,
  ViewChild,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Subscription, Subject, combineLatest } from 'rxjs';
import {
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
  NgForm,
  FormGroupDirective,
} from '@angular/forms';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TaskService } from 'src/app/providers/task.service';
import { takeUntil } from 'rxjs/operators';
import { GroupService } from 'src/app/providers/group.service';
import { StudentService } from 'src/app/providers/student.service';
import { CdkStepper } from '@angular/cdk/stepper';
import { TranslationService } from 'src/app/providers/translation.service';
export interface DialogData {}
@Component({
  selector: 'app-task-edit-diagnostic',
  templateUrl: './task-edit-diagnostic.component.html',
  styleUrls: ['./task-edit-diagnostic.component.scss'],
})
export class TaskEditDiagnosticComponent implements OnInit {
  restServerUrl;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isLoading: boolean = false;
  selectedIndex: number;
  deadlineStep: FormGroup;
  todayDate: Date = new Date();
  taskId;
  groupSelectStep: FormGroup;
  groups: any = [];
  teacherId;
  userType;
  studentsArray = [];
  taskTime;
  isSingleGroup: boolean = false;
  isSingleStudent: boolean = false;
  checkSelection: boolean = false;
  isClosedDialog: boolean = false;
  lastStep;
  taskType;
  @ViewChild('stepper') stepper: CdkStepper;
  @ViewChild('diagnosticDateChanger') diagnosticDateChanger: boolean = false;
  taskStudents = [];
  diagnostic: any = [];
  diagnosticType;
  DiagnosticTypesArray;
  deadline;
  diagnosticSelected;
  translatedText;
  selectionLoading: boolean = true;
  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    private taskService: TaskService,
    private groupService: GroupService,
    private studentService: StudentService,
    private ngZone: NgZone,
    private changeDetector: ChangeDetectorRef,
    private translationService: TranslationService,
  ) {
    this.restServerUrl = environment.evolutionAPI;
    if (injectedData) {
      if (typeof injectedData['taskId'] !== 'undefined') {
        this.taskId = this.injectedData['taskId'];
      }
      if (typeof injectedData['taskType'] !== 'undefined') {
        this.taskType = this.injectedData['taskType'];
      }
      if (typeof injectedData['diagnosticType'] !== 'undefined') {
        this.diagnosticType = this.injectedData['diagnosticType'];
      }
    }
  }

  ngOnInit() {
    this.userType = this.authService.getType();
    combineLatest([
      this.taskService.getSingleTaskUpdateListener(),
      this.taskService.getPhaseDatesUpdateListener(),
    ])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(([task, diagnostic]) => {
        // Process task and res data
        this.selectionLoading = false;
        this.deadline = task['deadline'];
        this.taskTime = task['time'];
        this.deadlineStep.get('diagnosticDeadline').setValue(task['deadline']);
        this.deadlineStep.get('diagnostic').setValue([task['type']]);
        // Filter diagnostic
        switch (this.diagnosticType) {
          case 'diagnostic-pre':
            this.diagnostic = diagnostic?.filter(
              (item) => item?.value == 'diagnostic-pre',
            );
            this.diagnostic = this.diagnostic[0];
            break;
          case 'diagnostic-mid':
            this.diagnostic = diagnostic?.filter(
              (item) => item?.value == 'diagnostic-mid',
            );
            this.diagnostic = this.diagnostic[0];
            break;
          case 'diagnostic-post':
            this.diagnostic = diagnostic?.filter(
              (item) => item?.value == 'diagnostic-post',
            );
            this.diagnostic = this.diagnostic[0];
            break;
          default:
            break;
        }
        // Set checkmark for date outside range
        // if (this.diagnostic.length != 0) {
        //   const startDate = new Date(this.diagnostic?.startDate);
        //   const endDate = new Date(this.diagnostic?.endDate);
        //   const deadline = new Date(task["deadline"]);
        //   this.diagnosticSelected = deadline >= startDate && deadline <= endDate ? false : true;
        // }
        this.diagnosticSelected = task['custom_deadline'];
      });

    this.teacherId = this.authService.getTeacherId();
    this.groupService
      .getGroupsToTeacher(false)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.groups = res;
        console.log(this.groups);
        this.groups.sort(function (a, b) {
          return a['group'].name.localeCompare(b['group'].name);
        });
        this.groups.forEach((element) => {
          // Add blocked value to identify preselected students
          element.group._students.map((obj) => {
            if (obj.diagnostic.length > 0) {
              obj['diagnostic_blocked'] = true;
            } else {
              obj['diagnostic_blocked'] = false;
            }
            // add current group to student selection
            obj['selectedGroupId'] = element.group._id;
            return obj;
          });
          // Sort students
          element.group._students.sort(function (a, b) {
            return a['username'].localeCompare(b['username']);
          });
        });
      });

    this.studentService
      .getStudentsForTaskEdit(this.taskId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res);
        this.taskStudents = res;
        res?.forEach((item) => {
          if (item) {
            this.studentsArray.push(item);
          }
        });
      });
    this.initForm();
  }

  initForm() {
    this.deadlineStep = this._formBuilder.group({
      diagnostic: ['', [Validators.required]],
      diagnosticDeadline: ['', [Validators.required]],
    });

    this.groupSelectStep = this._formBuilder.group({
      students: [''],
    });
    // Fill form with existing data
    this.taskService.getSingleTask(this.taskId);
    // Date picker restrictions
    this.taskService.getPhaseDates();
  }

  filterGroupsWithoutPersonalData(groups) {
    // only list groups that have students with gender value
    return groups?.filter((element) => {
      const hasStudents =
        element.group._students && element.group._students.length > 0;
      const hasEmptyGender = element.group._students.some(
        (student) => !student.gender,
      );
      return hasStudents && !hasEmptyGender;
    });
  }

  isOptionDisabled(
    group: any,
    selectedOptions: any[],
    student: any,
    blockedLicenses,
  ): boolean {
    // Calculate the remaining licenses for the student at that moment
    const remainingLicenses = this.getLicenseAmount([], 'di', 'left');

    // Check if the student is already selected
    const isSelected = selectedOptions.some(
      (selectedStudent: any) => selectedStudent._value._id === student._id,
    );

    // Disable the option if it is not selected and remaining licenses for the student are less than completionDetailStep.get("minutes").value
    const newStundent = student.diagnostic.length > 0 ? 0 : 1;
    const selectedStudentsWithoutLicense = selectedOptions.filter((item) => {
      if (item._value.diagnostic?.length == 0) {
        return true;
      } else {
        return item._value.diagnostic.length > 0 ? false : true;
      }
    });

    if (newStundent == 0) {
      return false;
    } else {
      return (
        !isSelected &&
        remainingLicenses - blockedLicenses <
          newStundent + selectedStudentsWithoutLicense.length
      );
    }
  }

  getDateFromString(dateString: string): Date {
    return new Date(dateString);
  }

  checkForItemSelection(array, value) {
    return array.filter((item) => item.value == value).length > 0
      ? true
      : false;
  }

  // select all students student and add or remove to array
  toggleSelectAll(students, select) {
    if (select == true) {
      students.options.forEach((element) => {
        if (element?._value?.status === 'active') {
          element.selected = true;
          this.studentsArray.push(element._value);
        }
      });
    } else {
      students.deselectAll();
      students.options.forEach((element) => {
        this.studentsArray = this.studentsArray.filter(
          (item) => item._id !== element._value._id,
        );
      });
    }
  }

  disableSelectAll(licenseLeft, students, blockedLicenses) {
    const selectedStudentsWithoutLicense = students.filter((item) => {
      if (item.diagnostic?.length == 0) {
        return true;
      } else {
        return item.diagnostic.length > 0 ? false : true;
      }
    });
    return licenseLeft < blockedLicenses + selectedStudentsWithoutLicense.length
      ? true
      : false;
  }

  // select student and add or remove to array
  handleSelection(event, students, group) {
    if (
      event.options[0].selected == false ||
      this.studentsArray.includes(event.options[0].value)
    ) {
      this.studentsArray = this.studentsArray.filter(
        (item) => item._id !== event.options[0].value._id,
      );
      //console.log(event.options[0].value)
      if (event.options[0].value.diagnostic_blocked == true) {
        // decrease already blocked licenses
        group.taskOverview.diagnose_blocked_licenses--;
      }
    } else if (
      event.options[0].selected == true &&
      !this.studentsArray.includes(event.options[0].value)
    ) {
      this.studentsArray.push(event.options[0].value);
      if (event.options[0].value.diagnostic_blocked == true) {
        // increase already blocked licenses
        group.taskOverview.diagnose_blocked_licenses++;
      }
      //this.studentsArray =  this.studentsArray.filter(item => item !== event.options[0].value);
    }
  }

  checkForSelection(student, index) {
    if (this.studentsArray.length > 0) {
      return this.studentsArray.some((el) => {
        return el._id === student._id;
      });
    }
  }

  getLicenseAmount(licenses, tag, type) {
    let amount = 0;
    if (licenses.length > 0) {
      licenses.map((item) => {
        if (item.type_key == tag) {
          if (type == 'total') {
            amount += item.amount_total;
          } else {
            amount += item.amount_left;
          }
        }
      });
      if (tag == 'di') {
        amount = amount > 0 ? amount : amount;
      }
    }
    return Math.floor(amount);
  }

  checkForDiagnosticExistence(diagnostic, type) {
    let foundType = diagnostic.filter((item) => item.kind == type);
    return foundType.length > 0 ? true : false;
  }

  checkForDiagnosticCompletion(diagnostic, type) {
    let foundType = diagnostic.filter(
      (item) => item?.kind == type && item?._task?.session_uuid,
    );
    return foundType.length > 0 ? true : false;
  }

  calculatedLicenses(licenseLeft, students, blockedLicenses) {
    const newStudents = students.filter((item) => {
      if (item._value.diagnostic?.length == 0) {
        return true;
      } else {
        return item._value.diagnostic.length > 0 ? false : true;
      }
    });

    let licenseCount = blockedLicenses + newStudents.length;
    return licenseLeft - licenseCount < 0 ? 0 : licenseLeft - licenseCount;
  }

  readingMinutes(studentsLength) {
    return this.taskTime * studentsLength;
  }

  /**
   *  close overlay
   */
  closeOverlay(event: boolean): void {
    this.dialog.closeAll();
    this.isClosedDialog = event;
  }

  /**
   *  get active step from stepper
   */
  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  }

  submitTask(formDirective: FormGroupDirective) {
    if (this.deadlineStep.invalid || this.groupSelectStep.invalid) {
      console.log('Form invalid');
      return;
    }
    this.isLoading = true;

    this.taskService
      .editMasterAndSubTasks({
        _id: this.taskId,
        deadline: this.deadlineStep.value.diagnosticDeadline,
        studentsArray: this.taskStudents,
        newStudentsArray: this.studentsArray,
        teacherId: this.teacherId,
        custom_deadline: this.diagnosticDateChanger['_checked'],
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.stepper.selectedIndex = 2;
      });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
