import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare const VERSION: string;
@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  translations: any;

  private languageSubject = new BehaviorSubject<string>(
    localStorage.getItem('language') || environment.language,
  );
  private translations$ = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  getTranslations() {
    return this.languageSubject.pipe(
      switchMap((language) => {
        const lang = language === 'en' ? 'en' : environment.language;
        return this.http.get(`../assets/i18n/${lang}.json?v=${VERSION}`, {
          params: { readalizer_customer: environment.readalizer_customer },
        });
      }),
      tap((translatedText: any) => {
        this.translations = translatedText;
        this.translations.t = (key, args) =>
          this.getTranslatedValue(this.translations, key, args);
        this.translations$.next(translatedText);
      }),
    );
  }

  getTranslation() {
    return this.translations$.asObservable();
  }

  getTranslatedValue(translation: any, key: string, arg: any) {
    if (arg == null) {
      return translation[key] || '';
    } else {
      let replacedText = translation[key];
      for (let k of Object.keys(arg)) {
        let regex = new RegExp(`%{{\\s*${k}\\s*}}`);
        replacedText = replacedText.replace(regex, arg[k]);
      }
      return replacedText;
    }
  }

  getTranslatedText(key: string) {
    return this.translations ? this.translations[key] : key;
  }

  setLanguage(language: string) {
    localStorage.setItem('language', language);
    this.languageSubject.next(language);
  }

  public getCurrentLanguage() {
    return this.languageSubject.value;
  }
}
