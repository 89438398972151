import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { CancelAddStudentComponent } from 'src/app/helpers/cancel-add-student/cancel-add-student.component';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { SchoolService } from 'src/app/providers/school.service';
import { TaskService } from 'src/app/providers/task.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { StudentService } from 'src/app/providers/student.service';

export interface DialogData {}
@Component({
  selector: 'app-diagnostic-create',
  templateUrl: './diagnostic-create.component.html',
  styleUrls: ['./diagnostic-create.component.scss'],
})
export class DiagnosticCreateComponent implements OnInit, OnDestroy {
  selectedDiagnostic: any;
  firstStep;
  groups: any = [];
  selectedStudents = [];
  selectedIndex: number;
  tasks;
  allSelected = false;
  isRead: boolean = false;
  isBook: boolean = false;
  studentsArray = [];
  groupArray = [];
  groupOverview = [];
  teacherId;
  bookTitle = 'Niko 3';
  avatarArray = [];
  taskOverview = [];
  isSingleGroup: boolean = false;
  isSingleStudent: boolean = false;
  isClosedDialog: boolean = false;
  singleGroupStudents = [];
  singleStudent = [];
  studentId;
  userType;
  todayDate: Date = new Date();
  isLoading: boolean = false;
  groupGrade;
  diagnosticType = '';
  diagnosticTitle = '';
  isGroupLoading: boolean = true;
  dateTodayIso;
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };
  DiagnosticTypesArray: string[] = [];

  diagnostics = [];
  dateToday = new Date();
  translatedText: any;
  currentYear: string;
  schoolYearTransition: boolean;
  nextYear: string;
  step: number = 1;
  isFormInvalid: boolean = true;
  taskOverView: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private authService: AuthService,
    private taskService: TaskService,
    private translationService: TranslationService,
    private schoolService: SchoolService,
    private studentService: StudentService,
    private changeDetector: ChangeDetectorRef,
  ) {
    if (injectedData) {
      if (typeof injectedData['type'] !== 'undefined') {
        this.diagnosticType = this.injectedData['type'];
        this.diagnosticTitle = this.generateDiagnosticTitle(
          this.diagnosticType,
        );
      }
      if (typeof injectedData['singleGroup'] !== 'undefined') {
        this.isSingleGroup = this.injectedData['singleGroup'];
      }
      if (typeof injectedData['students'] !== 'undefined') {
        this.singleGroupStudents = this.injectedData['students'];
      }
      if (typeof injectedData['isSingleStudent'] !== 'undefined') {
        this.isSingleStudent = this.injectedData['isSingleStudent'];
      }
      if (typeof injectedData['student'] !== 'undefined') {
        this.singleStudent = this.injectedData['student'];
      }
      if (typeof injectedData['studentId'] !== 'undefined') {
        this.studentId = this.injectedData['studentId'];
      }
      if (typeof injectedData['groupGrade'] !== 'undefined') {
        this.groupGrade = this.injectedData['groupGrade'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.teacherId = this.authService.getTeacherId();
    this.userType = this.authService.getType();

    /*if (this.isSingleGroup === true) {
      this.singleGroupStudents.forEach((element) => {
        this.studentsArray.push(element);
        this.thirdFormStep.get('students').setValidators(null);
      });
    } else if (this.isSingleGroup === false) {
      this.thirdFormStep.get('students').setValidators([Validators.required]);
    }

    if (this.isSingleStudent === true) {
      this.studentsArray.push(this.singleStudent);
      this.thirdFormStep.get('students').setValidators(null);
    } else if (this.isSingleGroup === false) {
      this.thirdFormStep.get('students').setValidators([Validators.required]);
    }*/
    this.trialStatus = this.authService.getUserTrialStatus();
    if (this.trialStatus.isActive) {
      this.diagnostics = [
        {
          phase: 1,
          startDate: moment().toISOString(),
          endDate: moment(this.trialStatus.expiryDate).toISOString(),
          name: 'Diagnose 1',
          value: 'diagnostic-pre',
          deadline: 'DiagnosticPre',
        },
      ];
    }
    this.getSchoolYearChanged();
  }

  getNextMonday(date = new Date()) {
    const dateCopy = new Date(date);
    const nextMonday = new Date(
      dateCopy.setDate(
        dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7),
      ),
    );
    return nextMonday;
  }

  checkDiagnosticByLicenses(diagnostic, licenses) {
    if (this.DiagnosticTypesArray && this.DiagnosticTypesArray.length > 0) {
      let type = this.DiagnosticTypesArray[0].replace(/diagnostic-/g, '');
      let diagnosticAvailability = this.studentService.getDiagnosticByLicenses(
        diagnostic,
        licenses,
      );
      return diagnosticAvailability[type];
    } else {
      return false;
    }
  }

  // to fix error in class detail
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  // add style to step 0 and set task type for selectedType()
  addStyle(value) {
    if (value === 'read') {
      this.isRead = !this.isRead;
      this.isBook = false;
    }

    if (value === 'book') {
      this.isRead = false;
      this.isBook = !this.isBook;
    }
  }

  getDateFromString(dateString: string): Date {
    return new Date(dateString);
  }

  checkForItemSelection(array, value) {
    return array.filter((item) => item.value === value).length > 0
      ? true
      : false;
  }

  closeAll(event: boolean): void {
    this.dialog.closeAll();
    this.isClosedDialog = event;
  }

  submitForm() {
    if (this.studentsArray.length === 0) return;
    let preTask;
    let midTask;
    let postTask;
    let tasks = [];
    if (this.selectedDiagnostic.diagnostic === 'diagnostic-pre') {
      preTask = {
        teacherId: this.teacherId,
        status: 'active',
        type: 'diagnostic-pre',
        deadline: this.selectedDiagnostic.deadlineDiagnosticPre.endOf('day'),
        custom_deadline: null,
      };
      tasks.push(preTask);
    }
    if (this.selectedDiagnostic.diagnostic === 'diagnostic-mid') {
      midTask = {
        teacherId: this.teacherId,
        status: 'active',
        type: 'diagnostic-mid',
        deadline: this.selectedDiagnostic.deadlineDiagnosticMid.endOf('day'),
        custom_deadline: null,
      };
      tasks.push(midTask);
    }
    if (this.selectedDiagnostic.diagnostic === 'diagnostic-post') {
      postTask = {
        teacherId: this.teacherId,
        status: 'active',
        type: 'diagnostic-post',
        deadline: this.selectedDiagnostic.deadlineDiagnosticPost.endOf('day'),
        custom_deadline: null,
      };
      tasks.push(postTask);
    }
    this.isLoading = true;
    this.taskService
      .createMasterTaskForDiagnostic(
        tasks,
        this.teacherId,
        this.studentsArray,
        this.trialStatus.isActive,
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          const totalSubTaskCount = res.reduce(
            (sum: number, current: any) => sum + current?.subTaskCount,
            0,
          );
          this.dialog.closeAll();
          let message = this.translatedText.t(
            'diagnostic_create_success_count_plural',
            {
              count: totalSubTaskCount,
            },
          );
          if (totalSubTaskCount == 0) {
            message = this.translatedText.t(
              'diagnostic_create_success_count_plural',
              {
                count: totalSubTaskCount,
              },
            );
          } else if (totalSubTaskCount > 0) {
            message = this.translatedText.t('diagnostic_create_success_count', {
              count: totalSubTaskCount,
            });
          }
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            panelClass: 'snack-success',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
          // update task list in studentOverview
          this.isLoading = false;
          //fix with next?
          if (this.isSingleStudent === true) {
            this.taskService.getTasksToStudents(this.studentId);
          } else {
            // update tasks
            this.taskService.getMasterTasksFromTeacher();
          }
        },
        error: (error: any) => {
          let message = 'Es ist ein technischer Fehler aufgetreten';
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      });
  }

  generateDiagnosticTitle(type) {
    let title = '';
    switch (type) {
      case 'pre':
        title = 'Diagnose 1';
        break;
      case 'mid':
        title = 'Diagnose 2';
        break;
      case 'post':
        title = 'Diagnose 3';
        break;
      default:
        break;
    }
    return title;
  }

  getSelectedDeadLineByType(payload) {
    let dateWithTitle = { date: '', headline: '' };
    switch (payload.diagnostic) {
      case 'diagnostic-pre':
        dateWithTitle.date = payload.deadlineDiagnosticPre;
        dateWithTitle.headline =
          this.translatedText.diagnostic_create_beginning_year;
        break;
      case 'diagnostic-mid':
        dateWithTitle.date = payload.deadlineDiagnosticMid;
        dateWithTitle.headline =
          this.translatedText.diagnostic_create_middle_year;
        break;
      case 'diagnostic-post':
        dateWithTitle.date = payload.deadlineDiagnosticPost;
        dateWithTitle.headline = this.translatedText.diagnostic_create_end_year;
        break;
      default:
        break;
    }
    return dateWithTitle;
  }

  checkForDiagnosticCompletion(diagnostic, type) {
    let foundType = diagnostic.filter(
      (item) => item?.kind === type && item?._task?.session_uuid,
    );
    return foundType.length > 0 ? true : false;
  }

  checkForDiagnosticExistence(diagnostic, type) {
    let foundType = diagnostic.filter((item) => item.kind === type);
    return foundType.length > 0 ? true : false;
  }

  getSchoolYearChanged() {
    this.schoolService
      .schoolYearChanged()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.schoolYearTransition = data.yearChange;
        this.currentYear = data.year;
        this.nextYear = data.nextYear;
      });
  }

  openCancelDialog() {
    this.dialog.closeAll();
    /* if (this.step > 1) {
      let dialogRef = this.dialog.open(CancelAddStudentComponent, {
        width: '100%',
        autoFocus: false,
        panelClass: 'task-delete-overlay-panel',
        disableClose: true,
        backdropClass: 'create-training-task-panel-backdrop',
        data: {},
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          // this.closeButton.emit(true);
        }
      });
    } else {
      //this.closeButton.emit(true);
    } */
  }

  // First Step Form Data - Choose Diagnosic Type
  getDateFormData(event: any) {
    this.isFormInvalid = event.status === 'VALID' ? false : true;
    let diagnosticTimeFrameDetails = event.payload;
    if (event.status === 'VALID') {
      const selectedDiagnosticDetails: any = this.getSelectedDeadLineByType(
        event.payload,
      );
      if (selectedDiagnosticDetails.date !== '') {
        // Subtract 28 days from the selected date
        const resultDate = new Date(selectedDiagnosticDetails.date);
        resultDate.setDate(selectedDiagnosticDetails.date._d.getDate() - 28);
        Object.assign(diagnosticTimeFrameDetails, {
          source: 'diagnostic',
          dueDate: selectedDiagnosticDetails.date,
          headline: selectedDiagnosticDetails.headline,
          taskVisibleFrom: resultDate,
        });
        this.selectedDiagnostic = diagnosticTimeFrameDetails;
      }
    }
  }

  getSelectedStudentsList(event: any) {
    if (event.students) {
      this.studentsArray = event.students;
      this.taskOverView = event.taskOverView;
    }
  }

  backBtn() {
    this.step = this.step - 1;
    // if (this.step === 1) {
    //   this.isFormInvalid = true;
    // }
  }

  nextBtn() {
    this.step = this.step + 1;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
