import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';

export interface RecordData {}

@Component({
  selector: 'app-record-finished-overlay',
  templateUrl: './record-finished-overlay.component.html',
  styleUrls: ['./record-finished-overlay.component.scss'],
})
export class RecordFinishedOverlayComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  translatedText: any;

  constructor(
    private dialogRef: MatDialogRef<RecordFinishedOverlayComponent>,
    private dialog: MatDialog,
    private translationService: TranslationService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
