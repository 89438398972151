import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-student-limit-reached',
  templateUrl: './student-limit-reached.component.html',
  styleUrls: ['./student-limit-reached.component.scss'],
})
export class StudentLimitReachedComponent implements OnInit {
  translatedText: any;
  route: any;
  STUDENT_LIMIT: number = 40;
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };
  descriptionText: string = '';
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: any,
    public dialog: MatDialog,
    private translationService: TranslationService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<StudentLimitReachedComponent>,
  ) {}

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.trialStatus = this.authService.getUserTrialStatus();
    if (this.trialStatus.isActive) {
      this.STUDENT_LIMIT = environment.whitelabel.trial_number_students;
    }
    this.descriptionText = this.translatedText.t(
      'admin_group_class_student_limit_reach_description',
      {
        studentLimit: this.STUDENT_LIMIT,
        studentLimitOne: this.STUDENT_LIMIT,
      },
    );
  }
  closePopup() {
    this.dialogRef.close();
  }
}
