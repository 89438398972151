import { CdkStepper } from '@angular/cdk/stepper';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { LicenseService } from 'src/app/providers/license.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from 'src/environments/environment';
export interface School {
  _id?: string;
  name?: string;
  zip?: string;
  city?: string;
  address?: string;
  school_type?: string;
  state?: string;
  uuid: string;
}

/**
 * Teacher create form
 */
@Component({
  selector: 'app-non-school-register',
  templateUrl: './non-school-register.component.html',
  styleUrls: ['./non-school-register.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'expandable-overlay-panel' },
    },
  ],
})
export class NonSchoolregisterComponent implements OnInit, OnDestroy {
  rForm: FormGroup;
  personalForm: FormGroup;
  licenseForm: FormGroup;
  organisationForm: FormGroup;
  purposeForm: FormGroup;
  confirmStep;
  createSchool;
  userTypes: string[];
  countryCtrl = new FormControl();
  filteredCountries: any[] = [];
  countries = [
    { code: '+49', name: 'Deutschland', slug: 'germany' },
    { code: '+48', name: 'Polen', slug: 'poland' },
    { code: '+351', name: 'Portugal', slug: 'portugal' },
    // Weitere Länder hinzufügen
  ];
  showAddButton: boolean = false;
  selectedIndex: number;
  prompt = 'Drücken Sie die <enter> Taste um einen neue Anrede hinzuzufügen"';
  isWebmail: boolean = false;
  isPhone: boolean = false;
  infoEmailText: string =
    'Dieser Dienst darf nur von Lehrkräften im Rahmen Ihrer Lehrtätigkeit und zur Evaluation des Dienstes genutzt werden. \n\n Bitte nutzen Sie für die Registrierung Ihre dienstliche E-Mail-Adresse. Sollten Sie über keine entsprechende E-Mail-Adresse verfügen und eine Webmail-Adresse (@gmail, @aol, @web, etc.) nutzen wollen, werden wir vor Gewährung eines Zugangs ggf. Ihre Identität überprüfen. Geben Sie hierzu bitte auch Ihre Telefonnummer an.';
  isLoading: boolean = false;
  page = 1;
  limit = -1;
  sortBy = 'name';
  sortDirection = 1;
  filterValue = '';
  groups;
  lastStep;
  isCreated: boolean = false;
  @ViewChild('stepper') stepper: CdkStepper;
  licenseError: boolean = false;
  license;
  licenseInfo = [];
  isSelectedCountry = false;
  translatedText: any;
  environment = environment;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  /**
   * Constructor registers userService and router
   */
  constructor(
    public dialog: MatDialog, // TODO: unused? private or public?
    public snackBar: MatSnackBar, // TODO: private or public?
    private _formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private authService: AuthService,
    private licenseService: LicenseService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    // Get user types for adding roles menu item
    this.userTypes = this.authService.getHierachicalRoles();

    this.personalForm = this._formBuilder.group({
      tel: [
        '+49',
        [
          Validators.required,
          this.onlyDigitsValidator(),
          Validators.minLength(5),
        ],
      ],
      firstname: ['', [Validators.required, this.noDigitsValidator()]],
      lastname: ['', [Validators.required, this.noDigitsValidator()]],
      email: ['', [Validators.required, this.customEmailValidator]],
      selectedCountry: [
        {
          code: '+49',
          name: 'Deutschland',
          slug: 'germany',
        },
        Validators.required,
      ],
    });

    this.licenseForm = this._formBuilder.group({
      license: ['', Validators.required],
    });

    this.purposeForm = this._formBuilder.group({
      purpose: ['', Validators.required],
      purposeOther: [''],
    });

    this.organisationForm = this._formBuilder.group({
      organisationType: ['', Validators.required],
      organisationName: ['', Validators.required],
      organisationAddress: ['', Validators.required],
      organisationCity: ['', [Validators.required, this.noDigitsValidator()]],
      organisationZip: ['', [Validators.required, this.onlyDigitsValidator()]],
      organisationState: ['', Validators.required],
    });
    this.filteredCountries = this.countries;
    //this.cd.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  filterCountries(query: string): void {
    // Filter countries excluding the already selected country
    const selectedCountry = this.personalForm.get('selectedCountry').value;

    this.filteredCountries = this.countries.filter((country) => {
      const includesQuery = country.name
        .toLowerCase()
        .includes(query.toLowerCase());
      const isNotSelectedCountry = country !== selectedCountry;

      return includesQuery && isNotSelectedCountry;
    });
  }

  onCountrySelected(event: MatAutocompleteSelectedEvent): void {
    this.isSelectedCountry = true;
    // Handle the selected country from the autocomplete
    const selectedCountry = event.option.value;
    this.personalForm.get('selectedCountry').setValue(selectedCountry);
  }

  displayCountry(country: any): string {
    return country ? `${country.name} (${country.code})` : '';
  }

  onlyDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && !/^\+?\d*$/.test(value)) {
        return { hasInvalidCharacters: true };
      }
      return null;
    };
  }

  customEmailValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (control.value && !emailRegex.test(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  }

  noDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && !/^\D*$/.test(value)) {
        return { hasDigits: true };
      }
      return null;
    };
  }

  checkLicense(license) {
    this.isLoading = true;
    this.licenseService.checkLicenseAvailability(license).subscribe({
      next: (res) => {
        this.licenseInfo = res;
        this.licenseError = false;
        this.submitForm();
      },
      error: (error: any) => {
        this.isLoading = false;
        this.licenseError = true;
        this.licenseForm.markAsDirty();
      },
    });
  }

  onCountryChange(event) {
    console.log(event);
    this.personalForm.get('tel').setValue(event.value.code);
    this.isSelectedCountry = true;
  }

  onPurposeChange(event) {
    console.log(event);
    if (event.value === 'other') {
      this.purposeForm.get('purposeOther').setValidators([Validators.required]);
      this.purposeForm.get('purposeOther').updateValueAndValidity();
    } else {
      this.purposeForm.get('purposeOther').setValidators(null);
      this.purposeForm.get('purposeOther').updateValueAndValidity();
    }
  }

  maskEmail(email: string): string {
    if (!email) {
      return '';
    }

    const parts = email.split('@');
    if (!parts) {
      return '';
    }
    const username = parts[0];
    const domain = parts[1];

    const maskedUsername =
      username?.charAt(0) + '*'.repeat(username?.length - 1);
    const maskedEmail = maskedUsername + '@' + domain;

    return maskedEmail;
  }

  EmailAvailabilty() {
    this.authService
      .checkEmailAvailabilty(this.personalForm.value.email)
      .subscribe({
        next: (res) => {
          this.stepper.selectedIndex = 1;
        },
        error: (error: any) => {
          this.isLoading = false;
          if (error?.status === 403) {
            this.personalForm.get('email').setErrors({ emailTaken: true });
            // this.snackBar.open("E-Mail-Adresse bereits vergeben", "",{
            //     panelClass: 'snack-error',
            //     duration: 3000,
            //     horizontalPosition: 'right'
            //     });
          }
        },
      });
  }

  resendRegistrationEmail() {
    this.authService.resendRegistrationEmail(
      this.personalForm.value.email,
      this.translatedText,
    );
  }

  submitForm() {
    if (this.personalForm.invalid) {
      console.log('Form invalid');
      return;
    }
    this.isLoading = true;

    const userData = {
      firstname: this.personalForm.value.firstname,
      lastname: this.personalForm.value.lastname,
      email: this.personalForm.value.email,
      tel: this.personalForm.value.tel,
      role: 'school_admin',
    };

    const schoolData = {
      school_type: this.organisationForm.value.organisationType,
      name: this.organisationForm.value.organisationName,
      address: this.organisationForm.value.organisationAddress,
      city: this.organisationForm.value.organisationCity,
      zip: this.organisationForm.value.organisationZip,
      state: this.organisationForm.value.organisationState,
    };

    const licenseData = {
      license: this.licenseForm.value.license,
    };

    const purposeData = {
      purpose: this.purposeForm.value.purpose,
      purposeOther: this.purposeForm.value.purposeOther,
    };

    this.authService
      .nonSchoolRegistration(
        userData,
        schoolData,
        licenseData,
        purposeData,
        this.translatedText,
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (res) => {
          console.log(res);
          this.isLoading = false;
          this.stepper.selectedIndex = 4;
        },
        error: (error: any) => {
          this.isLoading = false;
          console.log(error);
          if (error?.status === 403) {
            this.snackBar.open('E-Mail-Adresse bereits vergeben', '', {
              panelClass: 'snack-error',
              duration: 3000,
              horizontalPosition: 'right',
            });
          }
        },
      });
  }

  /**
   *  get active step from stepper
   */
  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  }

  /**
   * Unsubscribe on detroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
