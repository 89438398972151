<div class="container-fluid">
  <button mat-mini-fab type="button" [mat-dialog-close]="'close'" class="close-button close-button--right" mattSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>

  <div>
    <h3 class="h3 h3--bold">{{ translatedText.book_filter }}</h3>
    <p class="paragraph paragraph--M paragraph--bold mt-3 mb-3">{{ translatedText.book_filter_age }}</p>
    <mat-chip-listbox cdkDropList cdkDropListOrientation="horizontal" multiple>
      <mat-chip-option
        class="filter-chips"
        *ngFor="let age of allFilters['age']"
        (click)="age.selected = !age.selected; buttonsStateHandler($event, age, 'age')"
        [selected]="age.selected"
      >
        {{ age.viewValue }}</mat-chip-option
      >
    </mat-chip-listbox>

    <p class="paragraph paragraph--M paragraph--bold mt-3 mb-3">{{ translatedText.book_filter_grade }}</p>

    <mat-chip-listbox cdkDropList cdkDropListOrientation="horizontal" multiple>
      <mat-chip-option
        class="filter-chips"
        *ngFor="let grade of allFilters['grade']"
        [value]="grade?.value"
        (click)="grade.selected = !grade.selected; buttonsStateHandler($event, grade, 'grade')"
        [selected]="grade.selected"
      >
        {{ grade.viewValue }}</mat-chip-option
      >
    </mat-chip-listbox>
    <p class="paragraph paragraph--M paragraph--bold mt-3 mb-3">{{ translatedText.book_filter_genre }}</p>
    <mat-chip-listbox cdkDropList cdkDropListOrientation="horizontal" multiple>
      <mat-chip-option
        class="filter-chips"
        *ngFor="let category of allFilters['categories']"
        (click)="category.selected = !category.selected; buttonsStateHandler($event, category, 'categories')"
        [value]="category?.value"
        [selected]="category.selected"
      >
        {{ category?.viewValue }}</mat-chip-option
      >
    </mat-chip-listbox>

    <div class="button-wrap mt-5">
      <button
        mat-flat-button
        color="secondary"
        type="button"
        (click)="resetFilter()"
        [disabled]="resetButtonDisabled ? true : null"
      >
        <!-- numberOfSelectedFilters <  || selectedFilters != filterSelectedValues" -->
        {{ translatedText.book_filter_reset }}
      </button>
      <button
        mat-flat-button
        color="primary"
        type="button"
        (click)="applyFilters()"
        [disabled]="applybuttonDisabled ? true : null"
      >
        {{ translatedText.book_filter_apply }}
      </button>
    </div>
  </div>
</div>
