import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
} from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { CsvImportTeacherComponent } from 'src/app/csv-import/csv-import-teacher/csv-import-teacher.component';
import { AdminManagingOverlayComponent } from 'src/app/helpers/admin-managing-overlay/admin-managing-overlay.component';
import { PinCodeComponent } from 'src/app/helpers/pin-code/pin-code.component';
import { TeacherService } from 'src/app/providers/teacher.service';
import { TranslationService } from 'src/app/providers/translation.service';
import {
  UpgradeDialogComponent,
  UpgradeDialogData,
} from 'src/app/trial-phase/upgrade-banner/upgrade-banner.component';
import { NotificationOverlayComponent } from '../../../notification/notification-overlay/notification-overlay.component';
import { ErrorSnackbarComponent } from '../../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { AdminService, TeacherData } from '../../../providers/admin.service';
import { TeacherDeleteComponent } from '../../../teacher/teacher-delete/teacher-delete.component';
import { AdminTeacherCreateComponent } from '../new-admin-teacher-create/admin-teacher-create.component';
import { AdminTeacherEditComponent } from '../admin-teacher-edit/admin-teacher-edit.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';

const MAX_ALLOWED_TEACHERS_DURING_TRIAL = 2; // 1 Teacher + 1 School_Admin

/**
 * Teacher list with material table view, sorting and pagination
 */
@Component({
  selector: 'app-admin-teacher-list',
  templateUrl: './admin-teacher-list.component.html',
  styleUrls: ['./admin-teacher-list.component.scss'],
})
export class AdminTeacherListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('moreTeacherMenu') moreTeacherMenu: MatMenuTrigger;
  userAge = 0;
  teacher: any;
  categories: any;
  filterCategories;
  admin;
  teacherId;
  page = 1;
  limit = 10;
  sortBy = 'lastname';
  sortDirection = 1;
  matSortDirection = 'asc';
  filterValues = {
    search: '',
    groups: [],
    status: [],
    role: [],
  };
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };

  openedMenu: string = null;
  dataSource: TeacherData = null;
  pageEvent: PageEvent;
  displayedColumns: string[] = ['name', 'email', 'group', 'status', 'buttons'];
  userTypes: string[];
  userType: string;
  filterFormGroup: FormGroup;
  status: any[] = [
    { value: 'all', viewValue: 'Alle' },
    { value: 'active', viewValue: 'Aktiv' },
    { value: 'inactive', viewValue: 'Inaktiv' },
  ];

  groups: any[] = [
    { value: 'all', viewValue: 'Alle' },
    { value: '1a', viewValue: '1A' },
    { value: '2a', viewValue: '2A' },
    { value: '2b', viewValue: '2B' },
    { value: '3a', viewValue: '3A' },
  ];
  translatedText;
  loggedInAccountId: string | null = null;
  groupsForAddedTeacher: any;
  private searchInputEvent = new Subject<any>();
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor registers adminService and router
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private authService: AuthService,
    private teacherService: TeacherService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private translationService: TranslationService,
  ) {}

  //Listen to search input change
  ngOnInit(): void {
    this.searchInputEvent
      .pipe(
        takeUntil(this._unsubscribeAll),
        map((event) => event.target.value),
        filter(
          (filterValue) => filterValue.length == 0 || filterValue.length > 2,
        ),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        this.filterValues.search = value;
        this.page = 1;
        this.paginator.pageIndex = 0;
        // Check for search results
        this.getTeachers();
      });

    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    // Init datasource
    this.getTeachers();
    // Get user types for adding roles menu item
    this.userTypes = this.authService.getHierachicalRoles();
    this.userType = this.authService.getType();
    this.loggedInAccountId = this.authService.getUserId();
    this.filterFormGroup = new FormGroup({
      group: new FormControl(),
      status: new FormControl(),
    });

    this.trialStatus = this.authService.getUserTrialStatus();
    this.authService.getCurrentTrialStatus().subscribe({
      next: (trialStatus) => {
        this.trialStatus = trialStatus;
        //console.log('Admin dashboard status', this.trialStatus);
      },
    });
  }

  //API call on adminService to retrieve requested teacher
  getTeachers() {
    return this.adminService
      .getTeacherList(
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValues,
      )
      .pipe(
        map((teacherData: TeacherData) => {
          teacherData.data.forEach((item) => {
            // combine "firstname" & "lastname" to "name"-Attribut
            item['name'] = `${item['firstname']} ${item['lastname']}`;
          });
          this.dataSource = teacherData;
        }),
      )
      .subscribe();
  }

  // API call on adminService to retrieve requested teachers with sorted direction

  onSortChange(event: MatSort) {
    console.log(event);
    this.sortBy = event.active;
    this.matSortDirection = event.direction;
    this.sortDirection = event.direction == 'asc' ? 1 : -1;
    this.getTeachers();
  }

  onSelectChange(event: any, filter) {
    this.filterValues[filter] = event.value;
    // Load filtered teachers
    this.getTeachers();
  }

  //API call on adminService to retrieve requested teachers by pagination

  onPaginateChange(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.limit = event.pageSize;
    this.getTeachers();
  }

  /**
   * Send new input value to subscription
   */
  onSearchInputFilterChange(event) {
    this.searchInputEvent.next(event);
  }

  /**
   * Route to teacher detail component
   */
  navigateToTeacherDetail(id) {
    //this.router.navigate(['teacher-view/' + id]);
  }

  /**
   * Create new user by role
   */
  createNewUser(role) {
    // TODO: Create a new user or remove function
    console.log(role);
  }

  /**
   * open notification overlay and send all teacher as recipient
   */
  openNotificationOverlay(): void {
    let dialogRef = this.dialog.open(NotificationOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'notification-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'license-add-backdrop',
      data: {
        recipient: this.dataSource.data,
        type: 'teacher',
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  /**
   * generate text for selected mat select
   */
  generateSelectionText(obj, selection) {
    let returnText = '';
    if (selection != undefined && selection.length > 0) {
      if (typeof obj[0] === 'object') {
        let found = obj.filter((item) => item.value == selection[0]);
        returnText = found[0].viewValue;
      } else {
        returnText = selection[0];
      }
      if (selection.length > 1) {
        let additionText =
          selection.length === 2
            ? this.translatedText?.filter_more_single
            : this.translatedText?.filter_more;
        returnText += ' + ' + (selection.length - 1) + ' ' + additionText;
      }
      if (obj.length === selection.length) {
        returnText = this.translatedText?.filter_all;
      }
    }
    return returnText;
  }

  impersonateUser(userId) {
    if (this.userType == 'admin') {
      this.authService.impersonateUser(userId, '123');
    } else {
      this.openPinCode(userId);
    }
  }

  clickedOptions(element): void {
    this.openedMenu = element;
  }

  closeMenu(): void {
    this.openedMenu = null;
  }

  updateAdminRights(event, element): void {
    let dialogRef = this.dialog.open(AdminManagingOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'group-delete-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'bg-group-delete-overlay',
      data: {
        admin: element,
        managingType: event,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.getTeachers();
    });
  }

  updateTeacherStatus(teacher) {
    let newStatus = teacher?.status == 'active' ? 'inactive' : 'active';
    let formData = {
      teacherId: teacher._id,
      status: newStatus,
    };

    this.teacherService.updateTeacher(formData).subscribe({
      next: (res) => {
        // Update teacher list
        this.getTeachers();
      },
      error: (error: any) => {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText?.technical_error,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
    this.teacherService
      .readSingleTeacher(this.teacherId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.admin = res;
        //this.adminStatus = res['status'];
      });
  }

  /**
   * open enter pin code overlay)
   */
  openPinCode(userId): void {
    let dialogRef = this.dialog.open(PinCodeComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'pin-code-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'pin-code-backdrop',
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      if (res?.data?.length > 0) {
        this.authService.impersonateUser(userId, res.data);
      }
    });
  }

  /**
   * open create teacher overlay (stepper)
   */
  createTeacherOverlay() {
    if (this.trialStatus.isActive && this.dataSource.meta.itemCount > 1) {
      this.dialog.open<UpgradeDialogComponent, UpgradeDialogData>(
        UpgradeDialogComponent,
        {
          width: '85vw',
          autoFocus: false,
          disableClose: true,
          panelClass: 'home-trial-dialog-panel',
          // hasBackdrop: false,
          backdropClass: 'license-add-backdrop',
          data: { upgradeModalText: 'maxTeachersReached' },
        },
      );
      return;
    }

    let dialogRef = this.dialog.open(AdminTeacherCreateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'full-page-panel',
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {},
    });
    dialogRef.afterClosed().subscribe((res) => {
      // Refresh groups on dashboard
      this.getTeachers();
      //this.getGroupsByTeacher();
    });
  }

  openTeacherDeleteDialog(teacher): void {
    let deleteDialog = this.dialog.open(TeacherDeleteComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'delete-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'teacher-delete-overlay',
      data: {
        //teacherId: teacher._id,
        teacherName: teacher.name,
        teacher: teacher,
      },
    });
    // dialogRef.disableClose = true;
    deleteDialog.afterClosed().subscribe((res) => {
      deleteDialog.close();
      // Refresh teacher list
      this.getTeachers();
    });
    // dialogRef.disableClose = true;
    deleteDialog
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        deleteDialog.close();
        // Refresh teacher list
        this.getTeachers();
      });
  }

  editTeacher(teacherId, teacherEmail, teacherPosition) {
    let dialogRef = this.dialog.open(AdminTeacherEditComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'license-add-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {
        teacherId: teacherId,
        teacherEmail: teacherEmail,
        teacherPosition: this.userType,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      dialogRef.close();
      this.getTeachers();
      // Refresh groups on dashboard
      //this.getGroupsByTeacher();
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        dialogRef.close();
        this.getTeachers();
        // Refresh groups on dashboard
        //this.getGroupsByTeacher();
      });
  }

  openTeacherCsvImport(): void {
    let dialogRef = this.dialog.open(CsvImportTeacherComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'csv-import-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'csv-import-panel-backdrop',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getTeachers();
    });
  }

  shortenEmail(email) {
    if (window.innerWidth < 1000) {
      return email.length > 20 ? email.substring(0, 20) + '...' : email;
    } else {
      return email;
    }
  }

  /**
   * Unsubscribe on destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
