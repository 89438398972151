<div class="beta-info-wrap">
  <button mat-mini-fab type="button" class="close-button close-button--right" [mat-dialog-close]="'close'" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="helpdesk_wrap">
    <mat-icon svgIcon="streamline_outlined:warning-triangle-large"></mat-icon>
    <h3 class="h3 h3--bold">
      {{ translatedText.beta_info_warning }}
    </h3>

    <ng-container *ngIf="!betaLeaveInfo">
      <div class="beta-info-content">
        <p class="paragraph">
          {{ translatedText.beta_info_part_1 }}
          <strong>{{ translatedText.beta_info_part_2 }}.</strong>
          {{ translatedText.beta_info_list_title
          }}<!-- {{ translatedText.beta_info_part_3 }}
        <strong>{{ translatedText.beta_info_part_4 }}</strong>
        {{ translatedText.beta_info_part_5 }} -->
        </p>

        <ul>
          <li>
            <p class="paragraph">{{ translatedText.beta_info_list_item_1 }}</p>
          </li>
          <li>
            <p class="paragraph">{{ translatedText.beta_info_list_item_2 }}</p>
          </li>

          <li>
            <p class="paragraph">{{ translatedText.beta_info_list_item_3 }}</p>
          </li>
        </ul>

        <p class="paragraph">
          {{ translatedText.beta_info_list_item_4 }}
          <br />
          <br />
          {{ translatedText.beta_info_footer }}
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="betaLeaveInfo">
      <p class="paragraph">
        {{ translatedText.beta_info_leave_part_1 }}
        <strong>{{ translatedText.beta_info_leave_part_2 }}</strong>
        {{ translatedText.beta_info_leave_part_3 }}
      </p>
    </ng-container>

    <button mat-flat-button color="primary" (click)="closeOverlay()" type="button" class="beta-info-btn">
      {{ translatedText.beta_info_close }}
    </button>
  </div>
</div>
