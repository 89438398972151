import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { Directionality } from '@angular/cdk/bidi';
import { TranslationService } from 'src/app/providers/translation.service';
import { Subject, takeUntil } from 'rxjs';
import { AdminService } from 'src/app/providers/admin.service';
import { CancelAddStudentComponent } from 'src/app/helpers/cancel-add-student/cancel-add-student.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-group-create-stepper',
  templateUrl: './admin-group-create-stepper.component.html',
  styleUrls: ['./admin-group-create-stepper.component.scss'],
  providers: [
    { provide: CdkStepper, useExisting: AdminGroupCreateStepperComponent },
  ],
})
export class AdminGroupCreateStepperComponent extends CdkStepper {
  @Output('selectedIndex') informParentSelectedIndex =
    new EventEmitter<number>();
  @Output('step') informParentSelectedStep = new EventEmitter<number>();
  @Input('stepValue') stepValue: number;
  @Output('isClosedDialog') closeButton = new EventEmitter<boolean>();
  @Input('blobUrl') blobUrl: string | null;
  @Input('isLoading') isLoading: boolean;
  isFormInvalid: boolean = true;
  backButton: boolean = true;
  translatedText: any;
  currentStep: number = 1;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    dir: Directionality,
    changeDetectorRef: ChangeDetectorRef,
    private translationService: TranslationService,
    private adminService: AdminService,
    public dialog: MatDialog,
  ) {
    super(dir, changeDetectorRef, null);
  }

  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.adminService.$addClassFormStatus.subscribe((data: any) => {
      if (data) {
        this.isFormInvalid = data.status.trim() === 'INVALID' ? true : false;
      }
    });
  }

  onClick(index: number): void {
    this.steps;
    this.selectedIndex = index;
    this.informParentSelectedIndex.emit(this.selectedIndex);
    if (this.selectedIndex > 0) {
      this.backButton = false;
    } else {
      this.backButton = true;
    }
  }

  nextStep(index: number): void {
    this.informParentSelectedStep.emit(index);
  }

  nextBtn() {
    this.adminService.moveNext('next');
    this.isFormInvalid = true;
  }

  backBtn() {
    this.adminService.moveNext('back');
    this.isFormInvalid = false;
  }

  moveBack() {
    if (this.selectedIndex == 1) {
      this.backButton = true;
    }
    if (this.selectedIndex == 2) {
      this.selectedIndex = this.selectedIndex - 1;
      this.backButton = true;
    } else {
      this.selectedIndex = this.selectedIndex - 1;
    }
  }

  openCancelDialog() {
    if (this.currentStep > 1) {
      let dialogRef = this.dialog.open(CancelAddStudentComponent, {
        width: '100%',
        autoFocus: false,
        panelClass: 'task-delete-overlay-panel',
        disableClose: true,
        backdropClass: 'create-training-task-panel-backdrop',
        data: {},
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.closeButton.emit(true);
        }
      });
    } else {
      this.closeButton.emit(true);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.stepValue != undefined) {
      this.currentStep = changes.stepValue.currentValue;
      if (changes.stepValue.currentValue > 0) {
        this.backButton = false;
      }

      // By Default Next btn should be enabled for Assign license step
      //and csv import step (this last one has a preselected option)
      if (this.currentStep === 5 || this.currentStep === 2) {
        this.isFormInvalid = false;
      }
    }
  }
}
