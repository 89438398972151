<ng-container>
  <div *ngFor="let notification of notifications" class="notification-wrap">
    <ng-container *ngIf="notification.type === 'task'">
      <div class="message-header">
        <div class="student_chip student_chip__teacher">
          <mat-icon svgIcon="streamline_filled:teacher"></mat-icon>
          <p class="paragraph">{{ notification.fullName }}</p>
        </div>
        <div>
          <p class="paragraph disable-color">
            <!--     {{ formatCreatedAt(notification.createdAt) }} -->
          </p>
        </div>
      </div>

      <div class="message-info">
        <div class="icon-wrap">
          <mat-icon class="orange_target" svgIcon="streamline_filled:target"></mat-icon>
        </div>
        <div>
          <ng-container
            *ngIf="
              notification?._task?.type == 'diagnostic-pre' ||
              notification?._task?.type == 'diagnostic-mid' ||
              notification?._task?.type == 'diagnostic-post'
            "
          >
            <p class="paragraph">
              {{ translatedText.app_component_message }}
              <strong>3 {{ translatedText.app_component_minutes }}</strong>
              den angezeigten Text.
            </p>
          </ng-container>
          <ng-container *ngIf="notification?._task?.type == 'text'">
            <p class="paragraph">
              {{ translatedText.app_component_message }}
              <strong>{{ notification?._task?.wordcount }} {{ translatedText.app_component_words }}</strong>
              {{ translatedText.app_component_in }}
              <strong [innerHTML]="notification?._task?.book_name"></strong>.
            </p>
          </ng-container>
          <ng-container *ngIf="notification?._task?.type == 'time'">
            <p class="paragraph">
              {{ translatedText.app_component_message }}
              <strong
                >{{ notification?._task?.time }}
                {{
                  notification?._task?.time === 1
                    ? translatedText.app_component_minute
                    : translatedText.app_component_minutes
                }}</strong
              ><span *ngIf="notification?._task?.book_name">
                {{ translatedText.app_component_in }}
                <strong [innerHTML]="notification?._task?.book_name"></strong></span
              >.
              <span *ngIf="notification?._task?.mode == 'multiple'">{{
                translatedText.app_component_multiple_steps
              }}</span>
            </p>
          </ng-container>
          <ng-container *ngIf="notification?._task?.type == 'time-choice'">
            <p class="paragraph">
              {{ translatedText.app_component_message }}
              <strong
                >{{ notification?._task?.time }}
                {{
                  notification?._task?.time === 1
                    ? translatedText.app_component_minute
                    : translatedText.app_component_minutes
                }}</strong
              >
              {{ translatedText.app_component_book }}
              <span *ngIf="notification?._task?.mode == 'multiple'">{{
                translatedText.app_component_multiple_steps
              }}</span>
            </p>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div *ngIf="notification.type === 'session-processed'">
      <div class="message-header">
        <div class="student_chip student_chip__teacher">
          <mat-icon svgIcon="streamline_filled:orange-check"></mat-icon>
          <p class="paragraph">{{ translatedText.notification_task_results_title }}</p>
        </div>
      </div>

      <div class="session-processed-notification-text">
        <mat-icon svgIcon="streamline_filled:lightning"></mat-icon>
        <p
          class="paragraph"
          [innerHTML]="
            translatedText?.t('notification_task_results', {
              bookTitle: notification?._task?.book_name,
              taskDesc: notificationTaskType(notification)
            })
          "
        ></p>
      </div>
    </div>
    <button
      *ngIf="notification.type != 'session-processed'"
      mat-flat-button
      color="primary-student"
      class="mat-flat-button--full"
      (click)="openMessagePopup(notification); $event.preventDefault()"
    >
      {{ translatedText.app_component_to_message }}
    </button>
    <button
      *ngIf="notification.type === 'session-processed'"
      mat-flat-button
      color="primary-student"
      class="mat-flat-button--full"
      (click)="openRecordResult(notification)"
    >
      {{ translatedText.notification_task_results_button }}
    </button>
  </div>
</ng-container>
