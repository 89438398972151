import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ChangeDetectorRef,
  LOCALE_ID,
  Inject,
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { RecordRTCComponent } from '../../record-rtc/record-rtc.component';
import { BookListComponent } from '../../books/new-book-list/book-list.component';
import { takeUntil } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { NotificationListComponent } from '../../notification/notification-list/notification-list.component';
import { TaskService } from '../../providers/task.service';
import { Router } from '@angular/router';
import moment from 'moment';
import { TranslationService } from 'src/app/providers/translation.service';
import { RecordResultComponent } from 'src/app/record-rtc/record-result/record-result.component';
import { StudentService } from 'src/app/providers/student.service';
import { NotificationService } from 'src/app/providers/notification.service';

export interface Tasks {}

@Component({
  selector: 'app-student-task-list',
  templateUrl: './student-task-list.component.html',
  styleUrls: ['./student-task-list.component.scss'],
})
export class StudentTaskListComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  @Input('activeTab') activeTab: string;
  @Input('tasks') tasks: any;
  @Input('notifications') notifications: any;
  isActive: boolean = false;
  isFinished: boolean = false;
  isExpired: boolean = false;
  isselfing: boolean = false;
  isLoading: string = '';
  id;
  // tasks: Tasks[] = [];
  finishedTasks = [];
  activeTasks = [];
  expiredTasks = [];
  currentDate;
  taskDeadlineDay;
  tabPosition = 0;
  translatedText;

  constructor(
    private taskService: TaskService,
    private router: Router,
    public dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private translationService: TranslationService,
    private studentService: StudentService,
    private notificationService: NotificationService,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  ngOnInit() {
    this.locale = environment.language;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.activeTab = 'isActive';
  }

  getFormattedDate(deadline: Date): string {
    const datePipeLocale = this.locale === 'de' ? 'de-DE' : 'en-US';
    const formattedDeadline = new Date(deadline);
    return formattedDeadline.toLocaleDateString(datePipeLocale, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  getReadingDynamicNote(grade, reading_dynamic_normalized) {
    return this.taskService.getReadingDynamicNote(
      grade,
      Math.floor(reading_dynamic_normalized),
    );
  }

  getInsertionsNotes(grade, num_insertions) {
    return this.taskService.getInsertionsNotes(
      grade,
      Math.floor(num_insertions),
    );
  }

  getDeletionsNote(grade, num_deletions) {
    return this.taskService.getDeletionsNote(grade, Math.floor(num_deletions));
  }

  getSubstitutionsNote(grade, num_substitutions) {
    return this.taskService.getSubstitutionsNote(
      grade,
      Math.floor(num_substitutions),
    );
  }

  getReadingErrorNote(grade, reading_errors_score) {
    return this.taskService.getReadingErrorNote(
      grade,
      Math.floor(reading_errors_score),
    );
  }

  getReadingSpeedNote(grade, reading_speed_words_normalized) {
    return this.taskService.getReadingSpeedNote(
      grade,
      Math.floor(reading_speed_words_normalized),
    );
  }

  getInitialsFromName(firstname, lastname) {
    return firstname.slice(0, 1) + lastname.slice(0, 1);
  }

  getTasksByStatus(status) {
    let tasks = this.tasks.filter((item) => {
      return item['status'] == status && item['type'] != 'self';
    });
    switch (status) {
      case 'finished':
        //console.log(this.notifications)
        if (tasks.length > 0) {
          this.finishedTasks = tasks.filter((item) => {
            return (
              item['type'] != 'diagnostic-pre' &&
              item['type'] != 'diagnostic-mid' &&
              item['type'] != 'diagnostic-post'
            );
          });
          const updatedTask = this.finishedTasks.map((taskItem) => {
            const matchingNotification = this.notifications?.find(
              (notification) => notification?._task?._id === taskItem._id,
            );
            if (matchingNotification) {
              taskItem['_notification'] = matchingNotification;
            }
            return taskItem;
          });
          return updatedTask.sort(
            (a, b) =>
              new Date(b?.completed_at).getTime() -
              new Date(a?.completed_at).getTime(),
          );
        }
        break;
      case 'active':
        if (tasks.length > 0) {
          const updatedTask = tasks
            .filter((item) => {
              return (
                item['type'] !== 'diagnostic-pre' &&
                item['type'] !== 'diagnostic-mid' &&
                item['type'] !== 'diagnostic-post'
              );
            })
            .filter((item) => {
              if (item['mode'] === 'multiple') {
                return item;
              } else if (item['type'] === 'autonomous-placement') {
                return item;
              } else {
                const deadline = moment(item?.deadline);
                const now = moment(); // Aktuelles Datum und Uhrzeit
                const deadlineWithinFourWeeks = deadline.isBetween(
                  now,
                  now.clone().add(28, 'days'),
                  null,
                  '[]',
                );
                return deadlineWithinFourWeeks;
              }
            });

          return updatedTask.sort((a, b) => {
            if (a?.deadline < b?.deadline) {
              return -1;
            }
          });
        }
        break;
      case 'expired':
        if (tasks.length > 0) {
          const updatedTask = tasks.filter((item) => {
            return (
              item['type'] != 'diagnostic-pre' &&
              item['type'] != 'diagnostic-mid' &&
              item['type'] != 'diagnostic-post'
            );
          });
          return updatedTask.sort((a, b) => {
            if (a?.deadline < b?.deadline) {
              return -1;
            }
          });
        }
        break;
      default:
        break;
    }
    return tasks;
  }

  getSalutation(teacher) {
    if (teacher.salutation == 'divers' || teacher.salutation == 'Divers') {
      return teacher.firstname;
    } else {
      return teacher.salutation;
    }
  }

  getselfingTasks() {
    let tasks = this.tasks.filter((item) => {
      return item['status'] == 'finished' && item['type'] == 'self';
    });
    return tasks.sort((a, b) => {
      if (a?.completed_at > b?.completed_at) {
        return -1;
      }
    });
  }

  taskDeadline(deadline) {
    return (deadline = formatDate(deadline, 'yyyy-MM-dd', 'en'));
  }

  calculateReadTime(task) {
    return this.taskService.calculateSplitTaskTotal(task);
  }

  generatePercentForContinousTask(tasks: any[]) {
    return this.taskService.generatePercentForContinousTask(tasks);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeTab?.currentValue == 'isActive') {
      this.isActive = true;
      this.isExpired = false;
      this.isFinished = false;
      this.isselfing = false;
    } else if (changes.activeTab?.currentValue == 'isFinished') {
      this.isActive = false;
      this.isExpired = false;
      this.isFinished = true;
      this.isselfing = false;
    } else if (changes.activeTab?.currentValue == 'isExpired') {
      this.isActive = false;
      this.isExpired = true;
      this.isFinished = false;
      this.isselfing = false;
    } else if (changes.activeTab?.currentValue == 'isselfing') {
      this.isActive = false;
      this.isExpired = false;
      this.isFinished = false;
      this.isselfing = true;
    }
  }

  replaceHtmlContent(string) {
    return this.taskService.formatHTMLContent(string);
  }

  startTask(task) {
    console.log(task.uuid);
    let dialogRef;
    if (task.type == 'time') {
      dialogRef = this.dialog.open(BookListComponent, {
        width: '100%',
        autoFocus: false,
        panelClass: 'books-overlay-panel',
        hasBackdrop: false,
        disableClose: true,
        data: {
          type: task.type,
          time: task.time,
          taskId: task._id,
          userTaskUuid: task.uuid,
        },
        backdropClass: 'books-overlay-panel-backdrop',
      });
      // dialogRef.disableClose = true;
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {});
    } else {
      let taskData = {
        title: 'Lies ' + task.wordcount + ' Wörter in diesem Buch.',
        type: task.type,
        book_isbn: task.book_isbn,
        taskId: task._id,
        userTaskUuid: task.uuid,
        book_name: task.book_name,
        range: task.range,
      };
      if (
        task.type == 'diagnostic-pre' ||
        task.type == 'diagnostic-mid' ||
        task.type == 'diagnostic-post'
      ) {
        taskData['time'] = task.time;
      }
      dialogRef = this.dialog.open(RecordRTCComponent, {
        width: '100%',
        autoFocus: false,
        panelClass: 'start-task-overlay-panel',
        hasBackdrop: false,
        disableClose: true,
        data: taskData,
        backdropClass: 'start-task-overlay-panel-backdrop',
      });
      // dialogRef.disableClose = true;
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {});
    }
  }

  generateDiagnosticTitle(type) {
    let title = '';
    switch (type) {
      case 'diagnostic-pre':
        title = 'Diagnose 1';
        break;
      case 'diagnostic-mid':
        title = 'Diagnose 2';
        break;
      case 'diagnostic-post':
        title = 'Diagnose 3';
        break;
      default:
        break;
    }
    return title;
  }

  getLevelNotes(level) {
    return this.taskService.getLevelNotes(level);
  }

  openNotificationList(notification, task): void {
    let dialogRef = this.dialog.open(NotificationListComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'notification-list-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'license-add-backdrop',
      data: {
        notifications: [notification],
        task: task,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {});
  }

  openRecordResult(task, sessionUuid): void {
    this.isLoadingResults(task?._id + sessionUuid);
    this.studentService
      .getStudentTaskReport(task?._id, sessionUuid)
      .pipe()
      .subscribe((res) => {
        this.isLoadingResults('');
        const taskResult = res?.sessionData;
        let dialogRef = this.dialog.open(RecordResultComponent, {
          width: '100%',
          autoFocus: false,
          panelClass: 'record-rtc-result-overlay-panel',
          disableClose: true,
          backdropClass: 'license-add-backdrop',
          data: {
            result: taskResult,
            isLoading: false,
            taskData: task,
          },
        });
        dialogRef.afterClosed().subscribe((res) => {
          this.notificationService
            .updateNotification(task?._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res) => {
              //console.log(res);
            });
        });
      });
  }

  isLoadingResults(id) {
    this.isLoading = id;
  }

  calculateDaysLeft(date) {
    let daysLeft = this.taskService.calculateDaysLeft(date);
    let text = '';
    if (daysLeft == 1) {
      text =
        this.translatedText?.student_task_list_next +
        ' ' +
        daysLeft +
        ' ' +
        this.translatedText?.student_task_list_day;
    } else {
      text =
        this.translatedText?.student_task_list_next +
        ' ' +
        daysLeft +
        ' ' +
        this.translatedText?.student_task_list_days;
    }
    return text;
  }

  openTaskPreview(taskId) {
    this.router.navigate(['/task-preview/' + taskId]);
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
