import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { MatChipsModule } from '@angular/material/chips';
import { BooksFilterComponent } from './books-filter.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { LoadingSpinnerModule } from 'src/app/helpers/loading-spinner/loading-spinner.module';
@NgModule({
  declarations: [BooksFilterComponent],
  imports: [
    BrowserModule,
    MaterialModule,
    MatChipsModule,
    MatButtonToggleModule,
    LoadingSpinnerModule,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [BooksFilterComponent],
})
export class BooksFilterModule {}
