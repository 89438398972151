<!-- <button mat-icon-button type="button" class="circle light task_close" [disabled]="isLoading" (click)="closeTeacherOverlay()" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button> -->
<app-non-school-register-stepper
  class="school_admin-stepper"
  #stepper
  [linear]="true"
  [translatedText]="translatedText"
  [stepValue]="selectedIndex"
  (selectionChange)="getActiveSelection($event)"
>
  <cdk-step [stepControl]="personalForm" #step1="cdkStep">
    <div class="row">
      <div class="col-md-12">
        <div class="school_admin-wrap">
          <div class="school_admin-content">
            <div class="training-task_create_headline">
              <h2 class="h2 h2--bold">
                {{ translatedText.non_school_registration_personal_data }}
              </h2>
            </div>
            <form [formGroup]="personalForm" #formDirective="ngForm">
              <div class="row">
                <div class="col-md-6">
                  <mat-label>{{ translatedText.non_school_registration_firstname }}</mat-label>
                  <mat-form-field>
                    <mat-label>{{ translatedText.non_school_registration_firstname }}</mat-label>
                    <input matInput formControlName="firstname" type="text" required />
                    <mat-error *ngIf="personalForm.get('firstname').hasError('required')">
                      {{ translatedText.non_school_registration_firstname_error }}
                    </mat-error>
                    <mat-error *ngIf="personalForm.get('firstname').hasError('hasDigits')">
                      {{ translatedText.non_school_registration_hasDigits_error }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-label>{{ translatedText.non_school_registration_lastname }}</mat-label>
                  <mat-form-field>
                    <mat-label>{{ translatedText.non_school_registration_lastname }}</mat-label>
                    <input matInput formControlName="lastname" type="text" required />
                    <mat-error *ngIf="personalForm.get('lastname').hasError('required')">
                      {{ translatedText.non_school_registration_lastname_error }}
                    </mat-error>
                    <mat-error *ngIf="personalForm.get('lastname').hasError('hasDigits')">
                      {{ translatedText.non_school_registration_hasDigits_error }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  <mat-label>{{ translatedText.non_school_registration_email }}</mat-label>
                  <mat-form-field>
                    <mat-label>{{ translatedText.non_school_registration_email }}</mat-label>
                    <input matInput formControlName="email" type="text" required />
                    <mat-error *ngIf="personalForm.get('email').hasError('required')">
                      <!-- Please enter email address. -->
                      {{ translatedText.non_school_registration_email_error }}
                    </mat-error>
                    <mat-error *ngIf="personalForm.get('email').hasError('invalidEmail')">
                      <!-- Please enter valid email address. -->
                      {{ translatedText.non_school_registration_email_error_valid }}
                    </mat-error>
                    <mat-error *ngIf="personalForm.get('email').hasError('emailTaken')">
                      <!-- Email is taken -->
                      {{ translatedText.non_school_registration_email_error_used }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  <mat-label>{{ translatedText.non_school_registration_phone }}</mat-label>
                  <mat-form-field class="tel_form-field" floatLabel="never">
                    <input matInput formControlName="tel" type="text" required />
                    <mat-form-field class="country-selection">
                      <!-- Select dropdown with integrated input -->
                      <mat-icon
                        *ngIf="!isSelectedCountry"
                        class="placeholder-flag"
                        svgIcon="flags:{{ personalForm.get('selectedCountry').value.slug }}"
                      ></mat-icon>
                      <mat-select
                        formControlName="selectedCountry"
                        (selectionChange)="onCountryChange($event)"
                        class="phone-prefix"
                      >
                        <mat-select-trigger>
                          <mat-icon svgIcon="flags:{{ personalForm.get('selectedCountry').value.slug }}"></mat-icon>
                        </mat-select-trigger>
                        <!-- Integrated input for filtering -->
                        <div class="country-search-wrap">
                          <mat-icon svgIcon="streamline_outlined:search"></mat-icon>
                          <input
                            class="country-search"
                            matInput
                            placeholder="Suche nach einem Land"
                            (input)="filterCountries($event.target.value)"
                          />
                        </div>
                        <!-- Filtered options -->
                        <mat-option *ngFor="let country of filteredCountries" [value]="country">
                          <mat-icon svgIcon="flags:{{ country.slug }}"></mat-icon> {{ country.name }} ({{
                            country.code
                          }})
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </mat-form-field>
                </div>
              </div>
              <!-- <div class="personal-info">
                        <div class="row">
                          <div class="col-md-12 form_header-headline">
                            <p class="paragraph paragraph--bold paragraph--m">License Informationen</p>
                          </div>
                          <div class="col-md-12">
                            <mat-label>License code</mat-label>
                            <mat-form-field>
                              <input matInput formControlName="license" type="text" placeholder="License" required>
                              <mat-error *ngIf="personalForm.get('firstname').hasError('required')">
                                {{translatedText.non_school_registration_firstname_error}}
                              </mat-error> 
                              <mat-error *ngIf="personalForm.get('firstname').hasError('email')">
                                Bitte tragen Sie eine E-Mail-{{translatedText.non_school_registration_org_address}} ein
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                      </div> -->

              <div class="training-task_select_button">
                <button
                  [disabled]="personalForm.invalid"
                  mat-flat-button
                  color="primary"
                  class="mat-flat-button--full"
                  (click)="EmailAvailabilty()"
                >
                  {{ translatedText.non_school_registration_next }}
                </button>
              </div>
            </form>
          </div>
          <img [src]="'assets/' + environment.whitelabel.logo" class="logo" alt="" />
        </div>
      </div>
    </div>
  </cdk-step>
  <!-- organisation Data -->
  <cdk-step [stepControl]="organisationForm" #step1="cdkStep">
    <div class="row">
      <div class="col-md-12">
        <div class="school_admin-wrap">
          <div class="school_admin-content">
            <div class="training-task_create_headline">
              <h2 class="h2 h2--bold">
                {{ translatedText.non_school_registration_org_data }}
              </h2>
            </div>

            <form [formGroup]="organisationForm" #formDirective="ngForm">
              <p class="paragraph paragraph--bold title">
                {{ translatedText.non_school_registration_org_data_text }}
              </p>
              <div class="row">
                <div class="col-md-6">
                  <mat-label>{{ translatedText.non_school_registration_org_type }}</mat-label>
                  <mat-form-field>
                    <mat-select
                      formControlName="organisationType"
                      type="role"
                      disableOptionCentering
                      panelClass="grade-select"
                      placeholder="{{ translatedText.non_school_registration_select }}"
                    >
                      <mat-option value="Private person">{{
                        translatedText.non_school_registration_private
                      }}</mat-option>
                      <mat-option value="Press">{{ translatedText.non_school_registration_press }}</mat-option>
                      <mat-option value="Media">{{ translatedText.non_school_registration_media }}</mat-option>
                      <mat-option value="University">{{ translatedText.non_school_registration_school }}</mat-option>
                      <mat-option value="Other educational institution">{{
                        translatedText.non_school_registration_other_education
                      }}</mat-option>
                      <mat-option value="Sonstiges">{{ translatedText.non_school_registration_other }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="organisationForm.get('organisationType').hasError('required')">
                      <!-- Please choose a class. -->
                      {{ translatedText.non_school_registration_org_type_error }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-label>{{ translatedText.non_school_registration_org_name }}</mat-label>
                  <mat-form-field>
                    <input
                      matInput
                      formControlName="organisationName"
                      type="text"
                      placeholder="{{ translatedText.non_school_registration_org_name }}"
                      required
                    />
                  </mat-form-field>
                </div>
              </div>

              <p class="paragraph paragraph--bold title">
                {{ translatedText.non_school_registration_org_address }}
              </p>
              <div class="row">
                <div class="col-md-12">
                  <mat-label>{{ translatedText.non_school_registration_org_address }}</mat-label>
                  <mat-form-field>
                    <input
                      matInput
                      formControlName="organisationAddress"
                      type="text"
                      placeholder="{{ translatedText.non_school_registration_org_street }}"
                      required
                    />
                  </mat-form-field>
                </div>

                <div class="col-md-8">
                  <mat-label>{{ translatedText.non_school_registration_org_city }}</mat-label>
                  <mat-form-field>
                    <input
                      matInput
                      formControlName="organisationCity"
                      type="text"
                      placeholder="{{ translatedText.non_school_registration_org_city }}"
                      required
                    />
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <mat-label>{{ translatedText.non_school_registration_org_zip }}</mat-label>
                  <mat-form-field>
                    <input matInput formControlName="organisationZip" type="text" placeholder="000000" required />
                  </mat-form-field>
                </div>

                <div class="col-md-12">
                  <mat-label>{{ translatedText.non_school_registration_org_state }}</mat-label>
                  <mat-form-field>
                    <mat-select
                      formControlName="organisationState"
                      type="role"
                      disableOptionCentering
                      panelClass="grade-select"
                    >
                      <mat-option value="BW">Baden-Württemberg</mat-option>
                      <mat-option value="BY">Bayern</mat-option>
                      <mat-option value="BE">Berlin</mat-option>
                      <mat-option value="BB">Brandenburg</mat-option>
                      <mat-option value="HB">Bremen</mat-option>
                      <mat-option value="HH">Hamburg</mat-option>
                      <mat-option value="HE">Hessen</mat-option>
                      <mat-option value="MV">Mecklenburg-Vorpommern</mat-option>
                      <mat-option value="NI">Niedersachsen</mat-option>
                      <mat-option value="NW">Nordrhein-Westfalen</mat-option>
                      <mat-option value="RP">Rheinland-Pfalz</mat-option>
                      <mat-option value="SL">Saarland</mat-option>
                      <mat-option value="SN">Sachsen</mat-option>
                      <mat-option value="ST">Sachsen-Anhalt</mat-option>
                      <mat-option value="SH">Schleswig-Holstein</mat-option>
                      <mat-option value="TH">Thüringen</mat-option>
                    </mat-select>
                    <mat-error *ngIf="organisationForm.get('organisationState').hasError('required')">
                      {{ translatedText.non_school_registration_org_state_error }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="training-task_select_button">
                <button
                  [disabled]="organisationForm.invalid"
                  mat-flat-button
                  color="primary"
                  class="mat-flat-button--full"
                  cdkStepperNext
                >
                  {{ translatedText.non_school_registration_next }}
                </button>
              </div>
            </form>
          </div>
          <img [src]="'assets/' + environment.whitelabel.logo" class="logo" alt="" />
        </div>
      </div>
    </div>
  </cdk-step>

  <cdk-step [stepControl]="purposeForm">
    <div class="row">
      <div class="col-md-12">
        <div class="school_admin-wrap">
          <div class="school_admin-content">
            <div class="training-task_create_headline">
              <h2 class="h2 h2--bold">
                {{ translatedText.non_school_registration_purpose }}
              </h2>
              <p class="paragraph paragraph--bold">
                {{ translatedText.non_school_registration_purpose_text }}
              </p>
            </div>

            <form [formGroup]="purposeForm" #formDirective="ngForm">
              <div class="row">
                <div class="col-md-12">
                  <mat-label>{{ translatedText.non_school_registration_purpose }}</mat-label>
                  <mat-form-field>
                    <mat-select
                      formControlName="purpose"
                      type="role"
                      disableOptionCentering
                      panelClass="grade-select"
                      (selectionChange)="onPurposeChange($event)"
                    >
                      <mat-option value="{{ translatedText.non_school_registration_test }}">{{
                        translatedText.non_school_registration_test
                      }}</mat-option>
                      <mat-option value="{{ translatedText.non_school_registration_practice }}">{{
                        translatedText.non_school_registration_practice
                      }}</mat-option>
                      <mat-option value="{{ translatedText.non_school_registration_press_text }}">{{
                        translatedText.non_school_registration_press_text
                      }}</mat-option>
                      <mat-option value="{{ translatedText.non_school_registration_other_text }}">{{
                        translatedText.non_school_registration_other_text
                      }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="purposeForm.get('purpose').hasError('required')">
                      Bitte wählen Sie ein Verwendungszweck auswählen
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field
                    *ngIf="
                      purposeForm.get('purpose').value === 'Other...' ||
                      purposeForm.get('purpose').value === 'Sonstiges...'
                    "
                  >
                    <textarea
                      matInput
                      formControlName="purposeOther"
                      placeholder="{{ translatedText.non_school_registration_purpose_desc }}"
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="training-task_select_button">
                <button
                  [disabled]="purposeForm.invalid"
                  mat-flat-button
                  color="primary"
                  class="mat-flat-button--full"
                  cdkStepperNext
                >
                  {{ translatedText.non_school_registration_next }}
                </button>
              </div>
            </form>
          </div>
          <img [src]="'assets/' + environment.whitelabel.logo" class="logo" alt="" />
        </div>
      </div>
    </div>
  </cdk-step>

  <cdk-step [stepControl]="licenseForm">
    <div class="row">
      <div class="col-md-12">
        <div class="school_admin-wrap">
          <div class="school_admin-content">
            <div class="training-task_create_headline">
              <h2 class="h2 h2--bold">
                {{ translatedText.non_school_registration_license }}
              </h2>
              <div class="license-info">
                <mat-icon svgIcon="streamline_outlined:info-empty"></mat-icon>
                <p class="paragraph">
                  {{ translatedText.non_school_registration_license_text }}
                </p>
              </div>
            </div>

            <form [formGroup]="licenseForm" #formDirective="ngForm">
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field
                    [ngClass]="{
                      'mat-form-field-invalid': licenseError,
                      'ng-valid': !licenseError
                    }"
                  >
                    <input
                      matInput
                      formControlName="license"
                      type="text"
                      placeholder="{{ translatedText.non_school_registration_placeholder }}"
                      required
                    />
                    <mat-error *ngIf="licenseForm.get('license').hasError('required')">
                      {{ translatedText.non_school_registration_license_error }}
                    </mat-error>
                  </mat-form-field>
                  <p class="paragraph paragraph--s error" *ngIf="licenseError">
                    {{ translatedText.non_school_registration_license_error_used }}
                  </p>
                </div>
              </div>

              <div class="training-task_select_button">
                <button
                  [disabled]="licenseForm.invalid || isLoading"
                  mat-flat-button
                  color="primary"
                  class="mat-flat-button--full"
                  (click)="checkLicense(licenseForm.get('license').value)"
                >
                  {{ translatedText.non_school_registration_next }}
                </button>
              </div>
            </form>
          </div>
          <img [src]="'assets/' + environment.whitelabel.logo" class="logo" alt="" />
        </div>
      </div>
    </div>
  </cdk-step>
  <cdk-step [stepControl]="lastStep">
    <div class="row">
      <div class="col-md-12">
        <div class="school_admin-wrap">
          <div class="school_admin-content last-step">
            <img src="../../../../assets/sign-up.svg" alt="sign-up" />
            <h2 class="h2 h2--bold">
              {{ translatedText.non_school_registration_email_sent }}
              {{ maskEmail(personalForm.get('email').value) }}
              {{ translatedText.non_school_registration_email_sent_2 }}
            </h2>
            <p class="paragraph paragraph--xl">
              {{ translatedText.non_school_registration_email_sent_3 }}
            </p>
            <div class="training-task_select_button resend-email">
              <button mat-flat-button color="primary" class="mat-flat-button--full" (click)="resendRegistrationEmail()">
                {{ translatedText.non_school_registration_email_resend }}
              </button>
            </div>
          </div>
          <br />
          <br />
          <img [src]="'assets/' + environment.whitelabel.logo" class="logo" alt="" />
        </div>
      </div>
    </div>
  </cdk-step>
</app-non-school-register-stepper>
