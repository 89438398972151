import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { TermsOfUseComponent } from './terms-of-use.component';

@NgModule({
  declarations: [TermsOfUseComponent],
  imports: [BrowserModule, MaterialModule],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [TermsOfUseComponent],
})
export class TermsOfUseModule {}
