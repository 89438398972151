<div class="">
  <div class="">
    <div class="teacher-content-container">
      <div class="class_detail_header">
        <div class="class_detail_header_left">
          <div class="inline_text">
            <div class="report_backButton">
              <button mat-fab type="button" (click)="navigateBack()" class="backButton class_detail_button" matSuffix>
                <mat-icon svgIcon="streamline_outlined:left"></mat-icon>
              </button>
            </div>
            <h3 class="h3 h3--bold">{{ groupname }} - {{ school_year | slice : 2 }}</h3>
          </div>

          <div class="teacher_tabs_header_buttons">
            <mat-button-toggle-group
              #activeLicenseViewValue="matButtonToggleGroup"
              [value]="selectedLicenseView"
              (change)="onSelectedLicenseViewChange(activeLicenseViewValue.value)"
              [disableRipple]="true"
            >
              <mat-button-toggle value="license-2">
                <mat-icon class="hidden-mobile" svgIcon="streamline_outlined:lightning"></mat-icon>
                {{ translatedText.class_detail_training }}
              </mat-button-toggle>
              <mat-button-toggle value="license-1">
                <mat-icon class="hidden-mobile" svgIcon="streamline_outlined:archery"></mat-icon>
                {{ translatedText.class_detail_diagnostic }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <ng-container *ngIf="checkStudentsGender() == 0">
          <button
            mat-flat-button
            class="class_detail_download_btn"
            color="unset"
            type="button"
            (click)="openGroupRegeneratePDFDialog()"
            matTooltip="{{ translatedText.class_detail_download_pdf }}"
            *ngIf="groupname"
          >
            <mat-icon class="icon_position" svgIcon="streamline_filled:download_class_2"></mat-icon>
            <span class="button_add_class hidden-mobile">{{ translatedText.class_detail_download_pdf }} </span>
          </button>
        </ng-container>
      </div>

      <div class="row" *ngIf="checkStudentsGender() > 0">
        <div class="col-md-12">
          <div class="create_group-box edit-group">
            <div class="create_group-box-content">
              <h3 class="h3 h3--bold">
                {{ translatedText.class_detail_enter_data }}
              </h3>
              <p class="paragraph paragraph--l">
                {{ translatedText.class_detail_enter_data_text }}
              </p>
              <button mat-flat-button color="primary-inversion" (click)="editStudentsOverlay()" type="button">
                {{ translatedText.class_detail_enter_data_button }}
              </button>
            </div>
            <div class="edit-group-box-img">
              <img class="create_group-img" src="../../../assets/edit-group.svg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <!-- Diagnose chart -->
      <div class="row" [hidden]="selectedLicenseView != 'license-1'">
        <div class="col-md-12 col-sm-12">
          <div class="class_chart">
            <div class="class_chart_header">
              <div class="group_result_chart position_left">
                <p class="paragraph">
                  {{ translatedText.class_detail_detail_analysis }}
                </p>
                <!-- <div class="chart_toggle">
                                        <input type="checkbox" (change)="chartToggleChange($event)" id="switch" /><label for="switch"></label>
                                       <p class="paragraph paragraph--bold paragraph--s">Testvergleich</p> 
                                    </div> -->
              </div>
              <!-- <mat-form-field class="group_result_chart position_right">
                                    <mat-select (valueChange)="selectedResult($event)" value="half_year">
                                      <mat-option value="last_week">Letzte 7 Tage</mat-option>
                                      <mat-option value="last_month">{{translatedText.class_detail_last_month_2}}</mat-option>
                                      <mat-option value="half_year">{{translatedText.class_detail_last_half_year_2}}</mat-option>
                                    </mat-select>
                                </mat-form-field> -->
            </div>
            <!-- <p class="paragraph paragraph--s">Gesamtbewertung 19%</p> -->
            <ng-container *ngIf="!showComboChart && showLineChart">
              <h1 class="h1">{{ currentValue }}</h1>
            </ng-container>
            <ng-container *ngIf="showComboChart && showLineChart">
              <div class="chart_container">
                <combo-chart-component
                  [scheme]="comboBarScheme"
                  [colorSchemeLine]="lineChartScheme"
                  [results]="barChart"
                  [animations]="animations"
                  [lineChart]="lineChartData"
                  [gradient]="gradient"
                  [xAxis]="showXAxis"
                  [yAxis]="showYAxis"
                  [legend]="showLegend"
                  [legendTitle]="legendTitle"
                  [legendPosition]="legendPosition"
                  [showGridLines]="showGridLines"
                  [showXAxisLabel]="showXAxisLabel"
                  [showYAxisLabel]="showYAxisLabel"
                  [showRightYAxisLabel]="showYAxisLabel"
                  [yAxisLabel]="yAxisLabel"
                  [yAxisLabelRight]="yAxisLabelRight"
                >
                </combo-chart-component>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- Training chart -->
      <div class="row" [hidden]="selectedLicenseView != 'license-2'">
        <div class="col-md-12 col-sm-12">
          <div class="class_chart">
            <div class="set_inline">
              <mat-form-field class="group_result_chart transparent-input" appearance="fill">
                <mat-select
                  hideSingleSelectionIndicator
                  (valueChange)="selectedChartSettings($event)"
                  value="reading_speed_correct_words_per_minute"
                >
                  <mat-option value="reading_speed_correct_words_per_minute">{{
                    translatedText.class_detail_wcpm
                  }}</mat-option>
                  <mat-option value="reading_combined_score">{{
                    translatedText.class_detail_reading_points
                  }}</mat-option>
                  <mat-option value="reading_errors_score">{{ translatedText.class_detail_reading_errors }}</mat-option>
                  <mat-option value="reading_speed_words">{{ translatedText.class_detail_wpm }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="group_result_chart position_right" appearance="fill">
                <mat-select (valueChange)="selectedResult($event)" [value]="currentChartPeriod">
                  <mat-option value="last_week">{{ translatedText.class_detail_last_week_2 }}</mat-option>
                  <mat-option value="last_month">{{ translatedText.class_detail_last_month_2 }}</mat-option>
                  <mat-option value="half_year">{{ translatedText.class_detail_last_half_year_2 }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- <p class="paragraph-small paragraph-small">Gesamtbewertung 19%</p> -->
            <ng-container *ngIf="!showProgressChart">
              <h1 class="h1">{{ currentValue }}</h1>
            </ng-container>
            <ng-container class=" " *ngIf="showProgressChart">
              <ngx-charts-line-chart
                [view]="view"
                [scheme]="colorScheme"
                [legend]="legend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxis]="xAxis"
                [legendTitle]="legendTitle"
                [legendPosition]="legendPosition"
                [timeline]="timeline"
                [results]="progressChartData"
                [curve]="linearCurve"
              >
              </ngx-charts-line-chart>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="class_detail_table">
        <div class="class_detail_table_wrap">
          <div class="set_inline class_table_head">
            <p class="paragraph paragraph--bold">
              {{ translatedText.class_detail_class_overview }} ({{ students?.length }})
            </p>
            <!-- <button mat-flat-button color="unset" type="button" (click)="addStudentToClass($event, id)" *ngIf="groupname">
                            <mat-icon svgIcon="streamline_filled:add"></mat-icon>Schüler:in hinzufügen
                        </button> -->
          </div>
          <ng-container *ngIf="selectedLicenseView == 'license-1'">
            <div class="table-wrapper">
              <div class="table-container">
                <table
                  mat-table
                  #sortDiagnostic="matSort"
                  [dataSource]="dataSourceDiagnostic"
                  matSort
                  matSortDisableClear
                  matSortDirection="desc"
                  class="mat-elevation-z8"
                >
                  <ng-container matColumnDef="username">
                    <th mat-sort-header="username" mat-header-cell *matHeaderCellDef>
                      <span class="caption">Lernende</span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="table-username">
                        <span class="paragraph paragraph--s paragraph--bold username-with-avatar">
                          <span><img *ngIf="element?.avatarUrl" src="{{ element?.avatarUrl }}" /></span
                          >{{ element.username }}
                        </span>
                        <mat-icon
                          class="group-warning-circle"
                          *ngIf="element?.gender == ''"
                          svgIcon="streamline_outlined:warning-circle"
                        ></mat-icon>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="reading_combined_score">
                    <th
                      mat-sort-header="reading_combined_score"
                      mat-header-cell
                      *matHeaderCellDef
                      class="mat-column-align--center"
                    >
                      <span
                        class="caption"
                        matTooltip="{{ translatedText.class_detail_reading_points_tooltip }}"
                        matTooltipPosition="above"
                        >{{ translatedText.class_detail_reading_points }}</span
                      >
                    </th>
                    <td mat-cell *matCellDef="let element" class="mat-column-align--center">
                      <span
                        class="paragraph"
                        *ngIf="
                          combineDiagnosticScore(
                            element?.numberOfTasks.diagnostic['monthly'],
                            element?.diagnose_score,
                            'reading_combined_score'
                          ) >= 0
                        "
                        [countUp]="
                          combineDiagnosticScore(
                            element?.numberOfTasks.diagnostic['monthly'],
                            element?.diagnose_score,
                            'reading_combined_score'
                          )
                        "
                        >{{
                          combineDiagnosticScore(
                            element?.numberOfTasks.diagnostic['monthly'],
                            element?.diagnose_score,
                            'reading_combined_score'
                          )
                        }}</span
                      >
                      <span
                        class="paragraph"
                        *ngIf="
                          combineDiagnosticScore(
                            element?.numberOfTasks.diagnostic['monthly'],
                            element?.diagnose_score,
                            'reading_combined_score'
                          ) == '-'
                        "
                        >-</span
                      >
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="reading_errors_score">
                    <th
                      mat-sort-header="reading_errors_score"
                      mat-header-cell
                      *matHeaderCellDef
                      class="mat-column-align--center"
                    >
                      <span
                        class="caption"
                        matTooltip="{{ translatedText.class_detail_reading_errors_tooltip }}"
                        matTooltipPosition="above"
                        >{{ translatedText.class_detail_reading_errors }}</span
                      >
                    </th>
                    <td mat-cell *matCellDef="let element" class="mat-column-align--center">
                      <span
                        class="paragraph"
                        *ngIf="
                          combineDiagnosticScore(
                            element?.numberOfTasks.diagnostic['monthly'],
                            element?.diagnose_score,
                            'reading_errors_score'
                          ) >= 0
                        "
                        [countUp]="
                          combineDiagnosticScore(
                            element?.numberOfTasks.diagnostic['monthly'],
                            element?.diagnose_score,
                            'reading_errors_score'
                          )
                        "
                        >{{
                          combineDiagnosticScore(
                            element?.numberOfTasks.diagnostic['monthly'],
                            element?.diagnose_score,
                            'reading_errors_score'
                          )
                        }}</span
                      >
                      <span
                        class="paragraph"
                        *ngIf="
                          combineDiagnosticScore(
                            element?.numberOfTasks.diagnostic['monthly'],
                            element?.diagnose_score,
                            'reading_errors_score'
                          ) == '-'
                        "
                        >-</span
                      >
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="reading_speed_words">
                    <th
                      mat-sort-header="reading_speed_words"
                      mat-header-cell
                      *matHeaderCellDef
                      class="mat-column-align--center"
                    >
                      <span
                        class="caption"
                        matTooltip="{{ translatedText.class_detail_reading_speed_tooltip }}"
                        matTooltipPosition="above"
                        >{{ translatedText.class_detail_wpm }}</span
                      >
                    </th>
                    <td mat-cell *matCellDef="let element" class="mat-column-align--center">
                      <span
                        class="paragraph"
                        *ngIf="
                          combineDiagnosticScore(
                            element?.numberOfTasks.diagnostic['monthly'],
                            element?.diagnose_score,
                            'reading_speed_words'
                          ) >= 0
                        "
                        [countUp]="
                          combineDiagnosticScore(
                            element?.numberOfTasks.diagnostic['monthly'],
                            element?.diagnose_score,
                            'reading_speed_words'
                          )
                        "
                        >{{
                          combineDiagnosticScore(
                            element?.numberOfTasks.diagnostic['monthly'],
                            element?.diagnose_score,
                            'reading_speed_words'
                          )
                        }}</span
                      >
                      <span
                        class="paragraph"
                        *ngIf="
                          combineDiagnosticScore(
                            element?.numberOfTasks.diagnostic['monthly'],
                            element?.diagnose_score,
                            'reading_speed_words'
                          ) == '-'
                        "
                        >-</span
                      >
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="reading_speed_correct_words_per_minute">
                    <th
                      mat-sort-header="reading_speed_correct_words_per_minute"
                      mat-header-cell
                      *matHeaderCellDef
                      class="mat-column-align--center"
                    >
                      <span
                        class="caption"
                        matTooltip="{{ translatedText.class_detail_wcpm_tooltip }}"
                        matTooltipPosition="above"
                        >{{ translatedText.class_detail_wcpm }}</span
                      >
                    </th>
                    <td mat-cell *matCellDef="let element" class="mat-column-align--center">
                      <span
                        class="paragraph"
                        *ngIf="
                          combineDiagnosticScore(
                            element?.numberOfTasks.diagnostic['monthly'],
                            element?.diagnose_score,
                            'reading_speed_correct_words_per_minute'
                          ) >= 0
                        "
                        [countUp]="
                          combineDiagnosticScore(
                            element?.numberOfTasks.diagnostic['monthly'],
                            element?.diagnose_score,
                            'reading_speed_correct_words_per_minute'
                          )
                        "
                        >{{
                          combineDiagnosticScore(
                            element?.numberOfTasks.diagnostic['monthly'],
                            element?.diagnose_score,
                            'reading_speed_correct_words_per_minute'
                          )
                        }}</span
                      >
                      <span
                        class="paragraph"
                        *ngIf="
                          combineDiagnosticScore(
                            element?.numberOfTasks.diagnostic['monthly'],
                            element?.diagnose_score,
                            'reading_speed_correct_words_per_minute'
                          ) == '-'
                        "
                        >-</span
                      >
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="diagnostic">
                    <th mat-sort-header="diagnostic" mat-header-cell *matHeaderCellDef class="mat-column-align--center">
                      <span
                        class="caption"
                        matTooltip="{{ translatedText.class_detail_level_tooltip }}"
                        matTooltipPosition="above"
                        >{{ translatedText.class_detail_level }}</span
                      >
                    </th>
                    <td mat-cell *matCellDef="let element" class="mat-column-align--center">
                      <span class="paragraph">{{ generateDiagnosticScore(element?.diagnostic) }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="numberOfTasks">
                    <th
                      mat-sort-header="numberOfTasks"
                      mat-header-cell
                      *matHeaderCellDef
                      class="mat-column-align--center"
                    >
                      <span
                        class="caption"
                        matTooltip="{{ translatedText.class_detail_number_of_tasks_tooltip }}"
                        matTooltipPosition="above"
                        >{{ translatedText.class_detail_number_of_tests }}</span
                      >
                    </th>
                    <td mat-cell *matCellDef="let element" class="mat-column-align--center">
                      <span
                        class="paragraph"
                        [countUp]="element?.numberOfTasks.diagnostic['monthly']"
                        *ngIf="element?.numberOfTasks.diagnostic['monthly'] >= 0"
                        >{{ element?.numberOfTasks.diagnostic['monthly'] }}</span
                      >
                      <span class="paragraph" *ngIf="element?.numberOfTasks.diagnostic['monthly'] == '-'">{{
                        element?.numberOfTasks.diagnostic['monthly']
                      }}</span>
                    </td>
                  </ng-container>

                  <!-- <ng-container matColumnDef="tasks_length">
                                        <th mat-sort-header="tasks_length" mat-header-cell *matHeaderCellDef class="mat-column-align--center">
                                            <span class="caption">Aufgaben</span>
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="mat-column-align--center">
                                            <span class="paragraph">0</span>
                                        </td>
                                    </ng-container> -->

                  <ng-container matColumnDef="button">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" class="table-see-profile">
                      <button
                        mat-mini-fab
                        class="not-filled"
                        aria-label="Report"
                        (click)="openStudentOverview(element._id)"
                        matTooltip="{{ translatedText.class_detail_show_profile }}"
                      >
                        <mat-icon svgIcon="streamline_filled:next"></mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedLicenseView == 'license-2'">
            <div class="inline_text">
              <p class="paragraph paragraph--s licenses">
                {{ translatedText.class_detail_development }}
              </p>
              <mat-form-field class="class_detail_result_dropdown" appearance="fill">
                <mat-select (valueChange)="selectedResultTable($event)" [value]="currentTablePeriod">
                  <mat-option value="daily">{{ translatedText.class_detail_last_week }}</mat-option>
                  <mat-option value="weekly">{{ translatedText.class_detail_last_month }}</mat-option>
                  <mat-option value="monthly">{{ translatedText.class_detail_last_half_year }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="table-container">
              <table
                mat-table
                #sortTraining="matSort"
                [dataSource]="dataSource"
                matSort
                matSortDisableClear
                matSortDirection="desc"
                class="mat-elevation-z8"
              >
                <ng-container matColumnDef="username">
                  <th mat-sort-header="username" mat-header-cell *matHeaderCellDef>
                    <span class="caption">Lernende</span>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span class="paragraph paragraph--s paragraph--bold username-with-avatar">
                      <span><img *ngIf="element?.avatarUrl" src="{{ element?.avatarUrl }}" /></span
                      >{{ element.username }}
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="reading_combined_score">
                  <th
                    mat-sort-header="reading_combined_score"
                    mat-header-cell
                    *matHeaderCellDef
                    class="mat-column-align--center"
                  >
                    <span
                      class="caption"
                      matTooltip="{{ translatedText.class_detail_reading_points_tooltip }}"
                      matTooltipPosition="above"
                      >{{ translatedText.class_detail_reading_points }}</span
                    >
                  </th>
                  <td mat-cell *matCellDef="let element" class="mat-column-align--center">
                    <span
                      class="paragraph"
                      [countUp]="element?.reading_score[currentTablePeriod]?.reading_combined_score"
                      *ngIf="element?.reading_score[currentTablePeriod]?.reading_combined_score >= 0"
                      >{{ element?.reading_score[currentTablePeriod]?.reading_combined_score }}</span
                    >
                    <span
                      class="paragraph"
                      *ngIf="element?.reading_score[currentTablePeriod]?.reading_combined_score == '-'"
                      >{{ element?.reading_score[currentTablePeriod].reading_combined_score }}</span
                    >
                  </td>
                </ng-container>

                <ng-container matColumnDef="reading_errors_score">
                  <th
                    mat-sort-header="reading_errors_score"
                    mat-header-cell
                    *matHeaderCellDef
                    class="mat-column-align--center"
                  >
                    <span
                      class="caption"
                      matTooltip="{{ translatedText.class_detail_reading_errors_tooltip }}"
                      matTooltipPosition="above"
                      >{{ translatedText.class_detail_reading_errors }}</span
                    >
                  </th>
                  <td mat-cell *matCellDef="let element" class="mat-column-align--center">
                    <span
                      class="paragraph"
                      *ngIf="element?.reading_score[currentTablePeriod]?.reading_errors_score >= 0"
                      [countUp]="element?.reading_score[currentTablePeriod]?.reading_errors_score"
                      >{{ element?.reading_score[currentTablePeriod]?.reading_errors_score }}</span
                    >
                    <span
                      class="paragraph"
                      *ngIf="element?.reading_score[currentTablePeriod]?.reading_errors_score == '-'"
                      >{{ element?.reading_score[currentTablePeriod]?.reading_errors_score }}</span
                    >
                  </td>
                </ng-container>

                <ng-container matColumnDef="reading_speed_words">
                  <th
                    mat-sort-header="reading_speed_words"
                    mat-header-cell
                    *matHeaderCellDef
                    class="mat-column-align--center"
                  >
                    <span
                      class="caption"
                      matTooltip="{{ translatedText.class_detail_reading_speed_tooltip }}"
                      matTooltipPosition="above"
                      >{{ translatedText.class_detail_wpm }}</span
                    >
                  </th>
                  <td mat-cell *matCellDef="let element" class="mat-column-align--center">
                    <span
                      class="paragraph"
                      *ngIf="element?.reading_score[currentTablePeriod]?.reading_speed_words >= 0"
                      [countUp]="element?.reading_score[currentTablePeriod]?.reading_speed_words"
                      >{{ element?.reading_score[currentTablePeriod]?.reading_speed_words }}</span
                    >
                    <span
                      class="paragraph"
                      *ngIf="element?.reading_score[currentTablePeriod]?.reading_speed_words == '-'"
                      >{{ element?.reading_score[currentTablePeriod]?.reading_speed_words }}</span
                    >
                  </td>
                </ng-container>

                <ng-container matColumnDef="reading_speed_correct_words_per_minute">
                  <th
                    mat-sort-header="reading_speed_correct_words_per_minute"
                    mat-header-cell
                    *matHeaderCellDef
                    class="mat-column-align--center"
                  >
                    <span
                      class="caption"
                      matTooltip="{{ translatedText.class_detail_wcpm_tooltip }}"
                      matTooltipPosition="above"
                      >{{ translatedText.class_detail_wcpm }}</span
                    >
                  </th>
                  <td mat-cell *matCellDef="let element" class="mat-column-align--center">
                    <span
                      class="paragraph"
                      *ngIf="element?.reading_score[currentTablePeriod]?.reading_speed_correct_words_per_minute >= 0"
                      [countUp]="element?.reading_score[currentTablePeriod]?.reading_speed_correct_words_per_minute"
                      >{{ element?.reading_score[currentTablePeriod]?.reading_speed_correct_words_per_minute }}</span
                    >
                    <span
                      class="paragraph"
                      *ngIf="element?.reading_score[currentTablePeriod]?.reading_speed_correct_words_per_minute == '-'"
                      >{{ element?.reading_score[currentTablePeriod]?.reading_speed_correct_words_per_minute }}</span
                    >
                  </td>
                </ng-container>

                <ng-container matColumnDef="diagnostic">
                  <th mat-sort-header="diagnostic" mat-header-cell *matHeaderCellDef class="mat-column-align--center">
                    <span
                      class="caption"
                      matTooltip="{{ translatedText.class_detail_level_tooltip }}"
                      matTooltipPosition="above"
                      >{{ translatedText.class_detail_level }}</span
                    >
                  </th>
                  <td mat-cell *matCellDef="let element" class="mat-column-align--center">
                    <span class="paragraph">{{ generateDiagnosticScore(element?.diagnostic) }}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="numberOfTasks">
                  <th
                    mat-sort-header="numberOfTasks"
                    mat-header-cell
                    *matHeaderCellDef
                    class="mat-column-align--center"
                  >
                    <span
                      class="caption"
                      matTooltip="Diese Spalte zeigt die Anzahl der erledigten Aufgaben für den jeweiligen Lernenden im ausgewählten Zeitraum."
                      matTooltipPosition="above"
                      >{{ translatedText.class_detail_number_of_tasks }}</span
                    >
                  </th>
                  <td mat-cell *matCellDef="let element" class="mat-column-align--center">
                    <span
                      class="paragraph"
                      [countUp]="element?.numberOfTasks.training[currentTablePeriod]"
                      *ngIf="element?.numberOfTasks.training[currentTablePeriod] >= 0"
                      >{{ element?.numberOfTasks.training[currentTablePeriod] }}</span
                    >
                    <span class="paragraph" *ngIf="element?.numberOfTasks.training[currentTablePeriod] == '-'">{{
                      element?.numberOfTasks.training[currentTablePeriod]
                    }}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="button">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element" class="mat-column-align--right">
                    <button
                      mat-mini-fab
                      class="not-filled"
                      aria-label="Report"
                      (click)="openStudentOverview(element._id)"
                      matTooltip="{{ translatedText.class_detail_show_profile }}"
                    >
                      <mat-icon class="table_next_icon" svgIcon="streamline_filled:next"></mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
