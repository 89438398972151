<!-- <div class="book-reader-wrap" [ngClass]="additionaClass" [ngClass]="{'book-reader-wrap--toc-open': tocStatusOpen }">
  <div id="book-reader-viewer" [ngClass]="'book-reader-viewer ' + paragraphClass">
    <div [className]="'book-reader-content book-reader-content--' + action" #viewer [@fadeAnimation]="toogleContentChange ? 'fadeIn' : 'fadeOut'"></div>
  </div>
  <div class="book-reader-toc-wrap" #tableofcontents [ngClass]="{'open': tocStatusOpen }">
    <div>
      <h2>Kapitelauswahl</h2>
      <ul class="ebookchapters"></ul>
    </div>
  </div>
</div> -->

<!-- <aside>
  <ng-container *ngIf="apiDSEFs$ | async as apiData">
    <ul class="space-y-4" *ngIf="apiData">
      <li *ngFor="let entry of apiData">
        <a
          (click)="selectApiData(entry.isbn)"
          class="cursor-pointer flex items-center p-1 text-base font-normal hover:bg-gray-100 dark:hover:bg-gray-700">
          <span class="ml-1">{{ entry.metadata.title }}</span>
        </a>
      </li>
    </ul>
  </ng-container>
</aside> -->

<div
  class="ds-reader-wrapper"
  [ngClass]="{ 'ds-reader-wrapper--set': enableSelectionMode }"
  [@fadeAnimation]="toogleContentChange ? 'fadeIn' : 'fadeOut'"
>
  <ng-container *ngIf="!rangeContent && data$ | async as data">
    <ng-container>
      <ds-reader
        *ngIf="content$ | async as content"
        [dsef]="data"
        [dsefContent]="content!"
        [markup]="getMarkup()"
        [fontSize]="customFontSize"
        [fontFamily]="customFontFamily"
        [letterSpacing]="customLetterSpacing"
        [wordSpacing]="customWordSpacing"
        [lineHeight]="customLineHeight"
        [isLoading]="isLoading"
        [infinityScroll]="useInfinityScroll"
        [markupStyles]="styles"
        [scrollTo]="scrollTo"
        [showMarkup]="showAIMarkup"
        [textSelectionMode]="enableSelectionMode"
        (newPage)="newPage($event)"
        [startWord]="startWord"
        [pickWord]="pickWord"
        [readMode]="readMode"
        (textSelected)="logSelection($event)"
        [showHyphenation]="showHyphenation"
        (contextSelection)="handleContextSelection($event)"
        [contextMenuTemplate]="contextContent"
        (textClicked)="logWord($event)"
        (linkClicked)="logLink($event)"
        [linkHandling]="enableLinks"
        [showMenu]="showMenu"
        (showMenuChanged)="onShowMenuChanged($event)"
      >
      </ds-reader>
    </ng-container>

    <ng-container *ngIf="showTableOfContents">
      <ds-table-of-content class="table_of_content" [bookChapters]="data" (chapterClicked)="onChapterClick($event)">
      </ds-table-of-content>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="rangeContent && rangeContent.content.length > 0">
    <ds-reader
      [dsef]="dataRange"
      [dsefContent]="rangeContent"
      [markup]="getMarkup()"
      [fontSize]="customFontSize"
      [fontFamily]="customFontFamily"
      [letterSpacing]="customLetterSpacing"
      [wordSpacing]="customWordSpacing"
      [lineHeight]="customLineHeight"
      [isLoading]="isLoading"
      [infinityScroll]="useInfinityScroll"
      [startWord]="startWord"
      [markupStyles]="styles"
      [showMarkup]="showAIMarkup"
      [textSelectionStyles]="customTextSelectionStyles"
      [textSelectionMode]="enableSelectionMode"
      (newPage)="newPage($event)"
      (textSelected)="logSelection($event)"
      (contextSelection)="handleContextSelection($event)"
      [contextMenuTemplate]="contextContent"
      (linkClicked)="logLink($event)"
      [linkHandling]="enableLinks"
      [showMenu]="showMenu"
      (showMenuChanged)="onShowMenuChanged($event)"
    >
    </ds-reader>
  </ng-container>
</div>

<ng-template #contextContent let-clickedWordData="clickedWordData">
  <div *ngIf="showAIMarkup">
    <button mat-menu-item (click)="clickedWordReportError($event)">{{ translatedText.book_view_report_error }}</button>
    <!-- <button mat-menu-item (click)="clickedWordEditError()" *ngIf="showEditMenuItem">Fehler bearbeiten</button> -->
  </div>
</ng-template>
