import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from 'src/app/providers/translation.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-automode-task-frequency',
  templateUrl: './automode-task-frequency.component.html',
  styleUrls: ['./automode-task-frequency.component.scss'],
})
export class AutomodeTaskFrequencyComponent implements OnInit {
  weekdaysSelected: string[] = [];
  isAutoMode: boolean = false;
  isManuelMode: boolean = false;
  selectedDayLength: number = 0;
  isHolidayTask: boolean = false;
  translatedText;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  @Output() weekdaysSelect = new EventEmitter();

  constructor(
    private translationService: TranslationService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  toggleChipSelection(chip: string): void {
    const index = this.weekdaysSelected.indexOf(chip);
    if (index >= 0) {
      this.weekdaysSelected.splice(index, 1);
    } else {
      if (this.weekdaysSelected.length < 3) {
        this.weekdaysSelected.push(chip);
      }
    }
    this.weekdaysSelect.emit({
      weekdays: this.weekdaysSelected,
      isHolidayTask: this.isHolidayTask,
    });
  }
  holidayTaskNeeded(event: any) {
    this.weekdaysSelect.emit({
      weekdays: this.weekdaysSelected,
      isHolidayTask: this.isHolidayTask,
    });
  }
}
