<div class="school-success">
  <button mat-mini-fab type="button" [mat-dialog-close]="'trial-welcome'" class="close-button close-button--right"
    matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <mat-icon svgIcon="streamline_outlined:success_tick_v2" class="icon-120"></mat-icon>
  <div class="success-info">
    <h3 class="h3 h3--bold">{{successMsg}}</h3>
  </div>
  <button mat-flat-button color="primary" type="button" (click)="closeOverlay()">{{ translatedText.success_overlay_ok }}</button>
</div>
