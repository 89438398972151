import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StudentService } from 'src/app/providers/student.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { ErrorSnackbarComponent } from '../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { NotificationService } from '../../providers/notification.service';
import { AdminService } from 'src/app/providers/admin.service';

export interface DialogData {
  message: string;
}

@Component({
  selector: 'app-notification-overlay',
  templateUrl: './notification-overlay.component.html',
  styleUrls: ['./notification-overlay.component.scss'],
})
export class NotificationOverlayComponent implements OnInit {
  notificationForm: FormGroup;
  recipient;
  taskId;
  taskSessionUuid;
  userId;
  student;
  teacherId;
  notifications;
  buttonText: string = 'Verschicken';
  updateNotification: boolean = false;
  placeholder;
  task;
  groupName;
  restServerUrl;
  notificationType;
  headline;
  isSuccessful: boolean = false;
  translatedText: any;
  notificationIndex: number = 0;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private notificationService: NotificationService,
    private studentService: StudentService,
    private translationService: TranslationService,
    private adminService: AdminService,
  ) {
    this.restServerUrl = environment.evolutionAPI;
    if (injectedData) {
      if (typeof injectedData['taskId'] !== 'undefined') {
        this.taskId = this.injectedData['taskId'];
      }
      if (typeof injectedData['recipient'] !== 'undefined') {
        this.recipient = this.injectedData['recipient'];
      }
      if (typeof injectedData['student'] !== 'undefined') {
        this.student = this.injectedData['student'];
      }
      if (typeof injectedData['task'] !== 'undefined') {
        this.task = this.injectedData['task'];
      }
      if (typeof injectedData['taskSessionUuid'] !== 'undefined') {
        this.taskSessionUuid = this.injectedData['taskSessionUuid'];
      }
      if (typeof injectedData['groupName'] !== 'undefined') {
        this.groupName = this.injectedData['groupName'];
      }
      if (typeof injectedData['type'] !== 'undefined') {
        this.notificationType = this.injectedData['type'];
      }
    }
  }

  ngOnInit() {
    this.setTranslation(this.route.snapshot.firstChild.data.translation);
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.setTranslation(translatedText);
      });

    this.userId = this.authService.getUserId();
    this.teacherId = this.authService.getTeacherId();
    console.log(this.recipient);

    if (this.recipient == undefined) {
      this.studentService
        .getStudentbyId(this.student?._id)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          this.recipient = res['_user'];
        });
    }

    this.notificationForm = this._formBuilder.group({
      message: ['', Validators.required],
    });

    this.notificationService.getNotificationToTask(this.taskId, 'task');
    this.notificationService
      .getTaskNotificationUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((notifications: any) => {
        this.notifications = notifications;
        let sessionUuidMatch = this.notifications.some(
          (notification) =>
            notification.task_session_uuid === this.taskSessionUuid,
        );
        if (this.notifications?.length > 0 || sessionUuidMatch) {
          this.buttonText = this.translatedText?.notification_overlay_save;
          this.updateNotification = true;
          if (sessionUuidMatch) {
            this.notificationIndex = Math.max(
              this.notifications.findIndex(
                (item) => item?.task_session_uuid == this.taskSessionUuid,
              ),
              0,
            );
          }
          this.notificationForm
            .get('message')
            .setValue(this.notifications[this.notificationIndex].message);
        } else {
          this.buttonText = this.translatedText?.notification_overlay_submit;
        }
      });
  }

  setTranslation(translatedText: any) {
    this.translatedText = translatedText;
    if (this.notificationType == 'teacher') {
      this.headline = this.translatedText?.notification_overlay_teacher_title;
      this.placeholder =
        this.translatedText?.notification_overlay_teacher_placeholder;
    } else {
      this.headline = this.translatedText?.notification_overlay_title;
      this.placeholder = this.translatedText?.notification_overlay_placeholder;
    }
  }

  closeOverlay() {
    this.dialog.closeAll();
  }

  submitForm(form: FormGroupDirective) {
    if (this.notificationForm.invalid) {
      return;
    }
    let notification = {
      message: this.notificationForm.value.message,
      recipient: [this.recipient],
      type: 'task',
      sender: this.userId,
      _task: this.taskId,
      task: this.task,
    };
    if (this.taskSessionUuid != undefined) {
      notification['task_session_uuid'] = this.taskSessionUuid;
    }
    if (!this.updateNotification) {
      this.notificationService
        .createNotification(notification)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (response: any) => {
            this.isSuccessful = true;

            this.notificationForm.patchValue({ message: '' });
          },
          (error: any) => {
            let message = this.translatedText?.notification_overlay_error;
            this.snackBar.openFromComponent(ErrorSnackbarComponent, {
              panelClass: 'snack-error',
              data: message,
              duration: 3000,
              horizontalPosition: 'left',
            });
            this.notificationForm.patchValue({ message: '' });
          },
        );
    } else {
      const notificationData = {
        notificationId: this.notifications[this.notificationIndex]._id,
        message: this.notificationForm.value.message,
      };
      this.notificationService
        .updateNotificationMessage(notificationData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          console.log(res);
          this.isSuccessful = true;
        });
    }
  }

  getGroupName(groups: any) {
    if (groups) {
      const recentGroup = this.adminService.sortGroups(groups);
      if (recentGroup) {
        return recentGroup;
      }
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
