<div class="teacher-content-container">
  <div>
    <div class="class_detail_info">
      <div class="class_detail_info_left">
        <div class="inline_text">
          <div class="report_backButton">
            <button mat-fab type="button" (click)="navigateBack()" class="backButton class_detail_button" matSuffix>
              <mat-icon svgIcon="streamline_outlined:left"></mat-icon>
            </button>
          </div>
          <div class="class_detail_info-title">
            <h3 class="h3 h3--bold student_group_name" *ngIf="group?.length > 0">
              {{ getGroupName(group)?.name }}
            </h3>
            <div class="inline_text">
              <img class="avatar_img hidden-mobile" *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" />
              <h3 class="h3 h3--bold student_user_name">
                {{ student?.username }}
              </h3>
            </div>
          </div>
        </div>
        <div class="class_detail_info-buttons">
          <div class="teacher_tabs_header_buttons">
            <mat-button-toggle-group
              #activeLicenseViewValue="matButtonToggleGroup"
              [value]="selectedLicenseView"
              (change)="onSelectedLicenseViewChange(activeLicenseViewValue.value)"
            >
              <mat-button-toggle value="license-2">
                <mat-icon svgIcon="streamline_outlined:lightning" class="hidden-mobile"></mat-icon>
                {{ translatedText.student_overview_training }}
              </mat-button-toggle>
              <mat-button-toggle value="license-1">
                <mat-icon svgIcon="streamline_outlined:archery" class="hidden-mobile"></mat-icon>
                {{ translatedText.student_overview_diagnose }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
      <div class="inline_text class_detail_info_right">
        <button
          mat-flat-button
          color="unset"
          type="button"
          (click)="editStudentOverlay()"
          class="class_detail_profile_btn"
          matTooltip="{{ translatedText.class_detail_show_profile }}"
        >
          <mat-icon class="icon_position" svgIcon="streamline_filled:eye"></mat-icon
          ><span class="hidden-mobile">{{ translatedText.student_overview_profile }}</span>
        </button>
      </div>
    </div>
  </div>

  <div>
    <!-- <div class="col-md-12 col-sm-12 student_info_row">
            <div class="student_info">
                <div class="col-md-3">
                    <div class="class_header_item">
                        <div>
                            <h2 class="h2">180 <span>/ 240</span> </h2>
                            <p class="paragraph">Verfügbare Minuten zum Vorlesen</p>
                        </div>
                        <mat-icon class="icon_circle" svgIcon="streamline_outlined:mic"></mat-icon>
                   </div>
                </div>
                <div class="col-md-3">
                    <div class="class_header_item">
                        <div>
                            <h2 class="h2" [countUp]="readWords">{{readWords}}</h2>
                            <p class="paragraph">Gelesene Wörter in der aktuellen Woch</p>
                        </div>
                        <mat-icon class="icon_circle" svgIcon="streamline_outlined:book-icon"></mat-icon>
                   </div>
                </div>
                <div class="col-md-3">
                    <div class="class_header_item">
                        <div>
                            <h2 class="h2" [countUp]="generalPerformance">{{generalPerformance}}</h2>
                            <p class="paragraph">Lesepunkte</p>
                        </div>
                        <mat-icon class="icon_circle check" svgIcon="streamline_outlined:check-mark-circle-2"></mat-icon>
                   </div>
                </div>
                <div class="col-md-3">
                    <div class="class_header_item">
                        <div>
                            <h2 class="h2">Ein</h2>
                            <p class="paragraph">AutoMode</p>
                        </div>
                        <div class="chart_toggle">
                            <input type="checkbox" (change)="autoModeToggleChange($event)" id="switch" /><label for="switch"></label>
                        </div>
                   </div>
                </div>
             </div>
        </div> -->

    <!-- Diagnose chart -->
    <div [hidden]="selectedLicenseView != 'license-1'">
      <ng-container *ngIf="showProgressChart">
        <div class="student_chart">
          <div class="student_chart_header">
            <div class="group_result_chart position_left">
              <p class="paragraph paragraph--bold">
                {{ translatedText.student_overview_detail_analysis }}
              </p>
              <!-- <div class="chart_toggle">
                                <input type="checkbox" (change)="chartToggleChange($event)" id="lineChart" /><label for="lineChart"></label>
                               <p class="paragraph paragraph--bold paragraph--s">Testvergleich</p> 
                            </div> -->
            </div>
            <!-- <mat-form-field class="student_chart_selector">
                            <mat-select (valueChange)="selectedResult($event)" value="half_year">
                              <mat-option value="last_month">{{translatedText.student_overview_last_month}}</mat-option>
                              <mat-option value="half_year">{{translatedText.student_overview_half_year}}</mat-option>
                            </mat-select>
                        </mat-form-field> -->
          </div>

          <div class="chart_container">
            <!-- <ngx-charts-line-chart
                            [scheme]="colorScheme"
                            [legend]="true"
                            [showXAxisLabel]="showXAxisLabel"
                            [showYAxisLabel]="showYAxisLabel"
                            [xAxis]="xAxis"
                            [legendTitle]="legendTitle"
                            [legendPosition]="legendPosition"
                            [timeline]="timeline"
                            [results]="progressChartData"
                            [curve]="linearCurve"
                            (select)="onSelect($event)">
                        </ngx-charts-line-chart> -->
            <combo-chart-component
              [scheme]="comboBarScheme"
              [colorSchemeLine]="lineChartScheme"
              [results]="barChart"
              [animations]="animations"
              [lineChart]="lineChartData"
              [gradient]="gradient"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="showLegend"
              [legendTitle]="legendTitle"
              [legendPosition]="legendPosition"
              [showGridLines]="showGridLines"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [showRightYAxisLabel]="showYAxisLabel"
              [yAxisLabel]="yAxisLabel"
              [yAxisLabelRight]="yAxisLabelRight"
            >
            </combo-chart-component>
            <!-- <combo-custom-chart-component
                            [scheme]="comboBarScheme"
                            [colorSchemeLine]="lineChartScheme"
                            [results]="barChartGroup"
                            [animations]="animations"
                            [lineChart]="lineChartData"
                            [gradient]="gradient"
                            [xAxis]="showXAxis"
                            [yAxis]="showYAxis"
                            [legend]="showLegend"
                            [legendTitle]="legendTitle"
                            [legendPosition]="legendPosition"
                            [showGridLines]="showGridLines"
                            [showXAxisLabel]="showXAxisLabel"
                            [showYAxisLabel]="showYAxisLabel"
                            [showRightYAxisLabel]="showYAxisLabel"
                            [yAxisLabel]="yAxisLabel"
                            [yAxisLabelRight]="yAxisLabelRight"
                            [noBarWhenZero]="true"
                            [wrapTicks]="true">
                        </combo-custom-chart-component> -->
          </div>
        </div>
      </ng-container>
    </div>

    <!-- reading charts -->
    <div class="padding_right padding_left" [hidden]="selectedLicenseView != 'license-2'">
      <!-- col-md-12 col-sm-12 -->
      <ng-container *ngIf="showProgressChart">
        <div class="student_chart">
          <mat-form-field appearance="fill" class="student_chart_selector transparent-input">
            <mat-select
              hideSingleSelectionIndicator
              (valueChange)="selectedResult($event)"
              value="half_year"
              disableRipple
            >
              <mat-option value="last_month">{{ translatedText.student_overview_last_month }}</mat-option>
              <mat-option value="half_year">{{ translatedText.student_overview_half_year }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="chart-container">
            <ngx-charts-line-chart
              [scheme]="colorScheme"
              [legend]="true"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxis]="xAxis"
              [legendTitle]="legendTitle"
              [legendPosition]="legendPosition"
              [timeline]="timeline"
              [results]="progressChartData"
              [curve]="linearCurve"
              (select)="onSelect($event)"
            >
            </ngx-charts-line-chart>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- <div class="col-md-4 col-sm-12">
            <div class="student_note">
                <div class="student_note_header">
                    <p class="paragraph paragraph--bold">Notizen
                        <span>{{student?.notes?.length}}</span>
                    </p>
                    <button mat-icon-button color="unset" class="add_class" type="button" (click)="addNoteToStudent()">
                        <mat-icon class="icon_plus" svgIcon="streamline_outlined:plus"></mat-icon>
                    </button>
                </div>
                <div class="student_note_content_wrap">
                    <div class="student_note_content_empty" *ngIf="student?.notes?.length == 0 || student?.notes?.length == undefined">
                        <mat-icon class="icon_plus" svgIcon="streamline_outlined:card-icon"></mat-icon>
                        <p class="paragraph paragraph--s">Keine Notizen</p>
                    </div>
                    <ng-container *ngIf="student?.notes?.length > 0">

                    </ng-container>
                    <div class="student_note_content" *ngFor="let note of student?.notes">
                        <p class="paragraph pargraph--bold paragraph--xs">{{note?.content}}</p>
                        <div class="student_note_content_footer">
                            <p class="paragraph paragraph--bold paragraph--xs">{{note?._created | date: 'dd.MM.yyyy mm:ss'}}</p>
                            <button class="student_note_button" mat-flat-button color="unset" type="button">
                                <div class="note_dot"></div>
                                <div class="note_dot"></div>
                                <div class="note_dot"></div>
                            </button>
                        </div>
                    </div>
                </div>
             </div>
             <div class="student-license-info">
                <div class="license-box-wrap">
                    <p class="paragraph paragraph--bold headline">Verfügbare Lizenzen</p>
                    <div class="license-box" [ngClass]="diagnoseLicense?.length > 0 ? 'active' : ''">
                        <div class="student_chip student_chip__expired" *ngIf="diagnoseLicense?.length < 1">
                            <p class="paragraph">Inaktiv</p>
                        </div>
                        <div class="student_chip student_chip__deadline" *ngIf="diagnoseLicense?.length > 0">
                            <p class="paragraph">Aktiv</p>
                        </div>
                        <p class="paragraph paragraph--s">Diagnoselizenzen</p>
                    </div>

                    <div class="license-box" [ngClass]="{
                        'training-available': trainingMinutesProzent <= 82,
                        'training-warning': trainingMinutesProzent >= 83 && trainingMinutesProzent < 82,
                        'training-alarm': trainingMinutesProzent >= 91 && trainingMinutesProzent < 83}">
                        <div class="student_chip student_chip__expired" *ngIf="trainingLicense?.length < 1">
                            <p class="paragraph">Inaktiv</p>
                        </div>
                        <div class="training-minutes-line" *ngIf="trainingLicense?.length > 0">
                            <div class="training-minutes-wrap">
                                <p class="minutes-left">{{trainingMinutesTotal - trainingMinutesLeft}}</p>
                                <p class="paragraph paragraph--xl">/ {{trainingMinutesTotal}}</p>
                            </div>
                            <mat-icon class="warn" *ngIf="trainingMinutesProzent >= 91" svgIcon="streamline_outlined:warning-circle"></mat-icon>
                            <mat-icon class="medium" *ngIf="trainingMinutesProzent >= 83 && trainingMinutesProzent < 91" svgIcon="streamline_outlined:warning-triangle"></mat-icon>
                        </div>
                        <p class="paragraph paragraph--s">LaLeTu Minuten</p>
                    </div>
                    <div class="license-box">
                        <div class="student_chip student_chip__expired">
                            <p class="paragraph">Inaktiv</p>
                        </div>
                        <p class="paragraph paragraph--s">Typ #3 Lizenz</p>
                    </div>
                </div>
                <button mat-flat-button color="primary" type="button" (click)="openLicenseRequest(student?.username, student?.avatarUrl, student?._id, student?._school)">
                    <mat-icon svgIcon="streamline_outlined:license-plus"></mat-icon>
                    Lizenzen anfordern
                </button>
             </div>
        </div> -->
  </div>

  <div>
    <div>
      <div>
        <div class="toggle-tabs" [@.disabled]="true">
          <mat-tab-group
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            #tasksTabs
            (selectedTabChange)="tabChanged($event)"
            [(selectedIndex)]="selectedTabIndex"
          >
            <mat-tab label="{{ translatedText.student_overview_active_tasks }} {{ activeTasksArray?.length }}">
              <div class="teacher_tabs_header">
                <div class="teacher_tabs_header_select" [hidden]="selectedTaskView == 'table'">
                  <mat-form-field>
                    <div class="select-label">
                      <mat-label>{{ translatedText.student_overview_sort_by }}</mat-label>
                    </div>
                    <mat-select type="role" panelClass="dashboard-task-select" value="1" disableOptionCentering>
                      <mat-option value="1">{{ translatedText.student_overview_date }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <app-task-list [activeTab]="activeTab" [selectedView]="selectedTaskView" [tasks]="tasks"></app-task-list>
            </mat-tab>
            <mat-tab label="{{ translatedText.student_overview_finished_tasks }} {{ finishedTasksArray?.length }}">
              <div class="teacher_tabs_header">
                <div class="teacher_tabs_header_select" [hidden]="selectedTaskView == 'table'">
                  <mat-form-field>
                    <div class="select-label">
                      <mat-label>{{ translatedText.student_overview_sort_by }}</mat-label>
                    </div>
                    <mat-select type="role" panelClass="dashboard-task-select" value="1" disableOptionCentering>
                      <mat-option value="1">{{ translatedText.student_overview_date }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <!-- <div class="teacher_tabs_header_buttons">
                                    <mat-button-toggle-group #finishedViewValue="matButtonToggleGroup" [value]="selectedTaskView" (change)="onSelectedViewChange(finishedViewValue.value)">
                                        <mat-button-toggle value="card"><mat-icon  svgIcon="streamline_outlined:cards"></mat-icon>Kartenansicht</mat-button-toggle>
                                        <mat-button-toggle value="table"><mat-icon  svgIcon="streamline_outlined:table"></mat-icon>Tabellenansicht</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div> -->
                <!-- <button mat-flat-button color="unset" type="button" (click)="createTaskDialog()"><span [hidden]="this.groups.length > 0" matTooltipClass="create-task-tooltip"  class="create-task-tooltip-wrap" matTooltip="Zuerst Klasse anlegen"></span>  <mat-icon svgIcon="streamline_filled:add"></mat-icon>Erstellen</button> -->
              </div>
              <app-task-list [activeTab]="activeTab" [selectedView]="selectedTaskView" [tasks]="tasks"></app-task-list>
            </mat-tab>
            <mat-tab label="{{ translatedText.student_overview_expired_tasks }} {{ expiredTasksArray?.length }}">
              <div class="teacher_tabs_header">
                <div class="teacher_tabs_header_select" [hidden]="selectedTaskView == 'table'">
                  <mat-form-field>
                    <div class="select-label">
                      <mat-label>{{ translatedText.student_overview_sort_by }}</mat-label>
                    </div>
                    <mat-select type="role" panelClass="dashboard-task-select" value="1" disableOptionCentering>
                      <mat-option value="1">{{ translatedText.student_overview_date }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <!-- <div class="teacher_tabs_header_buttons">
                                    <mat-button-toggle-group #expiredViewValue="matButtonToggleGroup" [value]="selectedTaskView" (change)="onSelectedViewChange(expiredViewValue.value)">
                                        <mat-button-toggle value="card"><mat-icon  svgIcon="streamline_outlined:cards"></mat-icon>Kartenansicht</mat-button-toggle>
                                        <mat-button-toggle value="table"><mat-icon  svgIcon="streamline_outlined:table"></mat-icon>Tabellenansicht</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div> -->
                <!-- <button mat-flat-button color="unset" type="button" (click)="createTaskDialog()"><span [hidden]="this.groups.length > 0" matTooltipClass="create-task-tooltip"  class="create-task-tooltip-wrap" matTooltip="Zuerst Klasse anlegen"></span>  <mat-icon svgIcon="streamline_filled:add"></mat-icon>Erstellen</button> -->
              </div>
              <app-task-list [activeTab]="activeTab" [selectedView]="selectedTaskView" [tasks]="tasks"></app-task-list>
            </mat-tab>
            <!-- <mat-tab label="Freiwillig gelesen">
                            <app-task-list [activeTab]="activeTab" [selectedView]="selectedTaskView" [tasks]="tasks"></app-task-list>
                        </mat-tab> -->
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>
