import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { TranslationService } from 'src/app/providers/translation.service';

@Component({
  selector: 'app-books-fliter',
  templateUrl: './books-filter.component.html',
  styleUrls: ['./books-filter.component.scss'],
})
export class BooksFilterComponent implements OnInit {
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  translatedText: any;

  filterSelectedValues = {
    grade: [],
    age: [],
    //difficulty: [],
    categories: [],
    //publisher: [],
  };
  allFilters;
  numberOfSelectedFilters: number = 0;
  resetButtonDisabled: boolean = true;
  applybuttonDisabled: boolean = true;
  previousSelectedFilters;
  constructor(
    @Inject(MAT_DIALOG_DATA) public injectedData: any,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<BooksFilterComponent>,
    private translationService: TranslationService,
  ) {
    if (typeof injectedData['allFilters'] !== 'undefined') {
      this.allFilters = this.injectedData['allFilters'];
    }
    if (
      typeof injectedData['numberOfSelectedFilters'] !== 'undefined' &&
      injectedData['numberOfSelectedFilters'] !== 0
    ) {
      this.numberOfSelectedFilters =
        this.injectedData['numberOfSelectedFilters'];
    }
    if (typeof injectedData['selectedFilters'] !== 'undefined') {
      this.previousSelectedFilters = this.injectedData['selectedFilters'];
    }
  }

  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    if (this.numberOfSelectedFilters === 0) {
      this.resetFilter();
    }
  }

  applyFilters(): void {
    this.allFilters['age'].forEach((age) => {
      if (age.selected === true) {
        this.filterSelectedValues['age'].push(age.value);
      }
    });
    this.allFilters['grade'].forEach((grade) => {
      if (grade.selected === true) {
        this.filterSelectedValues['grade'].push(grade.value);
      }
    });
    this.allFilters['categories'].forEach((categories) => {
      if (categories.selected === true) {
        this.filterSelectedValues['categories'].push(categories.value);
      }
    });
    this.dialogRef.close(this.filterSelectedValues);
  }

  buttonsStateHandler(event, filter, filterGroup) {
    if (filter.selected) {
      this.numberOfSelectedFilters++;
    } else {
      this.numberOfSelectedFilters--;
    }
    if (this.numberOfSelectedFilters > 0) {
      this.resetButtonDisabled = false;
      this.applybuttonDisabled = false;
    } else {
      this.resetButtonDisabled = true;
      this.applybuttonDisabled = true;
    }
    //handles buttons if/when user opens reopens the pop up
    if (this.previousSelectedFilters != undefined) {
      if (
        this.previousSelectedFilters[filterGroup].indexOf(filter.value) > -1
      ) {
        this.applybuttonDisabled = false;
      }
    }
  }

  resetFilter(): void {
    for (const age of this.allFilters['age']) {
      age.selected = false;
    }
    for (const grade of this.allFilters['grade']) {
      grade.selected = false;
    }
    for (const category of this.allFilters['categories']) {
      category.selected = false;
    }
    this.filterSelectedValues['age'] = [];
    this.filterSelectedValues['categories'] = [];
    this.filterSelectedValues['grade'] = [];
    this.numberOfSelectedFilters = 0;
    this.resetButtonDisabled = true;
  }
}
