import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditTaskDeadlineComponent } from './edit-task-deadline.component';
import { TrialBannerModule } from 'src/app/trial-phase/trial-banner/trial-banner.module';
import { DueDateSelectorModule } from '../../../training-task/due-date-selector/due-date-selector.module';

@NgModule({
  declarations: [EditTaskDeadlineComponent],
  imports: [
    BrowserModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TrialBannerModule,
    DueDateSelectorModule,
  ],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [EditTaskDeadlineComponent],
})
export class EditTaskDeadlineModule {}
