<div class="">
  <app-not-loaded *ngIf="isOffline"></app-not-loaded>
  <div class="">
    <div class="teacher-content-container teacher-content-container--diff-margin">
      <app-license-activation-banner></app-license-activation-banner>

      <app-classes-update-banner
        *ngIf="isSchoolYearUpdateRequired && updateRequiredGroupCount >= 1"
        [classesUpdateHeading]="translatedText.classes_year_update_heading"
        [classesUpdateDescription]="translatedText.classes_year_update_desc"
        [updateButton]="translatedText.classes_update"
      >
      </app-classes-update-banner>

      <app-trial-banner
        *ngIf="trialStatus.isActive"
        [trialHeadline]="translatedText.license_trial_title"
        [trialText]="translatedText.license_trial_description"
        [expiredHeadline]="translatedText.license_trial_title_expired"
        [expiredText]="translatedText.license_trial_description_expired"
        [trialStatus]="trialStatus"
        [showExpirationDate]="true"
        [showButtons]="true"
      ></app-trial-banner>

      <h3 class="h3 h3--bold">{{ translatedText.admin_dashboard_headline }}</h3>
      <!--  <div class="row" *ngIf="groupCount == 0 && !isLoading">
        <div class="col-md-12">
          <div class="create_group-box">
            <div class="create_group-box-content">
              <h3 class="h3 h3--bold">
                {{ translatedText.admin_dashboard_teacher_creation_header }}
              </h3>
              <p class="paragraph paragraph--l">
                {{ translatedText.admin_dashboard_teacher_creation_text }}
              </p>
              <button mat-flat-button color="primary-inversion" type="button" (click)="createTeacherOverlay()">
                {{ translatedText.admin_dashboard_teacher_creation_button }}
              </button>
            </div>
            <div class="create_group-img-wrap">
              <img class="create_group-img" src="../../../assets/admin-dashboard.svg" alt="" />
            </div>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-md-8">
          <div class="admin-widget">
            <div class="admin-widget_header-wrap">
              <div class="admin-widget_header with-border" [ngClass]="{ 'admin-widget-loading-bg': isLoading }">
                <!-- skeleton loader code -->
                <div class="skeleton-widget-wrap" *ngIf="isLoading">
                  <div class="skeleton-loader bar short"></div>
                  <div class="skeleton-loader bar long"></div>
                </div>
                <div *ngIf="!isLoading">
                  <h2 class="h2 h2--bold" [countUp]="groupCount">
                    {{ groupCount }}
                  </h2>
                  <p class="paragraph paragraph--s">
                    {{ translatedText.admin_dashboard_groups }}
                  </p>
                </div>
                <div [hidden]="isLoading" class="icon-wrap">
                  <mat-icon svgIcon="streamline_outlined:class"></mat-icon>
                </div>
              </div>
              <div class="admin-widget_header" [ngClass]="{ 'admin-widget-loading-bg': isLoading }">
                <!-- skeleton loader code -->
                <div class="skeleton-widget-wrap" *ngIf="isLoading">
                  <div class="skeleton-loader bar short"></div>
                  <div class="skeleton-loader bar long"></div>
                </div>
                <div *ngIf="!isLoading">
                  <h2 class="h2 h2--bold" [countUp]="studentCount">
                    {{ studentCount }}
                  </h2>
                  <p class="paragraph paragraph--s">
                    {{ translatedText.admin_dashboard_students }}
                  </p>
                </div>
                <div [hidden]="isLoading" class="icon-wrap">
                  <mat-icon svgIcon="streamline_outlined:student-admin"></mat-icon>
                </div>
              </div>
            </div>

            <div class="admin-widget_link">
              <a class="links links--bold" [routerLink]="['/admin-group-list']">
                {{ translatedText.admin_dashboard_show_all_groups }}</a
              >
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="admin-widget">
            <div class="admin-widget_header" [ngClass]="{ 'admin-widget-loading-bg': isLoading }">
              <!-- skeleton loader code -->
              <div class="skeleton-widget-wrap" *ngIf="isLoading">
                <div class="skeleton-loader bar short"></div>
                <div class="skeleton-loader bar long"></div>
              </div>
              <div *ngIf="!isLoading">
                <h2 class="h2 h2--bold" [countUp]="teacherCount">
                  {{ teacherCount }}
                </h2>
                <p class="paragraph paragraph--s">
                  {{ translatedText.admin_dashboard_teachers }}
                </p>
              </div>
              <div [hidden]="isLoading" class="icon-wrap">
                <mat-icon svgIcon="streamline_outlined:user-star"></mat-icon>
              </div>
            </div>
            <div class="admin-widget_link">
              <a class="links links--bold" [routerLink]="['/admin-teacher-list']">
                {{ translatedText.admin_dashboard_show_all_teachers }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="admin-license-widget">
            <div class="admin-license-widget_header">
              <p class="paragraph paragraph--bold paragraph--m">
                {{ translatedText.admin_dashboard_licenses }}
              </p>
            </div>
            <mat-tab-group
              class="rounded_tabs rounded-tabs--license"
              [ngClass]="{ 'admin-license-tabs-loading': isLoading }"
            >
              <mat-tab
                label="{{ translatedText.admin_dashboard_diagnostic_licenses }} ({{
                  checkForUnlimitedDiagnosticLicense('di') ? '∞' : getFormattedLicenseAmount('di', 'total')
                }})"
              >
                <div class="license_amount">
                  <div class="admin-license-widget-total" [ngClass]="{ 'admin-widget-loading-bg': isLoading }">
                    <!-- skeleton loader code -->
                    <div class="skeleton-widget-wrap" *ngIf="isLoading">
                      <div class="skeleton-widget-wrap" *ngIf="isLoading">
                        <div class="skeleton-loader bar short"></div>
                        <div class="skeleton-loader bar long"></div>
                      </div>
                    </div>
                    <div *ngIf="!isLoading">
                      <h2 class="h2 h2--bold" *ngIf="checkForUnlimitedDiagnosticLicense('di')">∞</h2>
                      <h2
                        class="h2 h2--bold"
                        [countUp]="getLicenseAmount('di', 'total')"
                        *ngIf="!checkForUnlimitedDiagnosticLicense('di')"
                      >
                        {{ getFormattedLicenseAmount('di', 'total') }}
                      </h2>
                      <p class="paragraph paragraph--s">
                        {{ translatedText.admin_dashboard_licenses_overall }}
                      </p>
                    </div>
                  </div>

                  <div class="admin-license-widget-unused" [ngClass]="{ 'admin-widget-loading-bg': isLoading }">
                    <!-- skeleton loader code -->
                    <div class="skeleton-widget-wrap" *ngIf="isLoading">
                      <div class="skeleton-widget-wrap" *ngIf="isLoading">
                        <div class="skeleton-loader bar short"></div>
                        <div class="skeleton-loader bar long"></div>
                      </div>
                    </div>
                    <div *ngIf="!isLoading">
                      <h2 class="h2 h2--bold" *ngIf="checkForUnlimitedDiagnosticLicense('di')">∞</h2>
                      <h2
                        class="h2 h2--bold"
                        [countUp]="getLicenseAmount('di', 'left')"
                        *ngIf="!checkForUnlimitedDiagnosticLicense('di')"
                      >
                        {{ getFormattedLicenseAmount('di', 'left') }}
                      </h2>
                      <p class="paragraph paragraph--s">
                        {{ translatedText.admin_dashboard_unused_licenses }}
                      </p>
                    </div>
                  </div>

                  <div class="admin-license-widget-used" [ngClass]="{ 'admin-widget-loading-bg': isLoading }">
                    <!-- skeleton loader code -->
                    <div class="skeleton-widget-wrap" *ngIf="isLoading">
                      <div class="skeleton-widget-wrap" *ngIf="isLoading">
                        <div class="skeleton-loader bar short"></div>
                        <div class="skeleton-loader bar long"></div>
                      </div>
                    </div>
                    <div *ngIf="!isLoading">
                      <h2
                        class="h2 h2--bold"
                        [countUp]="getLicenseAmount('di', 'total') - getLicenseAmount('di', 'left')"
                      >
                        {{ getLicenseAmount('di', 'total') - getLicenseAmount('di', 'left') }}
                      </h2>
                      <p class="paragraph paragraph--s">
                        {{ translatedText.admin_dashboard_used_licenses }}
                      </p>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab
                label="{{ translatedText.admin_dashboard_traning_licenses }} ({{
                  checkForUnlimitedDiagnosticLicense('fo') ? '∞' : getFormattedLicenseAmount('fo', 'total')
                }})"
              >
                <div class="license_amount">
                  <div class="admin-license-widget-total">
                    <h2 class="h2 h2--bold" *ngIf="checkForUnlimitedDiagnosticLicense('fo')">∞</h2>
                    <h2
                      class="h2 h2--bold"
                      [countUp]="getLicenseAmount('fo', 'total')"
                      *ngIf="!checkForUnlimitedDiagnosticLicense('fo')"
                    >
                      {{ getFormattedLicenseAmount('fo', 'total') }}
                    </h2>
                    <p class="paragraph paragraph--s">
                      {{ translatedText.admin_dashboard_licenses_overall }}
                    </p>
                  </div>

                  <div class="admin-license-widget-unused">
                    <h2 class="h2 h2--bold" *ngIf="checkForUnlimitedDiagnosticLicense('fo')">∞</h2>
                    <h2
                      class="h2 h2--bold"
                      [countUp]="getLicenseAmount('fo', 'left')"
                      *ngIf="!checkForUnlimitedDiagnosticLicense('fo')"
                    >
                      {{ getLicenseAmount('fo', 'left') }}
                    </h2>
                    <p class="paragraph paragraph--s">
                      {{ translatedText.admin_dashboard_unused_licenses }}
                    </p>
                  </div>

                  <div class="admin-license-widget-used">
                    <h2
                      class="h2 h2--bold"
                      [countUp]="getLicenseAmount('fo', 'total') - getLicenseAmount('fo', 'left')"
                    >
                      {{ getLicenseAmount('fo', 'total') - getLicenseAmount('fo', 'left') }}
                    </h2>
                    <p class="paragraph paragraph--s">
                      {{ translatedText.admin_dashboard_used_licenses }}
                    </p>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="licensesToSchool.length == 0 && !licensesToSchoolLoading && !trialStatus.isActive">
        <div class="col-md-12">
          <div class="create_group-box">
            <div class="create_group-box-content">
              <h3 class="h3 h3--bold">
                {{ translatedText.admin_dashboard_license_needed_header }}
              </h3>
              <p class="paragraph paragraph--l">
                {{ translatedText.admin_dashboard_license_needed_text }}
              </p>
              <button
                mat-flat-button
                color="primary-inversion"
                type="button"
                (click)="goToLink('https://www.klett.de/inhalt/272087')"
              >
                {{ translatedText.admin_dashboard_license_needed_button }}
              </button>
            </div>
            <div class="create_group-img-wrap">
              <img class="more-licenses-img" src="../../../assets/more-licenses.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
