import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
  constructor(private router: Router) {}
  environment = environment;

  ngOnInit() {}

  navigateToOrderProcessingAgreement(): void {
    this.router.navigate(['/avv']);
  }
}
